/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { TableColumn } from "components/table/table.component";
import { Accessor } from "react-table";
import { IndividualCompositeVisibilityDataFragment } from "graphql/hasura/types-and-hooks";
import { Link } from "react-router-dom";
import _ from "lodash";

export type VisibilityTableSchema = Omit<
  IndividualCompositeVisibilityDataFragment,
  "__typename"
>;

export const VisibilityTableSchemaMap: Record<
  keyof VisibilityTableSchema,
  "string" | "number"
> = {
  Rnid: "string",
  // TIN_NO: string;
  UraTinNo: "string",
  // FIRST_NAME: string;
  Firstname: "string",
  // MIDDLE_NAME: string;
  MiddleName: "string",
  // SUR_NAME: string;
  Surname: "string",
  // EMAIL_ID: string;
  EmailId: "string",
  // BIRTH_DT: string;
  BirthDt: "string",
  // @ts-ignore
  Visible: "boolean",
};

export const getAccessorType = (
  accessor:
    | keyof VisibilityTableSchema
    | Accessor<VisibilityTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return VisibilityTableSchemaMap[accessor];
};

export const VisibilityTableColumns: TableColumn<VisibilityTableSchema>[] = [
  {
    Header: "TIN",
    // accessor: "TIN_NO",
    accessor: "UraTinNo",
    align: "center",
    Cell: ({ value }) => {
      return (
        <Link to={`/individuals/${value}/identification`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "First Name",
    // accessor: "FIRST_NAME",
    accessor: "Firstname",
    align: "left",
  },
  {
    Header: "Rnid",
    // accessor: "FIRST_NAME",
    accessor: "Rnid",
    hiddenColumn: true,
  },
  {
    Header: "Middle Name",
    // accessor: "MIDDLE_NAME",
    accessor: "MiddleName",
    align: "left",
  },
  {
    Header: "Surname",
    // accessor: "SUR_NAME",
    accessor: "Surname",
    align: "left",
  },
  {
    Header: "Date of Birth",
    // accessor: "BIRTH_DT",
    accessor: "BirthDt",
    align: "left",
    format: "date",
  },
  {
    Header: "Email Address",
    // accessor: "EMAIL_ID",
    accessor: "EmailId",
    align: "right",
  },
  // @ts-ignore
  { Header: "", accessor: "Visible", hiddenColumn: true },
];
