/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import Autocomplete from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core";
import Search from "@material-ui/icons/Search";

// Components
import { TextField as TextFieldBase } from "components/text-field/text-field.component";

const SearchBox = withStyles({
  popupIndicatorOpen: {
    transform: "none",
  },
})(Autocomplete) as typeof Autocomplete;

const TextField = withStyles((theme: any) => ({
  root: {
    "& label.Mui-focused:not(.Mui-error)": {
      color: theme.searchBox.labelTextColor,
    },
    "& .MuiFilledInput-root": {
      border: theme.searchBox.input.border,
      "& .MuiInputBase-input": {
        color: theme.searchBox.input.textColor,
      },
    },
    "& .MuiFilledInput-underline": {
      "&:after": {
        borderColor: theme.searchBox.inputUnderline.borderColor,
      },
    },
    "& .MuiFormHelperText-root.Mui-focused:not(.Mui-error)": {
      color: theme.searchBox.helperTextColor,
    },
  },
}))(TextFieldBase);

const SearchIcon = withStyles((theme: any) => ({
  root: {
    color: theme.searchBox.iconColor,
  },
}))(Search);

export default {
  SearchBox,
  TextField,
  SearchIcon,
};
