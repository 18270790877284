// Dependencies
import React, { useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rpt_NonIndividualBalanceSheetArgs,
  useRpt_NonIndividualBalanceSheetDetailsQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// components
import { columns as reportColumns } from "./balance-sheet-main.schema";

// Assets
import SC from "./balance-sheet-main.style";

interface InputProps {
  tin: string;
}

const BalanceSheetMain: React.FC<InputProps> = ({ tin }) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Rpt_NonIndividualBalanceSheetArgs =
    {
      where: { TinNo: { _eq: tin } },
    };

  const { data, loading, error } = useRpt_NonIndividualBalanceSheetDetailsQuery(
    {
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !tin,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
      },
    }
  );

  useAppErrorHandler(error);

  const columns = useMemo(() => reportColumns, []);

  return (
    <>
      {loading && <SC.ProgressIndicator open />}
      <SC.Table
        stickyHeader
        title="Balance Sheet"
        actionsOnRight={[]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        persistenceId="aeeb4261-169b-48eb-98e7-7f362c89161d"
        columns={columns}
        data={data?.rtcs_db_FnRPT_NonIndividualBalanceSheet || []}
      />
    </>
  );
};

export default BalanceSheetMain;
