import { TableColumn } from "components/table/table.component";
import { KccaCustomerSecondaryOwnerFragment } from "graphql/hasura/types-and-hooks";

export type KccaCustomerSecondaryOwnerTableSchema = Omit<
  KccaCustomerSecondaryOwnerFragment,
  "__typename"
>;

export const kccaCustomerSecondaryOwnerTableColumns: TableColumn<KccaCustomerSecondaryOwnerTableSchema>[] =
  [
    {
      Header: "Applicant Type",
      accessor: "CustomerApplicantType",
      align: "center",
      format: "string",
    },
    {
      Header: "Business Type",
      accessor: "CustomerBusinessType",
      align: "center",
      format: "string",
    },
    {
      Header: "Customer Type",
      accessor: "CustomerType",
      align: "center",
      format: "string",
    },
    {
      Header: "Street Name",
      accessor: "StreetName",
      align: "center",
      format: "string",
    },
    {
      Header: "Residential Address Village",
      accessor: "ResidentialAddressVillage",
      align: "center",
      format: "string",
    },
    {
      Header: "Residential Address Subcounty",
      accessor: "ResidentialAddressSubCounty",
      align: "center",
      format: "string",
    },
    {
      Header: "Residential Address County",
      accessor: "ResidentialAddressCounty",
      align: "center",
      format: "string",
    },
    {
      Header: "Residential Address Parish",
      accessor: "ResidentialAddressParish",
      align: "center",
      format: "string",
    },
    {
      Header: "Residential Address District",
      accessor: "ResidentialAddressDistrict",
      align: "center",
      format: "string",
    },
    {
      Header: "Email",
      accessor: "Email",
      align: "center",
      format: "string",
    },
    {
      Header: "Mobile No.",
      accessor: "Mobile",
      align: "right",
      format: "string",
    },
    {
      Header: "National ID No.",
      accessor: "IdentificationNationalId",
      align: "center",
      format: "string",
    },
    {
      Header: "NSSF No.",
      accessor: "IdentificationNssfNumber",
      align: "center",
      format: "string",
    },
    {
      Header: "Mother Maiden Name",
      accessor: "MotherMaidenName",
      align: "left",
      format: "string",
    },
  ];
