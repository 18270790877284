/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import React from "react";
import { Link } from "react-router-dom";

// Assets
import SC from "./administration-menu-item.styles";

type Item = {
  id: string;
  name: string;
  url?: string;
  children?: Item[];
};

interface AdministrationMenuItemProps {
  id: string;
  name: string;
  url?: string;
  parent?: boolean;
  childrenItems?: Item[];
  onClick: (id: string) => void;
}

const AdministrationMenuItem = React.forwardRef<
  any,
  AdministrationMenuItemProps
>(
  (
    {
      id: parentId,
      name: parentName,
      url: parentUrl,
      parent,
      childrenItems: parentChildrenItems = [],
      onClick,
    },
    ref
  ) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const isSubMenuOpen = Boolean(anchorEl);
    const hasChildrenItems = parentChildrenItems?.length || false;
    const isLeafNode = !hasChildrenItems;

    const handleClick = (event: React.MouseEvent<any>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
      if (isLeafNode) {
        onClick(parentId);
      }
    };

    return (
      <SC.AdministrationMenuItem
        isOpen={isSubMenuOpen}
        ref={ref}
        disableRipple
        onClick={handleClick}
        component={hasChildrenItems ? undefined : Link}
        to={hasChildrenItems ? undefined : parentUrl}
        parent={parent}
      >
        <SC.ItemsContainer>
          <SC.ParentName component="span">{parentName}</SC.ParentName>
          {hasChildrenItems && <SC.ArrowIcon />}
        </SC.ItemsContainer>
        {hasChildrenItems && (
          <>
            <SC.MenuContent
              anchorEl={anchorEl}
              open={isSubMenuOpen}
              getContentAnchorEl={null}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              PaperProps={{
                elevation: 4,
              }}
            >
              {/* reset pointer event here so that the menu items could receive mouse events */}
              <SC.Content>
                {parentChildrenItems.map((item: Item) => {
                  const { id, name, url, children } = item;
                  return (
                    <AdministrationMenuItem
                      key={id}
                      id={id}
                      name={name}
                      url={url}
                      childrenItems={children}
                      onClick={onClick}
                    />
                  );
                })}
              </SC.Content>
            </SC.MenuContent>
          </>
        )}
      </SC.AdministrationMenuItem>
    );
  }
);

export default AdministrationMenuItem;
