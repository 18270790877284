/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import preval from "preval.macro";

// GraphQL
import { useGetServerInfoQuery } from "graphql/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Assets
import SC from "./revision.styles";
import packageJson from "../../../package.json";

const BUILD_TIME = preval`module.exports = new Date().getTime();`;

export const VersionNumber: React.FC = () => {
  const uiBuildTime = new Date(BUILD_TIME).toISOString();
  const { data, error } = useGetServerInfoQuery();
  const gqlBuildTime = data?.GetServerInfo.BuildTime ?? "...";

  useAppErrorHandler(error);

  return (
    <SC.Box>
      <SC.Title>Revision</SC.Title>
      <SC.TreeView disableSelection defaultExpanded={["1"]}>
        <SC.TreeItem nodeId="1" icon="RTCS" label={packageJson.version}>
          {/* <SC.TreeItem nodeId="2" icon="Version" label={packageJson.version} /> */}
          <SC.TreeItem nodeId="3" icon="UI" label={uiBuildTime} />
          <SC.TreeItem nodeId="4" icon="GraphQL" label={gqlBuildTime} />
        </SC.TreeItem>
      </SC.TreeView>
    </SC.Box>
  );
};

export default VersionNumber;
