// Dependencies
import { useReactiveVar } from "@apollo/client";

import client from "./client";
import {
  TOKEN_KEY,
  AuthenticationData,
  authenticationDataVar,
} from "./authentication.utils";

export const useAuthenticatioData = (): AuthenticationData => {
  const authData = useReactiveVar(authenticationDataVar);
  return authData;
};

export const logIn = (token: string): void => {
  sessionStorage.setItem(TOKEN_KEY, token);
  const authData: AuthenticationData = {
    isLoggedIn: !!token,
    token,
  };
  authenticationDataVar(authData);
};

export const logOut = (): void => {
  sessionStorage.removeItem(TOKEN_KEY);
  const authData: AuthenticationData = {
    isLoggedIn: false,
    token: null,
  };
  authenticationDataVar(authData);
  client.clearStore();
};

export default {
  authenticationDataVar,
  useAuthenticatioData,
  logIn,
  logOut,
};
