import { TableColumn } from "components/table/table.component";
import {
  KccaCustomerPrimaryOwnerFragment,
  PropertiesValuationFragment,
} from "graphql/hasura/types-and-hooks";

export type KccaCustomerPrimaryOwnerTableSchema = Omit<
  KccaCustomerPrimaryOwnerFragment,
  "__typename"
>;

export const KccaColumns: TableColumn<
  PropertiesValuationFragment | Record<string, unknown>
>[] = [
  {
    Header: "Source",
    accessor: "source",
    align: "center",
    format: "string",
  },
  {
    Header: "Parameter",
    accessor: "parameter",
    align: "left",
  },
  {
    Header: "Value",
    accessor: "value",
    align: "center",
    format: "currency",
  },
];
