// Dependencies
/* eslint-disable no-alert */
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rcapture_RpTpropertiesArgs,
  useRCapturePropertyDetailsQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

// Utils
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import { columns } from "./property-details.schema";

// Assets
import SC from "./property-details.styles";

const TABLE_NAME = "rtcs_db_Fnrcapture_RPTproperties";

const RCapturePropertyDetails: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  // Fetch Selected Property
  const queryVariables: Query_RootRtcs_Db_Ph_Rcapture_RpTpropertiesArgs = {
    where: { Id: { _eq: id } },
    limit: 1,
  };

  // Property Details
  const { data, error, loading } = useRCapturePropertyDetailsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !id,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const reportData = useMemo(() => {
    if (data?.rtcs_db_Fnrcapture_RPTproperties.length === 0) return [];

    const propertyData = data?.rtcs_db_Fnrcapture_RPTproperties[0];

    if (!propertyData) return [];

    const newData = _.omit(propertyData, "__typename");

    const newList = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(newData)) {
      newList.push({
        question: key,
        answertext: value,
      });
    }

    return newList;
  }, [data]);

  return (
    <>
      <SC.ProgressIndicator open={loading || exportLoading} />
      <SC.Breadcrumb label="MDA" />
      <SC.Table
        title="Propert Details"
        actionsOnRight={[]}
        onAction={() => alert("under construction")}
        persistenceId="77aeeae9-12fb-4e43-96f3-18684daf7a4b"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        isPaginationHidden
        initialRowsPerPage={reportData.length}
        data={reportData}
        exportData={exportDataFn}
      />
    </>
  );
};

export default RCapturePropertyDetails;
