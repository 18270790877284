import React from "react";
import { CLIENT_NAME } from "graphql/client";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import {
  Query_RootRtcs_Db_Ph_Kcca_CustomerBusinessAssociateArgs,
  useKccaIndividualAffiliatesQuery,
} from "graphql/hasura/types-and-hooks";
import { affiliatesTableColumnsKCCA } from "../affiliates-tab.schema";

import SC from "./kcca-affiliates-table.styles";

export interface KccaAffiliatesProps {
  customerId?: string;
}

const KccaAffiliates: React.FC<KccaAffiliatesProps> = ({ customerId }) => {
  const kccaQueryVariables: Query_RootRtcs_Db_Ph_Kcca_CustomerBusinessAssociateArgs =
    {
      where: { CustomerId: { _eq: customerId?.split(",") } },
    };

  const { data: KccaData } = useKccaIndividualAffiliatesQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customerId,
    variables: {
      params: jsonToStringQueryVariables(kccaQueryVariables ?? {}),
    },
  });

  const kccaAffiliates = KccaData?.rtcs_db_Fnkcca_CustomerReference.map(
    (affiliate) => {
      return { ...affiliate, relationship: "Reference" };
    }
  );

  const businessAssociates =
    KccaData?.rtcs_db_Fnkcca_CustomerBusinessAssociate.map((affiliate) => {
      return { ...affiliate, relationship: "Business Associate" };
    });

  const contactPersons = KccaData?.rtcs_db_Fnkcca_CustomerContactPerson.map(
    (affiliate) => {
      return { ...affiliate, relationship: "Contact Person" };
    }
  );

  const guardians = KccaData?.rtcs_db_Fnkcca_CustomerGuardian.map(
    (affiliate) => {
      return { ...affiliate, relationship: "Guardian" };
    }
  );

  return (
    <SC.Table
      title="Kampala City Council Authority (KCCA)"
      persistenceId="32770e1e-7f6a-423d-be3c-5298c9addade"
      actionsOnRight={[]}
      onAction={() => {}}
      columns={affiliatesTableColumnsKCCA}
      data={
        [
          ...(kccaAffiliates ?? []),
          ...(businessAssociates ?? []),
          ...(contactPersons ?? []),
          ...(guardians ?? []),
        ] ?? []
      }
    />
  );
};

export default KccaAffiliates;
