import {
  withStyles,
  Grid as GridBase,
  Box as BoxBase,
  Typography as TypographyBase,
  Container as ContainerBase,
  Divider as DividerBase,
} from "@material-ui/core";

import MapTableContainerBase from "components/map-table-container/map-table-container.component";
import SwitchViewsBase from "components/switch-views/switch-views.component";
import LabelKeyValueBase from "components/label-key-value/label-key-value.component";
import MapContainerBase from "components/map-container/map-container.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

const Grid = GridBase;
const Box = withStyles({
  root: {
    textAlign: "center",
  },
})(BoxBase);

const Typography = TypographyBase;

const SwitchViews = SwitchViewsBase;

const LabelKeyValue = withStyles({
  root: {
    alignItems: "center",
  },
})(LabelKeyValueBase);

const Container = withStyles({
  root: {
    padding: 0,
  },
})(ContainerBase);

const Divider = withStyles({
  root: {
    color: "red",
  },
})(DividerBase);

const MapContainer = MapContainerBase;

const MapTableContainer = MapTableContainerBase;

export default {
  Grid,
  Box,
  Typography,
  SwitchViews,
  LabelKeyValue,
  Container,
  Divider,
  MapContainer,
  MapTableContainer,
  ProgressIndicator,
};
