/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { withStyles, Box, Button as ButtonBase } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import CancelCircleIcon from "@material-ui/icons/Cancel";
import CheckCircle from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MuiDialogActions from "@material-ui/core/DialogActions";

const GroupForm = withStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
})(Box);

const FormActions = withStyles({
  root: {
    justifyContent: "left",
  },
})(MuiDialogActions);

const Button = withStyles((theme: any) => ({
  root: {
    height: "1.8rem",
    color: theme.table.toolbarActions.iconColor,
    borderColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      borderColor: theme.table.toolbarActions.iconColor,
    },
  },
  contained: {
    color: theme.palette.background.default,
    backgroundColor: theme.table.toolbarActions.iconColor,
    "&:hover": {
      backgroundColor: theme.table.toolbarActions.iconColor,
    },
  },
  label: {
    textTransform: "capitalize",
  },
}))(ButtonBase);

export default {
  GroupForm,
  FormActions,
  EditIcon,
  CancelCircleIcon,
  CheckCircle,
  ErrorOutlineIcon,
  Button,
};
