// Dependencies
import { withStyles, Box as BoxBase, Typography } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";

const Box = withStyles({})(BoxBase);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Title = withStyles((theme: any) => ({
  root: {
    fontWeight: theme.table.toolbar.fontWeight,
    fontSize: theme.table.toolbar.fontSize,
    textAlign: "left",
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(3),
    opacity: 0.8,
  },
}))(Typography);

export default {
  Box,
  RoomIcon,
  Title,
};
