/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  RouteProps,
  Redirect,
} from "react-router-dom";
import { useWindowHeight } from "@react-hook/window-size";
import _ from "lodash";

// Components
import BannerNavigation from "components/banner-navigation/banner-navigation.component";

// Pages
import HomePage from "pages/home/home.component";
import MyProfilePage from "pages/my-profile/my-profile.component";
import UserManagementPage from "pages/user-management/user-management.component";
import GroupManagementPage from "pages/group-management/group-management.component";
import RoleManagementPage from "pages/role-management/role-management.component";
import HasuraPoc from "pages/hasura-poc/hasura-poc.component";
import IndividualPage from "pages/individual/individual.component";
import NonIndividualPage from "pages/non-individual/non-individual.component";
import PropertiesPage from "pages/properties/properties.component";
import ReportsMainPage from "pages/reports/main/main.component";
import IndividualTabPage from "pages/individual-tab/main/individual-tab.component";
import NonIndividualTabPage from "pages/non-individual-tab/main/non-individual-tab.component";
import PropertiesTabPage from "pages/properties-tab/main/main.component";
import VisibilityPage from "pages/visibility/visibility.component";
import RevisionPage from "pages/revision/revision.component";
import TaxExemptOrganisationPage from "pages/tax-exempt-organisation/tax-exempt-organisation.component";
import TaxpayersWithNoReturns from "pages/reports/taxpayers-with-no-returns/individuals/individuals.component";
import TaxpayersNoReturnsNonIndividuals from "pages/reports/taxpayers-with-no-returns/non-individuals/non-individuals.component";
import TaxpayersWithUndeclaredIncome from "pages/reports/taxpayers-with-undeclared-income/individuals/individuals.component";
import TaxpayersWithUndeclaredIncomeNonIndividuals from "pages/reports/taxpayers-with-undeclared-income/non-individuals/non-individuals.component";
import TaxpayersWithUnderreportedIncome from "pages/reports/taxpayers-with-underreported-income/individuals/individuals.component";
import TaxpayersWithUnderreportedIncomeNonIndividuals from "pages/reports/taxpayers-with-underreported-income/non-individuals/non-individuals.component";
import IndividualsOwnersWithNoTin from "pages/reports/unregistered-landlords/individuals/individuals.component";
import NonIndividualsOwnersWithNoTin from "pages/reports/unregistered-landlords/non-individuals/non-individuals.component";
import UgandaRegistration from "pages/reports/ursb/uganda-registration/uganda-registration.component";
import IndividualRegistration from "pages/reports/ura/individual-registration/individual-registration.component";
import IndividualTaxDetail from "pages/reports/ura/individual-tax-detail/individual-tax-detail.component";
import NonIndividualRegistration from "pages/reports/ura/non-individual-registration/non-individual-registration.component";
import NonIndividualTax from "pages/reports/ura/non-individual-tax-detail/non-individual-tax-detail.component";
import MlhudRegistration from "pages/reports/mlhud/mlhud-registration.component";
import CustomerRegistration from "pages/reports/kcca/customer-registration/customer-registration.component";
import PropertyRegistration from "pages/reports/kcca/property-registration/property-registration.component";
import WaterAndSewage from "pages/reports/nwsc/water-and-sewage.component";
import UmemeSubscriberAndProperty from "pages/reports/umeme/subscriber-and-property/subscriber-and-property.component";
import RCaptureProperties from "pages/reports/rcapture/properties/properties.component";
import RCapturePropertyDashboard from "pages/reports/rcapture/property-dashboard/property-dashboard.component";
import RCaptureUnitTypes from "pages/reports/rcapture/unittypes/unittypes.component";
import RCaptureLessors from "pages/reports/rcapture/lessors/lessors.component";
import RCaptureTenants from "pages/reports/rcapture/tenants/tenants.component";
import RCapturePropertyDetails from "pages/reports/rcapture/property-details/property-details.component";
import NiraRegistration from "pages/reports/nira/nira-registration.component";
import ExcessiveOwnerOccupancy from "pages/reports/kcca-ura/report.component";
import LandlordIncomeSummary from "pages/reports/declared-vs-reported/landlord-income-summary/landlord-income-summary.component";
import LandlordIncomeDetail from "pages/reports/declared-vs-reported/landlord-income-detail/landlord-income-detail.components";
import TenantIncomeDetail from "pages/reports/declared-vs-reported/tenant-income-detail/tenant-income-detail.component";
import OfficersAndOwnersRegistration from "pages/reports/ura/officers-and-owners/officers-and-owners.component";
import AssociatedRegisteredIndividualsToNonIndividuals from "pages/reports/ura/associated-registered-individuals-to-nonindividuals/associated-registered-individuals-to-nonindividuals.component";
import AssociatedRegisteredNonIndividualsToNonIndividuals from "pages/reports/ura/associated-registered-nonindividuals-to-nonindividuals/associated-registered-nonindividuals-to-nonindividuals.component";
import TenantRentalPayments from "pages/reports/ura/tenant-rental-payments/tenant-rental-payments.component";
import MobilePhoneNumbers from "pages/reports/ucc/mobile-phone-numbers/mobile-phone-numbers.component";

import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";

// Assets
import SC from "./main.styles";

const MainPage: React.FC = () => {
  const pageRef = React.useRef<HTMLDivElement>(null);
  const pageContentRef = React.useRef<HTMLDivElement>(null);

  const pageRefPosition = pageRef.current?.getBoundingClientRect();
  const pageContentPosition = pageContentRef.current?.getBoundingClientRect();

  const pagePositionY = pageRefPosition?.y ?? 0;
  const pageContentPositionY = pageContentPosition?.y ?? 0;

  const windowHeight = useWindowHeight();
  const pageHeight = windowHeight - pagePositionY;
  const pageContentHeight = windowHeight - pageContentPositionY;

  const match = useRouteMatch();
  const appPermissionValidator = useAppPermissionValidator();
  const routes: RouteProps[] = [
    {
      path: `${match.path}hasura-poc`,
      exact: true,
      strict: true,
      component: HasuraPoc,
    },
    {
      path: `${match.path}my-profile`,
      exact: true,
      strict: true,
      component: MyProfilePage,
    },
  ];

  if (appPermissionValidator) {
    if (appPermissionValidator(APP_PERMISSION.HOME_VIEW)) {
      routes.push({
        path: `${match.path}home`,
        exact: true,
        strict: true,
        component: HomePage,
      });
    }

    if (appPermissionValidator(APP_PERMISSION.INDIVIDUALS_VIEW)) {
      routes.push(
        {
          path: `${match.path}individuals`,
          exact: true,
          strict: true,
          component: IndividualPage,
        },
        {
          path: `${match.path}individuals/:repId/`,
          strict: true,
          component: IndividualTabPage,
        }
      );
    }

    if (appPermissionValidator(APP_PERMISSION.NON_INDIVIDUALS_VIEW)) {
      routes.push(
        {
          path: `${match.path}non-individuals`,
          exact: true,
          strict: true,
          component: NonIndividualPage,
        },
        {
          path: `${match.path}non-individuals/:repId/`,
          strict: true,
          component: NonIndividualTabPage,
        }
      );
    }

    if (appPermissionValidator(APP_PERMISSION.PROPERTIES_VIEW)) {
      routes.push(
        {
          path: `${match.path}properties`,
          exact: true,
          strict: true,
          component: PropertiesPage,
        },
        {
          path: `${match.path}properties/:repId/`,
          strict: true,
          component: PropertiesTabPage,
        }
      );
    }

    if (appPermissionValidator(APP_PERMISSION.REPORTS)) {
      routes.push(
        {
          path: `${match.path}reports`,
          exact: true,
          strict: true,
          component: ReportsMainPage,
        },
        {
          path: [
            `${match.path}reports/MDA`,
            `${match.path}reports/MDA/URA`,
            `${match.path}reports/MDA/NWSC`,
            `${match.path}reports/MDA/UMEME`,
            `${match.path}reports/MDA/KCCA`,
            `${match.path}reports/MDA/URSB`,
            `${match.path}reports/MDA/MLHUD`,
            `${match.path}reports/MDA/UCC`,
            `${match.path}reports/MDA/rCAPTURE`,
            `${match.path}reports/MDA/rCAPTURE/unittypes`,
            `${match.path}reports/MDA/rCAPTURE/lessors`,
            `${match.path}reports/MDA/rCAPTURE/tenants`,
          ],
          exact: true,
          strict: true,
          render: () => <Redirect to={`${match.path}reports`} />,
        },
        {
          path: [
            `${match.path}reports/analytics`,
            `${match.path}reports/analytics/:report`,
            `${match.path}reports/analytics/declared-vs-reported-income/tenant`,
            `${match.path}reports/analytics/declared-vs-reported-income/tenant/:landlordTin`,
            `${match.path}reports/analytics/declared-vs-reported-income/tenant/:landlordTin/:taxYear`,
            `${match.path}reports/analytics/declared-vs-reported-income/landlord`,
            `${match.path}reports/analytics/declared-vs-reported-income/landlord/:landlordTin`,
          ],
          exact: true,
          strict: true,
          render: () => <Redirect to={`${match.path}reports`} />,
        },
        {
          path: `${match.path}reports/MDA/URA/individual-registration`,
          exact: true,
          strict: true,
          component: IndividualRegistration,
        },
        {
          path: `${match.path}reports/MDA/URA/individual-tax-detail`,
          exact: true,
          strict: true,
          component: IndividualTaxDetail,
        },
        {
          path: `${match.path}reports/MDA/URA/non-individual-registration`,
          exact: true,
          strict: true,
          component: NonIndividualRegistration,
        },
        {
          path: `${match.path}reports/MDA/URA/non-individual-tax-detail`,
          exact: true,
          strict: true,
          component: NonIndividualTax,
        },
        {
          path: `${match.path}reports/MDA/URA/tenant-reported-rent-paid`,
          exact: true,
          strict: true,
          component: TenantRentalPayments,
        },
        {
          path: `${match.path}reports/MDA/UCC/mobile-phone-numbers`,
          exact: true,
          strict: true,
          component: MobilePhoneNumbers,
        },
        {
          path: `${match.path}reports/MDA/rCAPTURE/properties`,
          exact: true,
          strict: true,
          component: RCaptureProperties,
        },
        {
          path: `${match.path}reports/MDA/rCAPTURE/properties/:propertyId`,
          exact: true,
          strict: true,
          component: RCapturePropertyDashboard,
        },
        {
          path: `${match.path}reports/MDA/rCAPTURE/properties/:id/details`,
          // exact: true,
          strict: true,
          component: RCapturePropertyDetails,
        },
        {
          path: `${match.path}reports/MDA/rCAPTURE/unittypes/:unittypeId`,
          exact: true,
          strict: true,
          component: RCaptureUnitTypes,
        },
        {
          path: `${match.path}reports/MDA/rCAPTURE/lessors/:lessorId`,
          exact: true,
          strict: true,
          component: RCaptureLessors,
        },
        {
          path: `${match.path}reports/MDA/rCAPTURE/tenants/:tenantId`,
          exact: true,
          strict: true,
          component: RCaptureTenants,
        },
        {
          path: `${match.path}reports/analytics/unregistered-landlords/individuals`,
          exact: true,
          strict: true,
          component: IndividualsOwnersWithNoTin,
        },
        {
          path: `${match.path}reports/analytics/unregistered-landlords/non-individuals`,
          exact: true,
          strict: true,
          component: NonIndividualsOwnersWithNoTin,
        },
        {
          path: `${match.path}reports/analytics/taxpayers-with-no-returns/individuals`,
          strict: true,
          component: TaxpayersWithNoReturns,
        },
        {
          path: `${match.path}reports/analytics/taxpayers-with-no-returns/non-individuals`,
          strict: true,
          component: TaxpayersNoReturnsNonIndividuals,
        },
        {
          path: `${match.path}reports/analytics/taxpayers-with-no-income/individuals`,
          strict: true,
          component: TaxpayersWithUndeclaredIncome,
        },
        {
          path: `${match.path}reports/analytics/taxpayers-with-no-income/non-individuals`,
          strict: true,
          component: TaxpayersWithUndeclaredIncomeNonIndividuals,
        },
        {
          path: `${match.path}reports/analytics/taxpayers-with-underreported-income/individuals`,
          strict: true,
          component: TaxpayersWithUnderreportedIncome,
        },
        {
          path: `${match.path}reports/analytics/taxpayers-with-underreported-income/non-individuals`,
          strict: true,
          component: TaxpayersWithUnderreportedIncomeNonIndividuals,
        },
        {
          path: `${match.path}reports/MDA/URSB/uganda-registration-services-bureau`,
          strict: true,
          component: UgandaRegistration,
        },
        {
          path: `${match.path}reports/MDA/KCCA/customer-registration`,
          strict: true,
          component: CustomerRegistration,
        },
        {
          path: `${match.path}reports/MDA/KCCA/property-registration`,
          strict: true,
          component: PropertyRegistration,
        },
        {
          path: `${match.path}reports/MDA/NWSC/national-water-and-sewage-corporation`,
          exact: true,
          strict: true,
          component: WaterAndSewage,
        },
        {
          path: `${match.path}reports/MDA/UMEME/subscriber-and-property`,
          exact: true,
          strict: true,
          component: UmemeSubscriberAndProperty,
        },
        {
          path: `${match.path}reports/ura/individual-registration`,
          strict: true,
          component: IndividualRegistration,
        },
        {
          path: `${match.path}reports/ura/individual-tax-detail`,
          strict: true,
          component: IndividualTaxDetail,
        },
        {
          path: `${match.path}reports/MDA/mlhud/mlhud-registration`,
          strict: true,
          component: MlhudRegistration,
        },
        {
          path: `${match.path}reports/MDA/NIRA-registration`,
          strict: true,
          component: NiraRegistration,
        },
        {
          path: `${match.path}reports/MDA/ura/holding-owners-and-officers`,
          strict: true,
          component: OfficersAndOwnersRegistration,
        },
        {
          path: `${match.path}reports/MDA/ura/associated-registered-individuals-to-nonindividuals`,
          strict: true,
          component: AssociatedRegisteredIndividualsToNonIndividuals,
        },
        {
          path: `${match.path}reports/MDA/ura/associated-registered-nonindividuals-to-nonindividuals`,
          strict: true,
          component: AssociatedRegisteredNonIndividualsToNonIndividuals,
        },
        {
          path: `${match.path}reports/analytics/excessive-owner-occupancy/individuals`,
          strict: true,
          component: ExcessiveOwnerOccupancy,
        },
        {
          path: `${match.path}reports/analytics/landlord-declared-vs-tenant-reported/individuals-and-non-individuals`,
          strict: true,
          component: LandlordIncomeSummary,
        },
        {
          path: `${match.path}reports/analytics/landlord-declared-vs-tenant-reported/landlord/:returnNo/detail`,
          exact: true,
          strict: true,
          component: LandlordIncomeDetail,
        },
        {
          path: `${match.path}reports/analytics/landlord-declared-vs-tenant-reported/tenant/:landlordTin/:taxYear/detail`,
          exact: true,
          strict: true,
          component: TenantIncomeDetail,
        }
      );
    }

    if (appPermissionValidator(APP_PERMISSION.ADMIN)) {
      routes.push(
        {
          path: `${match.path}administration/user-management/users`,
          exact: true,
          strict: true,
          component: UserManagementPage,
        },
        {
          path: `${match.path}administration/user-management/groups`,
          exact: true,
          strict: true,
          component: GroupManagementPage,
        },
        {
          path: `${match.path}administration/user-management/roles`,
          exact: true,
          strict: true,
          component: RoleManagementPage,
        }
      );

      if (appPermissionValidator(APP_PERMISSION.ADMIN_SET_TAX_EXEMPT)) {
        routes.push({
          path: `${match.path}administration/tax-exempt-organisations`,
          exact: true,
          strict: true,
          component: TaxExemptOrganisationPage,
        });
      }

      if (appPermissionValidator(APP_PERMISSION.ADMIN_SET_VISIBILITY_LEVEL)) {
        routes.push({
          path: `${match.path}administration/visibility`,
          exact: true,
          strict: true,
          component: VisibilityPage,
        });
      }

      routes.push({
        path: `${match.path}administration/revision`,
        exact: true,
        strict: true,
        component: RevisionPage,
      });
    }

    routes.push({
      render: () => <>Not Found</>,
    });
  }

  return (
    <SC.Page maxWidth={false} ref={pageRef} maxHeight={pageHeight}>
      <BannerNavigation />
      <SC.PageContent
        maxWidth={false}
        ref={pageContentRef}
        maxHeight={pageContentHeight}
      >
        <Switch>
          {routes.map((route) => {
            const key = _.isArray(route.path)
              ? route.path[0]
              : route.path ?? "Not-Found";

            return <Route key={key} {...route} />;
          })}
        </Switch>
      </SC.PageContent>
    </SC.Page>
  );
};

export default MainPage;
