import React from "react";

// GraphQL
import { Rtcs_Db_Ph_Rpt_NonIndividualComposite } from "graphql/hasura/types-and-hooks";

// Components
import GraphChart from "components/graph-chart/graph-chart.component";

// Assets
import SC from "./rmatch-tab.styles";

export interface RMatchTabProps {
  // eslint-disable-next-line react/require-default-props
  rmatchNonIndividual?: Rtcs_Db_Ph_Rpt_NonIndividualComposite;
}

const RMatchTab: React.FC<RMatchTabProps> = ({ rmatchNonIndividual }) => {
  const [rMatchScore, elements] = React.useMemo(() => {
    if (rmatchNonIndividual) {
      const { MatchScore, Gml } = rmatchNonIndividual;

      if (Gml) return [MatchScore as number, JSON.parse(Gml).elements];
    }
    return [undefined, undefined];
  }, [rmatchNonIndividual]);

  if (!elements) return null;

  return (
    <SC.Container>
      {rMatchScore !== undefined && (
        <SC.Title>
          <b>rMatch Score:</b> {rMatchScore}
        </SC.Title>
      )}
      <GraphChart elements={elements} />
    </SC.Container>
  );
};

export default RMatchTab;
