// Dependencies
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

// Graphql
import {
  MutationAddNoteArgs as AddNoteData,
  NoteAttachmentFile as NoteAttachment,
} from "graphql/types-and-hooks";
import { createEmptyNoteData } from "graphql/rbac.utils";

// Assets
import SC from "./note-create.styles";

export interface NoteCreateProps {
  initialNoteData?: AddNoteData;
  onSubmit: (noteData: AddNoteData) => void;
  onCancel: () => void;
}

const NoteCreate: React.FC<NoteCreateProps> = ({
  initialNoteData = createEmptyNoteData(),
  onSubmit,
  onCancel,
}) => {
  const [noteData, setNoteData] = useState<AddNoteData>(initialNoteData);
  const [attachedFileNames, setAttachedFileNames] = useState<string[]>([]);
  const { repId: rID = "" } = useParams<{ repId: string }>();

  useEffect(() => {
    setNoteData({ ...initialNoteData, rID });
  }, [initialNoteData, rID]);

  const handleCancelOnClick = React.useCallback(() => {
    setNoteData(initialNoteData);
    onCancel();
  }, [initialNoteData, onCancel]);

  const handleOnSubmit: React.FormEventHandler = React.useCallback(
    (event) => {
      event.preventDefault();
      if (!noteData.comment) return;
      onSubmit(noteData);
    },
    [onSubmit, noteData]
  );

  const handleOnChange = React.useCallback((newNoteData: AddNoteData) => {
    setNoteData(newNoteData);
  }, []);

  const handleAttachFileEvent = ({
    currentTarget: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    if (!files || files.length < 0) return;

    const attachmentNames: string[] = [];
    const attachments: NoteAttachment[] = [];

    // Map files to NoteAttachment
    Array.from(files).forEach((file, index) => {
      const attachment: NoteAttachment = {
        id: index,
        file,
      };
      attachments.push(attachment);
      attachmentNames.push(file?.name);
    });

    setAttachedFileNames(attachmentNames);

    const newNoteData = {
      ...noteData,
      attachments,
    };

    handleOnChange(newNoteData);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { value: comment } = event.target;
    const newNoteData = { ...noteData, comment };
    handleOnChange(newNoteData);
  };

  return (
    <SC.NoteForm component="form" onSubmit={handleOnSubmit}>
      <SC.NoteFormContent>
        <SC.NoteToolbar id="" />
        <SC.FileAttachmentContainer>
          <label htmlFor="file-attachment">
            <SC.Typography variant="subtitle2">Attach File</SC.Typography>
            {attachedFileNames && (
              <SC.Attachment>{attachedFileNames.join(", ")}</SC.Attachment>
            )}
          </label>
          <input
            id="file-attachment"
            type="file"
            name="files"
            multiple
            onChange={handleAttachFileEvent}
          />
        </SC.FileAttachmentContainer>
        <SC.NoteFormRow>
          <SC.TextField
            title="Note"
            label="Comment"
            name="comment"
            helperText="Write a note"
            variant="filled"
            multiline
            rows={4}
            inputProps={{ maxLength: 2500 }}
            value={noteData.comment}
            onChange={handleTextFieldChange}
          />
          <SC.PostButton type="submit" variant="contained" color="primary">
            Post Note
          </SC.PostButton>
          <SC.CancelButton variant="outlined" onClick={handleCancelOnClick}>
            Cancel
          </SC.CancelButton>
        </SC.NoteFormRow>
      </SC.NoteFormContent>
    </SC.NoteForm>
  );
};

export default NoteCreate;
