/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { TableColumn } from "components/table/table.component";
import { isValidTin } from "graphql/hasura/rtcs.utils";
import {
  RegIndividualAffiliatesFragment,
  KccaCustomerBusinessAssociateIndividualAffiliatesFragment,
  KccaCustomerGuardianIndividualAffiliatesFragment,
  KccaCustomerReferenceIndividualAffiliatesFragment,
  KccaContactPersonIndividualAffiliatesFragment,
} from "graphql/hasura/types-and-hooks";
import { Link } from "react-router-dom";

export const affiliatesTableColumnsKCCA: TableColumn<
  | KccaContactPersonIndividualAffiliatesFragment
  | KccaCustomerBusinessAssociateIndividualAffiliatesFragment
  | KccaCustomerGuardianIndividualAffiliatesFragment
  | KccaCustomerReferenceIndividualAffiliatesFragment
>[] = [
  {
    Header: "Customer ID",
    accessor: "Id",
  },
  {
    Header: "Relationship",
    // @ts-ignore
    accessor: "relationship",
    align: "left",
  },
  {
    Header: "First Name",
    accessor: "Firstname",
    align: "left",
  },
  {
    Header: "Middle Name",
    accessor: "MiddleName",
    align: "left",
  },
  {
    Header: "Last Name",
    accessor: "Surname",
    align: "left",
  },
];

export const affiliatesTableColumnsURA: TableColumn<RegIndividualAffiliatesFragment>[] =
  [
    {
      Header: "TIN No.",
      accessor: "RefTin",
      align: "left",
      Cell: ({
        value,
        row: {
          values: { RefTin },
        },
      }: {
        value: string;
        row: { values: { RefTin: string } };
      }): React.ReactNode => {
        return isValidTin(RefTin) ? (
          <Link to={`/individuals/${RefTin}/identification`}>{value}</Link>
        ) : (
          <>{RefTin}</>
        );
      },
    },
    {
      Header: "Relationship",
      accessor: "RefPrsnDesgn",
      align: "left",
    },
    {
      Header: "First Name",
      accessor: "RefFirstName",
      align: "left",
    },
    {
      Header: "Surname",
      accessor: "RefSurname",
      align: "left",
    },
    {
      Header: "Other Name",
      accessor: "RefOtherName",
      align: "left",
    },
    {
      Header: "Family Name",
      accessor: "RefFamilyName",
      align: "left",
    },

    {
      Header: "Email",
      accessor: "RefEmailId",
      align: "left",
    },
    {
      Header: "Mobile Number",
      accessor: "RefMobileNumber",
      align: "center",
    },
    {
      Header: "Landline Number",
      accessor: "RefLandlineNumber",
      align: "center",
    },
  ];
