import React from "react";
import { fileSave } from "browser-fs-access";

// GraphQL
import {
  NoteAttachment as NoteAttachmentType,
  useGetFileLazyQuery,
} from "graphql/types-and-hooks";

// Component
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Utils
import { useErrorHandler } from "react-error-boundary";

// Assets
import SC from "./note-attachment.styles";

export interface NoteAttachmentProps {
  attachment: NoteAttachmentType;
}

export const NoteAttachment: React.FC<NoteAttachmentProps> = ({
  attachment,
  children,
}) => {
  const [GetFilesFn, { data, loading, error }] = useGetFileLazyQuery({
    fetchPolicy: "no-cache",
    variables: {
      filename: attachment.filename ?? "",
      folder: attachment.folder ?? "",
    },
  });
  const [conversionInProgress, setConversionInProgress] = React.useState(false);

  const errorHandler = useErrorHandler(error);

  React.useEffect(() => {
    if (data && data.GetFile.length > 0) {
      setConversionInProgress(true);
      // Convert base64 file content to Blob
      fetch(data.GetFile[0].content)
        .then((res) => res.blob())
        .then((blob) => {
          const { name } = attachment;
          const extension = name?.split(".").pop();

          setConversionInProgress(false);
          fileSave(blob, {
            fileName: name ?? "",
            extensions: extension ? [`.${extension}`] : undefined,
          });
        })
        .catch((err) => {
          setConversionInProgress(false);
          errorHandler(err);
        });
    }
  }, [attachment, data, errorHandler]);

  const handlerOnClick = () => {
    GetFilesFn();
  };

  return (
    <>
      <SC.NoteAttachment
        variant="caption"
        download={attachment.name}
        target="_blank"
        rel="noopener"
        onClick={handlerOnClick}
      >
        {children ?? attachment.name}
      </SC.NoteAttachment>

      <ProgressIndicator open={loading || conversionInProgress} />
    </>
  );
};

export default NoteAttachment;
