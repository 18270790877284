/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  withStyles,
  Container as ContainerBase,
  ListSubheader as ListSubheaderBase,
  Box as BoxBase,
  ContainerProps,
  makeStyles,
} from "@material-ui/core";

interface CustomContainerProps extends ContainerProps {
  maxHeight?: number;
}

const useContainerStyles = makeStyles({
  root: {
    overflowX: "auto",
    maxHeight: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
    height: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
  },
});

const TabsContainer = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => {
    const { maxHeight, ...muiProps } = props;
    const classes = useContainerStyles(props);
    return <ContainerBase className={classes.root} {...muiProps} ref={ref} />;
  }
);

const Container = withStyles((theme: any) => ({
  root: {
    overflowY: "hidden",
    padding: 0,
    margin: 0,
    paddingRight: 0,
    marginRight: 0,
    width: "inherit",
    maxWidth: "inherit",
    backgroundColor: theme.accordionBody.backgroundColor,
    height: "100vh",
  },
}))(ContainerBase);

const ListSubheader = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    margin: 0,
    padding: 0,
  },
}))(ListSubheaderBase);

const Box = withStyles(() => ({
  root: {
    height: "auto",
    paddingBottom: "5rem",
  },
}))(BoxBase);

export default {
  Container,
  ListSubheader,
  Box,
  TabsContainer,
};
