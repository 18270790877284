// Dependencies
import { withStyles, Box as BoxBase } from "@material-ui/core";

const Box = withStyles(() => ({
  root: {
    maxWidth: "inherit",
  },
}))(BoxBase);

const BoxInner = withStyles(() => ({
  root: {
    paddingTop: 20,
    paddingBottom: 100,
  },
}))(BoxBase);

export default {
  Box,
  BoxInner,
};
