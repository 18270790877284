// Dependencies
import React from "react";
import { CardActionArea } from "@material-ui/core";

// Assets
import logoUganda from "assets/images/Country_Logo.png";
import SC from "./banner-graphic.styles";

const BannerGraphic: React.FC = () => (
  <SC.BannerGraphic>
    <CardActionArea>
      <SC.Logo image={logoUganda} title="Logo Uganda War" />
      <SC.TextLogoContent>
        <SC.Text gutterBottom variant="h6">
          Rental Tax
        </SC.Text>
        <SC.Text gutterBottom variant="h6">
          Compliance System
        </SC.Text>
      </SC.TextLogoContent>
    </CardActionArea>
  </SC.BannerGraphic>
);

export default BannerGraphic;
