export const RESIDENTIAL_SUB_SFH = "RESIDENT SFH";
export const COMMERCIAL_SUB_RETAIL_AND_OFFICES = [
  "COMERCIAL OFFICES",
  "COMERCIAL RETAIL SHOPS",
];
export const COMMERCIAL_SUB_WAREHOUSE_AND_BARS = [
  "COMERCIAL WAREHOUSE",
  "COMERCIAL RESTAURANT/BAR",
];

export const COMMERCIAL = "COMMERCIAL";
export const RESIDENTIAL = "RESIDENTIAL";
export const CONDOMINIUM = "CONDOMINIUM";
