/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card as CardBase,
  withStyles,
  Typography as TypographyBase,
} from "@material-ui/core";

import Table from "components/table/table.component";

const Card = withStyles((theme: any) => ({
  root: {
    border: theme.tableCard.card.border,
    borderRadius: theme.tableCard.card.border,
    margin: theme.tableCard.card.margin,
  },
}))(CardBase);

const Typography = withStyles((theme: any) => ({
  root: {
    marginTop: theme.analysis.noAnalysis.marginTop,
    textAlign: "left",
  },
}))(TypographyBase);

export default {
  Table,
  Card,
  Typography,
};
