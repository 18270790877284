/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { Accessor } from "react-table";
import _ from "lodash";

export type ReportTableSchema = {
  CustomerId: number;
  KccaFirstName: string;
  KccaMiddleName: string;
  KccaSurname: string;
  MatchScore: number;
  MatchState: string;
  NumOwnedOccupied: number;
  MatchKccaUra: number;
  UraFirstname: string;
  UraSurname: string;
  EmailId: string;
  MobileNumber: string;
  IsIncomeSrcRental: string;
  UraTin: string;
  NationalId: string;
  SumAdjustedIncome: number;
  Rnid: string;
};

export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  CustomerId: "number",
  KccaFirstName: "string",
  KccaMiddleName: "string",
  KccaSurname: "string",
  MatchScore: "string",
  MatchState: "string",
  NumOwnedOccupied: "string",
  MatchKccaUra: "string",
  UraFirstname: "string",
  UraSurname: "string",
  EmailId: "string",
  MobileNumber: "string",
  IsIncomeSrcRental: "string",
  UraTin: "string",
  NationalId: "string",
  SumAdjustedIncome: "number",
  Rnid: "string",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | Accessor<ReportTableSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const reportTableColumns: TableColumn<ReportTableSchema>[] = [
  {
    Header: "RNID",
    accessor: "Rnid",
    align: "center",
  },
  {
    Header: "Customer No",
    accessor: "CustomerId",
    align: "center",
  },
  {
    Header: "KCCA First Name",
    accessor: "KccaFirstName",
    align: "left",
  },
  {
    Header: "KCCA Middle Name",
    accessor: "KccaMiddleName",
    align: "center",
  },
  {
    Header: "KCCA Surname",
    accessor: "KccaSurname",
    align: "left",
  },
  { Header: "rMATCH Score", accessor: "MatchScore", align: "center" },
  {
    Header: "Number of Owner Occupied Properties",
    accessor: "NumOwnedOccupied",
    align: "center",
  },
  {
    Header: "Total Adjusted Gross Rental Income",
    accessor: "SumAdjustedIncome",
    align: "center",
    format: "currency",
  },
  {
    Header: "Match KCCA Owner to URA Taxpayer",
    accessor: "MatchKccaUra",
    align: "left",
  },
  {
    Header: "URA First Name",
    accessor: "UraFirstname",
    align: "left",
  },
  {
    Header: "URA Surname",
    accessor: "UraSurname",
    align: "left",
  },
  {
    Header: "TIN No.",
    accessor: "UraTin",
    align: "left",
  },
  {
    Header: "Rental Income?",
    accessor: "IsIncomeSrcRental",
    align: "left",
  },
  {
    Header: "URA Mobile No.",
    accessor: "MobileNumber",
    align: "left",
  },
  {
    Header: "URA Email",
    accessor: "EmailId",
    align: "left",
  },
  {
    Header: "URA NIN",
    accessor: "NationalId",
    align: "left",
  },
];
