/* eslint-disable no-alert */
// Dependencies
import React, { useCallback, useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs,
  Order_By,
  Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column,
  useGetPaginatedSubscriberAndPropertyQuery,
  useSubscriberAndPropertyFilterQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  TableFetchDataFunction,
  INITIAL_ROWS_PER_PAGE,
} from "components/table/table.component";
import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";

import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";

// Schema
import { ColumnInstance } from "react-table";
import {
  TableColumns,
  getAccessorType,
} from "./subscriber-and-property.schema";

// Assets
import SC from "./subscriber-and-property.style";

const TABLE_NAME = "rtcs_db_Fnumeme_SubscriberAndProperty";

const SubscriberAndProperty: React.FC = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs | undefined
  >(undefined);
  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useGetPaginatedSubscriberAndPropertyQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const dataExport = useCallback(
    (cols: ColumnInstance[]) => {
      return exportDataFn(cols.filter((col) => col.id !== "expander"));
    },
    [exportDataFn]
  );

  const nwscData = React.useMemo(() => {
    const nwsc = data?.rtcs_db_Fnumeme_SubscriberAndProperty ?? [];
    return nwsc;
  }, [data]);

  const columns = useMemo(() => TableColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column.MeterNo,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_Fnumeme_SubscriberAndProperty_aggregatecm[0].value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData]
  );

  // TODO: Replace for useLazyQuery when the function has been updated
  // to return a Promise instead of void.
  // https://github.com/apollographql/react-apollo/issues/3499#issuecomment-639954192
  const { refetch: umemeFilterFn } = useSubscriberAndPropertyFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Umeme_SubscriberAndPropertyArgs =
            {
              where: { [column]: { _ilike: `${value}%` } },
              distinct_on: [
                column as Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column,
              ],
              limit: 10,
            };
          const customerData = await umemeFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions =
            customerData.data?.rtcs_db_Fnumeme_SubscriberAndProperty.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column]
            );
        }

        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [umemeFilterFn]
  );

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "Customer Name",
        columnName:
          Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column.CustomerName,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Telephone",
        columnName:
          Rtcs_Db_Ph_Umeme_SubscriberAndProperty_Select_Column.Telephone,
        fetchSuggestions,
      },
    ];
  }, [fetchSuggestions]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <Table
        actionsOnRight={[
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        title="Subscriber And Property"
        columns={columns}
        data={nwscData}
        onAction={() => alert("under construction")}
        headerPanel={<SC.Title>MDA - Subscriber and Property</SC.Title>}
        leftPanel={
          <TableFilter
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        persistenceId="0f8d3f59-a86b-458e-af12-99e78d6304b2"
        paginationControlled={paginationControlled}
        stickyHeader
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
        exportData={dataExport}
      />
      <ProgressIndicator open={loading || exportLoading} />
    </SC.Box>
  );
};

export default SubscriberAndProperty;
