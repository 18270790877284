// Dependencies
import React from "react";

// Assets
import SC from "./selectable-list-item.styles";

export interface SelectableListProps {
  id: string;
  label: string;
  title?: string;
  checked: boolean;
  isSelected?: boolean;
  isTreeItem?: boolean;
  isEditable: boolean;
  onClick?: React.MouseEventHandler<HTMLLIElement> &
    React.MouseEventHandler<HTMLButtonElement>;
  onCheckBoxClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export const SelectableListItem: React.FC<SelectableListProps> = React.memo(
  ({
    id,
    label,
    title,
    checked,
    isSelected,
    isTreeItem,
    isEditable,
    onClick,
    onCheckBoxClick,
  }) => (
    <SC.ListItem
      id={id}
      title={title}
      button
      onClick={onClick}
      isSelected={isSelected || undefined}
      isTreeItem={isTreeItem}
      disabled={!isEditable}
    >
      <SC.ListItemIcon>
        <SC.Checkbox
          id={id}
          edge="start"
          checked={checked}
          tabIndex={-1}
          disableRipple
          inputProps={{ "aria-labelledby": id }}
          onClick={onCheckBoxClick}
        />
      </SC.ListItemIcon>
      <SC.ListItemText id={id} primary={label} />
    </SC.ListItem>
  )
);

export default SelectableListItem;
