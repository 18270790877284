/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { TableColumn } from "components/table/table.component";
import { Accessor } from "react-table";
import { LandlordIncomeDetailFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";

export type LandlordIncomeDetailTableSchema = Omit<
  LandlordIncomeDetailFragment,
  "__typename"
>;

export const LANDLORD_INCOME_DETAIL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof LandlordIncomeDetailTableSchema,
  "string" | "number" | "Date"
> = {
  RtnRentalIncomeId: "string",
  RtnNo: "string",
  TinNo: "string",
  TaxPayerName: "string",
  RtnDt: "Date",
  RtnFromDt: "Date",
  RtnToDt: "Date",
  TenantTin: "string",
  TenantName: "string",
  PremiseLocation: "string",
  TenancyPeriodFrom: "Date",
  TenancyPeriodTo: "Date",
  RentalIncome: "number",
  IsAmendment: "string",
  IsResident: "string",
  RtnPeriodYear: "string",
  RtnDtTaxSummary: "Date",
  RtnFromDtTaxSummary: "Date",
  RtnToDtTaxSummary: "Date",
  GrossRentalIncome: "number",
  AssessedChrgbleRentlIncm: "number",
  RentincIncome: "number",
};

export const getAccessorType = (
  accessor:
    | keyof LandlordIncomeDetailTableSchema
    | Accessor<LandlordIncomeDetailTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return LANDLORD_INCOME_DETAIL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const LandlordIncomeDetailColumns: TableColumn<LandlordIncomeDetailTableSchema>[] =
  [
    {
      Header: "Return ID No.",
      accessor: "RtnRentalIncomeId",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Return No.",
      accessor: "RtnNo",
      align: "center",
      format: "string",
    },
    {
      Header: "TIN No.",
      accessor: "TinNo",
      align: "center",
      format: "string",
    },
    {
      Header: "Taxpayer Name",
      accessor: "TaxPayerName",
      align: "left",
      format: "string",
    },
    {
      Header: "Return Date",
      accessor: "RtnDt",
      align: "center",
      format: "date",
    },
    {
      Header: "Return Period From",
      accessor: "RtnFromDt",
      align: "center",
      format: "date",
    },
    {
      Header: "Return Period To",
      accessor: "RtnToDt",
      align: "center",
      format: "date",
    },
    {
      Header: "Tenant TIN",
      accessor: "TenantTin",
      align: "center",
      format: "string",
    },
    {
      Header: "Tenant Name",
      accessor: "TenantName",
      align: "left",
      format: "string",
    },
    {
      Header: "Premises Location",
      accessor: "PremiseLocation",
      align: "center",
      format: "string",
    },
    {
      Header: "Tenancy Period From",
      accessor: "TenancyPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      Header: "Tenancy Period To",
      accessor: "TenancyPeriodTo",
      align: "center",
      format: "date",
    },
    {
      Header: "Rent Reported",
      accessor: "RentalIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Is Amendment",
      accessor: "IsAmendment",
      align: "center",
      format: "string",
    },
    {
      Header: "Is Resident",
      accessor: "IsResident",
      align: "center",
      format: "string",
    },
    {
      Header: "Tenant Tax Period",
      accessor: "RtnPeriodYear",
      align: "center",
      format: "string",
    },
    {
      Header: "Tenant Tax Date",
      accessor: "RtnDtTaxSummary",
      align: "center",
      format: "date",
    },
  ];
