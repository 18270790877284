/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { withStyles, Checkbox as CheckboxMui } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import Table from "components/table/table.component";

const MainContainer = withStyles({})(Container);

const UserTable = withStyles({
  leftPane: {
    width: "30%",
    minWidth: "30%",
  },
})(Table) as typeof Table;

const Checkbox = withStyles((theme: any) => ({
  root: {
    padding: theme.table.IconButton.padding,
  },
}))(CheckboxMui);

export default {
  UserTable,
  MainContainer,
  Checkbox,
};
