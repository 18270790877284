/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { convertLocalDateStringToUTC } from "../../utils/internationalization";

export interface QueryBuilderProps {
  join: string;
  column: string;
  query: string;
  value: string | number | boolean;
  selectedType?: string;
}

export const queryBuilder = ({
  join,
  column,
  query,
  value,
  selectedType,
}: QueryBuilderProps) => {
  let newValue = selectedType === "string" ? `${value}`.toUpperCase() : value;

  // check if value has commas, for currency.
  if (selectedType === "number" && `${newValue}`.indexOf(",") > -1) {
    newValue = `${newValue}`.replaceAll(",", "");
    newValue = Number(newValue);
  }

  let generatedQuery = { [query]: newValue };
  switch (query) {
    case "_is_null":
      generatedQuery = { _is_null: true };
      break;
    case "_is_not_null":
      generatedQuery = { _is_null: false };
      break;
    case "_ilike":
      generatedQuery = { _ilike: `${newValue}%` };
      break;
    case "ilike_":
      generatedQuery = { _ilike: `%${newValue}` };
      break;
    case "_ilike_":
      generatedQuery = { _ilike: `%${newValue}%` };
      break;
    case "pattern":
      generatedQuery = { _ilike: newValue };
      break;
    case "_eq":
      try {
        // check if selectedType is date and newValue is string
        generatedQuery = {
          _eq: convertLocalDateStringToUTC(newValue as string),
        };
      } catch (e) {
        generatedQuery = { _eq: newValue };
      }
      break;
    default:
      generatedQuery = { [query]: newValue };
      break;
  }
  return { [column]: generatedQuery, operator: join };
};

export default queryBuilder;
