/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/style-prop-object */
// Dependencies
import React from "react";
import { TableCellProps } from "@material-ui/core";
import { useWindowHeight } from "@react-hook/window-size";

import {
  useTable,
  TableOptions,
  usePagination,
  Row,
  Column,
  useSortBy,
  useExpanded,
  useFlexLayout,
  useResizeColumns,
  useColumnOrder,
  useRowSelect,
  useFilters,
  useAsyncDebounce,
  Cell,
  UseSortByColumnOptions,
  UseResizeColumnsColumnOptions,
  UseFiltersColumnOptions,
  ColumnInstance,
} from "react-table";
import _ from "lodash";

// Components
import TableToolbar from "components/table-toolbar/table-toolbar.component";
import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";
import LeftPaneContainer from "components/left-pane/left-pane.component";
import TransferList from "components/transfer-list/transfer-list.component";
import FormattedValue from "components/formatted-value/formatted-value";
import { TableToolbarButtonProps } from "components/table-toolbar-button/table-toolbar-button.component";

// Assets
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import SC from "./table.styles";

const EXPANDER_CELL_ID = "expander";
export const VISIBILITY_CELL_ID = "visibility";

export const ROWS_PER_PAGE_OPTIONS_DEFAULT = [5, 10, 25];
export const INITIAL_ROWS_PER_PAGE = {
  DEFAULT: 5,
  REPORTS: 25,
  UNLIMITED: -1,
};

const expanderCell = {
  Header: () => null,
  id: EXPANDER_CELL_ID,
  disableSortBy: true,
  align: "left",
  Cell: ({ row }: { row: Row }) => (
    <SC.ExpanderIconButton
      // @ts-ignore
      {...row.getToggleRowExpandedProps()}
    >
      {
        // @ts-ignore
        row.isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />
      }
    </SC.ExpanderIconButton>
  ),
  disableResizing: true,
  width: 55,
  minWidth: 50,
  maxWidth: 50,
};

const getSelectorCell = (header: string | undefined) => ({
  Header: () => header || null,
  id: VISIBILITY_CELL_ID,
  disableSortBy: true,
  align: "left",
  Cell: ({ row }: { row: Row }) => (
    <SC.VisibilityIcon
      color="primary"
      // @ts-ignore
      {...row.getToggleRowSelectedProps()}
      // @ts-ignore
      onClick={() => {
        // eslint-disable-next-line no-param-reassign
        row.values.Visible = !row.values.Visible;
        // @ts-ignore
        onRowSelect(row.values);
      }}
      checked={row.values.Visible}
    />
  ),
  disableResizing: true,
  width: 80,
  minWidth: 80,
  maxWidth: 80,
});

export const TABLE_CELL_FORMATS = {
  DATE: "date",
  NUMBER: "number",
  CURRENCY: "currency",
  STRING: "string",
  PERCENT: "percent",
};

export const formatCellData = <D extends Record<string, unknown>>(
  { format }: TableColumn<D>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cell: Cell<D, any>
): React.ReactNode => {
  if (cell.value === null) return "";
  const cellValue = cell.value;

  switch (format) {
    case TABLE_CELL_FORMATS.CURRENCY:
      if (parseInt(cell.value, 10) === 0) return 0;
      return cell.row.index === 0 ? (
        <FormattedValue format="currency" value={cell.value} />
      ) : (
        <FormattedValue value={cell.value} format="number" />
      );

    case TABLE_CELL_FORMATS.DATE:
      return <FormattedValue format="date" value={cell.value} />;

    case TABLE_CELL_FORMATS.NUMBER:
      return <FormattedValue value={cell.value} format="number" />;

    case TABLE_CELL_FORMATS.PERCENT:
      if (cellValue === 0) return 0;

      return cell.row.index === 0 ? (
        <FormattedValue value={cellValue / 100} format="percent" />
      ) : (
        (cellValue * 1).toFixed(1).replace(/\.0+$/, "")
      );

    case TABLE_CELL_FORMATS.STRING:
      return cell.value === "nan" ? "" : cell.value;

    default:
      return cell.value;
  }
};

export type TableColumn<D extends Record<string, unknown>> = Column<D> &
  UseSortByColumnOptions<D> &
  UseResizeColumnsColumnOptions<D> &
  UseFiltersColumnOptions<D> & {
    align?: TableCellProps["align"];
    sort?: boolean;
    format?: "currency" | "string" | "number" | "date" | "percent";
    hiddenColumn?: boolean;
  };

export type TableFetchDataFunctionParams = {
  pageIndex: number;
  pageSize: number;
  sortBy: { id: string; desc: boolean }[];
};

export type TableFetchDataFunction = (
  params: TableFetchDataFunctionParams
) => void;

export type TablePaginationControlled = {
  /** It allows to pass a function to retrieve data from somewhere such as an API. */
  fetchData: TableFetchDataFunction;
  loading: boolean;
  totalRowsCount: number;
};

export interface TableProps<D extends Record<string, unknown>>
  extends TableOptions<D> {
  columns: TableColumn<D>[];
  footer?: React.ReactNode;
  title?: string | JSX.Element;
  isPaginationHidden?: boolean;
  toolbarButtonProps?: TableToolbarButtonProps;
  /** It will be used to persist the User Settings table state in the local storage. and should be unique from other table persistanceIds */
  persistenceId?: string;
  rowsPerPageOptions?: number[];
  renderExpandedRowSubComponent?: (row: Row<D>) => JSX.Element;
  renderVisibilityRowComponent?: boolean;
  selectorColumnHeader?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowSelect?: (values: any) => void;
  leftPanel?: JSX.Element;
  topPanel?: JSX.Element;
  headerPanel?: JSX.Element;
  actionsOnLeft?: TableToolbarAction[];
  actionsOnRight?: TableToolbarAction[];
  onAction: (action: TableToolbarAction) => void;
  paginationControlled?: TablePaginationControlled;
  enableMultiSort?: boolean;
  /**  Is used to specify that the table will have sticky header */
  stickyHeader?: boolean;
  /** Specify the height of the table */
  maxHeight?: number;
  /** Is used to specify the initial rows per page */
  initialRowsPerPage?: number;
  /** Allows to reset to zero the page index of the table pagination
   * by toggling the value of this property. */
  pageIndexResetSignal?: boolean;
  noDataComponent?: JSX.Element;
  exportData?: (columns: ColumnInstance<D>[]) => void;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Table = <D extends Record<string, unknown>>(props: TableProps<D>) => {
  const {
    title,
    toolbarButtonProps,
    persistenceId,
    enableMultiSort,
    rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS_DEFAULT,
    renderExpandedRowSubComponent,
    renderVisibilityRowComponent,
    selectorColumnHeader,
    onRowSelect,
    leftPanel,
    topPanel,
    headerPanel,
    actionsOnLeft,
    actionsOnRight,
    isPaginationHidden,
    onAction,
    exportData,
    columns,
    data,
    noDataComponent,
    paginationControlled,
    stickyHeader = false,
    maxHeight,
    initialRowsPerPage = INITIAL_ROWS_PER_PAGE.DEFAULT,
    pageIndexResetSignal,
    ...tableProps
  } = props;

  const classes = SC.useTableStyles();
  const [showFilter, setShowFilter] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [newPageIndex, setNewPageIndex] = React.useState(0);
  const tableId = `table-${persistenceId}`;
  const [actionStates, setActionStates] = React.useState<
    Record<TableToolbarAction, boolean | undefined>
  >({
    "fullscreen-expand": false,
    "filter-results": false,
    "export-to-excel-sheet/csv": false,
    add: false,
    "hide/show-columns": false,
  });

  const newColumns = React.useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      renderExpandedRowSubComponent
        ? ([expanderCell, ...columns] as TableColumn<D>[])
        : renderVisibilityRowComponent
        ? ([
            getSelectorCell(selectorColumnHeader),
            ...columns,
          ] as TableColumn<D>[])
        : columns,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, renderExpandedRowSubComponent, renderVisibilityRowComponent]
  );

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 100,
    }),
    []
  );

  const defaultSortColumn = React.useMemo(() => {
    const sortColumns: { id: string; desc: boolean }[] = [];
    newColumns.forEach((col) => {
      if (col.sort) {
        // @ts-ignore
        sortColumns.push({ id: col.accessor, desc: true });
      }
    });
    return sortColumns;
  }, [newColumns]);

  const storedState = React.useMemo(() => {
    if (persistenceId && typeof Storage !== "undefined") {
      const persistentState = localStorage.getItem(tableId);
      if (persistentState) {
        return JSON.parse(persistentState);
      }
    }
    return null;
  }, [persistenceId, tableId]);

  // No right TS types defined. The next version v8 will be being built
  // with TS natively. https://github.com/tannerlinsley/react-table/issues/3064
  const {
    getTableProps,
    headerGroups,
    footerGroups,
    getTableBodyProps,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    toggleSortBy,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { pageSize, sortBy },
    allColumns,
    // @ts-ignore
    setColumnOrder,
    setHiddenColumns,
    state,
    visibleColumns,
  } = useTable(
    {
      columns: newColumns,
      data,
      defaultColumn,
      // @ts-ignore
      manualPagination: !!paginationControlled,
      manualSortBy: !!paginationControlled,
      disableMultiSort: !enableMultiSort,
      disableSortRemove: false,
      pageCount,
      ...tableProps,
      initialState: {
        // @ts-ignore
        pageSize: initialRowsPerPage,
        sortBy: defaultSortColumn,
        // ...storedState,
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    useColumnOrder
  );

  const handlerOnChangePage = (newPage: number) => {
    setNewPageIndex(newPage);
    // gotoPage(newPage);
  };

  const handlerOnChangeSortOrder = (columnz: Column) => {
    if (columnz.id === "RtnPeriodYear" || columnz.id === "RtnDt") {
      // @ts-ignore
      const toggleDesc = !columnz.isSortedDesc;
      toggleSortBy("RtnPeriodYear", toggleDesc, enableMultiSort);
      toggleSortBy("RtnDt", toggleDesc, enableMultiSort);
    } else {
      // @ts-ignore
      toggleSortBy(columnz.id, !columnz.isSortedDesc, enableMultiSort);
    }
  };

  const handlerOnChangeRowsPerPage = (newRowsPerPage: number) => {
    setPageSize(newRowsPerPage);
  };

  const onActionExtract = (action: TableToolbarAction) => {
    switch (action) {
      case "filter-results":
        setShowFilter(!showFilter);
        break;
      case "hide/show-columns":
        setModal(!modal);
        break;
      case "export-to-excel-sheet/csv":
        if (exportData) {
          exportData(visibleColumns);
        } else {
          // handleExportToCSV(data, columns);
        }

        break;
      case "fullscreen-expand":
        setActionStates((prevState) => ({
          ...prevState,
          "fullscreen-expand": !prevState["fullscreen-expand"],
        }));
        break;
      default:
        onAction(action);
    }
  };

  React.useEffect(() => {
    if (!storedState) {
      const hiddenColumns: string[] = [];
      newColumns.forEach((col) => {
        if (col.hiddenColumn) {
          // @ts-ignore
          hiddenColumns.push(col.accessor);
        }
      });
      setHiddenColumns(hiddenColumns);
    }
  }, [newColumns, setHiddenColumns, storedState]);

  React.useEffect(() => {
    if (persistenceId && typeof Storage !== "undefined") {
      const stateToPersist = _.omit(state, ["pageIndex"]);

      localStorage.setItem(tableId, JSON.stringify(stateToPersist));
    }
  }, [state, persistenceId, tableId, paginationControlled]);

  const totalRowsCount = paginationControlled
    ? paginationControlled.totalRowsCount
    : data.length;

  React.useEffect(() => {
    setPageCount(Math.ceil(totalRowsCount / pageSize));
  }, [pageSize, totalRowsCount]);

  const fetchData = useAsyncDebounce(
    paginationControlled?.fetchData ?? (() => null),
    100
  );
  React.useEffect(() => {
    fetchData?.({ pageIndex: newPageIndex, pageSize, sortBy });
  }, [fetchData, newPageIndex, pageSize, sortBy]);

  React.useEffect(() => {
    // gotoPage(0);
  }, [gotoPage, pageIndexResetSignal]);

  // Variables used to calculate table container height for sticky header
  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const mainContainerPosition =
    mainContainerRef.current?.getBoundingClientRect();
  const tableContainerPosition =
    tableContainerRef.current?.getBoundingClientRect();
  const mainContainerPositionY = mainContainerPosition?.y ?? 0;
  const tableContainerPositionY = tableContainerPosition?.y ?? 0;
  const windowHeight = useWindowHeight();
  const containerHeight = windowHeight - mainContainerPositionY;
  const tableHeight = windowHeight - tableContainerPositionY;

  return (
    <SC.ExpansionContainer
      expanded={actionStates["fullscreen-expand"]}
      maxWidth={false}
    >
      <SC.Container
        maxWidth={false}
        className={classes.root}
        ref={mainContainerRef}
        maxHeight={maxHeight}
      >
        {leftPanel && (
          <SC.LeftPaneBox
            pane={showFilter}
            maxHeight={
              maxHeight ?? (stickyHeader ? containerHeight : undefined)
            }
          >
            <LeftPaneContainer>{leftPanel}</LeftPaneContainer>
          </SC.LeftPaneBox>
        )}

        <SC.RightPaneBox pane={leftPanel && showFilter}>
          <SC.TableContainer>
            {headerPanel && <SC.TopPaneBox>{headerPanel}</SC.TopPaneBox>}

            <TableToolbar
              title={title}
              toolbarButtonProps={toolbarButtonProps}
              rowsCount={totalRowsCount}
              page={newPageIndex}
              rowsPerPage={pageSize}
              isPaginationHidden={isPaginationHidden}
              rowsPerPageOptions={rowsPerPageOptions}
              onChangePage={handlerOnChangePage}
              onChangeRowsPerPage={handlerOnChangeRowsPerPage}
              actionsOnLeft={actionsOnLeft}
              actionsOnRight={actionsOnRight}
              onAction={onActionExtract}
              actionsStates={actionStates}
            />

            {topPanel && <SC.TopPaneBox>{topPanel}</SC.TopPaneBox>}

            <SC.Container
              // className={classes.tableContainer}
              maxWidth={false}
              ref={tableContainerRef}
              maxHeight={
                // eslint-disable-next-line no-nested-ternary
                stickyHeader
                  ? maxHeight
                    ? maxHeight -
                      mainContainerPositionY -
                      tableContainerPositionY
                    : tableHeight
                  : maxHeight
              }
            >
              <SC.Table {...getTableProps()} stickyHeader={stickyHeader}>
                <SC.TableHead>
                  {headerGroups.map((headerGroup) => (
                    <SC.TableRow {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => {
                        const tableColumn = column as TableColumn<D>;

                        return (
                          <SC.TableCell
                            /* @ts-ignore */
                            state={state.columnResizing}
                            cellid={column.id}
                            align={tableColumn.align}
                            {...column.getHeaderProps(
                              // @ts-ignore
                              column.getResizerProps
                            )}
                          >
                            <SC.TableCellHeader align={tableColumn.align}>
                              <span
                                onClick={() => handlerOnChangeSortOrder(column)}
                              >
                                <span
                                  {...column.getHeaderProps(
                                    // @ts-ignore
                                    column.getSortByToggleProps
                                  )}
                                >
                                  {column.render("Header")}
                                </span>
                                {
                                  // @ts-ignore
                                  column.isSorted && (
                                    <SC.TableSortLabel
                                      IconComponent={ArrowDropDownIcon}
                                      active
                                      direction={
                                        // @ts-ignore
                                        column.isSortedDesc ? "desc" : "asc"
                                      }
                                    />
                                  )
                                }
                              </span>
                              {
                                // @ts-ignore
                                column.canResize && (
                                  <SC.Resizer
                                    {...column.getHeaderProps(
                                      // @ts-ignore
                                      column.getResizerProps
                                    )}
                                  />
                                )
                              }
                            </SC.TableCellHeader>
                          </SC.TableCell>
                        );
                      })}
                    </SC.TableRow>
                  ))}
                </SC.TableHead>
                {noDataComponent && data.length < 1 ? (
                  <SC.TableBody>
                    <SC.TableRow>
                      <SC.TableCell>{noDataComponent}</SC.TableCell>
                    </SC.TableRow>
                  </SC.TableBody>
                ) : (
                  <SC.TableBody {...getTableBodyProps()}>
                    {page.map((row: Row<D>) => {
                      prepareRow(row);
                      return (
                        <React.Fragment key={row.id}>
                          <SC.TableRow {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              const tableColumn = cell.column as TableColumn<D>;

                              return (
                                <SC.TableCell
                                  {...cell.getCellProps()}
                                  /* @ts-ignore */
                                  state={state.columnResizing}
                                  cellid={cell.column.id}
                                  align={tableColumn.align}
                                  padding={
                                    cell.column.id === EXPANDER_CELL_ID
                                      ? "none"
                                      : "default"
                                  }
                                >
                                  {tableColumn.format
                                    ? formatCellData(tableColumn, cell)
                                    : cell.render("Cell")}
                                </SC.TableCell>
                              );
                            })}
                          </SC.TableRow>
                          {
                            // @ts-ignore
                            renderExpandedRowSubComponent && row.isExpanded && (
                              <SC.TableRow>
                                <SC.TableCell colSpan={newColumns.length}>
                                  {renderExpandedRowSubComponent(row)}
                                </SC.TableCell>
                              </SC.TableRow>
                            )
                          }
                        </React.Fragment>
                      );
                    })}
                  </SC.TableBody>
                )}

                <SC.TableFooter>
                  {footerGroups.map((group) => (
                    <SC.TableRow {...group.getFooterGroupProps()}>
                      {group.headers.map((column) => {
                        const tableColumn = column as TableColumn<D>;

                        return (
                          <SC.TableCell
                            {...column.getFooterProps()}
                            align={tableColumn.align}
                          >
                            {column.render("Footer")}
                          </SC.TableCell>
                        );
                      })}
                    </SC.TableRow>
                  ))}
                </SC.TableFooter>
              </SC.Table>
            </SC.Container>
          </SC.TableContainer>
        </SC.RightPaneBox>

        <TransferList
          columns={allColumns}
          visibleColumns={visibleColumns}
          open={modal}
          onClose={() => setModal(!modal)}
          onSortColumns={(orderIds: string[], hiddenIds: string[]) => {
            setColumnOrder(orderIds);
            setHiddenColumns(hiddenIds);
            setModal(!modal);
          }}
        />
      </SC.Container>
    </SC.ExpansionContainer>
  );
};

export default Table;
