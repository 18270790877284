/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { EntityTypeFragment, Maybe } from "graphql/types-and-hooks";
// eslint-disable-next-line import/prefer-default-export
export const checkVisibleIndividual = (
  visibleArray: EntityTypeFragment[],
  Rnid: Maybe<string> | undefined
) => {
  if (visibleArray && visibleArray.length > 0) {
    const index = visibleArray.findIndex((el: EntityTypeFragment) => {
      return el.entityId === Rnid;
    });
    if (index !== -1) {
      if (visibleArray[index].filterValue !== 0) {
        return true;
      }
    }
  }
  return false;
};
