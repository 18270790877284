// Dependencies
import { withStyles, Container } from "@material-ui/core";

const ContainerApplicator = withStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
}))(Container);

export default {
  ContainerApplicator,
};
