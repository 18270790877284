/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
// Dependencies
import React from "react";

// Assets
import "./TopMenuBar.css";

import SC from "./styles";

class TopMenuBar extends React.PureComponent {
  /* Not use withRouter and pass the location property due to
     https://github.com/ReactTraining/react-router/blob/v4.2.2/packages/
     react-router/docs/guides/blocked-updates.md */

  constructor(props) {
    super(props);
    this.state = { route: "home" };
  }

  handleLinkOnClick = (route) => {
    this.setState({ route });
    this.props.onLinkClick(route);
  };

  render() {
    const { route } = this.state;

    return (
      <SC.Grid
        container
        direction="row"
        alignItems="center"
        className="TopMenuBar"
      >
        <SC.Grid item xs={10}>
          <SC.Grid container>
            <SC.GridIconContainer item>
              {(route === "settings" || route === "search") && (
                <div
                  className="TopMenuBarOption btn p-0"
                  onClick={() => this.handleLinkOnClick("back")}
                >
                  <SC.ArrowBackIcons />
                </div>
              )}
            </SC.GridIconContainer>

            <SC.GridIconContainer item>
              <div
                className="TopMenuBarOption btn p-0"
                onClick={() => this.handleLinkOnClick("home")}
              >
                <SC.HomeIcons />
              </div>
            </SC.GridIconContainer>

            <SC.GridIconContainer item>
              <div
                className="TopMenuBarOption btn p-0"
                onClick={() => this.handleLinkOnClick("search")}
              >
                <SC.SearchIcons />
              </div>
            </SC.GridIconContainer>
          </SC.Grid>
        </SC.Grid>

        <SC.Grid item xs={2}>
          <div
            className="TopMenuBarOption btn p-0"
            onClick={() => this.handleLinkOnClick("settings")}
          >
            <SC.MenuIcons />
          </div>
        </SC.Grid>
      </SC.Grid>
    );
  }
}

export default TopMenuBar;
