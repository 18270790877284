/* eslint-disable no-alert */
// Dependencies
import React, { useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Nira_RawDataArgs,
  Order_By,
  Rtcs_Db_Ph_Nira_RawData_Select_Column,
  useNiraRegistrationQuery,
  useNiraFilterQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";

// Schema
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import { niraTableColumns, getAccessorType } from "./nira-registration.schema";

// Assets
import SC from "./nira-registration.styles";

const TABLE_NAME = "rtcs_db_Fnnira_RawData";

const NiraRegistration: React.FC = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Nira_RawDataArgs | undefined
  >(undefined);
  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useNiraRegistrationQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const reportData = React.useMemo(() => {
    const nira = data?.rtcs_db_Fnnira_RawData ?? [];
    return nira;
  }, [data]);

  const columns = useMemo(() => niraTableColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    (params) => {
      const { pageIndex, pageSize, sortBy } = params;
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Nira_RawData_Select_Column.GivenName,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_Fnnira_RawData_aggregatecm[0].value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData]
  );

  // TODO: Replace for useLazyQuery when the function has been updated
  // to return a Promise instead of void.
  // https://github.com/apollographql/react-apollo/issues/3499#issuecomment-639954192
  const { refetch: PropertyFilterFn } = useNiraFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Nira_RawDataArgs = {
            where: { [column]: { _ilike: `${value}%` } },
            distinct_on: [column as Rtcs_Db_Ph_Nira_RawData_Select_Column],
          };
          const propertyFilterData = await PropertyFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions = propertyFilterData.data?.rtcs_db_Fnnira_RawData.map(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (option) => option[column]
          );
        }

        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [PropertyFilterFn]
  );

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "First Name",
        columnName: Rtcs_Db_Ph_Nira_RawData_Select_Column.GivenName,
        fetchSuggestions,
      },

      {
        type: TableFilterType.AUTOFILL,
        label: "Last Name/Maiden Name",
        columnName: Rtcs_Db_Ph_Nira_RawData_Select_Column.Surname,
        fetchSuggestions,
      },

      {
        type: TableFilterType.AUTOFILL,
        label: "NIN",
        columnName: Rtcs_Db_Ph_Nira_RawData_Select_Column.NationalId,
        fetchSuggestions,
      },

      {
        type: TableFilterType.AUTOFILL,
        label: "Email",
        columnName: Rtcs_Db_Ph_Nira_RawData_Select_Column.Email,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Mobile",
        columnName: Rtcs_Db_Ph_Nira_RawData_Select_Column.MobileNumber,
        fetchSuggestions,
      },
    ];
  }, [fetchSuggestions]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <Table
        actionsOnRight={[
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        title="NIRA - Registration"
        headerPanel={
          <SC.Title>
            MDA - National Identification and Registration Authority
          </SC.Title>
        }
        columns={columns}
        data={reportData ?? []}
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        onAction={() => alert("under construction")}
        leftPanel={
          <TableFilter
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        persistenceId="6d16e660-4304-4682-a4cf-cb9191b99e7b"
        paginationControlled={paginationControlled}
        stickyHeader
        pageIndexResetSignal={pageIndexResetSignal}
        exportData={exportDataFn}
      />
      <ProgressIndicator open={loading || exportLoading} />
    </SC.Box>
  );
};

export default NiraRegistration;
