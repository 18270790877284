/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import { ButtonProps } from "@material-ui/core";

// Assets
import SC from "./table-toolbar-button.styles";

export interface TableToolbarButtonProps extends ButtonProps {
  isLoading?: boolean;
}

export const TableToolbarButton: React.FC<TableToolbarButtonProps> = ({
  children,
  isLoading,
  ...props
}) => (
  <SC.ToolbarButton size="small" variant="outlined" color="primary" {...props}>
    {children}
    {isLoading && <SC.IndicatorProgress size={30} />}
  </SC.ToolbarButton>
);

export default TableToolbarButton;
