import React from "react";
import {
  Query_RootRtcs_Db_Ph_Ura_RentalLandLordIncomeArgs,
  Query_RootRtcs_Db_Ph_Ura_RentalLandLordIncome_AggregateArgs,
  Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column,
  useUraRentalLandLordIncomeReportQuery,
} from "graphql/hasura/types-and-hooks";

// Graphql
import { CLIENT_NAME } from "graphql/client";

// Data export
import { useExportData } from "exports/useExportData";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";
import { columns as reportColumns } from "./individual-landlord-rental-income.schema";

// Styles
import SC from "./individual-landlord-rental-income.styles";

interface InputProps {
  tin: string;
}

const TABLE_NAME = "rtcs_db_Fnura_RentalLandLordIncome";

const IndividualLandlordRentalIncome: React.FC<InputProps> = ({ tin }) => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Ura_RentalLandLordIncome_AggregateArgs | undefined
  >({
    where: { TinNo: { _eq: tin } },
  });

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useUraRentalLandLordIncomeReportQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  // Data export
  const [exportDataFn, { loading: exportLloading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  // Table Columns
  const columns = React.useMemo(() => reportColumns, []);

  const returns = React.useMemo(
    () => data?.rtcs_db_Fnura_RentalLandLordIncome ?? [],
    [data]
  );

  const { refetch: ReportFilterFn } = useUraRentalLandLordIncomeReportQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Ura_RentalLandLordIncomeArgs =
            {
              where: {
                [column]: { _ilike: `${value}%` },
                _and: [{ TinNo: { _eq: tin } }],
              },
              distinct_on: [
                column as Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column,
              ],
              limit: 10,
            };

          const individualReportFilterData = await ReportFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions =
            individualReportFilterData.data.rtcs_db_Fnura_RentalLandLordIncome.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column]
            );
        }

        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [ReportFilterFn, tin]
  );

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "Tenant Name",
        columnName:
          Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column.TenantName,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Premises Location",
        columnName:
          Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column.PremiseLocation,
        fetchSuggestions,
      },
      {
        type: TableFilterType.SELECT,
        label: "Tax Year",
        columnName: Rtcs_Db_Ph_Ura_RentalLandLordIncome_Select_Column.RtnFromDt,
        options: [
          { key: "1", label: "All", value: "" },
          {
            key: "2017-2017",
            label: "2017 - 2017",
            value: "2016-12-31T21:00:00+00:00",
          },
          {
            key: "2017-2018",
            label: "2017 - 2018",
            value: "2017-06-30T21:00:00+00:00",
          },
          {
            key: "2018-2018",
            label: "2018 - 2018",
            value: "2017-12-31T21:00:00+00:00",
          },
          {
            key: "2018-2019",
            label: "2018 - 2019",
            value: "2018-06-30T21:00:00+00:00",
          },
          {
            key: "2019-2019",
            label: "2019 - 2019",
            value: "2018-12-31T21:00:00+00:00",
          },
          {
            key: "2019-2020",
            label: "2019 - 2020",
            value: "2019-06-30T21:00:00+00:00",
          },
          {
            key: "2020-2020",
            label: "2020 - 2020",
            value: "2019-12-31T21:00:00+00:00",
          },
          {
            key: "2020-2021",
            label: "2020 - 2021",
            value: "2020-06-30T21:00:00+00:00",
          },
          {
            key: "2021-2021",
            label: "2021 - 2021",
            value: "2020-12-31T21:00:00+00:00",
          },
          {
            key: "2021-2022",
            label: "2021 - 2022",
            value: "2021-06-30T21:00:00+00:00",
          },
          {
            key: "2022-2022",
            label: "2022 - 2022",
            value: "2021-12-31T21:00:00+00:00",
          },
        ],
      },
    ];
  }, [fetchSuggestions]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      const RntToDtCondition: FilterCondition = {};

      const updatedCondition = conditions.map((condition) => {
        if (condition.RtnFromDt) {
          // eslint-disable-next-line @typescript-eslint/dot-notation
          const RtnFromDtValue = condition.RtnFromDt["_ilike"].replace("%", "");

          // eslint-disable-next-line no-param-reassign
          condition = {
            ...condition,
            RtnFromDt: {
              _eq: RtnFromDtValue,
            },
          };
        }

        return condition;
      });

      // check if updatedCondition contains key
      if (RntToDtCondition && Object.keys(RntToDtCondition).length > 0) {
        updatedCondition.push(RntToDtCondition);
      }

      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          TinNo: { _eq: tin },
          _and: updatedCondition.length > 0 ? updatedCondition : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    [tin]
  );

  return (
    <>
      <SC.ProgressIndicator open={loading || exportLloading} />
      <SC.Table
        title="Landlord Declared Rental Income"
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        columns={columns}
        data={returns}
        stickyHeader
        persistenceId="83baa612-23d5-42ac-9444-febf322398dc"
        exportData={exportDataFn}
        leftPanel={
          <TableFilter
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
          />
        }
        pageIndexResetSignal={pageIndexResetSignal}
      />
    </>
  );
};

export default IndividualLandlordRentalIncome;
