/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";

// styles
import SC from "./report-container.styles";

export interface ReportContainerProps {
  children?: React.ReactNode;
  title?: string;
  content?: string[];
}

export const ReportContainer = ({
  children,
  title,
  content,
}: ReportContainerProps) => {
  return (
    <SC.Grid item xs={6}>
      <SC.Paper elevation={0}>
        <SC.Label> {title} </SC.Label>
        {content && content.map((cont) => <SC.Content> {cont} </SC.Content>)}
        <SC.ScrollView>{children}</SC.ScrollView>
      </SC.Paper>
    </SC.Grid>
  );
};

export default ReportContainer;
