/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withStyles,
  Container as ContainerBase,
  Accordion as AccordionBase,
  AccordionDetails as AccordionDetailsBase,
  AccordionSummary as AccordionSummaryBase,
  Typography as TypographyBase,
  Grid,
  // Box,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreBase } from "@material-ui/icons";

const Container = withStyles((theme: any) => ({
  root: {
    marginTop: theme.taxHistory.padding,
  },
}))(ContainerBase);

const Accordion = withStyles((theme: any) => ({
  root: {
    boxShadow: theme.taxHistory.accordion.boxShadow,
    "&:not(:last-child)": {
      borderBottom: theme.taxHistory.accordion.borderBottom,
    },
    "&:before": {
      display: theme.taxHistory.accordion.display,
    },
    "&$expanded": {
      margin: theme.taxHistory.accordion.margin,
    },
  },
}))(AccordionBase);

const AccordionSummary = withStyles((theme: any) => ({
  root: {
    flexDirection: theme.taxHistory.header.flexDirection,
    padding: theme.taxHistory.header.padding,
    alignItems: theme.taxHistory.header.alignItems,
    textAlign: theme.taxHistory.header.textAlign,
    "& .MuiAccordionSummary-content": {
      paddingLeft: theme.taxHistory.header.content.paddingLeft,
      paddingTop: theme.taxHistory.header.content.paddingTop,
    },
    "& .MuiAccordionSummary-expandIcon": {
      padding: theme.taxHistory.header.expandIcon.padding,
    },
    "& .MuiAccordionSummary-content.Mui-expanded, .MuiAccordionSummary-content":
      {
        margin: theme.taxHistory.header.expanded.margin,
      },
  },
}))(AccordionSummaryBase);

const AccordionTitle = withStyles((theme: any) => ({
  root: {
    fontSize: theme.taxHistory.financialSummary.header.fontSize,
    fontWeight: theme.taxHistory.financialSummary.header.fontWeight,
  },
}))(TypographyBase);

const AccordionTitleDetails = withStyles((theme: any) => ({
  root: {
    fontSize: theme.taxHistory.financialSummary.header.fontSize,
    fontWeight: theme.taxHistory.financialSummary.header.fontWeight,
    textAlign: theme.taxHistory.financialSummary.header.textAlign,
    paddingRight: theme.taxHistory.financialSummary.header.paddingRight,
  },
}))(TypographyBase);

const ExpandMore = withStyles((theme: any) => ({
  root: {
    color: theme.palette.primary.main,
  },
}))(ExpandMoreBase);

const AccordionDetails = withStyles((theme: any) => ({
  root: {
    paddingTop: theme.taxHistory.content.paddingTop,
    paddingLeft: theme.taxHistory.content.paddingLeft,
    textAlign: theme.taxHistory.content.textAlign,
    height: theme.taxHistory.content.height,
    overflow: theme.taxHistory.content.overflow,
    "& .MuiContainer-root": {
      padding: theme.taxHistory.content.root.padding,
      marginTop: theme.taxHistory.content.root.marginTop,
      paddingRight: theme.taxHistory.content.root.paddingRight,
    },
  },
}))(AccordionDetailsBase);

export default {
  Container,
  Accordion,
  AccordionSummary,
  AccordionTitle,
  AccordionTitleDetails,
  AccordionDetails,
  ExpandMore,
  Grid,
};
