/* eslint-disable import/prefer-default-export */
import {
  Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  Rtcs_Db_Ph_Ura_RentExpenseDetails,
} from "graphql/hasura/types-and-hooks";
import { percentageOf } from "utils/math";

const sumCategoryExpenses = (
  expenses: Rtcs_Db_Ph_Ura_RentExpenseDetails[] | undefined,
  category: string
) =>
  expenses?.reduce(
    (sum, { Expensetype, Amount }: Rtcs_Db_Ph_Ura_RentExpenseDetails) => {
      if (Expensetype?.toLocaleLowerCase() === category) return sum + Amount;

      return sum + 0;
    },
    0
  ) || 0;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getStructure = (
  returns: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  expenses: Rtcs_Db_Ph_Ura_RentExpenseDetails[]
) => {
  const operating = sumCategoryExpenses(expenses, "operating expense");
  const admin = sumCategoryExpenses(expenses, "administrative expense");
  const incomeFinance = sumCategoryExpenses(expenses, "financial expense");

  return [
    {
      label: "Gross Rental Income",
      value: returns.GrossRentalIncome,
      format: "currency",
      level: 0,
    },
    {
      label: "Assessed Chargeable Rental Income",
      value: returns.AssessedChrgblRentlIncm,
      format: "currency",
      level: 0,
    },
    {
      label: "Rental Expenses",
      value: returns.TotExpPurelyRental,
      format: "currency",
      level: 1,
      floatingText: ` ${percentageOf(
        returns.GrossRentalIncome,
        returns.TotExpPurelyRental,
        "of total Gross rental Income"
      )}`,
      items: [
        {
          label: "Rental Operating Expenses",
          value: operating,
          format: "currency",
          level: 2,
          floatingText: percentageOf(
            returns.TotExpPurelyRental,
            operating,
            "of total rental expenses"
          ),
        },
        {
          label: "Rental Admin Expenses Total",
          value: admin,
          format: "currency",
          level: 2,
          floatingText: percentageOf(
            returns.TotExpPurelyRental,
            admin,
            " of total rental expenses"
          ),
        },
        {
          label: "Rental Income Finance Expenses Total",
          value: incomeFinance,
          format: "currency",
          level: 2,
          floatingText: percentageOf(
            returns.TotExpPurelyRental,
            incomeFinance,
            " of total rental expenses"
          ),
        },
      ],
    },
    {
      label: "Rental Profit",
      value: returns.ProfitLossRental,
      format: "currency",
      level: 0,
    },
    {
      label: "Self Assessment on Rental Income Tax",
      value: returns.SelfAssessTaxPaidRent,
      format: "currency",
      level: 0,
    },
    {
      label: "Rental Income Tax",
      value: returns.RentincTax,
      format: "currency",
      level: 0,
    },
  ];
};
