/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  EntityFilterName,
  EntityIdType,
  EntityInput,
  Job,
  useGetEntityFilterQuery,
  useSetVisibilityFilterMutation,
} from "graphql/types-and-hooks";
import {
  Order_By,
  Query_RootRtcs_Db_Ph_Rpt_IndividualCompositeArgs,
  Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column,
  useGetPaginatedVisibilityIndividualCompositeQuery,
} from "graphql/hasura/types-and-hooks";
import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { CLIENT_NAME } from "graphql/client";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
  VISIBILITY_CELL_ID,
} from "components/table/table.component";
import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";
import TableFilter, {
  FilterCondition,
} from "components/table-filter/table-filter.component";
import useJobStatus from "jobs/useJobStatus";
import { checkVisibleIndividual } from "utils/visibility";
import { TableToolbarButtonProps } from "components/table-toolbar-button/table-toolbar-button.component";
import { ColumnInstance } from "react-table";
import { VisibilityTableColumns } from "./visibility.schema";

// Styles
import SC from "./visibility.styles";

const TABLE_NAME = "rtcs_db_FnRPT_IndividualComposite";

export const VisibilityPage = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Rpt_IndividualCompositeArgs | undefined
  >({
    where: {},
    limit: 0,
  });
  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);
  const [filterInputs, setFilterInputs] = useState([]);
  const [entityVariables, setEntityVariables] = useState<
    EntityInput[] | undefined
  >(undefined);

  const { data, loading, error } =
    useGetPaginatedVisibilityIndividualCompositeQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !queryVariables,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
        aggregateParams: jsonToStringQueryVariables({
          where: queryVariables?.where,
        }),
      },
    });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  const [updateVisibilityFn, { data: updateData, error: errorUpdate }] =
    useSetVisibilityFilterMutation();

  const {
    loading: entityLoading,
    data: entityData,
    error: entityError,
  } = useGetEntityFilterQuery({
    skip: !entityVariables,
    variables: {
      filterName: EntityFilterName.VisibilityLevel,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      entityInputs: entityVariables,
    },
  });

  const [runJob, { jobStatus, jobStatusError, runJobError, jobRunning }] =
    useJobStatus(Job.UpdateEntityFilters);

  const errorHandler = useAppErrorHandler(
    jobStatusError || runJobError || errorUpdate || exportError
  );

  const handlerApplyJobs = React.useCallback(() => {
    runJob().catch((_error) => {
      if (isServerError(_error as Error)) {
        errorHandler(_error as Error);
      } else {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while applying visibility permissions"
          )
        );
      }
    });
  }, [errorHandler, runJob]);

  useEffect(() => {
    if (data) {
      const individuals = data.rtcs_db_FnRPT_IndividualComposite || [];
      // @ts-ignore
      const entityInputs: EntityInput[] = individuals.map((user) => ({
        entityIdType: EntityIdType.Rnid,
        entityId: user.Rnid,
      }));
      setEntityVariables(entityInputs);
    }
  }, [data]);

  const columns = useMemo(() => VisibilityTableColumns, []);

  const users = React.useMemo(() => {
    if (!data && !entityData) {
      return [];
    }
    const individuals = data?.rtcs_db_FnRPT_IndividualComposite || [];
    if (entityData) {
      return individuals.map((user) => ({
        ...user,
        Visible: checkVisibleIndividual(entityData.GetEntityFilter, user.Rnid),
      }));
    }
    return [];
  }, [data, entityData]);

  useEffect(() => {
    if (updateData && updateData?.SetEntityFilter.length > 0) {
      if (filterInputs.length > 0) {
        setFilterInputs([]);
        handlerApplyJobs();
      }
    }
  }, [handlerApplyJobs, updateData, filterInputs]);

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    (params) => {
      const { pageIndex, pageSize, sortBy } = params;
      // This default column is also set in the table columns with the property
      // "sort: true"
      const defaultSortColumn = {
        id: "Rnid",
        desc: true,
      };
      // Support just for one sort column at once
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;

      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_FnRPT_IndividualComposite_aggregatecm[0].value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData]
  );

  const onTableFilterSearch = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
        offset: 0,
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  const setUpdatedTableData = (values: { Rnid: string; Visible: boolean }) => {
    const hideIndividuals = Object.assign(filterInputs);
    hideIndividuals.push({
      entityIdType: EntityIdType.Rnid,
      entityId: values.Rnid,
      filterValue: values.Visible ? 1 : 0,
    });
    setFilterInputs(hideIndividuals);
  };

  const saveButtonProps = React.useMemo(
    () =>
      ({
        children: "Save Changes",
        isLoading: !jobStatus || jobRunning,
        disabled: !jobStatus || jobRunning,
        onClick: () => {
          if (filterInputs.length > 0) {
            updateVisibilityFn({
              variables: {
                filterName: EntityFilterName.VisibilityLevel,
                filterInputs,
              },
            });
          }
        },
      } as TableToolbarButtonProps),
    [filterInputs, jobRunning, jobStatus, updateVisibilityFn]
  );

  useAppErrorHandler(error || errorUpdate || entityError);

  const dataExport = useCallback(
    (cols: ColumnInstance[]) =>
      exportDataFn(cols.filter((col) => col.id !== VISIBILITY_CELL_ID)),
    [exportDataFn]
  );

  return (
    <SC.Box>
      <ProgressIndicator open={loading || entityLoading || exportLoading} />
      <Table
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        title="Visibility"
        toolbarButtonProps={saveButtonProps}
        renderVisibilityRowComponent
        selectorColumnHeader="Hidden"
        onRowSelect={(values) => setUpdatedTableData(values)}
        leftPanel={
          <TableFilter
            searchInputs={[
              {
                label: "First name, Surname or TIN number",
                columnNames: [
                  Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column.UraTinNo,
                  Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column.Firstname,
                  Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column.MiddleName,
                  Rtcs_Db_Ph_Rpt_IndividualComposite_Select_Column.Surname,
                ],
              },
            ]}
            onSearchChange={onTableFilterSearch}
          />
        }
        columns={columns}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        data={users as VisibilityTableSchema[]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        paginationControlled={paginationControlled}
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.DEFAULT}
        persistenceId="289d962b-3655-4c36-848a-2e7-f089-5ef13-456"
        stickyHeader
        pageIndexResetSignal={pageIndexResetSignal}
        exportData={dataExport}
      />
    </SC.Box>
  );
};

export default VisibilityPage;
