// Dependencies
import React from "react";

// Components
import SelectableList from "components/selectable-list/selectable-list.component";
import TreeViewList from "components/tree-view-list/tree-view-list.component";

// Assets
import {
  PermissionData,
  PermissionInput,
  PermissionType,
} from "graphql/types-and-hooks";
import SC from "./permission-details.styles";

interface PermissionDetailsProps {
  permissions: PermissionData[];
  isEditable: boolean;
  permissionType: PermissionType;
  resetData: boolean;
  onChange: (permissions: PermissionData[], parent: string) => void;
  onParentPermissionsChange: (
    permissions: PermissionData[],
    permissionType: PermissionType
  ) => void;
  onPermissionsChange: (
    permissionsList: PermissionInput[],
    permissionType: PermissionType
  ) => void;
  cleanPermissionsChangedList: boolean;
}

export const PermissionDetails: React.FC<PermissionDetailsProps> = ({
  permissions,
  isEditable,
  permissionType,
  resetData,
  onChange,
  onParentPermissionsChange,
  onPermissionsChange,
  cleanPermissionsChangedList,
}) => {
  const [optionsList, setOptionsList] = React.useState<PermissionData[]>([]);
  const [currentOption, setCurrentOption] = React.useState<
    PermissionData | undefined
  >(permissions?.[0]);
  const [permissionsChangedList, setPermissionsChangedList] = React.useState<
    PermissionInput[]
  >([]);
  const currentPermissions = React.useMemo(() => {
    return (
      currentOption?.children
        ?.slice()
        .sort((a, b) => a.name?.localeCompare(b.name ?? "") || 0) ?? []
    );
  }, [currentOption]);
  const areAllPermissionsChecked = React.useMemo(() => {
    return !currentPermissions.find((permission) => !permission.enabled);
  }, [currentPermissions]);

  React.useEffect(() => {
    setOptionsList((oldOptionsList) => {
      if (oldOptionsList.length === 0 && permissions.length > 0) {
        setCurrentOption(permissions[0]);
      }

      return permissions;
    });
  }, [permissions]);

  React.useEffect(() => {
    if (resetData) {
      setPermissionsChangedList([]);
      setOptionsList(permissions);
    }
    if (cleanPermissionsChangedList) {
      setPermissionsChangedList([]);
    }
  }, [permissions, resetData, cleanPermissionsChangedList]);

  React.useEffect(() => {
    onPermissionsChange(permissionsChangedList, permissionType);
  }, [onPermissionsChange, permissionType, permissionsChangedList]);

  const checkItem = (optionTree: PermissionData, state: boolean) => {
    const { id, enabled } = optionTree;

    if (enabled !== state) {
      const optionIndex = permissionsChangedList.findIndex(
        (opt) => opt.id === id
      );

      if (optionIndex !== -1) {
        setPermissionsChangedList((oldPermissions) =>
          oldPermissions.filter((perm) => perm.id !== id)
        );
      } else {
        setPermissionsChangedList((oldPermissions) => [
          ...oldPermissions,
          {
            id,
            enabled: state,
          },
        ]);
      }
    }

    return { ...optionTree, enabled: state };
  };

  const createItem = (optionsTree: PermissionData[], optionId: string) => {
    const optionIndex = optionsTree.findIndex((opt) => opt.id === optionId);

    if (optionIndex > -1) {
      const option = optionsTree[optionIndex];
      const newOptionsTree = [...optionsTree];

      newOptionsTree[optionIndex] = checkItem(option, !option.enabled);

      return newOptionsTree;
    }

    return optionsTree;
  };

  const handleSelectedTreeOption = (optionId: string) => {
    const newChildren = createItem(currentPermissions, optionId);
    const optionIndex = optionsList.findIndex(
      (opt) => opt.id === currentOption?.id
    );

    const newOptionsList = [...optionsList];
    const newCurrentOption = {
      ...optionsList[optionIndex],
      children: newChildren,
    };
    newOptionsList[optionIndex] = newCurrentOption;
    setOptionsList(newOptionsList);
    setCurrentOption(newCurrentOption);

    onChange(newChildren, newCurrentOption?.id ?? "");
  };

  const handleSelectedListOption = (selectedOption: PermissionData) => {
    setCurrentOption(selectedOption);
  };

  const handleSelectedCheckboxFromList = (checkedOption: PermissionData) => {
    const optionIndexList = optionsList.findIndex(
      (opt) => opt.id === checkedOption.id
    );

    if (optionIndexList < 0) return;

    const newParentOption = {
      ...checkedOption,
      enabled: !checkedOption.enabled,
    };
    const newOptionsList = [...optionsList];
    newOptionsList[optionIndexList] = newParentOption;

    setOptionsList(newOptionsList);
    setCurrentOption(newParentOption);

    const optionIndex = permissionsChangedList.findIndex(
      (opt) => opt.id === checkedOption.id
    );
    if (optionIndex !== -1) {
      setPermissionsChangedList((oldPermissions) =>
        oldPermissions.filter((perm) => perm.id !== checkedOption.id)
      );
    } else {
      const permissionObject: PermissionInput = {
        id: checkedOption.id,
        enabled: newParentOption.enabled,
      };
      setPermissionsChangedList([...permissionsChangedList, permissionObject]);
    }

    onParentPermissionsChange(newOptionsList, permissionType);
  };

  const handlerAllPermissionsOnClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    const newChildren = currentPermissions.map((permission) =>
      checkItem(permission, checked)
    );
    const optionIndex = optionsList.findIndex(
      (opt) => opt.id === currentOption?.id
    );

    const newOptionsList = [...optionsList];
    const newCurrentOption = {
      ...optionsList[optionIndex],
      children: newChildren,
    };
    newOptionsList[optionIndex] = newCurrentOption;
    setOptionsList(newOptionsList);
    setCurrentOption(newCurrentOption);

    onChange(newChildren, newCurrentOption?.id ?? "");
  };

  const sortedOptionList = React.useMemo(
    () => optionsList.sort((a, b) => a.name?.localeCompare(b.name ?? "") || 0),
    [optionsList]
  );

  return (
    <SC.Grid item xs={12} MarginTop={0}>
      <SC.Grid item xs={3} scrollable paddingright="25px">
        <SC.InputLabelTab>
          {permissionType === PermissionType.Application ? "Tab" : "Table"}
        </SC.InputLabelTab>
        <SelectableList
          items={sortedOptionList}
          isEditable={isEditable}
          selectedItem={currentOption}
          getItemKey={(item) => item.id ?? ""}
          getItemLabel={(item) => item.name ?? ""}
          getItemTitle={(item) => item.description ?? ""}
          getItemChecked={(item) => item.enabled ?? false}
          onListChange={handleSelectedListOption}
          onCheckboxChange={handleSelectedCheckboxFromList}
        />
      </SC.Grid>
      <SC.Grid item xs={6} scrollable direction="column">
        <SC.PermissionsHeading>
          <SC.InputLabelPermissions>Permissions</SC.InputLabelPermissions>
          <SC.Checkbox
            disabled={!(isEditable && !!currentOption?.enabled)}
            checked={areAllPermissionsChecked}
            onChange={handlerAllPermissionsOnClick}
          />
        </SC.PermissionsHeading>
        <TreeViewList
          items={currentPermissions}
          isEditable={isEditable && !!currentOption?.enabled}
          getItemKey={(item) => item.id ?? ""}
          getItemLabel={(item) => item.name ?? ""}
          getItemTitle={(item) => item.description ?? ""}
          getItemChecked={(item) => item.enabled ?? false}
          getItemChildren={(item) => item.children ?? []}
          onChange={handleSelectedTreeOption}
        />
      </SC.Grid>
    </SC.Grid>
  );
};
export default PermissionDetails;
