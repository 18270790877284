/* eslint-disable @typescript-eslint/no-explicit-any */
import { Paper as PaperBase, withStyles } from "@material-ui/core";
import { PhotoLibrary } from "@material-ui/icons";

const Paper = withStyles(() => ({
  root: {
    height: "100%",
    display: "grid",
    placeItems: "center",
  },
}))(PaperBase);

const Icon = withStyles((theme: any) => ({
  root: {
    fontSize: theme.imagePlaceholder.icon.fontSize,
    color: theme.imagePlaceholder.icon.color,
  },
}))(PhotoLibrary);

export default {
  Paper,
  Icon,
};
