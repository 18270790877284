/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Tab as TabBase,
  Tabs as TabsBase,
  TabProps,
  Box,
} from "@material-ui/core";

const Tabs = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
  },
  flexContainer: {
    justifyContent: "flex-end",
  },
  indicator: {
    height: 5,
    backgroundColor: theme.tab.color,
    width: "inherite",
  },
}))(TabsBase);

const Tab = withStyles((theme: any) => ({
  root: {
    textTransform: "none",
    minWidth: "auto",
    width: "auto",
    fontWeight: (props: TabProps) => (props.selected ? "bold" : 500),
    color: theme.tab.color,
    fontSize: theme.tab.fontSize,
    borderColor: theme.tab.color,
  },
  wrapper: {
    color: theme.label.color,
  },
}))(TabBase);

export default {
  Tabs,
  Tab,
  Box,
};
