/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  Box as BoxBase,
  withStyles,
  Typography,
  Button,
  Divider,
} from "@material-ui/core";

const Container = withStyles((theme: any) => ({
  root: theme.tableFilter.container,
}))(BoxBase);

const Label = withStyles(() => ({
  root: {
    paddingBottom: "2.28rem",
    fontSize: "1.44rem",
  },
  // root: theme.tableFilter.label,
}))(Typography);

const Box = withStyles((theme: any) => ({
  root: theme.tableFilter.box,
}))(BoxBase);

const BoxButton = withStyles((theme: any) => ({
  root: theme.tableFilter.boxButton,
}))(BoxBase);

const ButtonSearch = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    textTransform: "capitalize",
  },
}))(Button);

export default {
  Container,
  Box,
  Label,
  BoxButton,
  ButtonSearch,
  Divider,
};
