// Dependencies
import React, { useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rmatch_NonIndividualRiskScoreArgs,
  Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column,
  useGetPaginatedNonIndividualRiskScoresQuery,
  Order_By,
  useNonIndividualRiskScoresFilterQuery,
  useGetMaxMinNonIndividualRiskScoreQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

// utils
import { useAppErrorHandler } from "errors/app.errors";

// Components
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";
import {
  aggregateArrayToObject,
  jsonToStringQueryVariables,
} from "graphql/hasura/rtcs.utils";
import {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";
import {
  columns as reportColumns,
  getAccessorType,
} from "./non-individual.schema";

// Assets
import SC from "./non-individual.styles";

const TABLE_NAME = "rtcs_db_Fnrmatch_NonIndividualRiskScore";

const NonIndividualPage: React.FC = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Rmatch_NonIndividualRiskScoreArgs | undefined
  >();

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useGetPaginatedNonIndividualRiskScoresQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const {
    data: maxMinRiskScore,
    loading: maxMinRiskScoreLoading,
    error: maxMinRiskScoreError,
  } = useGetMaxMinNonIndividualRiskScoreQuery({
    context: { clientName: CLIENT_NAME.HASURA },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || maxMinRiskScoreError || exportError);

  const columns = useMemo(() => reportColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column.Rnid,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatecm[0].value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData]
  );

  const { refetch: ReportFilterFn } = useNonIndividualRiskScoresFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Rmatch_NonIndividualRiskScoreArgs =
            {
              where: { [column]: { _ilike: `${value}%` } },
              distinct_on: [
                column as Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column,
              ],
              limit: 10,
            };

          const individualReportFilterData = await ReportFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions =
            individualReportFilterData.data.rtcs_db_Fnrmatch_NonIndividualRiskScore.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column]
            );
        }

        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [ReportFilterFn]
  );

  const newObj = aggregateArrayToObject(
    maxMinRiskScore?.rtcs_db_Fnrmatch_NonIndividualRiskScore_aggregatecm
  );

  const minRiskscore = newObj?.['min("Riskscore")'];

  const maxRiskscore = newObj?.['max("Riskscore")'];

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Risk Score",
        min: minRiskscore,
        max: maxRiskscore,
        columnName:
          Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column.Riskscore,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Risk Code",
        columnName:
          Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column.Riskcode,
        fetchSuggestions,
      },
    ];
  }, [fetchSuggestions, minRiskscore, maxRiskscore]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  const onTableFilterSearch = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  return (
    <>
      <SC.ProgressIndicator
        open={loading || maxMinRiskScoreLoading || exportLoading}
      />
      <SC.Table
        title="Non Individuals"
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        persistenceId="8db48835-dbdf-4002-a561-a6217bfcc09f"
        stickyHeader
        columns={columns}
        data={data?.rtcs_db_Fnrmatch_NonIndividualRiskScore ?? []}
        paginationControlled={paginationControlled}
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
        leftPanel={
          <TableFilter
            searchInputs={[
              {
                label: "Taxpayer Name, Trading Name, TIN number",
                columnNames: [
                  Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column.Taxpayerassumed,
                  Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column.Tradingname,
                  Rtcs_Db_Ph_Rmatch_NonIndividualRiskScore_Select_Column.Uratinno,
                ],
              },
            ]}
            onSearchChange={onTableFilterSearch}
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        exportData={exportDataFn}
      />
    </>
  );
};

export default NonIndividualPage;
