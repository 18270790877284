/* eslint-disable no-alert */
import React, { useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Order_By,
  Query_RootRtcs_Db_Ph_Ucc_UccArgs,
  Rtcs_Db_Ph_Ucc_Ucc_Select_Column,
  useGetPaginateduccReportQuery,
  useUccReportFilterQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

import { useAppErrorHandler } from "errors/app.errors";

// component depend
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";
import {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

import {
  columns as tableColumns,
  getAccessorType,
} from "./mobile-phone-numbers.schema";

import SC from "./mobile-phone-numbers.styles";

const TABLE_NAME = "rtcs_db_Fnucc_UCC";

const MobilePhoneNumbers: React.FC = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Ucc_UccArgs | undefined
  >(undefined);

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useGetPaginateduccReportQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const reportData = React.useMemo(() => {
    const report = data?.rtcs_db_Fnucc_UCC ?? [];
    return report;
  }, [data]);

  const columns = useMemo(() => tableColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    (params) => {
      const { pageIndex, pageSize, sortBy } = params;
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Ucc_Ucc_Select_Column.Customerid,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_Fnucc_UCC_aggregatecm[0].value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData]
  );

  const { refetch: ReportFilterFn } = useUccReportFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Ucc_UccArgs = {
            where: { [column]: { _ilike: `${value}%` } },
            distinct_on: [column as Rtcs_Db_Ph_Ucc_Ucc_Select_Column],
            limit: 10,
          };

          const uccReportFilterData = await ReportFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions = uccReportFilterData.data.rtcs_db_Fnucc_UCC.map(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (option) => option[column]
          );
        }

        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [ReportFilterFn]
  );

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "Mobile Phone",
        columnName: Rtcs_Db_Ph_Ucc_Ucc_Select_Column.Kccamobile,
        fetchSuggestions,
      },

      {
        type: TableFilterType.AUTOFILL,
        label: "First Name",
        columnName: Rtcs_Db_Ph_Ucc_Ucc_Select_Column.Firstname,
        fetchSuggestions,
      },

      {
        type: TableFilterType.AUTOFILL,
        label: "Surname",
        columnName: Rtcs_Db_Ph_Ucc_Ucc_Select_Column.Surname,
        fetchSuggestions,
      },

      {
        type: TableFilterType.AUTOFILL,
        label: "Identifier",
        columnName: Rtcs_Db_Ph_Ucc_Ucc_Select_Column.Idnumber,
        fetchSuggestions,
      },
    ];
  }, [fetchSuggestions]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <SC.ProgressIndicator open={loading || exportLoading} />
      <SC.Breadcrumb />
      <SC.Table
        title="Mobile Registration Data"
        actionsOnRight={[
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        columns={columns}
        data={reportData}
        leftPanel={
          <TableFilter
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        stickyHeader
        persistenceId="8da59275-eec2-43f7-9a87-7d51fc8969ae"
        paginationControlled={paginationControlled}
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
        exportData={exportDataFn}
      />
    </SC.Box>
  );
};

export default MobilePhoneNumbers;
