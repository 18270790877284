/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles, IconButton as IconButtonMui } from "@material-ui/core";

const IconButton = withStyles((theme: any) => ({
  root: {
    marginRight: theme.spacing(2),
    padding: theme.table.IconButton.paddindWithLef,
  },
}))(IconButtonMui);

export default {
  IconButton,
};
