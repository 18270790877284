export const API = Object.freeze({
  SETTINGS: {
    FEEDBACK_CATEGORIES: "api/feedback/categories",
    FEEDBACK: "api/feedback",
  },
});

export const MAP = Object.freeze({
  TYPE_ID: {
    ROADMAP: "roadmap",
    SATELLITE: "satellite",
    HYBRID: "hybrid",
    TERRAIN: "terrain",
  },
});

export const SETTINGS = Object.freeze({
  VIEW: "Change Map Type",
  DOCUMENTS: "Documents",
  REGION: "Region",
  LANGUAGE: "Language",
  CONTACT_US: "Contact Us",
});
