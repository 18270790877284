/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import _ from "lodash";
import { UccReportFragment } from "graphql/hasura/types-and-hooks";
import { Accessor } from "react-table";
import { TableColumn } from "components/table/table.component";

export type ReportTableSchema = Omit<
  UccReportFragment,
  "__typename" | "File" | "LastUpdated" | "Line" | "Uuid" | "WhoUpdated"
>;

export const UCC_PHONE_NUMBER_TABLE_SCHEMA_DATA_TYPE: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  Carrier: "string",
  Count: "number",
  Customerid: "string",
  Firstname: "string",
  Idnumber: "string",
  Idtype: "string",
  Kccamobile: "string",
  Surname: "string",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | Accessor<ReportTableSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return UCC_PHONE_NUMBER_TABLE_SCHEMA_DATA_TYPE[accessor];
};

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    Header: "First Name",
    accessor: "Firstname",
    format: "string",
    sort: true,
  },
  {
    Header: "Surname Name",
    accessor: "Surname",
    format: "string",
  },
  {
    Header: "Customer ID",
    accessor: "Customerid",
    format: "string",
  },
  {
    Header: "ID Type",
    accessor: "Idtype",
    format: "string",
  },
  {
    Header: "ID Number",
    accessor: "Idnumber",
    format: "string",
  },
  {
    Header: "Count",
    accessor: "Count",
    format: "number",
  },
  {
    Header: "Carrier",
    accessor: "Carrier",
    format: "string",
  },
  {
    Header: "Mobile",
    accessor: "Kccamobile",
    format: "string",
  },
];
