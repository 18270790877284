/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Container as ContainerBase,
  ListSubheader as ListSubheaderBase,
  Box as BoxBase,
} from "@material-ui/core";

import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import SubHeader from "components/sub-header/sub-header.component";

const Container = withStyles((theme: any) => ({
  root: {
    overflowY: "hidden",
    padding: 0,
    margin: 0,
    paddingRight: 0,
    marginRight: 0,
    backgroundColor: theme.accordionBody.backgroundColor,
    height: "max-content",
    maxWidth: "inherit",
    paddingBottom: theme.propertiesTab.container.paddingBottom,
  },
}))(ContainerBase);

const Box = withStyles(() => ({
  root: {
    height: "78%",
  },
}))(BoxBase);

const ListSubheader = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    margin: 0,
    padding: 0,
    zIndex: 2,
  },
}))(ListSubheaderBase);

export default {
  Container,
  Breadcrumb,
  ListSubheader,
  SubHeader,
  Box,
};
