/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { withStyles, Box as BoxMui, List } from "@material-ui/core";

const Box = withStyles((theme: any) => ({
  root: {
    paddingRight: "2rem",
    height: 300,
    overflowY: "auto",
    marginTop: theme.spacing(2),
  },
}))(BoxMui);

export default {
  Box,
  List,
};
