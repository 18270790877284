/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from "react";
import { TableColumn } from "components/table/table.component";
import { Link, useRouteMatch } from "react-router-dom";
import _ from "lodash";
import { Accessor } from "react-table";
import { UraRptIndividualTaxSummariesFragment } from "graphql/hasura/types-and-hooks";

export type TaxHistorySchema = Omit<
  UraRptIndividualTaxSummariesFragment,
  "__typename"
>;

export const TaxHistorySchemaMap: Record<
  keyof TaxHistorySchema,
  "string" | "number" | "date"
> = {
  RtnPeriodYear: "date",
  RtnNo: "number",
  IsAmendment: "string",
  TotIncome: "number",
  TotTax: "number",
  RentincTax: "number",
  GrossRentalIncome: "number",
  TotRate: "number",
  ChargeableIncome: "number",
  RentincRate: "number",
  RentalIncomeP: "number",
  ChargeableIncomeP: "number",
  ReturnType: "string",
  RtnDt: "date",
};

export const getAccessorType = (
  accessor: keyof TaxHistorySchema | Accessor<TaxHistorySchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return TaxHistorySchemaMap[accessor];
};

export const taxHistoryColumns: TableColumn<TaxHistorySchema>[] = [
  {
    Header: "Tax Year",
    accessor: "RtnPeriodYear",
    align: "left",
    sort: true,
  },
  {
    Header: "Return No.",
    accessor: "RtnNo",
    align: "left",
    Cell: ({ cell: { value } }) => {
      const { url } = useRouteMatch();
      return (
        <Link to={`${url}/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Amendment",
    accessor: "IsAmendment",
    align: "center",
  },
  {
    Header: "ReturnType",
    accessor: "ReturnType",
    format: "string",
  },
  {
    Header: "Return Date",
    accessor: "RtnDt",
    align: "center",
    format: "date",
    sort: true,
  },
  {
    Header: "Total Income",
    accessor: "TotIncome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Total Income Tax Declared",
    accessor: "TotTax",
    align: "right",
    format: "currency",
  },
  {
    Header: "IncomeTax %",
    accessor: "TotRate",
    align: "right",
    format: "percent",
  },
  {
    Header: "Total Gross Rental Income",
    accessor: "GrossRentalIncome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Total Chargeable Rental Income",
    accessor: "ChargeableIncome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Rental Tax",
    accessor: "RentincTax",
    align: "right",
    format: "currency",
  },
  {
    Header: "Rental Tax %",
    accessor: "RentincRate",
    align: "right",
    format: "percent",
  },
  {
    Header: "Chargeable Income Percent",
    accessor: "ChargeableIncomeP",
    hiddenColumn: true,
    align: "right",
    format: "percent",
  },
  {
    Header: "Rental Income Percent",
    accessor: "RentalIncomeP",
    hiddenColumn: true,
    align: "right",
    format: "percent",
  },
  {
    Header: "Rental Income / Total Income (%)",
    // @ts-ignore
    accessor: "n/a",

    align: "center",
    Cell: ({
      row: {
        // @ts-ignore
        values: { RentalIncomeP },
      },
    }) => {
      return RentalIncomeP;
    },
    disableSortBy: true,
  },
];
