// TODO: Remove this after changes are made to Non Individual Composite
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

import {
  UraAuthIndividualQuery_UraAuthIndividual_ElementKey,
  UraRegAssociateQuery_UraRegAssociate_ElementKey,
} from "graphql/hasura/rtcs.types";

export interface UraAuthIndividual {
  label: string;
  accessor: UraAuthIndividualQuery_UraAuthIndividual_ElementKey;
  index: number;
}

export interface UraRegAssociate {
  label: string;
  accessor: UraRegAssociateQuery_UraRegAssociate_ElementKey;
  index: number;
}

export const authIndvidiualColumnOne: UraAuthIndividual[] = [
  {
    label: "First Name",
    accessor: "AuthctcdFirstName",
    index: 0,
  },
  {
    label: "Surname",
    accessor: "AuthctcdSurname",
    index: 1,
  },
  {
    label: "Family Name",
    accessor: "AuthctcdFamilyName",
    index: 2,
  },
  {
    label: "Other Name",
    accessor: "AuthctcdOtherName",
    index: 3,
  },
];

export const authIndvidiualColumnTwo: UraAuthIndividual[] = [
  {
    label: "Person Designation",
    accessor: "AuthctcdPrsnDesgn",
    index: 0,
  },
  {
    label: "Landline No.",
    accessor: "AuthctcdLandlineNumber",
    index: 1,
  },
  {
    label: "Mobile No.",
    accessor: "AuthctcdMobileNumber",
    index: 2,
  },
  {
    label: "Email",
    accessor: "AuthctcdEmailId",
    index: 3,
  },
  {
    label: "Contact No.",
    accessor:
      "AuthctcdContactNumber" as UraAuthIndividualQuery_UraAuthIndividual_ElementKey,
    index: 4,
  },
  {
    label: "TIN No.",
    accessor:
      "AuthctcdTin" as UraAuthIndividualQuery_UraAuthIndividual_ElementKey,
    index: 5,
  },
  {
    label: "National ID No.",
    accessor:
      "NationalId" as UraAuthIndividualQuery_UraAuthIndividual_ElementKey,
    index: 6,
  },
];

export const regAssociateColumnOne: UraRegAssociate[] = [
  {
    label: "RNID",
    accessor:
      "RnidAssociate" as UraAuthIndividualQuery_UraAuthIndividual_ElementKey,
    index: 0,
  },
  {
    label: "First Name",
    accessor:
      "FirstName" as UraAuthIndividualQuery_UraAuthIndividual_ElementKey,
    index: 1,
  },
  {
    label: "Surname",
    accessor: "Surname" as UraAuthIndividualQuery_UraAuthIndividual_ElementKey,
    index: 2,
  },
  {
    label: "Family Name",
    accessor:
      "FamilyName" as UraAuthIndividualQuery_UraAuthIndividual_ElementKey,
    index: 3,
  },
];
export const regAssociateColumnTwo: UraRegAssociate[] = [
  {
    label: "Relationship",
    accessor:
      "EntityDesignation" as UraAuthIndividualQuery_UraAuthIndividual_ElementKey,
    index: 0,
  },
  {
    label: "TIN No",
    accessor: "AssociateTin",
    index: 1,
  },
];

export default {};
