/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { IndividualCompliancePlusRiskReportFragment } from "graphql/hasura/types-and-hooks";
import { ReactNode } from "react";

export type ReportTableSchema = Omit<
  IndividualCompliancePlusRiskReportFragment,
  "__typename" | "RiskCode"
>;

export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  Averagerateperrentedproperty: "number",
  Compliancecategory: "string",
  Countura: "string",
  Customerid: "string",
  Difference: "string",
  Emailid: "string",
  Grossrentalincome: "number",
  Isamendment: "string",
  Isincomesrcbsns: "string",
  Isincomesrcemp: "string",
  Isincomesrcprpty: "string",
  Isincomesrcrental: "string",
  Kccabirthdate: "number",
  Kccaemail: "string",
  Kccafirstname: "string",
  Kccamobile1: "string",
  Kccamobile2: "string",
  Kccasurname: "string",
  Kccatelephone: "string",
  Kccatin: "string",
  Matchcategory: "string",
  Matchscore: "string",
  Matchscoreattributes: "string",
  Mobilenumber: "string",
  Nationalid: "string",
  Numproperties: "number",
  Numrentedproperties: "number",
  Percentagedifference: "number",
  Propincincome: "string",
  Rnid: "string",
  Rtnno: "string",
  Rtnperiodyear: "string",
  Source: "string",
  Sumadjustedincome: "number",
  Sumgrossvalue: "number",
  Sumnonrateablevalue: "number",
  Sumrateablevalue: "number",
  Urafirstname: "string",
  Uramiddlename: "string",
  Urasurname: "string",
  Uratinno: "string",
  RiskScore: "number",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | Accessor<ReportTableSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const reportTableColumns: TableColumn<ReportTableSchema>[] = [
  {
    Header: "RNID",
    accessor: "Rnid",
    align: "left",
    Cell: ({
      row: {
        values: { Rnid },
      },
    }) => {
      return (
        <Link to={`/individuals/${Rnid}/identification`} color="inherit">
          {Rnid}
        </Link>
      );
    },
  },
  {
    Header: "Risk Score",
    accessor: "RiskScore",
    align: "center",
    sort: true,
  },
  {
    Header: "Customer ID",
    accessor: "Customerid",
    align: "left",
  },
  {
    Header: "First Name",
    accessor: "Kccafirstname",
    align: "left",
  },
  {
    Header: "Surname",
    accessor: "Kccasurname",
    align: "left",
  },
  {
    Header: "Mobile",
    accessor: "Kccamobile1",
    align: "left",
  },
  {
    Header: "Mobile2",
    accessor: "Kccamobile2",
    align: "left",
  },
  {
    Header: "Telephone",
    accessor: "Kccatelephone",
    align: "left",
  },
  {
    Header: "Birth Date",
    accessor: "Kccabirthdate",
    align: "center",
    format: "date",
  },
  {
    Header: "KCCA NIN",
    accessor: "Nationalid",
    align: "left",
  },
  {
    Header: "Email",
    accessor: "Kccaemail",
    align: "left",
  },

  {
    Header: "Total No. Of Properties",
    accessor: "Numproperties",
    align: "right",
    Cell: ({
      value,
      row: {
        values: { Rnid },
      },
    }: {
      value: string;
      row: { values: { Rnid: string } };
    }): ReactNode => {
      return (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Total No. Of Rented Properties",
    accessor: "Numrentedproperties",
    align: "right",
    Cell: ({
      value,
      row: {
        values: { Rnid },
      },
    }: {
      value: string;
      row: { values: { Rnid: string } };
    }): ReactNode => {
      return (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Sum Rateable Value Of Rented Properties",
    accessor: "Sumrateablevalue",
    align: "right",
    format: "currency",
  },
  {
    Header: "Sum Adjusted Gross Rental Income",
    accessor: "Sumadjustedincome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Average Rate Per Rented Property",
    accessor: "Averagerateperrentedproperty",
    align: "right",
    format: "currency",
  },
];
