/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Link } from "react-router-dom";
import React from "react";

// styles
import SC from "./report-card.styles";

export interface ReportCardProps {
  title?: string;
  titleWithoutLink?: string;
  type?: string;
  content?: string[];
  buttonContent?: string[];
  link?: string[];
  subtitle?: string[];
  otherText?: string;
  nickname?: string;
  onClickReport?: () => void;
}

export const ReportCard = ({
  title,
  titleWithoutLink,
  content,
  subtitle,
  otherText,
  buttonContent,
  link,
  onClickReport,
}: ReportCardProps) => {
  return (
    <SC.Container item xs={12}>
      <SC.Paper elevation={0}>
        <SC.Title>{title}</SC.Title>
        <SC.TitleWithoutLink>{titleWithoutLink}</SC.TitleWithoutLink>
        {content?.map((cont) => (
          <SC.Content key={cont}> {cont} </SC.Content>
        ))}
        {buttonContent?.map((cont, index) => (
          <SC.TitleLink
            key={cont}
            underline="none"
            to={`${link?.[index]}`}
            component={Link}
          >
            {cont}
          </SC.TitleLink>
        ))}
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          subtitle?.map((subt: any) => (
            <SC.TitleLink
              key={subt.label}
              underline="none"
              to={subt.type === "report" ? "#" : `${subt.url}`}
              onClick={subt.type === "report" ? onClickReport : undefined}
              component={Link}
            >
              {subt.label}
            </SC.TitleLink>
          ))
        }
        {otherText && <SC.Label> {otherText} </SC.Label>}
      </SC.Paper>
    </SC.Container>
  );
};

export default ReportCard;
