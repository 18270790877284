/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Link } from "react-router-dom";
import { TableColumn } from "components/table/table.component";

import {
  RCapturePropertyLessorsFragment,
  RCapturePropertyTenantFragment,
  RCapturePropertyUnitTypeFragment,
} from "graphql/hasura/types-and-hooks";

export type LessorTableSchema = Omit<
  RCapturePropertyLessorsFragment,
  "__typename"
>;
export type TenantTableSchema = Omit<
  RCapturePropertyTenantFragment,
  "__typename"
>;
export type UnitTypesTableSchema = Omit<
  RCapturePropertyUnitTypeFragment,
  "__typename"
>;

// lessor Columns
export const lessorColumns: TableColumn<LessorTableSchema>[] = [
  {
    Header: "uuid",
    accessor: "Id",
    Cell: ({ value }) => {
      return (
        <Link to={`/reports/MDA/rCAPTURE/lessors/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "SGUID",
    accessor: "Sguid",
  },
  {
    Header: "Identifier",
    accessor: "Identifier",
  },
  {
    Header: "Type",
    accessor: "Type",
  },
  {
    Header: "Title",
    accessor: "Title",
  },
  {
    Header: "FirstName",
    accessor: "Firstname",
  },
  {
    Header: "LastName",
    accessor: "Lastname",
  },
  {
    Header: "BusinessTradingName",
    accessor: "Businesstradingname",
  },
  {
    Header: "LegalBusinessName",
    accessor: "Legalbusinessname",
  },
  {
    Header: "RegisteredBusinessNumber",
    accessor: "Registeredbusinessnumber",
  },
  {
    Header: "TradingLicenseNumber",
    accessor: "Tradinglicensenumber",
  },
  {
    Header: "TIN",
    accessor: "Tin",
  },
  {
    Header: "PositionInCompany",
    accessor: "Positionincompany",
  },
  {
    Header: "CIN",
    accessor: "Cin",
  },
  {
    Header: "Phone1",
    accessor: "Phone1",
  },
  {
    Header: "Phone2",
    accessor: "Phone2",
  },
  {
    Header: "Phone3",
    accessor: "Phone3",
  },
  {
    Header: "Phone4",
    accessor: "Phone4",
  },
  {
    Header: "Email",
    accessor: "Email",
  },
  {
    Header: "Nationality",
    accessor: "Nationality",
  },
  {
    Header: "BuildingName",
    accessor: "Buildingname",
  },
  {
    Header: "PlotNumber",
    accessor: "Plotnumber",
  },
  {
    Header: "StreetName",
    accessor: "Streetname",
  },
  {
    Header: "District",
    accessor: "District",
  },
  {
    Header: "Village",
    accessor: "Village",
  },
  {
    Header: "Parish",
    accessor: "Parish",
  },
  {
    Header: "Subcounty",
    accessor: "Subcounty",
  },
  {
    Header: "County",
    accessor: "County",
  },
  {
    Header: "Region",
    accessor: "Region",
  },
  {
    Header: "PostalPOBox",
    accessor: "Postalpobox",
  },
  {
    Header: "PostalDistrict",
    accessor: "Postaldistrict",
  },
  {
    Header: "RepTitle",
    accessor: "Reptitle",
  },
  {
    Header: "RepFirstName",
    accessor: "Repfirstname",
  },
  {
    Header: "RepLastname",
    accessor: "Replastname",
  },
  {
    Header: "RepDOB",
    accessor: "Repdob",
    format: "date",
  },
  {
    Header: "RepPhone1",
    accessor: "Repphone1",
  },
  {
    Header: "RepOtherCountry",
    accessor: "Repothercountry",
  },
  {
    Header: "RepNationality",
    accessor: "Repnationality",
  },
  {
    Header: "CustomerID",
    accessor: "Customerid",
  },
  {
    Header: "CustomerIDSrc",
    accessor: "Customeridsrc",
  },
];

// tenant columns
export const tenantColumns: TableColumn<TenantTableSchema>[] = [
  {
    Header: "uuid",
    accessor: "Id",
    Cell: ({ value }) => {
      return (
        <Link to={`/reports/MDA/rCAPTURE/tenants/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Identifier",
    accessor: "Identifier",
  },
  {
    Header: "FloorNumber",
    accessor: "Floornumber",
  },
  {
    Header: "ShopNumber",
    accessor: "Shopnumber",
  },
  {
    Header: "ShopName",
    accessor: "Shopname",
  },
  {
    Header: "TypeOfGoodsSold",
    accessor: "Typeofgoodssold",
  },
  {
    Header: "NumberBedrooms",
    accessor: "Numberbedrooms",
    align: "right",
    format: "number",
  },
  {
    Header: "NumberBathrooms",
    accessor: "Numberbathrooms",
    align: "right",
    format: "number",
  },
  {
    Header: "EstimatedShopSize",
    accessor: "Estimatedshopsize",
  },
  {
    Header: "Type",
    accessor: "Type",
  },
  {
    Header: "Title",
    accessor: "Title",
  },
  {
    Header: "FirstName",
    accessor: "Firstname",
  },
  {
    Header: "LastName",
    accessor: "Lastname",
  },
  {
    Header: "BusinessTradingName",
    accessor: "Businesstradingname",
  },
  {
    Header: "LegalBusinessName",
    accessor: "Legalbusinessname",
  },
  {
    Header: "RegisteredBusinessNumber",
    accessor: "Registeredbusinessnumber",
  },
  {
    Header: "TIN",
    accessor: "Tin",
  },
  {
    Header: "MDA",
    accessor: "Mda",
  },
  {
    Header: "PositionInCompany",
    accessor: "Positionincompany",
  },
  {
    Header: "IsUnitLet",
    accessor: "Isunitlet",
  },
  {
    Header: "OwnerOccupiedOrRented",
    accessor: "Owneroccupiedorrented",
  },
  {
    Header: "Phone1",
    accessor: "Phone1",
  },
  {
    Header: "Phone2",
    accessor: "Phone2",
  },
  {
    Header: "Phone3",
    accessor: "Phone3",
  },
  {
    Header: "Phone4",
    accessor: "Phone4",
  },
  {
    Header: "Email",
    accessor: "Email",
  },
  {
    Header: "Nationality",
    accessor: "Nationality",
  },
  {
    Header: "RepTitle",
    accessor: "Reptitle",
  },
  {
    Header: "RepTitleOther",
    accessor: "Reptitleother",
  },
  {
    Header: "RepFirstName",
    accessor: "Repfirstname",
  },
  {
    Header: "RepMiddleName",
    accessor: "Repmiddlename",
  },
  {
    Header: "RepLastName",
    accessor: "Replastname",
  },
  {
    Header: "RepEmail",
    accessor: "Repemail",
  },
  {
    Header: "RepPhone1",
    accessor: "Repphone1",
  },
  {
    Header: "RepNationality",
    accessor: "Repnationality",
  },
  {
    Header: "RepOtherCountry",
    accessor: "Repothercountry",
  },
  {
    Header: "Actualrentpaidpermonth",
    accessor: "Actualrentpaidpermonth",
    align: "right",
    format: "currency",
  },
  {
    Header: "Cactualrentpaidpermonth",
    accessor: "Cactualrentpaidpermonth",
    align: "right",
    format: "currency",
  },
  {
    Header: "Rentreportedbylessorpermonth",
    accessor: "Rentreportedbylessorpermonth",
    align: "right",
    format: "currency",
  },
  {
    Header: "Currencyreportedrent",
    accessor: "Currencyreportedrent",
  },
  {
    Header: "Amountofrentreceipts",
    accessor: "Amountofrentreceipts",
    align: "right",
    format: "currency",
  },
  {
    Header: "Currencyactualrent",
    accessor: "Currencyactualrent",
  },
  {
    Header: "Notes",
    accessor: "Notes",
  },
];

// unittype columns
export const unitTypeColumns: TableColumn<UnitTypesTableSchema>[] = [
  {
    Header: "Id",
    accessor: "Id",
    Cell: ({ value }) => {
      return (
        <Link to={`/reports/MDA/rCAPTURE/unittypes/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "UnitType",
    accessor: "Unittype",
  },
  {
    Header: "UnitSize",
    accessor: "Unitsize",
    align: "right",
    format: "number",
  },
  {
    Header: "NumberBedrooms",
    accessor: "Numberbedrooms",
    align: "right",
    format: "number",
  },
  {
    Header: "NumberBathrooms",
    accessor: "Numberbathrooms",
    align: "right",
    format: "number",
  },
  {
    Header: "UnitRent",
    accessor: "Unitrent",
    format: "currency",
  },
  {
    Header: "Currencyadvertisedrent",
    accessor: "Currencyadvertisedrent",
    format: "currency",
  },
  {
    Header: "UnitFurnished",
    accessor: "Unitfurnished",
  },
  {
    Header: "NumberUnitsType",
    accessor: "Numberunitstype",
    align: "right",
  },
  {
    Header: "Uuid_property",
    accessor: "Uuidproperty",
    Cell: ({ value }) => {
      return (
        <Link
          to={`/reports/MDA/rCAPTURE/properties/${value}/details`}
          color="inherit"
        >
          {value}
        </Link>
      );
    },
  },
  {
    Header: "UGUID",
    accessor: "Uguid",
  },
];
