// Dependencies
import { withStyles, Box as BoxBase, Checkbox } from "@material-ui/core";
import RoomIcon from "@material-ui/icons/Room";

const Box = withStyles({})(BoxBase);

const VisibilityIcon = withStyles(() => ({
  root: {
    paddingTop: 0,
  },
}))(Checkbox);

export default {
  Box,
  RoomIcon,
  VisibilityIcon,
};
