/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { TableColumn } from "components/table/table.component";
import { IndividualCompliancePlusRiskReportFragment } from "graphql/hasura/types-and-hooks";

export type ReportTableSchema = Omit<
  IndividualCompliancePlusRiskReportFragment,
  | "__typename"
  | "RtnNo"
  | "IsAmendment"
  | "PropincIncome"
  | "RentincIncome"
  | "Difference"
  | "RiskCode"
  | "Customerid"
  | "Kccabirthdate"
  | "Kccamobile1"
  | "Kccamobile2"
  | "Kccaemail"
  | "Kccatelephone"
  | "Source"
>;

export const INDIVIDUAL_DEFAULT_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  Rnid: "string",
  Uratinno: "string",
  Uramiddlename: "string",
  Urafirstname: "string",
  Urasurname: "string",
  Matchscore: "number",
  Kccatin: "string",
  Sumrateablevalue: "number",
  Sumnonrateablevalue: "number",
  Sumgrossvalue: "number",
  Sumadjustedincome: "number",
  Rtnperiodyear: "string",
  Rtnno: "string",
  Propincincome: "number",
  Averagerateperrentedproperty: "number",
  Compliancecategory: "string",
  Countura: "number",
  Percentagedifference: "number",
  Numrentedproperties: "number",
  Numproperties: "number",
  Nationalid: "string",
  Mobilenumber: "string",
  Matchscoreattributes: "string",
  Matchcategory: "string",
  Kccasurname: "string",
  Kccafirstname: "string",
  Isincomesrcrental: "string",
  Isincomesrcprpty: "string",
  Isincomesrcemp: "string",
  Isincomesrcbsns: "string",
  Isamendment: "string",
  Grossrentalincome: "number",
  Emailid: "string",
  RiskScore: "number",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | Accessor<ReportTableSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_DEFAULT_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    Header: "RNID",
    accessor: "Rnid",
    align: "left",
    Cell: ({
      cell: { value },
      row: {
        values: { Rnid },
      },
    }) => (
      <Link to={`/individuals/${Rnid}/identification`} color="inherit">
        {value}
      </Link>
    ),
  },
  {
    Header: "Risk Score",
    accessor: "RiskScore",
    align: "center",
    sort: true,
  },
  {
    Header: "URA First Name",
    accessor: "Urafirstname",
    align: "left",
  },
  {
    Header: "URA Middle Name",
    accessor: "Uramiddlename",
    align: "left",
  },
  {
    Header: "URA Surname",
    accessor: "Urasurname",
    align: "left",
  },
  {
    Header: "rMATCH Score",
    accessor: "Matchscore",
    align: "center",
    Cell: ({
      cell: { value },
      row: {
        values: { Rnid },
      },
    }) => (
      <Link to={`/individuals/${Rnid}/analysis`} color="inherit">
        {value}
      </Link>
    ),
  },
  {
    Header: "TIN No.",
    accessor: "Uratinno",
    align: "center",
  },
  {
    Header: "Rental Income?",
    accessor: "Isincomesrcrental",
    align: "center",
  },
  {
    Header: "URA Mobile No.",
    accessor: "Mobilenumber",
    align: "center",
  },
  {
    Header: "URA Email",
    accessor: "Emailid",
    align: "center",
  },
  {
    Header: "URA NIN",
    accessor: "Nationalid",
    align: "center",
  },
  {
    Header: "Tax Year",
    accessor: "Rtnperiodyear",
    align: "center",
  },
  {
    Header: "Total Number of Properties",
    accessor: "Numproperties",
    align: "center",
    Cell: ({
      cell: { value },
      row: {
        values: { Rnid },
      },
    }) => (
      <Link to={`/individuals/${Rnid}/properties`} color="inherit">
        {value}
      </Link>
    ),
  },
  {
    Header: "Total Number of Rental Properties",
    accessor: "Numrentedproperties",
    align: "center",
    Cell: ({
      cell: { value },
      row: {
        values: { Rnid },
      },
    }) => (
      <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
        {value}
      </Link>
    ),
  },
  {
    Header: "Total Adjusted Gross Rental Income",
    accessor: "Sumadjustedincome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Average Rate Per Property",
    accessor: "Averagerateperrentedproperty",
    align: "right",
    format: "currency",
  },
];
