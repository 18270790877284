/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles, Typography } from "@material-ui/core";
import TaxHistoryAccordion from "components/tax-history-accordion/tax-history-accordion";
import NestedList from "components/nested-list/nested-list.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

const SectionSubHeaderTypography = withStyles((theme: any) => ({
  root: {
    paddingTop: "2.28rem",
    paddingBottom: "0.28rem",
    fontSize: theme.taxHistory.financialSummary.subHeader.fontSize,
    fontWeight: theme.taxHistory.financialSummary.subHeader.fontWeight,
    lineHeight: "1.71rem",
    "& .MuiTypography-gutterBottom": {
      marginBottom: 0,
    },
  },
}))(Typography);

export default {
  SectionSubHeaderTypography,
  TaxHistoryAccordion,
  NestedList,
  ProgressIndicator,
};
