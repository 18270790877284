import React from "react";
import { useLocation } from "react-router-dom";

// Components
import LandingPage from "components/landing-page/landing-page.component";
import PasswordField from "components/password-field/password-field.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Assets
import SC from "components/landing-page/landing-page.styles";
import { AlertProps } from "components/alert/alert.component";

// GraphQL
import { useResetPasswordMutation } from "graphql/types-and-hooks";

// Utils
import { useAppErrorHandler, isServerError } from "errors/app.errors";

const ResetPassword: React.FC = () => {
  const [firstPassword, setFirstPassword] = React.useState<string>("");
  const [secondPassword, setSecondPassword] = React.useState<string>("");
  const location = useLocation();
  const { pathname } = location;
  const verificationCode = pathname.split("/").slice(2)[0];

  const [alertMessage, setAlertMessage] = React.useState<
    AlertProps | undefined
  >();

  const [resetPassword, { data, loading }] = useResetPasswordMutation({
    context: {
      noAuthNeeded: true,
    },
  });
  const errorHandler = useAppErrorHandler();

  React.useEffect(() => {
    if (firstPassword !== secondPassword) {
      setAlertMessage({
        message: "Passwords do not match.",
        severity: "error",
      });
    } else {
      setAlertMessage(undefined);
    }
  }, [firstPassword, secondPassword]);

  const closeAlertMessage = () => {
    setAlertMessage(undefined);
  };

  const handlerPasswords = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (name === "firstpassword") {
      setFirstPassword(value);
    } else {
      setSecondPassword(value);
    }
  };

  const handlerOnSubmit: React.FormEventHandler = React.useCallback(
    async (event) => {
      event.preventDefault();

      if (firstPassword === secondPassword && firstPassword !== "")
        try {
          setAlertMessage(undefined);
          await resetPassword({
            variables: {
              code: verificationCode,
              password: firstPassword,
            },
          });
          setAlertMessage({
            message: "Your password has been successfully updated",
            severity: "success",
          });
        } catch (error) {
          const err = error as Error;
          if (isServerError(err)) {
            setAlertMessage({
              message: err.message,
              severity: "error",
            });
          } else {
            errorHandler(err);
          }
        }
    },
    [
      errorHandler,
      firstPassword,
      resetPassword,
      secondPassword,
      verificationCode,
    ]
  );

  return (
    <LandingPage>
      <SC.Box component="form" onSubmit={handlerOnSubmit}>
        <ProgressIndicator open={loading} />
        {alertMessage && (
          <SC.Alert
            elevation={0}
            icon={false}
            severity={alertMessage.severity}
            message={alertMessage.message}
            onClose={closeAlertMessage}
          />
        )}

        {data?.ResetPassword ? (
          <SC.Box>
            <SC.ButtonContainer>
              <SC.Button color="primary" variant="contained" href="/login">
                Go To Login Page
              </SC.Button>
            </SC.ButtonContainer>
          </SC.Box>
        ) : (
          <SC.Box>
            <SC.TexFieldBox>
              <PasswordField
                name="firstpassword"
                label="Password"
                value={firstPassword}
                required
                onChange={handlerPasswords}
              />
            </SC.TexFieldBox>
            <SC.TexFieldBox>
              <PasswordField
                name="secondpassword"
                label="Confirm Your Password"
                value={secondPassword}
                required
                onChange={handlerPasswords}
              />
            </SC.TexFieldBox>
            <SC.HelpContainerBox>
              <SC.HelpBoxLeft>
                <SC.HelpText>Enter your Password</SC.HelpText>
              </SC.HelpBoxLeft>
            </SC.HelpContainerBox>
            <SC.ButtonContainer>
              <SC.Button color="primary" variant="contained" type="submit">
                Reset
              </SC.Button>
            </SC.ButtonContainer>
          </SC.Box>
        )}
      </SC.Box>
    </LandingPage>
  );
};

export default ResetPassword;
