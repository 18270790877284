/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Card,
  CardContent,
  TextField as TextFieldBase,
  Box,
  Button,
  withStyles,
  Typography,
} from "@material-ui/core";
import NoteToolbar from "../note-toolbar/note-toolbar.component";

const NoteForm = withStyles(() => ({
  root: {
    marginBottom: "1.14rem",
  },
}))(Card);

const NoteFormRow = withStyles(() => ({
  root: {
    paddingTop: "1.14rem",
    display: "flex",
    alignItems: "center",
  },
}))(Box);

const NoteFormContent = withStyles(() => ({
  root: {
    paddingTop: "1.14rem",
    paddingBottom: "1.14rem",
    paddingRight: "2.28rem",
    paddingLeft: "2.28rem",
    "&:last-child": {
      paddingBottom: "1.14rem",
    },
  },
}))(CardContent);

const FileAttachmentContainer = withStyles((theme: any) => ({
  root: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    marginTop: "1.14rem",
    "& > *": {
      marginRight: "1.14rem",
    },
    "& input": {
      position: "absolute",
      top: 0,
      zIndex: "-1",
    },
    "& input:focus + label": {
      outline: "2px solid",
    },
    "& label": {
      display: "flex",
      alignItems: "center",
      color: theme.palette.primary.main,
      cursor: "pointer",
      "& > h6": {
        fontSize: theme.notes.body.fontSize,
        marginRight: "1.14rem",
      },
    },
  },
}))(Box);

const PostButton = withStyles(() => ({
  root: {
    marginLeft: 0,
    marginRight: "1.14rem",
  },
}))(Button);

const CancelButton = withStyles(() => ({
  root: {
    marginLeft: 0,
  },
}))(Button);

const TextField = withStyles(() => ({
  root: {
    margin: 0,
    marginRight: "1.14rem",
    flexGrow: 1,
    "& MuiInputBase-input": {
      backgroundColor: "green",
      maxHeight: "13.21rem",
    },
  },
}))(TextFieldBase);

const Attachment = withStyles((theme: any) => ({
  root: {
    marginRight: "1.14rem",
    fontSize: "0.75rem",
    color: theme.palette.text.disabled,
  },
}))(Typography);

export default {
  TextField,
  NoteForm,
  NoteToolbar,
  NoteFormContent,
  NoteFormRow,
  FileAttachmentContainer,
  PostButton,
  CancelButton,
  Typography,
  Attachment,
};
