// TODO: Remove this after changes are made to Non Individual Composite
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck
import React from "react";

// GraphQL
import {
  Query_RootRtcs_Db_Ph_Ura_RegNonIndividualArgs,
  useNonIndividualAffilatesAndIndividualsQuery,
} from "graphql/hasura/types-and-hooks";
import { CLIENT_NAME } from "graphql/client";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

import SC from "./affilates-and-individuals.styles";
import {
  authIndvidiualColumnOne,
  authIndvidiualColumnTwo,
  regAssociateColumnOne,
  regAssociateColumnTwo,
} from "./accessors";

interface AffiliatesAndIndividualsProps {
  rnid: string;
}

const AffiliatesAndIndividuals: React.FC<AffiliatesAndIndividualsProps> = ({
  rnid,
}) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Ura_RegNonIndividualArgs = {
    where: { Rnid: { _eq: rnid } },
  };
  const { data, error } = useNonIndividualAffilatesAndIndividualsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !rnid,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  useAppErrorHandler(error);

  const columnOneData = authIndvidiualColumnOne.map(
    ({ index, accessor, label }) => {
      return {
        index,
        label,
        value: data?.rtcs_db_Fnura_RegNonIndividual[0]?.[accessor],
      };
    }
  );

  const columnTwoData = authIndvidiualColumnTwo.map(
    ({ index, accessor, label }) => {
      return {
        index,
        label,
        value: data?.rtcs_db_Fnura_RegNonIndividual[0]?.[accessor],
      };
    }
  );

  const { AuthctcdFirstName, AuthctcdSurname } =
    data?.rtcs_db_Fnura_RegNonIndividual[0] || {};

  const registeredAssociates = data?.rtcs_db_Fnura_RegAssociates.map(
    (associate) => {
      return {
        columnOne: regAssociateColumnOne.map(({ index, accessor, label }) => {
          return {
            label,
            index,
            // @ts-ignore
            value: associate[accessor],
          };
        }),
        columnTwo: regAssociateColumnTwo.map(({ index, accessor, label }) => {
          return {
            label,
            index,
            // @ts-ignore
            value: associate[accessor],
          };
        }),
      };
    }
  );

  return (
    <SC.Container maxWidth={false}>
      {data?.rtcs_db_Fnura_RegNonIndividual.length > 0 && (
        <SC.Box>
          <SC.Typography variant="h5">Authenicated Individual</SC.Typography>

          <SC.Card elevation={0}>
            <SC.Grid container>
              <SC.Grid item xs={12}>
                <SC.Typography variant="h6">{`${AuthctcdFirstName} ${AuthctcdSurname}`}</SC.Typography>
              </SC.Grid>
              <SC.Grid item xs={6}>
                {columnOneData.map((idn) => (
                  <SC.LabelKeyValue
                    label={idn.label}
                    value={idn.value ?? ""}
                    key={idn.index}
                  />
                ))}
              </SC.Grid>
              <SC.Grid item xs={6}>
                {columnTwoData.map((idn) => (
                  <SC.LabelKeyValue
                    label={idn.label}
                    value={idn.value ?? ""}
                    key={idn.index}
                  />
                ))}
              </SC.Grid>
            </SC.Grid>
          </SC.Card>
        </SC.Box>
      )}

      {data?.rtcs_db_Fnura_RegAssociates.length > 0 && (
        <SC.Box>
          <SC.Typography variant="h5">Registered Associates</SC.Typography>
          {registeredAssociates.map((associate) => {
            return (
              <SC.Card elevation={0}>
                <SC.Grid container>
                  <SC.Grid item xs={12}>
                    <SC.Typography variant="h6">{`${associate.columnOne[1].value} ${associate.columnOne[2].value}`}</SC.Typography>
                  </SC.Grid>
                  <SC.Grid item xs={6}>
                    {associate.columnOne.map((idn) => (
                      <SC.LabelKeyValue
                        label={idn.label}
                        value={idn.value}
                        key={idn.index}
                      />
                    ))}
                  </SC.Grid>
                  <SC.Grid item xs={6}>
                    {associate.columnTwo.map((idn) => (
                      <SC.LabelKeyValue
                        label={idn.label}
                        value={idn.value}
                        key={idn.index}
                      />
                    ))}
                  </SC.Grid>
                </SC.Grid>
              </SC.Card>
            );
          })}
        </SC.Box>
      )}
    </SC.Container>
  );
};

export default AffiliatesAndIndividuals;
