/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Accordion,
  AccordionSummary as AccordionSummaryBase,
  AccordionDetails,
  Typography,
  IconButton,
  List,
} from "@material-ui/core";

const AccordionSummary = withStyles({
  content: {
    alignItems: "center",
  },
})(AccordionSummaryBase);

const Label = withStyles((theme: any) => ({
  root: {
    marginRight: theme.spacing(2),
  },
}))(Typography);

export default {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Label,
  IconButton,
  List,
};
