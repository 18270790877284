/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { Maybe } from "graphql/types-and-hooks";

// eslint-disable-next-line import/prefer-default-export
export const formatNumber = (value: string) => {
  let newValue = 0;
  try {
    newValue = Number(value.replace(/\D/g, ""));
  } catch (error) {
    newValue = Number(value);
  }
  return newValue;
};

export const averageRatePerRentedProperty = (
  value1: number,
  value2: number
) => {
  if (value1 === 0 && value2 === 0) {
    return 0;
  }
  return value1 / value2;
};

export const range = (start: number, stop: number, step: number) =>
  Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step);

export const roundOffToNearestMillion = (value: number) => {
  if (value < 1000000) {
    return value;
  }
  const newVar = value.toString().split("");
  if (Number(newVar[6]) >= 5) {
    return `${Number(newVar.slice(0, 6).join("")) + 1}`;
  }
  return `${Number(newVar.slice(0, 6).join(""))}`;
};

export const percentageOf = (
  total: Maybe<string> | undefined,
  value: Maybe<string> | undefined | number,
  description: string
) => {
  if (total === null || value == null) return "";

  if (Number(total) < 1) return "0 %";

  const result = (Number(value) / Number(total)) * 100;

  return `${parseFloat(result.toFixed(2))} % ${description}`;
};
