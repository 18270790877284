/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";

import SC from "./styles";

export const ImagePlaceholder = () => {
  return (
    <SC.ImageContainer>
      <SC.Icon />
    </SC.ImageContainer>
  );
};

export default ImagePlaceholder;
