/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import {
  Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  Rtcs_Db_Ph_Ura_RentExpenseDetails,
} from "graphql/hasura/types-and-hooks";

import { getFormattedDate } from "utils/internationalization";
import { percentageOf } from "utils/math";

const modelExpense = (
  { Name, Amount }: Rtcs_Db_Ph_Ura_RentExpenseDetails,
  level: number
) => ({
  label: Name,
  value: Amount,
  format: "currency",
  level,
});

export const modelOtherExpenses = (
  data: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData
) => [
  {
    label: "Profit/Loss on Account of Rental Income for Year",
    value: data.ProfitLossRental,
    format: "currency",
    level: 0,
    floatingText: percentageOf(
      data.GrossRentalIncome,
      data.ProfitLossRental,
      "of total rental income"
    ),
  },
  {
    label: "Add: Allowable Expenses/Deductions",
    value: data.NonAllowblExp,
    format: "currency",
    level: 0,
  },
  {
    label: "Adjusted Profit or Loss on Rent",
    value: data.AdjProfitLossRental,
    format: "currency",
    level: 0,
  },
  {
    label: "Allowable Apportioned Capital Allowance",
    value: data.AllowblApportionedCap,
    format: "currency",
    level: 0,
  },
  {
    label: "Less: Other Allowable Expenses/Deductions",
    value: data.OthrAllowblExp,
    format: "currency",
    level: 0,
  },
  {
    label: "Adjusted Rental Income or Loss for the Year",
    value: data.AdjRentlIncmLoss,
    format: "currency",
    level: 0,
  },
  {
    label: "Broght Forward Assessed Loss from Previous Year",
    value: data.BrghtFwdAssessedLoss,
    format: "currency",
    level: 0,
  },
  {
    label: "Assessed Chargeable Income for Year",
    value: data.AssessedChrgblRentlIncm,
    format: "currency",
    level: 0,
  },
  {
    label: "Assessed Rental Loss for the Year",
    value: data.AssessedRentlLoss,
    format: "currency",
    level: 0,
  },
];

export const modelFinancingExpenses = (
  {
    FinanceExp,
    TotExpPurelyRental,
  }: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  financialExpenses: Rtcs_Db_Ph_Ura_RentExpenseDetails[] | undefined
) => [
  {
    label: "Financing Expenses",
    value: FinanceExp,
    format: "currency",
    level: 1,
    floatingText: percentageOf(
      TotExpPurelyRental,
      FinanceExp,
      "of total rental expenses"
    ),
    items: financialExpenses?.map((expense) => modelExpense(expense, 2)),
  },
];

export const modelAdministrativeExpenses = (
  {
    AdministratingExp,
    TotExpPurelyRental,
  }: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  administrativeExpenses: Rtcs_Db_Ph_Ura_RentExpenseDetails[] | undefined
) => [
  {
    label: "Administrative Expenses",
    value: AdministratingExp,
    format: "currency",
    level: 1,
    floatingText: percentageOf(
      TotExpPurelyRental,
      AdministratingExp,
      "of total rental expenses"
    ),
    items: administrativeExpenses?.map((expense) => modelExpense(expense, 2)),
  },
];

export const modelGrossRentalIncome = (
  {
    GrossRentalIncome,
    TotExpPurelyRental,
    OperatingExp,
  }: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData,
  operationalExpenses: Rtcs_Db_Ph_Ura_RentExpenseDetails[] | undefined
) => [
  {
    label: "Gross Rental Income",
    value: GrossRentalIncome,
    format: "currency",
    level: 0,
  },
  {
    label: "Total Rental Expenses",
    value: TotExpPurelyRental,
    format: "currency",
    level: 0,
  },
  {
    label: "Operating Expenses",
    value: OperatingExp,
    format: "currency",
    level: 1,
    floatingText: percentageOf(
      TotExpPurelyRental,
      OperatingExp,
      "of total rental expenses"
    ),
    items: operationalExpenses?.map((expense) => modelExpense(expense, 2)),
  },
];

export const modelSubTitles = ({
  RtnPeriodYear,
  RtnDt,
  IsAmendment,
}: Rtcs_Db_Ph_Rpt_NonIndividualTaxSummaryData) => [
  {
    label: "Assessment Period:",
    value: RtnPeriodYear?.replace("-", "/"),
  },
  {
    label: "Date Submitted:",
    value: getFormattedDate(RtnDt),
  },
  {
    label: "Type of Return:",
    value: IsAmendment === "N" ? "Original" : "AMENDMENT",
  },
];
