/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React from "react";
import { useTheme } from "@material-ui/core";

// Components
import Pane from "components/pane/pane.component";
import TextField from "components/text-field/text-field.component";
import Autocomplete from "components/autocomplete/autocomplete.component";
import List from "components/list/list.component";
import UserProfilePicture from "components/user-profile-picture/user-profile-picture.component";
import DateTimeField from "components/datetime-field/datetime-field.component";
import PasswordField from "components/password-field/password-field.component";
import { UserFormState } from "components/user-form/user-form.component";

// Types
import {
  UserData,
  DepartmentData,
  OrganisationData,
  GroupData,
  RoleData,
} from "graphql/types-and-hooks";

// Utils
import { resizeImage } from "utils/image";

// Assets
import SC from "./user-form-fields.styles";

interface Props {
  userData: UserFormState;
  isEditable: boolean;
  isProfile: boolean;
  isCreatingUser?: boolean;
  organisations?: OrganisationData[];
  departments?: DepartmentData[];
  groups?: GroupData[];
  onChange: (userData: UserData) => void;
}

const UserFormFields: React.FC<Props> = ({
  userData,
  isEditable,
  isProfile,
  isCreatingUser,
  organisations = [],
  departments = [],
  groups = [],
  onChange,
}) => {
  const theme: any = useTheme();
  const userRoles: RoleData[] = [];
  const handleActiveStatus = () => {
    const newUserData = { ...userData };
    newUserData.active = !newUserData.active;
    onChange(newUserData);
  };

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newUserData = { ...userData };

    switch (name) {
      case "workAddress":
        newUserData.work = { ...newUserData.work, address: value };
        break;
      case "workPhone":
        newUserData.work = { ...newUserData.work, phone: value };
        break;
      default:
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        newUserData[name] = value;
        break;
    }

    onChange(newUserData);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newUserData = { ...userData, password: event.target.value };
    onChange(newUserData);
  };

  const handleAutocompleteDepartmentChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: DepartmentData | null
  ) => {
    const newUserData = { ...userData, department: value };
    onChange(newUserData);
  };

  const handleAutocompleteOrganisationChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: OrganisationData | null
  ) => {
    const newUserData = { ...userData, organisation: value };
    onChange(newUserData);
  };

  const handleAutocompleteGroupChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: GroupData | null
  ) => {
    const newUserData = { ...userData, groups: [value] };
    onChange(newUserData);
  };

  const handleInputProfilePicture: React.ChangeEventHandler<
    HTMLInputElement
  > = (event) => {
    if (event.target.files && window.FileReader) {
      const file = event.target.files[0];

      if (file && file.type.match("image.*")) {
        const { width = 200, height = 200 } = theme.userProfilePicture;

        resizeImage(
          file,
          width as number,
          height as number,
          (result: string | Blob | File | ProgressEvent<FileReader>) => {
            const newUserData = {
              ...userData,
              [event.target.name]: result.toString(),
            };
            onChange(newUserData);
          }
        );
      }
    }
  };

  userData.groups?.forEach((group) => {
    group?.roles?.forEach((role) => {
      if (role != null && !userRoles.includes(role)) userRoles.push(role);
    });
  });

  return (
    <Pane>
      <SC.Grid container spacing={2} disabledMarginTop>
        <SC.Grid item xs={6} disabledMarginTop>
          <UserProfilePicture
            name="profilePicture"
            urlPicture={userData.profilePicture!}
            isEditable={isEditable}
            onChange={handleInputProfilePicture}
          />
        </SC.Grid>
        <SC.Grid item xs={6} disabledMarginTop>
          <SC.Box disabledMarginTop>
            <TextField
              name="firstName"
              label="First Name"
              placeholder="John"
              autoComplete="off"
              fullWidth
              required
              value={userData.firstName}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
          </SC.Box>
          <SC.Box>
            <TextField
              name="lastName"
              label="Last Name"
              placeholder="Doe"
              autoComplete="off"
              fullWidth
              required
              value={userData.lastName}
              onChange={handleTextFieldChange}
              disabled={!isEditable}
            />
          </SC.Box>
          <SC.Box>
            <Autocomplete<OrganisationData, false, false, false>
              disabled={!isEditable}
              options={organisations}
              getOptionLabel={(userOrganisation) => userOrganisation.name ?? ""}
              getOptionSelected={(option, value) => option.id === value.id}
              textFieldProps={{ label: "Organisation" }}
              value={userData.organisation}
              onChange={handleAutocompleteOrganisationChange}
            />
          </SC.Box>
          <SC.Box>
            <Autocomplete<DepartmentData, false, false, false>
              disabled={!isEditable}
              options={departments}
              getOptionLabel={(userDepartment) => userDepartment.name ?? ""}
              getOptionSelected={(option, value) => option.id === value.id}
              textFieldProps={{ label: "Department" }}
              value={userData.department}
              onChange={handleAutocompleteDepartmentChange}
            />
          </SC.Box>
          <SC.Box>
            <TextField
              name="email"
              label="Email"
              placeholder="john@doe.com"
              fullWidth
              type="email"
              required
              value={userData.email}
              onChange={handleTextFieldChange}
              disabled={isProfile || !isEditable}
            />
          </SC.Box>
          {!isProfile && (
            <PasswordField
              label={isCreatingUser ? "Password" : "Enter new password"}
              placeholder="Password"
              required={isCreatingUser}
              value={userData.password ?? ""}
              onChange={handlePasswordChange}
              disabled={isProfile || !isEditable}
            />
          )}
        </SC.Grid>
      </SC.Grid>

      {/* Second Row */}
      {!isProfile && (
        <SC.Grid container spacing={2}>
          <SC.Grid item xs={2} />
          {!isCreatingUser && (
            <SC.Grid
              container
              spacing={2}
              xs={4}
              alignToRight
              disabledMarginTop
            >
              <SC.Grid item xs={3}>
                <SC.InputLabelCenter>Email Verified</SC.InputLabelCenter>
                <SC.Checkbox
                  size="small"
                  checked={userData.emailVerified ?? false}
                  disabled
                />
              </SC.Grid>
              <SC.Grid item xs={3}>
                <SC.InputLabelCenter>Active</SC.InputLabelCenter>
                <SC.Checkbox
                  name="active"
                  size="small"
                  checked={!!userData.active}
                  onChange={handleActiveStatus}
                  disabled={isProfile || !isEditable}
                />
              </SC.Grid>
            </SC.Grid>
          )}
          <SC.Grid item xs={3}>
            <DateTimeField
              value={userData.creationDate}
              label="Creation Date"
              disabled
              onChange={() => {}}
            />
          </SC.Grid>
          <SC.Grid item xs={3}>
            <DateTimeField
              value={userData.passwordModificationDate}
              label="Password Modification Date"
              disabled
              required
              onChange={() => {}}
            />
          </SC.Grid>
        </SC.Grid>
      )}

      {/* Third Row */}
      {!isProfile && (
        <SC.Grid container spacing={2}>
          <SC.Grid item xs={4}>
            <Autocomplete<GroupData, false, true, false>
              disableClearable
              disabled={isProfile || !isEditable}
              options={groups}
              getOptionLabel={(userGroup) => userGroup.name ?? ""}
              getOptionSelected={(option, value) => option.id === value.id}
              onChange={handleAutocompleteGroupChange}
              textFieldProps={{
                label: "Groups",
                required: (userData.groups ?? []).length === 0,
              }}
              value={userData.groups?.[0] as GroupData}
            />
          </SC.Grid>
          <SC.Grid item xs={4}>
            <SC.InputLabel>Inherited Roles</SC.InputLabel>
            <List<RoleData>
              // isEditable={!isEditable}
              items={userRoles}
              getItemKey={(userRole) => userRole.id ?? ""}
              getItemLabel={(userRole) => userRole.name ?? ""}
              dense
            />
          </SC.Grid>
        </SC.Grid>
      )}

      <SC.Grid container spacing={2}>
        <SC.Grid item xs={4}>
          <TextField
            name="workAddress"
            label="Work Address"
            placeholder="Carlsbad, CA"
            fullWidth
            value={userData.work?.address}
            onChange={handleTextFieldChange}
            disabled={!isEditable}
          />
        </SC.Grid>
        <SC.Grid item xs={4}>
          <TextField
            name="workPhone"
            label="Work Phone"
            placeholder="415 555-8725"
            fullWidth
            value={userData.work?.phone}
            onChange={handleTextFieldChange}
            disabled={!isEditable}
          />
        </SC.Grid>
        <SC.Grid item xs={4}>
          <TextField
            name="mobilePhone"
            label="Mobile Phone"
            placeholder="281 843-6334"
            fullWidth
            value={userData.mobilePhone}
            onChange={handleTextFieldChange}
            disabled={!isEditable}
          />
        </SC.Grid>
      </SC.Grid>
      {/* Fourth Row */}
    </Pane>
  );
};

export default UserFormFields;
