/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Utils
import { isDefined } from "./is";

export function isFirstRender(items) {
  return (
    !isDefined(items) || items.length === 0 || Object.keys(items).length === 0
  );
}

const collator = new Intl.Collator("en", { sensitivity: "base" });

export function ascendingAlphabeticalOrder(strA, strB) {
  return collator.compare(strA, strB);
}

export function descendingAlphabeticalOrder(strA, strB) {
  return -collator.compare(strA, strB);
}

export const containsHtmlTags = (str) => /<.+?>/.test(str);
