// Dependencies
import { withStyles, Box } from "@material-ui/core";

const App = withStyles({
  root: {
    display: "flex",
    minHeight: "100vh",
    textAlign: "center",
  },
})(Box);

export default {
  App,
};
