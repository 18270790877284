/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box as BoxBase,
  withStyles,
  Typography,
  Dialog,
  DialogActions as DialogActionsBase,
  DialogTitle,
  DialogContent as DialogContentBase,
  Grid as GridBase,
  Button as ButtonBase,
  TextField as TextFieldBase,
  Select,
  Tooltip,
  IconButton as IconButtonBase,
  InputAdornment,
  MenuItem as MenuItemBase,
} from "@material-ui/core";
import { Delete as DeleteIcon } from "@material-ui/icons";

const Container = withStyles(() => ({
  root: {},
}))(BoxBase);

const SelectField = withStyles(() => ({
  root: {
    width: "100%",
    borderWidth: "0px",
    ".MuiInputBase-root": {
      width: "100%",
    },
  },
  select: {
    width: "100%",
  },
  selectMenu: {
    width: "100%",
  },
}))(Select);

const MenuItem = MenuItemBase;

const DialogActions = withStyles((theme: any) => ({
  root: {
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(5),
    justifyContent: "flex-start",
  },
}))(DialogActionsBase);

const DialogContent = withStyles((theme: any) => ({
  root: {
    paddingBottom: theme.spacing(1),
  },
}))(DialogContentBase);

const Label = withStyles((theme: any) => ({
  root: {
    cursor: "pointer",
    color: theme.palette.primary.main,
  },
}))(Typography);

const Button = withStyles((theme: any) => ({
  root: {
    alignSelf: "center",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    textTransform: "none",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    textAlign: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.background.default,
    },
  },
}))(ButtonBase);

const CancelButton = withStyles((theme: any) => ({
  root: {
    alignSelf: "center",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: "#D2DCE6 1px solid",
    textTransform: "none",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(3),
    textAlign: "center",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
}))(ButtonBase);

const TextButton = withStyles((theme: any) => ({
  root: {
    alignSelf: "center",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main,
    border: "#fff 1px solid",
    textTransform: "none",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginRight: theme.spacing(3),
    textAlign: "center",
    "&:hover": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main,
    },
  },
  disabled: {
    border: "#fff 0px solid !important",
  },
}))(ButtonBase);

const Grid = GridBase;

const GridSelect = withStyles((theme: any) => ({
  root: {
    "& .MuiInputBase-root": {
      width: "100%",
    },
    "& .MuiInput-underline:before": {
      borderBottomWidth: 0,
    },
    "& .MuiInput-underline:after": {
      borderBottomWidth: 0,
      backgroundColor: "transparent",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: theme.palette.background.default,
      "&:hover": {
        borderColor: theme.palette.background.default,
      },
    },
  },
}))(GridBase);

const TextField = withStyles(() => ({
  root: {
    width: "100%",
    marginTop: "1.2rem",
  },
}))(TextFieldBase);

const Box = BoxBase;

const IconButton = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(1),
    "& .MuiSvgIcon-root": {
      fontSize: theme.table.toolbarActions.fontSize,
    },
    "&:not(.Mui-disabled) .MuiSvgIcon-root": {
      color: theme.universalFilter.color,
    },
  },
}))(IconButtonBase);

export default {
  Container,
  Label,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  Grid,
  GridSelect,
  SelectField,
  Button,
  CancelButton,
  TextButton,
  TextField,
  Box,
  Tooltip,
  DeleteIcon,
  IconButton,
  InputAdornment,
  MenuItem,
};
