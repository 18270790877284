import React from "react";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import { KccaCustomerNonIndividualQuery_KccaCustomerNonIndividual_ElementKey } from "graphql/hasura/rtcs.types";
import {
  Query_RootRtcs_Db_Ph_Kcca_CountyArgs,
  Query_RootRtcs_Db_Ph_Kcca_CustomerArgs,
  Query_RootRtcs_Db_Ph_Kcca_DistrictArgs,
  Query_RootRtcs_Db_Ph_Kcca_ParishArgs,
  Query_RootRtcs_Db_Ph_Kcca_SubCountyArgs,
  Query_RootRtcs_Db_Ph_Kcca_VillageArgs,
  useKccaCustomerNonIndividualQuery,
  useKccaCustomerResidentialAddressCountyQuery,
  useKccaCustomerResidentialAddressDistrictQuery,
  useKccaCustomerResidentialAddressParishQuery,
  useKccaCustomerResidentialAddressSubCountyQuery,
  useKccaCustomerResidentialAddressVillageQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import {
  AccordionHeader,
  Header,
  Content,
} from "components/accordion-header/accordion-header.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

type ResidentialAddressElementKey =
  | "ResidentialAddressCountyName"
  | "ResidentialAddressDistrictName"
  | "ResidentialAddressParishName"
  | "ResidentialAddressSubCountyName"
  | "ResidentialAddressVillageName";

const isResidentialAddressElementKey = (
  maybeResidentialAddressElementKey: unknown
): maybeResidentialAddressElementKey is ResidentialAddressElementKey => {
  return (
    _.isString(maybeResidentialAddressElementKey) &&
    maybeResidentialAddressElementKey.startsWith("ResidentialAddress")
  );
};

export interface UraNonIndividual {
  label: string;
  accessor?:
    | KccaCustomerNonIndividualQuery_KccaCustomerNonIndividual_ElementKey
    | ResidentialAddressElementKey;
}

export const identifiers: UraNonIndividual[] = [
  // { label: "Trading Name", accessor: "TradingName" },
  // { label: "Business Certification No.", accessor: "BsnsCertNumber" },
  { label: "Business Name", accessor: "BusinessName" },
  { label: "Entity Legal Name", accessor: "EntityLegalName" },
  { label: "Customer ID", accessor: "Id" },
  { label: "RNID", accessor: "Rnid" },
];
export const businessAddress: UraNonIndividual[] = [
  { label: "Plot No.", accessor: "PlotNumber" },
  { label: "Street Address", accessor: "StreetName" },
  { label: "Building Name", accessor: "BuildingName" },
  { label: "District Name", accessor: "ResidentialAddressDistrictName" },
  // { label: "Town", accessor: "BsnsaddrTownName" },
  { label: "County", accessor: "ResidentialAddressCountyName" },
  { label: "Sub County", accessor: "ResidentialAddressSubCountyName" },
  { label: "Parish", accessor: "ResidentialAddressParishName" },
  { label: "Village", accessor: "ResidentialAddressVillageName" },
];
export const contactInfo: UraNonIndividual[] = [
  { label: "Email Address", accessor: "Email" },
  { label: "Landline Phone No.", accessor: "Telephone" },
  { label: "Mobile No.", accessor: "Mobile" },
  { label: "" }, // create separator
  // { label: "Income Source Rental?", accessor: "IsIncomeSrcRental" },
  // { label: "Income Source Business?", accessor: "IsIncomeSrcBsns" },
  // { label: "Income Source Property?", accessor: "IsIncomeSrcPrpty" },
];

export interface NonIndividualTabHeaderKccaProps {
  rnid: string;
  customerIds: string;
}

export const NonIndividualTabHeaderKcca: React.FC<
  NonIndividualTabHeaderKccaProps
> = ({ rnid, customerIds }) => {
  const customerId = customerIds.split(",")?.[0];
  const queryVariablesNonIndividuals: Query_RootRtcs_Db_Ph_Kcca_CustomerArgs = {
    where: rnid.startsWith("NON")
      ? { Rnid: { _eq: rnid } }
      : { Id: { _eq: customerId } },
  };
  const { data, error, loading } = useKccaCustomerNonIndividualQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customerId,
    variables: {
      params: jsonToStringQueryVariables(queryVariablesNonIndividuals),
    },
  });

  const customer = data?.rtcs_db_Fnkcca_Customer?.filter(
    (value) => value.Id === customerId
  )?.[0];

  const queryVariablesResidentialAddressCounty: Query_RootRtcs_Db_Ph_Kcca_CountyArgs =
    {
      where: { Id: { _eq: customer?.ResidentialAddressCountyId } },
    };
  const {
    data: residentialAddressCountyData,
    error: residentialAddressCountyError,
    loading: residentialAddressCountyLoading,
  } = useKccaCustomerResidentialAddressCountyQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customer?.ResidentialAddressCountyId,
    variables: {
      params: jsonToStringQueryVariables(
        queryVariablesResidentialAddressCounty
      ),
    },
  });

  const queryVariablesResidentialAddressDistrict: Query_RootRtcs_Db_Ph_Kcca_DistrictArgs =
    {
      where: { Id: { _eq: customer?.ResidentialAddressDistrictId } },
    };
  const {
    data: residentialAddressDistrictData,
    error: residentialAddressDistrictError,
    loading: residentialAddressDistrictLoading,
  } = useKccaCustomerResidentialAddressDistrictQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customer?.ResidentialAddressDistrictId,
    variables: {
      params: jsonToStringQueryVariables(
        queryVariablesResidentialAddressDistrict
      ),
    },
  });

  const queryVariablesResidentialAddressParish: Query_RootRtcs_Db_Ph_Kcca_ParishArgs =
    {
      where: { Id: { _eq: customer?.ResidentialAddressParishId } },
    };
  const {
    data: residentialAddressParishData,
    error: residentialAddressParishError,
    loading: residentialAddressParishLoading,
  } = useKccaCustomerResidentialAddressParishQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customer?.ResidentialAddressParishId,
    variables: {
      params: jsonToStringQueryVariables(
        queryVariablesResidentialAddressParish
      ),
    },
  });

  const queryVariablesResidentialAddressSubCounty: Query_RootRtcs_Db_Ph_Kcca_SubCountyArgs =
    {
      where: { Id: { _eq: customer?.ResidentialAddressSubCountyId } },
    };
  const {
    data: residentialAddressSubCountyData,
    error: residentialAddressSubCountyError,
    loading: residentialAddressSubCountyLoading,
  } = useKccaCustomerResidentialAddressSubCountyQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customer?.ResidentialAddressSubCountyId,
    variables: {
      params: jsonToStringQueryVariables(
        queryVariablesResidentialAddressSubCounty
      ),
    },
  });

  const queryVariablesResidentialAddressVillage: Query_RootRtcs_Db_Ph_Kcca_VillageArgs =
    {
      where: { Id: { _eq: customer?.ResidentialAddressVillageId } },
    };
  const {
    data: residentialAddressVillageData,
    error: residentialAddressVillageError,
    loading: residentialAddressVillageLoading,
  } = useKccaCustomerResidentialAddressVillageQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customer?.ResidentialAddressVillageId,
    variables: {
      params: jsonToStringQueryVariables(
        queryVariablesResidentialAddressVillage
      ),
    },
  });

  useAppErrorHandler(
    error ||
      residentialAddressCountyError ||
      residentialAddressDistrictError ||
      residentialAddressParishError ||
      residentialAddressSubCountyError ||
      residentialAddressVillageError
  );

  if (!customer) {
    return null;
  }

  const residentialAddressInfo: Record<ResidentialAddressElementKey, string> = {
    ResidentialAddressCountyName:
      residentialAddressCountyData?.rtcs_db_Fnkcca_County?.[0]?.Name ?? "",
    ResidentialAddressDistrictName:
      residentialAddressDistrictData?.rtcs_db_Fnkcca_District?.[0]?.Name ?? "",
    ResidentialAddressParishName:
      residentialAddressParishData?.rtcs_db_Fnkcca_Parish?.[0]?.Name ?? "",
    ResidentialAddressSubCountyName:
      residentialAddressSubCountyData?.rtcs_db_Fnkcca_SubCounty?.[0]?.Name ??
      "",
    ResidentialAddressVillageName:
      residentialAddressVillageData?.rtcs_db_Fnkcca_Village?.[0]?.Name ?? "",
  };

  const header: Header[] = [
    { index: 0, label: `${customer.EntityLegalName ?? ""}`, type: "bold" },
    { index: 1, label: "RNID", type: "bold" },
    { index: 2, label: rnid },
    { index: 3, label: "CustomerId", type: "bold" },
    { index: 4, label: `${customerId}` },
  ];

  const mapFunction = (info: UraNonIndividual, index: number) => {
    const { accessor } = info;
    let value = "";

    if (accessor)
      value = isResidentialAddressElementKey(accessor)
        ? residentialAddressInfo[accessor]
        : customer?.[accessor];

    return { ...info, index, value };
  };
  const content: Content[] = [
    {
      index: 0,
      title: "Names and Identifiers",
      data: identifiers.map(mapFunction),
    },
    {
      index: 1,
      title: "Business Address",
      data: businessAddress.map(mapFunction),
    },
    {
      index: 2,
      title: "Contact Info and Related",
      data: contactInfo.map(mapFunction),
    },
  ];

  const isLoading =
    loading ||
    residentialAddressCountyLoading ||
    residentialAddressDistrictLoading ||
    residentialAddressParishLoading ||
    residentialAddressSubCountyLoading ||
    residentialAddressVillageLoading;

  return (
    <AccordionHeader
      header={header}
      content={content}
      source="KCCA"
      isLoading={isLoading}
    />
  );
};

export default NonIndividualTabHeaderKcca;
