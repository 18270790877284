/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/ban-ts-comment */

// Dependencies
import React, { useMemo } from "react";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rpt_IndividualTaxSummaryDataArgs,
  Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData,
  useUraRptIndividualTaxSummaryQuery,
  Rtcs_Db_Ph_Ura_IndividualMortgage,
  useIndividualMortgageQuery,
  Rtcs_Db_Ph_Ura_RentalLandLordIncome,
  useIndividualTenantsQuery,
  Maybe,
} from "graphql/hasura/types-and-hooks";

import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";

import { ListObject } from "components/nested-list/nested-list.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { getFormattedDate } from "utils/internationalization";
import { percentageOf } from "utils/math";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import SC from "./financial-summary.styles";

export interface ListItemInterface {
  label: string;
  value: string;
  level: number;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

const FinancialSummary: React.FC = () => {
  const intl = useIntl();
  const { rtnNO } = useParams<{ rtnNO: string }>();

  const queryVariables: Query_RootRtcs_Db_Ph_Rpt_IndividualTaxSummaryDataArgs =
    {
      where: { RtnNo: { _eq: rtnNO } },
    };

  const {
    loading: rentalReturnLoading,
    data: rentalReturnData,
    error: rentalReturnError,
  } = useUraRptIndividualTaxSummaryQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !rtnNO,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const {
    loading: mortgageLoading,
    data: mortgageData,
    error: mortgageError,
  } = useIndividualMortgageQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !rtnNO,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const {
    loading: tenantLoading,
    data: tenantData,
    error: tenantError,
  } = useIndividualTenantsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !rtnNO,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  useAppErrorHandler(rentalReturnError || mortgageError || tenantError);

  const individual = useMemo(
    () => rentalReturnData?.rtcs_db_FnRPT_IndividualTaxSummaryData ?? [],
    [rentalReturnData]
  )[0];

  const mortgage = useMemo(
    () =>
      mortgageData?.rtcs_db_Fnura_IndividualMortgage.map(
        ({
          FinanceName,
          FinanceTin,
          DeedNo,
          PeriodFrom,
          PeriodTo,
          TotalMortgage,
          IntersetMortgage,
        }: Rtcs_Db_Ph_Ura_IndividualMortgage) => [
          {
            label: "Mortgage Financier Name",
            value: FinanceName,
            level: 0,
          },
          {
            label: "Mortgage Financier TIN",
            value: FinanceTin,
            level: 1,
          },
          {
            label: "Mortgage Financier Deed",
            value: DeedNo,
            level: 1,
          },
          {
            label: "Mortgage Period From",
            value: PeriodFrom,
            level: 1,
            format: "date",
          },
          {
            label: "Mortgage Period To",
            value: PeriodTo,
            level: 1,
            format: "date",
          },
          {
            label: "Mortgage Sum",
            value: TotalMortgage,
            level: 1,
            format: "currency",
          },
          {
            label: "Mortgage Interest For Year",
            value: IntersetMortgage,
            format: "currency",
            level: 1,
          },
        ]
      ) ?? [],
    [mortgageData]
  );

  const tenants = useMemo(
    () =>
      tenantData?.rtcs_db_Fnura_RentalLandLordIncome.map(
        ({
          TenantName,
          TenantTin,
          PremiseLocation,
          TenancyPeriodFrom,
          TenancyPeriodTo,
          RentalIncome,
        }: Rtcs_Db_Ph_Ura_RentalLandLordIncome) => [
          {
            label: "Tenant Name",
            value: TenantName,
            level: 0,
          },
          {
            label: "Tenant TIN",
            value: TenantTin,
            level: 1,
          },
          {
            label: "Physical Location of Tenant",
            value: PremiseLocation,
            level: 1,
          },
          {
            label: "Period of Occupancy From",
            value: TenancyPeriodFrom,
            level: 1,
            format: "date",
          },
          {
            label: "Period of Occupancy To",
            value: TenancyPeriodTo,
            level: 1,
            format: "date",
          },
          {
            label: "Gross Rental Income Recieved",
            value: RentalIncome,
            format: "currency",
            level: 1,
          },
        ]
      ) ?? [],
    [tenantData]
  );

  function getTotalNonRentalIncome(
    _EMPLYMNT_INCOME: Maybe<string> | undefined,
    _PROPINC_INCOME: Maybe<string> | undefined
  ) {
    const totalNonRentalIncome = Array.prototype.slice
      // eslint-disable-next-line prefer-rest-params
      .call(arguments)
      .map((value: Maybe<string> | undefined) =>
        !value || value === "nan" ? 0 : Number(value)
      )
      .reduce((a, b) => a + b, 0);

    return totalNonRentalIncome.toString();
  }

  const modelSubTitles = ({
    RtnPeriodYear,
    RtnDt,
    IsAmendment,
  }: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData) => [
    {
      label: "Assessment Period:",
      value: RtnPeriodYear?.replace("-", "/"),
    },
    {
      label: "Date Submitted:",
      value: RtnDt ? getFormattedDate(RtnDt) : "",
    },
    {
      label: "Type of Return:",
      value: IsAmendment === "N" ? "Original" : "AMENDMENT",
    },
  ];

  const modelTotalIncome = ({
    TotIncome,
    PropincIncome,
    EmplymntIncome,
  }: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData) => [
    {
      label: "Total Income",
      value: TotIncome,
      format: "currency",
      level: 0,
      floatingText: "% of Total Income",
      items: [
        {
          label: "Total Non-Rental Income",
          value: getTotalNonRentalIncome(EmplymntIncome, PropincIncome),
          format: "currency",
          level: 1,
          floatingText: percentageOf(
            TotIncome,
            getTotalNonRentalIncome(EmplymntIncome, PropincIncome),
            ""
          ),
          items: [
            {
              label: "Employment Income",
              value: EmplymntIncome,
              format: "currency",
              level: 2,
              floatingText: percentageOf(TotIncome, EmplymntIncome, ""),
            },
            {
              label: "Property Income",
              value: PropincIncome,
              format: "currency",
              level: 2,
              floatingText: percentageOf(TotIncome, PropincIncome, ""),
            },
          ],
        },
      ],
    },
  ];

  const modelGrossRentalIncome = ({
    GrossRentalIncome,
    TotRentalIncm,
    PartnerRentIncome,
  }: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData) => [
    {
      label: "Gross Rental Income",
      value: GrossRentalIncome,
      format: "currency",
      level: 0,
    },
    {
      label: "Total Rental Income",
      value: TotRentalIncm,
      format: "currency",
      level: 1,
    },
    {
      label: "Partner Rental Income",
      value: PartnerRentIncome,
      format: "currency",
      level: 1,
    },
  ];

  const modelAllawableList = ({
    DeductIncmTax,
    MotgageIntDecd,
  }: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData) => [
    {
      label: "Allowable Deduction(20 %)",
      value: DeductIncmTax,
      format: "currency",
      level: 0,
    },
    {
      label: "Mortgage Interest Deduction",
      value: MotgageIntDecd,
      format: "currency",
      level: 0,
    },
  ];

  const modelTotalChargableIncome = ({
    ChargeableIncome,
    ThresholdAmnt,
  }: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData) => [
    {
      label: "Total chargeable Rental Income",
      value: ChargeableIncome,
      format: "currency",
      level: 0,
    },
    {
      label: "Threshold for Rental Income",
      value: ThresholdAmnt,
      format: "currency",
      level: 0,
    },
  ];

  const modelNetRentalIncome = ({
    NetRentIncm,
    TotIncome,
  }: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData) => [
    {
      label: "Net Rental Income",
      value: NetRentIncm,
      format: "currency",
      level: 0,
      floatingText: percentageOf(TotIncome, NetRentIncm, "of total income"),
    },
  ];

  const modelTaxPayable = ({
    TaxAssessed,
  }: Rtcs_Db_Ph_Rpt_IndividualTaxSummaryData) => [
    {
      label: "Rental Tax Payable",
      value: TaxAssessed,
      format: "currency",
      level: 0,
    },
  ];

  const renderList = (
    title: string,
    listItems: ListItemInterface[][]
  ): JSX.Element | null => {
    return (
      <>
        <SC.SectionSubHeaderTypography variant="body2" gutterBottom>
          {listItems.length < 1 ? `No ${title}` : `${title} :`}
        </SC.SectionSubHeaderTypography>

        {listItems.map((item: ListItemInterface[]) => (
          <SC.NestedList data={item as ListObject[]} />
        ))}
      </>
    );
  };

  if (!individual) return null;

  return (
    <>
      <SC.ProgressIndicator
        open={rentalReturnLoading || mortgageLoading || tenantLoading}
      />
      <SC.TaxHistoryAccordion
        title="Financial Summary"
        subtitles={modelSubTitles(individual)}
      >
        <SC.SectionSubHeaderTypography>
          {/* Resident Status: {individual?.IS_RESIDENT} */}
          Resident Status: {individual?.IsResident}
        </SC.SectionSubHeaderTypography>
        <SC.NestedList data={modelTotalIncome(individual)} />
        <SC.NestedList data={modelGrossRentalIncome(individual)} />
        <SC.NestedList data={modelAllawableList(individual)} />
        <SC.NestedList data={modelTotalChargableIncome(individual)} />
        <SC.NestedList data={modelNetRentalIncome(individual)} />
        <SC.NestedList data={modelTaxPayable(individual)} />
        <>{renderList("Tenant", tenants as ListItemInterface[][])}</>
        <>{renderList("Mortgage", mortgage as ListItemInterface[][])}</>
      </SC.TaxHistoryAccordion>
    </>
  );
};

export default FinancialSummary;
