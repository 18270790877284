/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// Dependencies
import React from "react";

// Assets
import "./Place.css";

const Place = ({ text, icon, defaultIcon, onClick }) => (
  <div className="Place" onClick={() => onClick()}>
    <img className="PlaceIcon img-fluid" src={icon || defaultIcon} alt={text} />
  </div>
);

export default Place;
