import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};

/** Represents the structure of the contact data. */
export type ContactData = {
  __typename?: "ContactData";
  /** Address of the contact data. */
  address?: Maybe<Scalars["String"]>;
  /** Phone of the contact data. */
  phone?: Maybe<Scalars["String"]>;
};

export enum DagsterPipelineRunStatus {
  Queued = "QUEUED",
  NotStarted = "NOT_STARTED",
  Managed = "MANAGED",
  Starting = "STARTING",
  Started = "STARTED",
  Success = "SUCCESS",
  Failure = "FAILURE",
  Canceling = "CANCELING",
  Canceled = "CANCELED",
}

/** Represents the data of a department. */
export type DepartmentData = {
  __typename?: "DepartmentData";
  /** Department ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the department. */
  name?: Maybe<Scalars["String"]>;
};

export enum Entity {
  User = "USER",
  Group = "GROUP",
  Role = "ROLE",
  Department = "DEPARTMENT",
  Organisation = "ORGANISATION",
}

/** Entity Filter value. */
export type EntityFilter = {
  __typename?: "EntityFilter";
  /** Entity Filter ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Entity ID Type */
  entityIdType?: Maybe<EntityIdType>;
  /** Entity ID. */
  entityId?: Maybe<Scalars["ID"]>;
  /** Filter Name. */
  filterName?: Maybe<EntityFilterName>;
  /** Filter Value. */
  filterValue?: Maybe<Scalars["Int"]>;
};

/** Entity Filter input. */
export type EntityFilterInput = {
  /** Entity ID Type */
  entityIdType: EntityIdType;
  /** Entity ID */
  entityId: Scalars["ID"];
  /** Filter Value. */
  filterValue: Scalars["Int"];
};

export enum EntityFilterName {
  VisibilityLevel = "VISIBILITY_LEVEL",
  TaxExempt = "TAX_EXEMPT",
}

export enum EntityIdType {
  Rnid = "RNID",
}

/** Entity input. */
export type EntityInput = {
  /** Entity ID Type */
  entityIdType: EntityIdType;
  /** Entity ID */
  entityId: Scalars["ID"];
};

/** Represents the file content got from the rTCS storage platform. */
export type FileContent = {
  __typename?: "FileContent";
  filename: Scalars["String"];
  content: Scalars["String"];
};

/** Represents the file location within the rTCS storage platform. */
export type FileLocation = {
  folder: Scalars["String"];
  filename: Scalars["String"];
};

export enum Format {
  Email = "EMAIL",
  StandardName = "STANDARD_NAME",
  Password = "PASSWORD",
}

/** Represents the data of a group. */
export type GroupData = {
  __typename?: "GroupData";
  /** Group ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the group. It is unique and could be used as another identifier. */
  name?: Maybe<Scalars["String"]>;
  /** Description of the group. */
  description?: Maybe<Scalars["String"]>;
  /** UTC datetime when the group data was created. Not implemented yet. */
  creationDate?: Maybe<Scalars["String"]>;
  /** Roles assigned to the group. */
  roles?: Maybe<Array<Maybe<RoleData>>>;
};

/** Represents the relationship between a group and the roles assigned to this group. */
export type GroupRoleData = {
  __typename?: "GroupRoleData";
  /** Group who owns this relationship. */
  group?: Maybe<GroupData>;
  /** Roles assigned to the group of this relationship. */
  roles?: Maybe<Array<Maybe<RoleData>>>;
};

export enum Job {
  ApplyDataPermissions = "APPLY_DATA_PERMISSIONS",
  UpdateEntityFilters = "UPDATE_ENTITY_FILTERS",
  UpdateUsers = "UPDATE_USERS",
}

/** Represents a job status. */
export type JobStatus = {
  __typename?: "JobStatus";
  /** Dagster pipeline run status. */
  status: DagsterPipelineRunStatus;
  /** Text that gives more information about the status. */
  message: Scalars["String"];
};

export type Mutation = {
  __typename?: "Mutation";
  /** Create a new role. */
  AddRole: Array<Maybe<RoleData>>;
  /** Create a new user. */
  AddUser: Array<Maybe<UserData>>;
  /** Create a new group. */
  AddGroup: Array<Maybe<GroupData>>;
  /** Create a new organisation. */
  AddOrganisation: Array<Maybe<OrganisationData>>;
  /** Create a new department. */
  AddDepartment: Array<Maybe<DepartmentData>>;
  /** Create the relationship between a group and a role. */
  AddGroupRole: Array<Maybe<GroupData>>;
  /** Create the relationship between an user and a group. */
  AddUserGroup: Array<Maybe<UserData>>;
  /**
   * Updates the relationship between an user and an organisation.
   * If no organisationID is passed or its value is null, the user
   * will not be linked to any organisation.
   */
  UpdateUserOrganisation: Array<Maybe<UserData>>;
  /**
   * Updates or creates the relationship between an user and a department.
   * If no departmentID is passed or its value is null, the user will not
   * be linked to any department.
   */
  UpdateUserDepartment: Array<Maybe<UserData>>;
  /** Update the data of a role. */
  UpdateRole: Array<Maybe<RoleData>>;
  /** Update the information of the user. */
  UpdateUser: Array<Maybe<UserData>>;
  /** Update the current user's profile. */
  UpdateCurrentUserProfile: UserProfile;
  /**
   * Updates the relationship between the current user and an organisation.
   * If no organisationID is passed or its value is null, the user
   * will not be linked to any organisation.
   */
  UpdateCurrentUserProfileOrganisation: UserProfile;
  /**
   * Updates or creates the relationship between the current user and a department.
   * If no departmentID is passed or its value is null, the user will not
   * be linked to any department.
   */
  UpdateCurrentUserProfileDepartment: UserProfile;
  /** Update the data of the group. */
  UpdateGroup: Array<Maybe<GroupData>>;
  /** Update the data of the organisation. */
  UpdateOrganisation: Array<Maybe<OrganisationData>>;
  /** Update the data of the department. */
  UpdateDepartment: Array<Maybe<DepartmentData>>;
  /** Delete the role data related with the ID provided. */
  DeleteRole: Scalars["ID"];
  /** Delete the user data related with the ID provided. */
  DeleteUser: Scalars["ID"];
  /** Delete the group data related with the ID provided. */
  DeleteGroup: Scalars["ID"];
  /** Delete the organisation data related with the ID provided. */
  DeleteOrganisation: Scalars["ID"];
  /** Delete the department data related with the ID provided. */
  DeleteDepartment: Scalars["ID"];
  /** Delete the relationships between a group and its roles assigned. */
  DeleteGroupRole: Array<GroupData>;
  /** Delete the relationships between an user and the groups belongs to. */
  DeleteUserGroup: Array<UserData>;
  /** Execute a Job in background. */
  RunJob: Scalars["String"];
  /** Sends an email to the user whom the email address given belongs to. */
  SendEmailVerification: UserData;
  /** Validates if the Email Verification code provided is valid. */
  ValidateEmailVerificationCode: Scalars["Boolean"];
  /** Updates the user password with the new one if the code is valid. */
  ResetPassword: Scalars["Boolean"];
  /** Set filter values for each input of the MDA entity list. */
  SetEntityFilter: Array<EntityFilter>;
  /** Create a new note. */
  AddNote: Array<Maybe<NoteData>>;
};

export type MutationAddRoleArgs = {
  name: Scalars["String"];
  description: Scalars["String"];
  visibleLevel: Scalars["Int"];
  permissionInputs?: Maybe<Array<Maybe<PermissionInput>>>;
};

export type MutationAddUserArgs = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  active: Scalars["Boolean"];
  profilePicture: Scalars["String"];
  mobilePhone?: Maybe<Scalars["String"]>;
  workAddress?: Maybe<Scalars["String"]>;
  workPhone?: Maybe<Scalars["String"]>;
  password: Scalars["String"];
};

export type MutationAddGroupArgs = {
  name: Scalars["String"];
  description: Scalars["String"];
};

export type MutationAddOrganisationArgs = {
  name: Scalars["String"];
};

export type MutationAddDepartmentArgs = {
  name: Scalars["String"];
};

export type MutationAddGroupRoleArgs = {
  groupID: Scalars["ID"];
  roleID: Array<Maybe<Scalars["ID"]>>;
};

export type MutationAddUserGroupArgs = {
  userID: Scalars["ID"];
  groupID: Array<Maybe<Scalars["ID"]>>;
};

export type MutationUpdateUserOrganisationArgs = {
  userID: Scalars["ID"];
  organisationID?: Maybe<Scalars["ID"]>;
};

export type MutationUpdateUserDepartmentArgs = {
  userID: Scalars["ID"];
  departmentID?: Maybe<Scalars["ID"]>;
};

export type MutationUpdateRoleArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  visibleLevel?: Maybe<Scalars["Int"]>;
  permissionInputs?: Maybe<Array<Maybe<PermissionInput>>>;
};

export type MutationUpdateUserArgs = {
  id: Scalars["ID"];
  email?: Maybe<Scalars["String"]>;
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  workAddress?: Maybe<Scalars["String"]>;
  workPhone?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
};

export type MutationUpdateCurrentUserProfileArgs = {
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  workAddress?: Maybe<Scalars["String"]>;
  workPhone?: Maybe<Scalars["String"]>;
};

export type MutationUpdateCurrentUserProfileOrganisationArgs = {
  organisationID?: Maybe<Scalars["ID"]>;
};

export type MutationUpdateCurrentUserProfileDepartmentArgs = {
  departmentID?: Maybe<Scalars["ID"]>;
};

export type MutationUpdateGroupArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
};

export type MutationUpdateOrganisationArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type MutationUpdateDepartmentArgs = {
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
};

export type MutationDeleteRoleArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGroupArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteOrganisationArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteDepartmentArgs = {
  id: Scalars["ID"];
};

export type MutationDeleteGroupRoleArgs = {
  groupID: Scalars["ID"];
  roleID: Array<Scalars["ID"]>;
};

export type MutationDeleteUserGroupArgs = {
  userID: Scalars["ID"];
  groupID: Array<Scalars["ID"]>;
};

export type MutationRunJobArgs = {
  job: Job;
};

export type MutationSendEmailVerificationArgs = {
  userId?: Maybe<Scalars["ID"]>;
  userEmail?: Maybe<Scalars["String"]>;
};

export type MutationValidateEmailVerificationCodeArgs = {
  code: Scalars["String"];
};

export type MutationResetPasswordArgs = {
  code: Scalars["String"];
  password: Scalars["String"];
};

export type MutationSetEntityFilterArgs = {
  filterName: EntityFilterName;
  filterInputs: Array<EntityFilterInput>;
};

export type MutationAddNoteArgs = {
  comment: Scalars["String"];
  rID: Scalars["String"];
  attachments?: Maybe<Array<NoteAttachmentFile>>;
};

/** Represents the data of a note attachment. */
export type NoteAttachment = {
  __typename?: "NoteAttachment";
  /** Note Attachment Name. */
  name?: Maybe<Scalars["String"]>;
  /** Folder where note's attachment is stored. */
  folder?: Maybe<Scalars["String"]>;
  /** Note Attachment filename. */
  filename?: Maybe<Scalars["String"]>;
};

/** Represents the data of a note attachment. */
export type NoteAttachmentFile = {
  /** Note Attachment Path. */
  id: Scalars["Int"];
  file: Scalars["Upload"];
};

/** Represents the data of a note. */
export type NoteData = {
  __typename?: "NoteData";
  /** Note ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Note Comment. */
  comment?: Maybe<Scalars["String"]>;
  /** Ripplenami ID. */
  rID?: Maybe<Scalars["String"]>;
  /** UTC datetime when the note information was created. Not implemented yet. */
  creationDate?: Maybe<Scalars["String"]>;
  /** Note Attachments ID. */
  attachments?: Maybe<Array<Maybe<NoteAttachment>>>;
  /** Note Attachments Author. */
  user?: Maybe<UserData>;
};

/** Represents the data of an organisation. */
export type OrganisationData = {
  __typename?: "OrganisationData";
  /** Organisation ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the organisation. */
  name?: Maybe<Scalars["String"]>;
};

export type PermissionData = {
  __typename?: "PermissionData";
  /** Permissions ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the permission. */
  name?: Maybe<Scalars["String"]>;
  /** Descrition of the permission. */
  description?: Maybe<Scalars["String"]>;
  /** Type of the permission. It can be either Application or Data permission. */
  type?: Maybe<PermissionType>;
  /** Sequence number used by the UI to display the permissions in a consecutive order. */
  order?: Maybe<Scalars["Int"]>;
  /** Enable/disable the enforcement of this permission. */
  enabled?: Maybe<Scalars["Boolean"]>;
  /** Permissions associated with this permission. */
  children?: Maybe<Array<PermissionData>>;
};

export type PermissionInput = {
  /** Permission ID */
  id?: Maybe<Scalars["ID"]>;
  /** Enable/disable the enforcement of this permission. */
  enabled?: Maybe<Scalars["Boolean"]>;
};

export enum PermissionType {
  Application = "APPLICATION",
  Data = "DATA",
}

export type Query = {
  __typename?: "Query";
  /** Retrieves a list of all roles or a specific role for the given ID. */
  GetRole: Array<Maybe<RoleData>>;
  /** Retrieves a list of all users or a specific user for the given ID or email. */
  GetUser: Array<UserData>;
  /** Retrieves a list of all organisations or a specific one for the given ID. */
  GetOrganisation: Array<Maybe<OrganisationData>>;
  /** Retrieves a list of all departments or a specific one for the given ID. */
  GetDepartment: Array<Maybe<DepartmentData>>;
  /** Retrieves a list of all groups or a specific group for the given ID. */
  GetGroup: Array<Maybe<GroupData>>;
  /** Retrieves a list of all available permissions. */
  GetPermissions: Array<Maybe<PermissionData>>;
  /** Retrieves the user profile of the logged in user. */
  GetCurrentUserProfile: UserProfile;
  /**
   * Contains the final permissions computed by the processing of all inherited permissions of the roles
   * and groups related with the logged in user.
   */
  GetCurrentUserPermissions: Array<Maybe<PermissionData>>;
  /** Retrieves the status of the job given. */
  GetJobStatus: JobStatus;
  /** Creates a JWT token that allows the user to log in. */
  LogIn: Scalars["String"];
  /** Send a password reset request to the email address. */
  SendPasswordResetRequest: Scalars["Boolean"];
  /** Get server information. */
  GetServerInfo: ServerInfo;
  /** Get the Entity Filter values associated with the MDA entities. */
  GetEntityFilter: Array<EntityFilter>;
  /** Serializes and retrieves data for a given table. */
  ExportData: Scalars["String"];
  /** Retrieves a list of notes. */
  GetNote: Array<NoteData>;
  /** Serializes and retrieves files for the given file locations. */
  GetFile: Array<FileContent>;
  /** Retrieves an report based on query Ripplenami ID. */
  GetReport: ReportData;
};

export type QueryGetRoleArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetUserArgs = {
  id?: Maybe<Scalars["ID"]>;
  email?: Maybe<Scalars["String"]>;
};

export type QueryGetOrganisationArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetDepartmentArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetGroupArgs = {
  id?: Maybe<Scalars["ID"]>;
};

export type QueryGetPermissionsArgs = {
  type?: Maybe<PermissionType>;
};

export type QueryGetCurrentUserPermissionsArgs = {
  type?: Maybe<PermissionType>;
};

export type QueryGetJobStatusArgs = {
  job: Job;
};

export type QueryLogInArgs = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type QuerySendPasswordResetRequestArgs = {
  email: Scalars["String"];
};

export type QueryGetEntityFilterArgs = {
  filterName: EntityFilterName;
  entityInputs: Array<EntityInput>;
};

export type QueryExportDataArgs = {
  tableName: Scalars["String"];
  tableColumns: Array<TableColumn>;
  tableFilterJSONString: Scalars["String"];
};

export type QueryGetNoteArgs = {
  id?: Maybe<Scalars["ID"]>;
  rID?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["String"]>;
};

export type QueryGetFileArgs = {
  fileLocations: Array<FileLocation>;
};

export type QueryGetReportArgs = {
  rID: Scalars["String"];
};

/** Represents the data of a note. */
export type ReportData = {
  __typename?: "ReportData";
  /** Report (byte64 string). */
  data?: Maybe<Scalars["String"]>;
};

export enum Role {
  SystemAdmin = "SYSTEM_ADMIN",
  AppAdmin = "APP_ADMIN",
}

/** Represents the data of a role. */
export type RoleData = {
  __typename?: "RoleData";
  /** Role ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Name of the role. It is unique and could be used as another identifier. */
  name?: Maybe<Scalars["String"]>;
  /** Description of the role.  */
  description?: Maybe<Scalars["String"]>;
  /**
   * Visible Level for this role that will provides tha ability to see specific
   * designated records (Row Level Access).
   */
  visibleLevel?: Maybe<Scalars["Int"]>;
  /** UTC datetime when the role data was created. Not implemented yet. */
  creationDate?: Maybe<Scalars["String"]>;
  /**
   * Permissions is a JSON string that specifies read and write access to the GraphQL entities
   * through their queries and mutations respectively.
   */
  permissions: Array<PermissionData>;
};

/** Server Information. */
export type ServerInfo = {
  __typename?: "ServerInfo";
  /** Project name. */
  Name: Scalars["String"];
  /** Build date/time. */
  BuildTime: Scalars["String"];
};

/** Represents a table column of a table to export. */
export type TableColumn = {
  heading: Scalars["String"];
  fieldName: Scalars["String"];
};

/** Represents the information of an user. */
export type UserData = {
  __typename?: "UserData";
  /** User ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Email of the user. It is unique and could be used as another identifier. */
  email?: Maybe<Scalars["String"]>;
  /** Firstname of the user. */
  firstName?: Maybe<Scalars["String"]>;
  /** Lastname of the user. */
  lastName?: Maybe<Scalars["String"]>;
  /** Activate/deactivate the user. */
  active?: Maybe<Scalars["Boolean"]>;
  /** Profile picture of the user encoded in a base64 string. */
  profilePicture?: Maybe<Scalars["String"]>;
  /** Mobile phone of the user. */
  mobilePhone?: Maybe<Scalars["String"]>;
  /** Work contact data of the user. */
  work?: Maybe<ContactData>;
  /** UTC datetime when the password user was modified. */
  passwordModificationDate?: Maybe<Scalars["String"]>;
  /** Flag that indicates if whether or not the user has verified its email address. */
  emailVerified?: Maybe<Scalars["Boolean"]>;
  /** Login attempt counter. */
  loginAttemptCounter?: Maybe<Scalars["Int"]>;
  /** UTC datetime when the user information was last updated. */
  updated?: Maybe<Scalars["String"]>;
  /** UTC datetime when the user information was created. */
  creationDate?: Maybe<Scalars["String"]>;
  /** Groups the user belongs to. */
  groups?: Maybe<Array<Maybe<GroupData>>>;
  /** Organisation in which the user works. */
  organisation?: Maybe<OrganisationData>;
  /** Department the user belongs to. */
  department?: Maybe<DepartmentData>;
};

/** Represents the information of an user. */
export type UserProfile = {
  __typename?: "UserProfile";
  /** User ID. */
  id?: Maybe<Scalars["ID"]>;
  /** Email of the user. It is unique and could be used as another identifier. */
  email?: Maybe<Scalars["String"]>;
  /** Firstname of the user. */
  firstName?: Maybe<Scalars["String"]>;
  /** Lastname of the user. */
  lastName?: Maybe<Scalars["String"]>;
  /** Profile picture of the user encoded in a base64 string. */
  profilePicture?: Maybe<Scalars["String"]>;
  /** Mobile phone of the user. */
  mobilePhone?: Maybe<Scalars["String"]>;
  /** Work contact data of the user. */
  work?: Maybe<ContactData>;
  /** Organisation in which the user works. */
  organisation?: Maybe<OrganisationData>;
  /** Department the user belongs to. */
  department?: Maybe<DepartmentData>;
};

export type ValidateEmailVerificationCodeMutationVariables = Exact<{
  code: Scalars["String"];
}>;

export type ValidateEmailVerificationCodeMutation = {
  __typename?: "Mutation";
} & Pick<Mutation, "ValidateEmailVerificationCode">;

export type SendEmailVerificationMutationVariables = Exact<{
  userId?: Maybe<Scalars["ID"]>;
  userEmail?: Maybe<Scalars["String"]>;
}>;

export type SendEmailVerificationMutation = { __typename?: "Mutation" } & {
  SendEmailVerification: { __typename?: "UserData" } & UserFragmentFragment;
};

export type LogInQueryVariables = Exact<{
  email: Scalars["String"];
  password: Scalars["String"];
}>;

export type LogInQuery = { __typename?: "Query" } & Pick<Query, "LogIn">;

export type SendPasswordResetRequestQueryVariables = Exact<{
  email: Scalars["String"];
}>;

export type SendPasswordResetRequestQuery = { __typename?: "Query" } & Pick<
  Query,
  "SendPasswordResetRequest"
>;

export type ResetPasswordMutationVariables = Exact<{
  code: Scalars["String"];
  password: Scalars["String"];
}>;

export type ResetPasswordMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "ResetPassword"
>;

export type GetServerInfoQueryVariables = Exact<{ [key: string]: never }>;

export type GetServerInfoQuery = { __typename?: "Query" } & {
  GetServerInfo: { __typename?: "ServerInfo" } & Pick<
    ServerInfo,
    "Name" | "BuildTime"
  >;
};

export type ExportDataQueryVariables = Exact<{
  tableName: Scalars["String"];
  tableColumns: Array<TableColumn> | TableColumn;
  tableFilterJSONString: Scalars["String"];
}>;

export type ExportDataQuery = { __typename?: "Query" } & Pick<
  Query,
  "ExportData"
>;

export type NoteFragmentFragment = { __typename?: "NoteData" } & Pick<
  NoteData,
  "id" | "comment" | "rID" | "creationDate"
> & {
    attachments?: Maybe<
      Array<
        Maybe<
          { __typename?: "NoteAttachment" } & Pick<
            NoteAttachment,
            "name" | "folder" | "filename"
          >
        >
      >
    >;
    user?: Maybe<{ __typename?: "UserData" } & UserFragmentFragment>;
  };

export type GetNoteQueryVariables = Exact<{
  id?: Maybe<Scalars["ID"]>;
  rID?: Maybe<Scalars["String"]>;
  orderBy?: Maybe<Scalars["String"]>;
  offset?: Maybe<Scalars["String"]>;
}>;

export type GetNoteQuery = { __typename?: "Query" } & {
  GetNote: Array<{ __typename?: "NoteData" } & NoteFragmentFragment>;
};

export type AddNoteMutationVariables = Exact<{
  comment: Scalars["String"];
  rID: Scalars["String"];
  attachments?: Maybe<Array<NoteAttachmentFile> | NoteAttachmentFile>;
}>;

export type AddNoteMutation = { __typename?: "Mutation" } & {
  AddNote: Array<Maybe<{ __typename?: "NoteData" } & NoteFragmentFragment>>;
};

export type GetFileQueryVariables = Exact<{
  folder: Scalars["String"];
  filename: Scalars["String"];
}>;

export type GetFileQuery = { __typename?: "Query" } & {
  GetFile: Array<
    { __typename?: "FileContent" } & Pick<FileContent, "filename" | "content">
  >;
};

export type PermissionFragmentFragment = {
  __typename?: "PermissionData";
} & Pick<
  PermissionData,
  "id" | "name" | "description" | "type" | "order" | "enabled"
> & {
    children?: Maybe<
      Array<
        { __typename?: "PermissionData" } & Pick<
          PermissionData,
          "id" | "name" | "description" | "type" | "order" | "enabled"
        > & {
            children?: Maybe<
              Array<
                { __typename?: "PermissionData" } & Pick<
                  PermissionData,
                  "id" | "name" | "description" | "type" | "order" | "enabled"
                >
              >
            >;
          }
      >
    >;
  };

export type RoleFragmentFragment = { __typename?: "RoleData" } & Pick<
  RoleData,
  "id" | "name" | "description" | "visibleLevel" | "creationDate"
>;

export type GroupFragmentFragment = { __typename?: "GroupData" } & Pick<
  GroupData,
  "id" | "name" | "description" | "creationDate"
> & {
    roles?: Maybe<
      Array<
        Maybe<
          { __typename?: "RoleData" } & Pick<
            RoleData,
            "id" | "name" | "description" | "visibleLevel" | "creationDate"
          >
        >
      >
    >;
  };

export type UserFragmentFragment = { __typename?: "UserData" } & Pick<
  UserData,
  | "id"
  | "email"
  | "firstName"
  | "lastName"
  | "active"
  | "profilePicture"
  | "mobilePhone"
  | "updated"
  | "creationDate"
  | "passwordModificationDate"
  | "emailVerified"
> & {
    work?: Maybe<
      { __typename?: "ContactData" } & Pick<ContactData, "address" | "phone">
    >;
    organisation?: Maybe<
      { __typename?: "OrganisationData" } & Pick<
        OrganisationData,
        "id" | "name"
      >
    >;
    department?: Maybe<
      { __typename?: "DepartmentData" } & Pick<DepartmentData, "id" | "name">
    >;
    groups?: Maybe<
      Array<
        Maybe<
          { __typename?: "GroupData" } & Pick<
            GroupData,
            "id" | "name" | "description" | "creationDate"
          > & {
              roles?: Maybe<
                Array<
                  Maybe<
                    { __typename?: "RoleData" } & Pick<
                      RoleData,
                      | "id"
                      | "name"
                      | "description"
                      | "visibleLevel"
                      | "creationDate"
                    >
                  >
                >
              >;
            }
        >
      >
    >;
  };

export type UserProfileFragmentFragment = { __typename?: "UserProfile" } & Pick<
  UserProfile,
  "id" | "email" | "firstName" | "lastName" | "profilePicture" | "mobilePhone"
> & {
    work?: Maybe<
      { __typename?: "ContactData" } & Pick<ContactData, "address" | "phone">
    >;
    organisation?: Maybe<
      { __typename?: "OrganisationData" } & Pick<
        OrganisationData,
        "id" | "name"
      >
    >;
    department?: Maybe<
      { __typename?: "DepartmentData" } & Pick<DepartmentData, "id" | "name">
    >;
  };

export type GetPermissionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetPermissionsQuery = { __typename?: "Query" } & {
  GetPermissions: Array<
    Maybe<{ __typename?: "PermissionData" } & PermissionFragmentFragment>
  >;
};

export type GetCurrentUserProfileQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetCurrentUserProfileQuery = { __typename?: "Query" } & {
  GetCurrentUserProfile: {
    __typename?: "UserProfile";
  } & UserProfileFragmentFragment;
};

export type GetDepartmentQueryVariables = Exact<{ [key: string]: never }>;

export type GetDepartmentQuery = { __typename?: "Query" } & {
  GetDepartment: Array<
    Maybe<
      { __typename?: "DepartmentData" } & Pick<DepartmentData, "id" | "name">
    >
  >;
};

export type GetOrganisationQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganisationQuery = { __typename?: "Query" } & {
  GetOrganisation: Array<
    Maybe<
      { __typename?: "OrganisationData" } & Pick<
        OrganisationData,
        "id" | "name"
      >
    >
  >;
};

export type UpdateUserDataMutationVariables = Exact<{
  id: Scalars["ID"];
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  active?: Maybe<Scalars["Boolean"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  email?: Maybe<Scalars["String"]>;
  workAddress?: Maybe<Scalars["String"]>;
  workPhone?: Maybe<Scalars["String"]>;
  password?: Maybe<Scalars["String"]>;
}>;

export type UpdateUserDataMutation = { __typename?: "Mutation" } & {
  UpdateUser: Array<Maybe<{ __typename?: "UserData" } & UserFragmentFragment>>;
};

export type UpdateCurrentUserProfileMutationVariables = Exact<{
  firstName?: Maybe<Scalars["String"]>;
  lastName?: Maybe<Scalars["String"]>;
  profilePicture?: Maybe<Scalars["String"]>;
  mobilePhone?: Maybe<Scalars["String"]>;
  workAddress?: Maybe<Scalars["String"]>;
  workPhone?: Maybe<Scalars["String"]>;
}>;

export type UpdateCurrentUserProfileMutation = { __typename?: "Mutation" } & {
  UpdateCurrentUserProfile: {
    __typename?: "UserProfile";
  } & UserProfileFragmentFragment;
};

export type UpdateUserOrganisationMutationVariables = Exact<{
  userID: Scalars["ID"];
  organisationID?: Maybe<Scalars["ID"]>;
}>;

export type UpdateUserOrganisationMutation = { __typename?: "Mutation" } & {
  UpdateUserOrganisation: Array<
    Maybe<{ __typename?: "UserData" } & UserFragmentFragment>
  >;
};

export type UpdateUserDeparmentMutationVariables = Exact<{
  userID: Scalars["ID"];
  departmentID?: Maybe<Scalars["ID"]>;
}>;

export type UpdateUserDeparmentMutation = { __typename?: "Mutation" } & {
  UpdateUserDepartment: Array<
    Maybe<{ __typename?: "UserData" } & UserFragmentFragment>
  >;
};

export type UpdateCurrentUserProfileOrganisationMutationVariables = Exact<{
  organisationID?: Maybe<Scalars["ID"]>;
}>;

export type UpdateCurrentUserProfileOrganisationMutation = {
  __typename?: "Mutation";
} & {
  UpdateCurrentUserProfileOrganisation: {
    __typename?: "UserProfile";
  } & UserProfileFragmentFragment;
};

export type UpdateCurrentUserProfileDepartmentMutationVariables = Exact<{
  departmentID?: Maybe<Scalars["ID"]>;
}>;

export type UpdateCurrentUserProfileDepartmentMutation = {
  __typename?: "Mutation";
} & {
  UpdateCurrentUserProfileDepartment: {
    __typename?: "UserProfile";
  } & UserProfileFragmentFragment;
};

export type CreateUserMutationVariables = Exact<{
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  active: Scalars["Boolean"];
  profilePicture: Scalars["String"];
  mobilePhone: Scalars["String"];
  workAddress: Scalars["String"];
  workPhone: Scalars["String"];
  password: Scalars["String"];
}>;

export type CreateUserMutation = { __typename?: "Mutation" } & {
  AddUser: Array<Maybe<{ __typename?: "UserData" } & UserFragmentFragment>>;
};

export type DeleteUserMutationVariables = Exact<{
  userID: Scalars["ID"];
}>;

export type DeleteUserMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "DeleteUser"
>;

export type AddUserGroupMutationVariables = Exact<{
  userID: Scalars["ID"];
  groupID: Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>;
}>;

export type AddUserGroupMutation = { __typename?: "Mutation" } & {
  AddUserGroup: Array<
    Maybe<{ __typename?: "UserData" } & UserFragmentFragment>
  >;
};

export type UpdateUserGroupMutationVariables = Exact<{
  userID: Scalars["ID"];
  groupID: Array<Scalars["ID"]> | Scalars["ID"];
  groupIDNew: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type UpdateUserGroupMutation = { __typename?: "Mutation" } & {
  DeleteUserGroup: Array<{ __typename?: "UserData" } & UserFragmentFragment>;
  AddUserGroup: Array<
    Maybe<{ __typename?: "UserData" } & UserFragmentFragment>
  >;
};

export type DeleteUserGroupMutationVariables = Exact<{
  userID: Scalars["ID"];
  groupID: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteUserGroupMutation = { __typename?: "Mutation" } & {
  DeleteUserGroup: Array<{ __typename?: "UserData" } & UserFragmentFragment>;
};

export type GetGroupQueryVariables = Exact<{ [key: string]: never }>;

export type GetGroupQuery = { __typename?: "Query" } & {
  GetGroup: Array<Maybe<{ __typename?: "GroupData" } & GroupFragmentFragment>>;
};

export type GetAllUsersQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllUsersQuery = { __typename?: "Query" } & {
  GetUser: Array<{ __typename?: "UserData" } & UserFragmentFragment>;
};

export type AddRoleMutationVariables = Exact<{
  name: Scalars["String"];
  description: Scalars["String"];
  visibleLevel: Scalars["Int"];
  permissionInputs?: Maybe<
    Array<Maybe<PermissionInput>> | Maybe<PermissionInput>
  >;
}>;

export type AddRoleMutation = { __typename?: "Mutation" } & {
  AddRole: Array<Maybe<{ __typename?: "RoleData" } & RoleFragmentFragment>>;
};

export type GetRoleQueryVariables = Exact<{ [key: string]: never }>;

export type GetRoleQuery = { __typename?: "Query" } & {
  GetRole: Array<Maybe<{ __typename?: "RoleData" } & RoleFragmentFragment>>;
};

export type GetRoleWithPermissionsQueryVariables = Exact<{
  roleId: Scalars["ID"];
}>;

export type GetRoleWithPermissionsQuery = { __typename?: "Query" } & {
  GetRole: Array<
    Maybe<
      { __typename?: "RoleData" } & {
        permissions: Array<
          { __typename?: "PermissionData" } & PermissionFragmentFragment
        >;
      } & RoleFragmentFragment
    >
  >;
};

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars["ID"];
  name: Scalars["String"];
  description: Scalars["String"];
  visibleLevel: Scalars["Int"];
  permissionInputs?: Maybe<
    Array<Maybe<PermissionInput>> | Maybe<PermissionInput>
  >;
}>;

export type UpdateRoleMutation = { __typename?: "Mutation" } & {
  UpdateRole: Array<
    Maybe<
      { __typename?: "RoleData" } & {
        permissions: Array<
          { __typename?: "PermissionData" } & PermissionFragmentFragment
        >;
      } & RoleFragmentFragment
    >
  >;
};

export type UpdatePermissionMutationVariables = Exact<{
  roleId: Scalars["ID"];
  permissionInputs?: Maybe<
    Array<Maybe<PermissionInput>> | Maybe<PermissionInput>
  >;
}>;

export type UpdatePermissionMutation = { __typename?: "Mutation" } & {
  UpdateRole: Array<Maybe<{ __typename?: "RoleData" } & RoleFragmentFragment>>;
};

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteRoleMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "DeleteRole"
>;

export type AddGroupMutationVariables = Exact<{
  name: Scalars["String"];
  description: Scalars["String"];
}>;

export type AddGroupMutation = { __typename?: "Mutation" } & {
  AddGroup: Array<Maybe<{ __typename?: "GroupData" } & GroupFragmentFragment>>;
};

export type UpdateGroupMutationVariables = Exact<{
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
}>;

export type UpdateGroupMutation = { __typename?: "Mutation" } & {
  UpdateGroup: Array<
    Maybe<{ __typename?: "GroupData" } & GroupFragmentFragment>
  >;
};

export type DeleteGroupMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DeleteGroupMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "DeleteGroup"
>;

export type AddGroupRoleMutationVariables = Exact<{
  groupID: Scalars["ID"];
  roleID: Array<Maybe<Scalars["ID"]>> | Maybe<Scalars["ID"]>;
}>;

export type AddGroupRoleMutation = { __typename?: "Mutation" } & {
  AddGroupRole: Array<
    Maybe<{ __typename?: "GroupData" } & GroupFragmentFragment>
  >;
};

export type DeleteGroupRoleMutationVariables = Exact<{
  groupID: Scalars["ID"];
  roleID: Array<Scalars["ID"]> | Scalars["ID"];
}>;

export type DeleteGroupRoleMutation = { __typename?: "Mutation" } & {
  DeleteGroupRole: Array<{ __typename?: "GroupData" } & GroupFragmentFragment>;
};

export type GetCurrentUserPermissionsQueryVariables = Exact<{
  type?: Maybe<PermissionType>;
}>;

export type GetCurrentUserPermissionsQuery = { __typename?: "Query" } & {
  GetCurrentUserPermissions: Array<
    Maybe<{ __typename?: "PermissionData" } & PermissionFragmentFragment>
  >;
};

export type RunJobMutationVariables = Exact<{
  job: Job;
}>;

export type RunJobMutation = { __typename?: "Mutation" } & Pick<
  Mutation,
  "RunJob"
>;

export type GetJobStatusQueryVariables = Exact<{
  job: Job;
}>;

export type GetJobStatusQuery = { __typename?: "Query" } & {
  GetJobStatus: { __typename?: "JobStatus" } & Pick<
    JobStatus,
    "status" | "message"
  >;
};

export type GetReportQueryVariables = Exact<{
  rnid: Scalars["String"];
}>;

export type GetReportQuery = { __typename?: "Query" } & {
  GetReport: { __typename?: "ReportData" } & Pick<ReportData, "data">;
};

export type EntityTypeFragment = { __typename?: "EntityFilter" } & Pick<
  EntityFilter,
  "id" | "entityIdType" | "entityId" | "filterName" | "filterValue"
>;

export type SetVisibilityFilterMutationVariables = Exact<{
  filterName: EntityFilterName;
  filterInputs: Array<EntityFilterInput> | EntityFilterInput;
}>;

export type SetVisibilityFilterMutation = { __typename?: "Mutation" } & {
  SetEntityFilter: Array<{ __typename?: "EntityFilter" } & EntityTypeFragment>;
};

export type GetEntityFilterQueryVariables = Exact<{
  filterName: EntityFilterName;
  entityInputs: Array<EntityInput> | EntityInput;
}>;

export type GetEntityFilterQuery = { __typename?: "Query" } & {
  GetEntityFilter: Array<{ __typename?: "EntityFilter" } & EntityTypeFragment>;
};

export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on UserData {
    id
    email
    firstName
    lastName
    active
    profilePicture
    mobilePhone
    work {
      address
      phone
    }
    updated
    creationDate
    passwordModificationDate
    emailVerified
    organisation {
      id
      name
    }
    department {
      id
      name
    }
    groups {
      id
      name
      description
      creationDate
      roles {
        id
        name
        description
        visibleLevel
        creationDate
      }
    }
  }
`;
export const NoteFragmentFragmentDoc = gql`
  fragment NoteFragment on NoteData {
    id
    comment
    rID
    creationDate
    attachments {
      name
      folder
      filename
    }
    user {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export const PermissionFragmentFragmentDoc = gql`
  fragment PermissionFragment on PermissionData {
    id
    name
    description
    type
    order
    enabled
    children {
      id
      name
      description
      type
      order
      enabled
      children {
        id
        name
        description
        type
        order
        enabled
      }
    }
  }
`;
export const RoleFragmentFragmentDoc = gql`
  fragment RoleFragment on RoleData {
    id
    name
    description
    visibleLevel
    creationDate
  }
`;
export const GroupFragmentFragmentDoc = gql`
  fragment GroupFragment on GroupData {
    id
    name
    description
    creationDate
    roles {
      id
      name
      description
      visibleLevel
      creationDate
    }
  }
`;
export const UserProfileFragmentFragmentDoc = gql`
  fragment UserProfileFragment on UserProfile {
    id
    email
    firstName
    lastName
    profilePicture
    mobilePhone
    work {
      address
      phone
    }
    organisation {
      id
      name
    }
    department {
      id
      name
    }
  }
`;
export const EntityTypeFragmentDoc = gql`
  fragment EntityType on EntityFilter {
    id
    entityIdType
    entityId
    filterName
    filterValue
  }
`;
export const ValidateEmailVerificationCodeDocument = gql`
  mutation ValidateEmailVerificationCode($code: String!) {
    ValidateEmailVerificationCode(code: $code)
  }
`;
export type ValidateEmailVerificationCodeMutationFn = Apollo.MutationFunction<
  ValidateEmailVerificationCodeMutation,
  ValidateEmailVerificationCodeMutationVariables
>;

/**
 * __useValidateEmailVerificationCodeMutation__
 *
 * To run a mutation, you first call `useValidateEmailVerificationCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidateEmailVerificationCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validateEmailVerificationCodeMutation, { data, loading, error }] = useValidateEmailVerificationCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidateEmailVerificationCodeMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ValidateEmailVerificationCodeMutation,
    ValidateEmailVerificationCodeMutationVariables
  >
) {
  return Apollo.useMutation<
    ValidateEmailVerificationCodeMutation,
    ValidateEmailVerificationCodeMutationVariables
  >(ValidateEmailVerificationCodeDocument, baseOptions);
}
export type ValidateEmailVerificationCodeMutationHookResult = ReturnType<
  typeof useValidateEmailVerificationCodeMutation
>;
export type ValidateEmailVerificationCodeMutationResult =
  Apollo.MutationResult<ValidateEmailVerificationCodeMutation>;
export type ValidateEmailVerificationCodeMutationOptions =
  Apollo.BaseMutationOptions<
    ValidateEmailVerificationCodeMutation,
    ValidateEmailVerificationCodeMutationVariables
  >;
export const SendEmailVerificationDocument = gql`
  mutation SendEmailVerification($userId: ID, $userEmail: String) {
    SendEmailVerification(userId: $userId, userEmail: $userEmail) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type SendEmailVerificationMutationFn = Apollo.MutationFunction<
  SendEmailVerificationMutation,
  SendEmailVerificationMutationVariables
>;

/**
 * __useSendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useSendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendEmailVerificationMutation, { data, loading, error }] = useSendEmailVerificationMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      userEmail: // value for 'userEmail'
 *   },
 * });
 */
export function useSendEmailVerificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SendEmailVerificationMutation,
    SendEmailVerificationMutationVariables
  >
) {
  return Apollo.useMutation<
    SendEmailVerificationMutation,
    SendEmailVerificationMutationVariables
  >(SendEmailVerificationDocument, baseOptions);
}
export type SendEmailVerificationMutationHookResult = ReturnType<
  typeof useSendEmailVerificationMutation
>;
export type SendEmailVerificationMutationResult =
  Apollo.MutationResult<SendEmailVerificationMutation>;
export type SendEmailVerificationMutationOptions = Apollo.BaseMutationOptions<
  SendEmailVerificationMutation,
  SendEmailVerificationMutationVariables
>;
export const LogInDocument = gql`
  query LogIn($email: String!, $password: String!) {
    LogIn(email: $email, password: $password)
  }
`;

/**
 * __useLogInQuery__
 *
 * To run a query within a React component, call `useLogInQuery` and pass it any options that fit your needs.
 * When your component renders, `useLogInQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLogInQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLogInQuery(
  baseOptions: Apollo.QueryHookOptions<LogInQuery, LogInQueryVariables>
) {
  return Apollo.useQuery<LogInQuery, LogInQueryVariables>(
    LogInDocument,
    baseOptions
  );
}
export function useLogInLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LogInQuery, LogInQueryVariables>
) {
  return Apollo.useLazyQuery<LogInQuery, LogInQueryVariables>(
    LogInDocument,
    baseOptions
  );
}
export type LogInQueryHookResult = ReturnType<typeof useLogInQuery>;
export type LogInLazyQueryHookResult = ReturnType<typeof useLogInLazyQuery>;
export type LogInQueryResult = Apollo.QueryResult<
  LogInQuery,
  LogInQueryVariables
>;
export const SendPasswordResetRequestDocument = gql`
  query SendPasswordResetRequest($email: String!) {
    SendPasswordResetRequest(email: $email)
  }
`;

/**
 * __useSendPasswordResetRequestQuery__
 *
 * To run a query within a React component, call `useSendPasswordResetRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendPasswordResetRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendPasswordResetRequestQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useSendPasswordResetRequestQuery(
  baseOptions: Apollo.QueryHookOptions<
    SendPasswordResetRequestQuery,
    SendPasswordResetRequestQueryVariables
  >
) {
  return Apollo.useQuery<
    SendPasswordResetRequestQuery,
    SendPasswordResetRequestQueryVariables
  >(SendPasswordResetRequestDocument, baseOptions);
}
export function useSendPasswordResetRequestLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    SendPasswordResetRequestQuery,
    SendPasswordResetRequestQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    SendPasswordResetRequestQuery,
    SendPasswordResetRequestQueryVariables
  >(SendPasswordResetRequestDocument, baseOptions);
}
export type SendPasswordResetRequestQueryHookResult = ReturnType<
  typeof useSendPasswordResetRequestQuery
>;
export type SendPasswordResetRequestLazyQueryHookResult = ReturnType<
  typeof useSendPasswordResetRequestLazyQuery
>;
export type SendPasswordResetRequestQueryResult = Apollo.QueryResult<
  SendPasswordResetRequestQuery,
  SendPasswordResetRequestQueryVariables
>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($code: String!, $password: String!) {
    ResetPassword(code: $code, password: $password)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      code: // value for 'code'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >
) {
  return Apollo.useMutation<
    ResetPasswordMutation,
    ResetPasswordMutationVariables
  >(ResetPasswordDocument, baseOptions);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const GetServerInfoDocument = gql`
  query GetServerInfo {
    GetServerInfo {
      Name
      BuildTime
    }
  }
`;

/**
 * __useGetServerInfoQuery__
 *
 * To run a query within a React component, call `useGetServerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetServerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetServerInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetServerInfoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetServerInfoQuery,
    GetServerInfoQueryVariables
  >
) {
  return Apollo.useQuery<GetServerInfoQuery, GetServerInfoQueryVariables>(
    GetServerInfoDocument,
    baseOptions
  );
}
export function useGetServerInfoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetServerInfoQuery,
    GetServerInfoQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetServerInfoQuery, GetServerInfoQueryVariables>(
    GetServerInfoDocument,
    baseOptions
  );
}
export type GetServerInfoQueryHookResult = ReturnType<
  typeof useGetServerInfoQuery
>;
export type GetServerInfoLazyQueryHookResult = ReturnType<
  typeof useGetServerInfoLazyQuery
>;
export type GetServerInfoQueryResult = Apollo.QueryResult<
  GetServerInfoQuery,
  GetServerInfoQueryVariables
>;
export const ExportDataDocument = gql`
  query ExportData(
    $tableName: String!
    $tableColumns: [TableColumn!]!
    $tableFilterJSONString: String!
  ) {
    ExportData(
      tableName: $tableName
      tableColumns: $tableColumns
      tableFilterJSONString: $tableFilterJSONString
    )
  }
`;

/**
 * __useExportDataQuery__
 *
 * To run a query within a React component, call `useExportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportDataQuery({
 *   variables: {
 *      tableName: // value for 'tableName'
 *      tableColumns: // value for 'tableColumns'
 *      tableFilterJSONString: // value for 'tableFilterJSONString'
 *   },
 * });
 */
export function useExportDataQuery(
  baseOptions: Apollo.QueryHookOptions<
    ExportDataQuery,
    ExportDataQueryVariables
  >
) {
  return Apollo.useQuery<ExportDataQuery, ExportDataQueryVariables>(
    ExportDataDocument,
    baseOptions
  );
}
export function useExportDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    ExportDataQuery,
    ExportDataQueryVariables
  >
) {
  return Apollo.useLazyQuery<ExportDataQuery, ExportDataQueryVariables>(
    ExportDataDocument,
    baseOptions
  );
}
export type ExportDataQueryHookResult = ReturnType<typeof useExportDataQuery>;
export type ExportDataLazyQueryHookResult = ReturnType<
  typeof useExportDataLazyQuery
>;
export type ExportDataQueryResult = Apollo.QueryResult<
  ExportDataQuery,
  ExportDataQueryVariables
>;
export const GetNoteDocument = gql`
  query getNote($id: ID, $rID: String, $orderBy: String, $offset: String) {
    GetNote(id: $id, rID: $rID, orderBy: $orderBy, offset: $offset) {
      ...NoteFragment
    }
  }
  ${NoteFragmentFragmentDoc}
`;

/**
 * __useGetNoteQuery__
 *
 * To run a query within a React component, call `useGetNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *      rID: // value for 'rID'
 *      orderBy: // value for 'orderBy'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetNoteQuery(
  baseOptions?: Apollo.QueryHookOptions<GetNoteQuery, GetNoteQueryVariables>
) {
  return Apollo.useQuery<GetNoteQuery, GetNoteQueryVariables>(
    GetNoteDocument,
    baseOptions
  );
}
export function useGetNoteLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNoteQuery, GetNoteQueryVariables>
) {
  return Apollo.useLazyQuery<GetNoteQuery, GetNoteQueryVariables>(
    GetNoteDocument,
    baseOptions
  );
}
export type GetNoteQueryHookResult = ReturnType<typeof useGetNoteQuery>;
export type GetNoteLazyQueryHookResult = ReturnType<typeof useGetNoteLazyQuery>;
export type GetNoteQueryResult = Apollo.QueryResult<
  GetNoteQuery,
  GetNoteQueryVariables
>;
export const AddNoteDocument = gql`
  mutation AddNote(
    $comment: String!
    $rID: String!
    $attachments: [NoteAttachmentFile!]
  ) {
    AddNote(comment: $comment, rID: $rID, attachments: $attachments) {
      ...NoteFragment
    }
  }
  ${NoteFragmentFragmentDoc}
`;
export type AddNoteMutationFn = Apollo.MutationFunction<
  AddNoteMutation,
  AddNoteMutationVariables
>;

/**
 * __useAddNoteMutation__
 *
 * To run a mutation, you first call `useAddNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addNoteMutation, { data, loading, error }] = useAddNoteMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      rID: // value for 'rID'
 *      attachments: // value for 'attachments'
 *   },
 * });
 */
export function useAddNoteMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddNoteMutation,
    AddNoteMutationVariables
  >
) {
  return Apollo.useMutation<AddNoteMutation, AddNoteMutationVariables>(
    AddNoteDocument,
    baseOptions
  );
}
export type AddNoteMutationHookResult = ReturnType<typeof useAddNoteMutation>;
export type AddNoteMutationResult = Apollo.MutationResult<AddNoteMutation>;
export type AddNoteMutationOptions = Apollo.BaseMutationOptions<
  AddNoteMutation,
  AddNoteMutationVariables
>;
export const GetFileDocument = gql`
  query GetFile($folder: String!, $filename: String!) {
    GetFile(fileLocations: [{ folder: $folder, filename: $filename }]) {
      filename
      content
    }
  }
`;

/**
 * __useGetFileQuery__
 *
 * To run a query within a React component, call `useGetFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileQuery({
 *   variables: {
 *      folder: // value for 'folder'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useGetFileQuery(
  baseOptions: Apollo.QueryHookOptions<GetFileQuery, GetFileQueryVariables>
) {
  return Apollo.useQuery<GetFileQuery, GetFileQueryVariables>(
    GetFileDocument,
    baseOptions
  );
}
export function useGetFileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFileQuery, GetFileQueryVariables>
) {
  return Apollo.useLazyQuery<GetFileQuery, GetFileQueryVariables>(
    GetFileDocument,
    baseOptions
  );
}
export type GetFileQueryHookResult = ReturnType<typeof useGetFileQuery>;
export type GetFileLazyQueryHookResult = ReturnType<typeof useGetFileLazyQuery>;
export type GetFileQueryResult = Apollo.QueryResult<
  GetFileQuery,
  GetFileQueryVariables
>;
export const GetPermissionsDocument = gql`
  query GetPermissions {
    GetPermissions {
      ...PermissionFragment
    }
  }
  ${PermissionFragmentFragmentDoc}
`;

/**
 * __useGetPermissionsQuery__
 *
 * To run a query within a React component, call `useGetPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPermissionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  return Apollo.useQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    baseOptions
  );
}
export function useGetPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPermissionsQuery,
    GetPermissionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetPermissionsQuery, GetPermissionsQueryVariables>(
    GetPermissionsDocument,
    baseOptions
  );
}
export type GetPermissionsQueryHookResult = ReturnType<
  typeof useGetPermissionsQuery
>;
export type GetPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetPermissionsLazyQuery
>;
export type GetPermissionsQueryResult = Apollo.QueryResult<
  GetPermissionsQuery,
  GetPermissionsQueryVariables
>;
export const GetCurrentUserProfileDocument = gql`
  query GetCurrentUserProfile {
    GetCurrentUserProfile {
      ...UserProfileFragment
    }
  }
  ${UserProfileFragmentFragmentDoc}
`;

/**
 * __useGetCurrentUserProfileQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserProfileQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserProfileQuery,
    GetCurrentUserProfileQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentUserProfileQuery,
    GetCurrentUserProfileQueryVariables
  >(GetCurrentUserProfileDocument, baseOptions);
}
export function useGetCurrentUserProfileLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserProfileQuery,
    GetCurrentUserProfileQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentUserProfileQuery,
    GetCurrentUserProfileQueryVariables
  >(GetCurrentUserProfileDocument, baseOptions);
}
export type GetCurrentUserProfileQueryHookResult = ReturnType<
  typeof useGetCurrentUserProfileQuery
>;
export type GetCurrentUserProfileLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserProfileLazyQuery
>;
export type GetCurrentUserProfileQueryResult = Apollo.QueryResult<
  GetCurrentUserProfileQuery,
  GetCurrentUserProfileQueryVariables
>;
export const GetDepartmentDocument = gql`
  query getDepartment {
    GetDepartment {
      id
      name
    }
  }
`;

/**
 * __useGetDepartmentQuery__
 *
 * To run a query within a React component, call `useGetDepartmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDepartmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDepartmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDepartmentQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDepartmentQuery,
    GetDepartmentQueryVariables
  >
) {
  return Apollo.useQuery<GetDepartmentQuery, GetDepartmentQueryVariables>(
    GetDepartmentDocument,
    baseOptions
  );
}
export function useGetDepartmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDepartmentQuery,
    GetDepartmentQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetDepartmentQuery, GetDepartmentQueryVariables>(
    GetDepartmentDocument,
    baseOptions
  );
}
export type GetDepartmentQueryHookResult = ReturnType<
  typeof useGetDepartmentQuery
>;
export type GetDepartmentLazyQueryHookResult = ReturnType<
  typeof useGetDepartmentLazyQuery
>;
export type GetDepartmentQueryResult = Apollo.QueryResult<
  GetDepartmentQuery,
  GetDepartmentQueryVariables
>;
export const GetOrganisationDocument = gql`
  query getOrganisation {
    GetOrganisation {
      id
      name
    }
  }
`;

/**
 * __useGetOrganisationQuery__
 *
 * To run a query within a React component, call `useGetOrganisationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganisationQuery,
    GetOrganisationQueryVariables
  >
) {
  return Apollo.useQuery<GetOrganisationQuery, GetOrganisationQueryVariables>(
    GetOrganisationDocument,
    baseOptions
  );
}
export function useGetOrganisationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganisationQuery,
    GetOrganisationQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetOrganisationQuery,
    GetOrganisationQueryVariables
  >(GetOrganisationDocument, baseOptions);
}
export type GetOrganisationQueryHookResult = ReturnType<
  typeof useGetOrganisationQuery
>;
export type GetOrganisationLazyQueryHookResult = ReturnType<
  typeof useGetOrganisationLazyQuery
>;
export type GetOrganisationQueryResult = Apollo.QueryResult<
  GetOrganisationQuery,
  GetOrganisationQueryVariables
>;
export const UpdateUserDataDocument = gql`
  mutation UpdateUserData(
    $id: ID!
    $firstName: String
    $lastName: String
    $active: Boolean
    $mobilePhone: String
    $profilePicture: String
    $email: String
    $workAddress: String
    $workPhone: String
    $password: String
  ) {
    UpdateUser(
      id: $id
      firstName: $firstName
      lastName: $lastName
      active: $active
      profilePicture: $profilePicture
      email: $email
      mobilePhone: $mobilePhone
      workAddress: $workAddress
      workPhone: $workPhone
      password: $password
    ) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserDataMutationFn = Apollo.MutationFunction<
  UpdateUserDataMutation,
  UpdateUserDataMutationVariables
>;

/**
 * __useUpdateUserDataMutation__
 *
 * To run a mutation, you first call `useUpdateUserDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDataMutation, { data, loading, error }] = useUpdateUserDataMutation({
 *   variables: {
 *      id: // value for 'id'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      active: // value for 'active'
 *      mobilePhone: // value for 'mobilePhone'
 *      profilePicture: // value for 'profilePicture'
 *      email: // value for 'email'
 *      workAddress: // value for 'workAddress'
 *      workPhone: // value for 'workPhone'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserDataMutation,
    UpdateUserDataMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserDataMutation,
    UpdateUserDataMutationVariables
  >(UpdateUserDataDocument, baseOptions);
}
export type UpdateUserDataMutationHookResult = ReturnType<
  typeof useUpdateUserDataMutation
>;
export type UpdateUserDataMutationResult =
  Apollo.MutationResult<UpdateUserDataMutation>;
export type UpdateUserDataMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserDataMutation,
  UpdateUserDataMutationVariables
>;
export const UpdateCurrentUserProfileDocument = gql`
  mutation UpdateCurrentUserProfile(
    $firstName: String
    $lastName: String
    $profilePicture: String
    $mobilePhone: String
    $workAddress: String
    $workPhone: String
  ) {
    UpdateCurrentUserProfile(
      firstName: $firstName
      lastName: $lastName
      profilePicture: $profilePicture
      mobilePhone: $mobilePhone
      workAddress: $workAddress
      workPhone: $workPhone
    ) {
      ...UserProfileFragment
    }
  }
  ${UserProfileFragmentFragmentDoc}
`;
export type UpdateCurrentUserProfileMutationFn = Apollo.MutationFunction<
  UpdateCurrentUserProfileMutation,
  UpdateCurrentUserProfileMutationVariables
>;

/**
 * __useUpdateCurrentUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserProfileMutation, { data, loading, error }] = useUpdateCurrentUserProfileMutation({
 *   variables: {
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      profilePicture: // value for 'profilePicture'
 *      mobilePhone: // value for 'mobilePhone'
 *      workAddress: // value for 'workAddress'
 *      workPhone: // value for 'workPhone'
 *   },
 * });
 */
export function useUpdateCurrentUserProfileMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserProfileMutation,
    UpdateCurrentUserProfileMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCurrentUserProfileMutation,
    UpdateCurrentUserProfileMutationVariables
  >(UpdateCurrentUserProfileDocument, baseOptions);
}
export type UpdateCurrentUserProfileMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserProfileMutation
>;
export type UpdateCurrentUserProfileMutationResult =
  Apollo.MutationResult<UpdateCurrentUserProfileMutation>;
export type UpdateCurrentUserProfileMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentUserProfileMutation,
    UpdateCurrentUserProfileMutationVariables
  >;
export const UpdateUserOrganisationDocument = gql`
  mutation UpdateUserOrganisation($userID: ID!, $organisationID: ID) {
    UpdateUserOrganisation(userID: $userID, organisationID: $organisationID) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserOrganisationMutationFn = Apollo.MutationFunction<
  UpdateUserOrganisationMutation,
  UpdateUserOrganisationMutationVariables
>;

/**
 * __useUpdateUserOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateUserOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserOrganisationMutation, { data, loading, error }] = useUpdateUserOrganisationMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      organisationID: // value for 'organisationID'
 *   },
 * });
 */
export function useUpdateUserOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserOrganisationMutation,
    UpdateUserOrganisationMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserOrganisationMutation,
    UpdateUserOrganisationMutationVariables
  >(UpdateUserOrganisationDocument, baseOptions);
}
export type UpdateUserOrganisationMutationHookResult = ReturnType<
  typeof useUpdateUserOrganisationMutation
>;
export type UpdateUserOrganisationMutationResult =
  Apollo.MutationResult<UpdateUserOrganisationMutation>;
export type UpdateUserOrganisationMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserOrganisationMutation,
  UpdateUserOrganisationMutationVariables
>;
export const UpdateUserDeparmentDocument = gql`
  mutation UpdateUserDeparment($userID: ID!, $departmentID: ID) {
    UpdateUserDepartment(userID: $userID, departmentID: $departmentID) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserDeparmentMutationFn = Apollo.MutationFunction<
  UpdateUserDeparmentMutation,
  UpdateUserDeparmentMutationVariables
>;

/**
 * __useUpdateUserDeparmentMutation__
 *
 * To run a mutation, you first call `useUpdateUserDeparmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserDeparmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserDeparmentMutation, { data, loading, error }] = useUpdateUserDeparmentMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      departmentID: // value for 'departmentID'
 *   },
 * });
 */
export function useUpdateUserDeparmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserDeparmentMutation,
    UpdateUserDeparmentMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserDeparmentMutation,
    UpdateUserDeparmentMutationVariables
  >(UpdateUserDeparmentDocument, baseOptions);
}
export type UpdateUserDeparmentMutationHookResult = ReturnType<
  typeof useUpdateUserDeparmentMutation
>;
export type UpdateUserDeparmentMutationResult =
  Apollo.MutationResult<UpdateUserDeparmentMutation>;
export type UpdateUserDeparmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserDeparmentMutation,
  UpdateUserDeparmentMutationVariables
>;
export const UpdateCurrentUserProfileOrganisationDocument = gql`
  mutation UpdateCurrentUserProfileOrganisation($organisationID: ID) {
    UpdateCurrentUserProfileOrganisation(organisationID: $organisationID) {
      ...UserProfileFragment
    }
  }
  ${UserProfileFragmentFragmentDoc}
`;
export type UpdateCurrentUserProfileOrganisationMutationFn =
  Apollo.MutationFunction<
    UpdateCurrentUserProfileOrganisationMutation,
    UpdateCurrentUserProfileOrganisationMutationVariables
  >;

/**
 * __useUpdateCurrentUserProfileOrganisationMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserProfileOrganisationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserProfileOrganisationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserProfileOrganisationMutation, { data, loading, error }] = useUpdateCurrentUserProfileOrganisationMutation({
 *   variables: {
 *      organisationID: // value for 'organisationID'
 *   },
 * });
 */
export function useUpdateCurrentUserProfileOrganisationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserProfileOrganisationMutation,
    UpdateCurrentUserProfileOrganisationMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCurrentUserProfileOrganisationMutation,
    UpdateCurrentUserProfileOrganisationMutationVariables
  >(UpdateCurrentUserProfileOrganisationDocument, baseOptions);
}
export type UpdateCurrentUserProfileOrganisationMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserProfileOrganisationMutation
>;
export type UpdateCurrentUserProfileOrganisationMutationResult =
  Apollo.MutationResult<UpdateCurrentUserProfileOrganisationMutation>;
export type UpdateCurrentUserProfileOrganisationMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentUserProfileOrganisationMutation,
    UpdateCurrentUserProfileOrganisationMutationVariables
  >;
export const UpdateCurrentUserProfileDepartmentDocument = gql`
  mutation UpdateCurrentUserProfileDepartment($departmentID: ID) {
    UpdateCurrentUserProfileDepartment(departmentID: $departmentID) {
      ...UserProfileFragment
    }
  }
  ${UserProfileFragmentFragmentDoc}
`;
export type UpdateCurrentUserProfileDepartmentMutationFn =
  Apollo.MutationFunction<
    UpdateCurrentUserProfileDepartmentMutation,
    UpdateCurrentUserProfileDepartmentMutationVariables
  >;

/**
 * __useUpdateCurrentUserProfileDepartmentMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentUserProfileDepartmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentUserProfileDepartmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentUserProfileDepartmentMutation, { data, loading, error }] = useUpdateCurrentUserProfileDepartmentMutation({
 *   variables: {
 *      departmentID: // value for 'departmentID'
 *   },
 * });
 */
export function useUpdateCurrentUserProfileDepartmentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentUserProfileDepartmentMutation,
    UpdateCurrentUserProfileDepartmentMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateCurrentUserProfileDepartmentMutation,
    UpdateCurrentUserProfileDepartmentMutationVariables
  >(UpdateCurrentUserProfileDepartmentDocument, baseOptions);
}
export type UpdateCurrentUserProfileDepartmentMutationHookResult = ReturnType<
  typeof useUpdateCurrentUserProfileDepartmentMutation
>;
export type UpdateCurrentUserProfileDepartmentMutationResult =
  Apollo.MutationResult<UpdateCurrentUserProfileDepartmentMutation>;
export type UpdateCurrentUserProfileDepartmentMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateCurrentUserProfileDepartmentMutation,
    UpdateCurrentUserProfileDepartmentMutationVariables
  >;
export const CreateUserDocument = gql`
  mutation CreateUser(
    $email: String!
    $firstName: String!
    $lastName: String!
    $active: Boolean!
    $profilePicture: String!
    $mobilePhone: String!
    $workAddress: String!
    $workPhone: String!
    $password: String!
  ) {
    AddUser(
      email: $email
      firstName: $firstName
      lastName: $lastName
      active: $active
      profilePicture: $profilePicture
      mobilePhone: $mobilePhone
      workAddress: $workAddress
      workPhone: $workPhone
      password: $password
    ) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type CreateUserMutationFn = Apollo.MutationFunction<
  CreateUserMutation,
  CreateUserMutationVariables
>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      active: // value for 'active'
 *      profilePicture: // value for 'profilePicture'
 *      mobilePhone: // value for 'mobilePhone'
 *      workAddress: // value for 'workAddress'
 *      workPhone: // value for 'workPhone'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useCreateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    CreateUserMutation,
    CreateUserMutationVariables
  >
) {
  return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(
    CreateUserDocument,
    baseOptions
  );
}
export type CreateUserMutationHookResult = ReturnType<
  typeof useCreateUserMutation
>;
export type CreateUserMutationResult =
  Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<
  CreateUserMutation,
  CreateUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation DeleteUser($userID: ID!) {
    DeleteUser(id: $userID)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >
) {
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    baseOptions
  );
}
export type DeleteUserMutationHookResult = ReturnType<
  typeof useDeleteUserMutation
>;
export type DeleteUserMutationResult =
  Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const AddUserGroupDocument = gql`
  mutation AddUserGroup($userID: ID!, $groupID: [ID]!) {
    AddUserGroup(userID: $userID, groupID: $groupID) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type AddUserGroupMutationFn = Apollo.MutationFunction<
  AddUserGroupMutation,
  AddUserGroupMutationVariables
>;

/**
 * __useAddUserGroupMutation__
 *
 * To run a mutation, you first call `useAddUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserGroupMutation, { data, loading, error }] = useAddUserGroupMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      groupID: // value for 'groupID'
 *   },
 * });
 */
export function useAddUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserGroupMutation,
    AddUserGroupMutationVariables
  >
) {
  return Apollo.useMutation<
    AddUserGroupMutation,
    AddUserGroupMutationVariables
  >(AddUserGroupDocument, baseOptions);
}
export type AddUserGroupMutationHookResult = ReturnType<
  typeof useAddUserGroupMutation
>;
export type AddUserGroupMutationResult =
  Apollo.MutationResult<AddUserGroupMutation>;
export type AddUserGroupMutationOptions = Apollo.BaseMutationOptions<
  AddUserGroupMutation,
  AddUserGroupMutationVariables
>;
export const UpdateUserGroupDocument = gql`
  mutation UpdateUserGroup(
    $userID: ID!
    $groupID: [ID!]!
    $groupIDNew: [ID!]!
  ) {
    DeleteUserGroup(userID: $userID, groupID: $groupID) {
      ...UserFragment
    }
    AddUserGroup(userID: $userID, groupID: $groupIDNew) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type UpdateUserGroupMutationFn = Apollo.MutationFunction<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;

/**
 * __useUpdateUserGroupMutation__
 *
 * To run a mutation, you first call `useUpdateUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserGroupMutation, { data, loading, error }] = useUpdateUserGroupMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      groupID: // value for 'groupID'
 *      groupIDNew: // value for 'groupIDNew'
 *   },
 * });
 */
export function useUpdateUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdateUserGroupMutation,
    UpdateUserGroupMutationVariables
  >(UpdateUserGroupDocument, baseOptions);
}
export type UpdateUserGroupMutationHookResult = ReturnType<
  typeof useUpdateUserGroupMutation
>;
export type UpdateUserGroupMutationResult =
  Apollo.MutationResult<UpdateUserGroupMutation>;
export type UpdateUserGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserGroupMutation,
  UpdateUserGroupMutationVariables
>;
export const DeleteUserGroupDocument = gql`
  mutation DeleteUserGroup($userID: ID!, $groupID: [ID!]!) {
    DeleteUserGroup(userID: $userID, groupID: $groupID) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;
export type DeleteUserGroupMutationFn = Apollo.MutationFunction<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>;

/**
 * __useDeleteUserGroupMutation__
 *
 * To run a mutation, you first call `useDeleteUserGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserGroupMutation, { data, loading, error }] = useDeleteUserGroupMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      groupID: // value for 'groupID'
 *   },
 * });
 */
export function useDeleteUserGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteUserGroupMutation,
    DeleteUserGroupMutationVariables
  >(DeleteUserGroupDocument, baseOptions);
}
export type DeleteUserGroupMutationHookResult = ReturnType<
  typeof useDeleteUserGroupMutation
>;
export type DeleteUserGroupMutationResult =
  Apollo.MutationResult<DeleteUserGroupMutation>;
export type DeleteUserGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables
>;
export const GetGroupDocument = gql`
  query getGroup {
    GetGroup {
      ...GroupFragment
    }
  }
  ${GroupFragmentFragmentDoc}
`;

/**
 * __useGetGroupQuery__
 *
 * To run a query within a React component, call `useGetGroupQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGroupQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGroupQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGroupQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGroupQuery, GetGroupQueryVariables>
) {
  return Apollo.useQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    baseOptions
  );
}
export function useGetGroupLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetGroupQuery,
    GetGroupQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetGroupQuery, GetGroupQueryVariables>(
    GetGroupDocument,
    baseOptions
  );
}
export type GetGroupQueryHookResult = ReturnType<typeof useGetGroupQuery>;
export type GetGroupLazyQueryHookResult = ReturnType<
  typeof useGetGroupLazyQuery
>;
export type GetGroupQueryResult = Apollo.QueryResult<
  GetGroupQuery,
  GetGroupQueryVariables
>;
export const GetAllUsersDocument = gql`
  query GetAllUsers {
    GetUser {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
`;

/**
 * __useGetAllUsersQuery__
 *
 * To run a query within a React component, call `useGetAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllUsersQuery,
    GetAllUsersQueryVariables
  >
) {
  return Apollo.useQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    baseOptions
  );
}
export function useGetAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllUsersQuery,
    GetAllUsersQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetAllUsersQuery, GetAllUsersQueryVariables>(
    GetAllUsersDocument,
    baseOptions
  );
}
export type GetAllUsersQueryHookResult = ReturnType<typeof useGetAllUsersQuery>;
export type GetAllUsersLazyQueryHookResult = ReturnType<
  typeof useGetAllUsersLazyQuery
>;
export type GetAllUsersQueryResult = Apollo.QueryResult<
  GetAllUsersQuery,
  GetAllUsersQueryVariables
>;
export const AddRoleDocument = gql`
  mutation AddRole(
    $name: String!
    $description: String!
    $visibleLevel: Int!
    $permissionInputs: [PermissionInput]
  ) {
    AddRole(
      name: $name
      description: $description
      visibleLevel: $visibleLevel
      permissionInputs: $permissionInputs
    ) {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
`;
export type AddRoleMutationFn = Apollo.MutationFunction<
  AddRoleMutation,
  AddRoleMutationVariables
>;

/**
 * __useAddRoleMutation__
 *
 * To run a mutation, you first call `useAddRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoleMutation, { data, loading, error }] = useAddRoleMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      visibleLevel: // value for 'visibleLevel'
 *      permissionInputs: // value for 'permissionInputs'
 *   },
 * });
 */
export function useAddRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRoleMutation,
    AddRoleMutationVariables
  >
) {
  return Apollo.useMutation<AddRoleMutation, AddRoleMutationVariables>(
    AddRoleDocument,
    baseOptions
  );
}
export type AddRoleMutationHookResult = ReturnType<typeof useAddRoleMutation>;
export type AddRoleMutationResult = Apollo.MutationResult<AddRoleMutation>;
export type AddRoleMutationOptions = Apollo.BaseMutationOptions<
  AddRoleMutation,
  AddRoleMutationVariables
>;
export const GetRoleDocument = gql`
  query GetRole {
    GetRole {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
`;

/**
 * __useGetRoleQuery__
 *
 * To run a query within a React component, call `useGetRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRoleQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRoleQuery, GetRoleQueryVariables>
) {
  return Apollo.useQuery<GetRoleQuery, GetRoleQueryVariables>(
    GetRoleDocument,
    baseOptions
  );
}
export function useGetRoleLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoleQuery, GetRoleQueryVariables>
) {
  return Apollo.useLazyQuery<GetRoleQuery, GetRoleQueryVariables>(
    GetRoleDocument,
    baseOptions
  );
}
export type GetRoleQueryHookResult = ReturnType<typeof useGetRoleQuery>;
export type GetRoleLazyQueryHookResult = ReturnType<typeof useGetRoleLazyQuery>;
export type GetRoleQueryResult = Apollo.QueryResult<
  GetRoleQuery,
  GetRoleQueryVariables
>;
export const GetRoleWithPermissionsDocument = gql`
  query GetRoleWithPermissions($roleId: ID!) {
    GetRole(id: $roleId) {
      ...RoleFragment
      permissions {
        ...PermissionFragment
      }
    }
  }
  ${RoleFragmentFragmentDoc}
  ${PermissionFragmentFragmentDoc}
`;

/**
 * __useGetRoleWithPermissionsQuery__
 *
 * To run a query within a React component, call `useGetRoleWithPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoleWithPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoleWithPermissionsQuery({
 *   variables: {
 *      roleId: // value for 'roleId'
 *   },
 * });
 */
export function useGetRoleWithPermissionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetRoleWithPermissionsQuery,
    GetRoleWithPermissionsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetRoleWithPermissionsQuery,
    GetRoleWithPermissionsQueryVariables
  >(GetRoleWithPermissionsDocument, baseOptions);
}
export function useGetRoleWithPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRoleWithPermissionsQuery,
    GetRoleWithPermissionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetRoleWithPermissionsQuery,
    GetRoleWithPermissionsQueryVariables
  >(GetRoleWithPermissionsDocument, baseOptions);
}
export type GetRoleWithPermissionsQueryHookResult = ReturnType<
  typeof useGetRoleWithPermissionsQuery
>;
export type GetRoleWithPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetRoleWithPermissionsLazyQuery
>;
export type GetRoleWithPermissionsQueryResult = Apollo.QueryResult<
  GetRoleWithPermissionsQuery,
  GetRoleWithPermissionsQueryVariables
>;
export const UpdateRoleDocument = gql`
  mutation UpdateRole(
    $id: ID!
    $name: String!
    $description: String!
    $visibleLevel: Int!
    $permissionInputs: [PermissionInput]
  ) {
    UpdateRole(
      id: $id
      name: $name
      description: $description
      visibleLevel: $visibleLevel
      permissionInputs: $permissionInputs
    ) {
      ...RoleFragment
      permissions {
        ...PermissionFragment
      }
    }
  }
  ${RoleFragmentFragmentDoc}
  ${PermissionFragmentFragmentDoc}
`;
export type UpdateRoleMutationFn = Apollo.MutationFunction<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      visibleLevel: // value for 'visibleLevel'
 *      permissionInputs: // value for 'permissionInputs'
 *   },
 * });
 */
export function useUpdateRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateRoleMutation,
    UpdateRoleMutationVariables
  >
) {
  return Apollo.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(
    UpdateRoleDocument,
    baseOptions
  );
}
export type UpdateRoleMutationHookResult = ReturnType<
  typeof useUpdateRoleMutation
>;
export type UpdateRoleMutationResult =
  Apollo.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoleMutation,
  UpdateRoleMutationVariables
>;
export const UpdatePermissionDocument = gql`
  mutation UpdatePermission(
    $roleId: ID!
    $permissionInputs: [PermissionInput]
  ) {
    UpdateRole(id: $roleId, permissionInputs: $permissionInputs) {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
`;
export type UpdatePermissionMutationFn = Apollo.MutationFunction<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>;

/**
 * __useUpdatePermissionMutation__
 *
 * To run a mutation, you first call `useUpdatePermissionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePermissionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePermissionMutation, { data, loading, error }] = useUpdatePermissionMutation({
 *   variables: {
 *      roleId: // value for 'roleId'
 *      permissionInputs: // value for 'permissionInputs'
 *   },
 * });
 */
export function useUpdatePermissionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdatePermissionMutation,
    UpdatePermissionMutationVariables
  >
) {
  return Apollo.useMutation<
    UpdatePermissionMutation,
    UpdatePermissionMutationVariables
  >(UpdatePermissionDocument, baseOptions);
}
export type UpdatePermissionMutationHookResult = ReturnType<
  typeof useUpdatePermissionMutation
>;
export type UpdatePermissionMutationResult =
  Apollo.MutationResult<UpdatePermissionMutation>;
export type UpdatePermissionMutationOptions = Apollo.BaseMutationOptions<
  UpdatePermissionMutation,
  UpdatePermissionMutationVariables
>;
export const DeleteRoleDocument = gql`
  mutation DeleteRole($id: ID!) {
    DeleteRole(id: $id)
  }
`;
export type DeleteRoleMutationFn = Apollo.MutationFunction<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteRoleMutation,
    DeleteRoleMutationVariables
  >
) {
  return Apollo.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(
    DeleteRoleDocument,
    baseOptions
  );
}
export type DeleteRoleMutationHookResult = ReturnType<
  typeof useDeleteRoleMutation
>;
export type DeleteRoleMutationResult =
  Apollo.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoleMutation,
  DeleteRoleMutationVariables
>;
export const AddGroupDocument = gql`
  mutation AddGroup($name: String!, $description: String!) {
    AddGroup(name: $name, description: $description) {
      ...GroupFragment
    }
  }
  ${GroupFragmentFragmentDoc}
`;
export type AddGroupMutationFn = Apollo.MutationFunction<
  AddGroupMutation,
  AddGroupMutationVariables
>;

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useAddGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupMutation,
    AddGroupMutationVariables
  >
) {
  return Apollo.useMutation<AddGroupMutation, AddGroupMutationVariables>(
    AddGroupDocument,
    baseOptions
  );
}
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = Apollo.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = Apollo.BaseMutationOptions<
  AddGroupMutation,
  AddGroupMutationVariables
>;
export const UpdateGroupDocument = gql`
  mutation UpdateGroup($id: ID!, $name: String, $description: String) {
    UpdateGroup(id: $id, name: $name, description: $description) {
      ...GroupFragment
    }
  }
  ${GroupFragmentFragmentDoc}
`;
export type UpdateGroupMutationFn = Apollo.MutationFunction<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;

/**
 * __useUpdateGroupMutation__
 *
 * To run a mutation, you first call `useUpdateGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGroupMutation, { data, loading, error }] = useUpdateGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateGroupMutation,
    UpdateGroupMutationVariables
  >
) {
  return Apollo.useMutation<UpdateGroupMutation, UpdateGroupMutationVariables>(
    UpdateGroupDocument,
    baseOptions
  );
}
export type UpdateGroupMutationHookResult = ReturnType<
  typeof useUpdateGroupMutation
>;
export type UpdateGroupMutationResult =
  Apollo.MutationResult<UpdateGroupMutation>;
export type UpdateGroupMutationOptions = Apollo.BaseMutationOptions<
  UpdateGroupMutation,
  UpdateGroupMutationVariables
>;
export const DeleteGroupDocument = gql`
  mutation DeleteGroup($id: ID!) {
    DeleteGroup(id: $id)
  }
`;
export type DeleteGroupMutationFn = Apollo.MutationFunction<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;

/**
 * __useDeleteGroupMutation__
 *
 * To run a mutation, you first call `useDeleteGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupMutation, { data, loading, error }] = useDeleteGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupMutation,
    DeleteGroupMutationVariables
  >
) {
  return Apollo.useMutation<DeleteGroupMutation, DeleteGroupMutationVariables>(
    DeleteGroupDocument,
    baseOptions
  );
}
export type DeleteGroupMutationHookResult = ReturnType<
  typeof useDeleteGroupMutation
>;
export type DeleteGroupMutationResult =
  Apollo.MutationResult<DeleteGroupMutation>;
export type DeleteGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupMutation,
  DeleteGroupMutationVariables
>;
export const AddGroupRoleDocument = gql`
  mutation AddGroupRole($groupID: ID!, $roleID: [ID]!) {
    AddGroupRole(groupID: $groupID, roleID: $roleID) {
      ...GroupFragment
    }
  }
  ${GroupFragmentFragmentDoc}
`;
export type AddGroupRoleMutationFn = Apollo.MutationFunction<
  AddGroupRoleMutation,
  AddGroupRoleMutationVariables
>;

/**
 * __useAddGroupRoleMutation__
 *
 * To run a mutation, you first call `useAddGroupRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupRoleMutation, { data, loading, error }] = useAddGroupRoleMutation({
 *   variables: {
 *      groupID: // value for 'groupID'
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useAddGroupRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddGroupRoleMutation,
    AddGroupRoleMutationVariables
  >
) {
  return Apollo.useMutation<
    AddGroupRoleMutation,
    AddGroupRoleMutationVariables
  >(AddGroupRoleDocument, baseOptions);
}
export type AddGroupRoleMutationHookResult = ReturnType<
  typeof useAddGroupRoleMutation
>;
export type AddGroupRoleMutationResult =
  Apollo.MutationResult<AddGroupRoleMutation>;
export type AddGroupRoleMutationOptions = Apollo.BaseMutationOptions<
  AddGroupRoleMutation,
  AddGroupRoleMutationVariables
>;
export const DeleteGroupRoleDocument = gql`
  mutation DeleteGroupRole($groupID: ID!, $roleID: [ID!]!) {
    DeleteGroupRole(groupID: $groupID, roleID: $roleID) {
      ...GroupFragment
    }
  }
  ${GroupFragmentFragmentDoc}
`;
export type DeleteGroupRoleMutationFn = Apollo.MutationFunction<
  DeleteGroupRoleMutation,
  DeleteGroupRoleMutationVariables
>;

/**
 * __useDeleteGroupRoleMutation__
 *
 * To run a mutation, you first call `useDeleteGroupRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGroupRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGroupRoleMutation, { data, loading, error }] = useDeleteGroupRoleMutation({
 *   variables: {
 *      groupID: // value for 'groupID'
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useDeleteGroupRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteGroupRoleMutation,
    DeleteGroupRoleMutationVariables
  >
) {
  return Apollo.useMutation<
    DeleteGroupRoleMutation,
    DeleteGroupRoleMutationVariables
  >(DeleteGroupRoleDocument, baseOptions);
}
export type DeleteGroupRoleMutationHookResult = ReturnType<
  typeof useDeleteGroupRoleMutation
>;
export type DeleteGroupRoleMutationResult =
  Apollo.MutationResult<DeleteGroupRoleMutation>;
export type DeleteGroupRoleMutationOptions = Apollo.BaseMutationOptions<
  DeleteGroupRoleMutation,
  DeleteGroupRoleMutationVariables
>;
export const GetCurrentUserPermissionsDocument = gql`
  query GetCurrentUserPermissions($type: PermissionType) {
    GetCurrentUserPermissions(type: $type) {
      ...PermissionFragment
    }
  }
  ${PermissionFragmentFragmentDoc}
`;

/**
 * __useGetCurrentUserPermissionsQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserPermissionsQuery({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetCurrentUserPermissionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCurrentUserPermissionsQuery,
    GetCurrentUserPermissionsQueryVariables
  >
) {
  return Apollo.useQuery<
    GetCurrentUserPermissionsQuery,
    GetCurrentUserPermissionsQueryVariables
  >(GetCurrentUserPermissionsDocument, baseOptions);
}
export function useGetCurrentUserPermissionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentUserPermissionsQuery,
    GetCurrentUserPermissionsQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetCurrentUserPermissionsQuery,
    GetCurrentUserPermissionsQueryVariables
  >(GetCurrentUserPermissionsDocument, baseOptions);
}
export type GetCurrentUserPermissionsQueryHookResult = ReturnType<
  typeof useGetCurrentUserPermissionsQuery
>;
export type GetCurrentUserPermissionsLazyQueryHookResult = ReturnType<
  typeof useGetCurrentUserPermissionsLazyQuery
>;
export type GetCurrentUserPermissionsQueryResult = Apollo.QueryResult<
  GetCurrentUserPermissionsQuery,
  GetCurrentUserPermissionsQueryVariables
>;
export const RunJobDocument = gql`
  mutation RunJob($job: Job!) {
    RunJob(job: $job)
  }
`;
export type RunJobMutationFn = Apollo.MutationFunction<
  RunJobMutation,
  RunJobMutationVariables
>;

/**
 * __useRunJobMutation__
 *
 * To run a mutation, you first call `useRunJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runJobMutation, { data, loading, error }] = useRunJobMutation({
 *   variables: {
 *      job: // value for 'job'
 *   },
 * });
 */
export function useRunJobMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RunJobMutation,
    RunJobMutationVariables
  >
) {
  return Apollo.useMutation<RunJobMutation, RunJobMutationVariables>(
    RunJobDocument,
    baseOptions
  );
}
export type RunJobMutationHookResult = ReturnType<typeof useRunJobMutation>;
export type RunJobMutationResult = Apollo.MutationResult<RunJobMutation>;
export type RunJobMutationOptions = Apollo.BaseMutationOptions<
  RunJobMutation,
  RunJobMutationVariables
>;
export const GetJobStatusDocument = gql`
  query GetJobStatus($job: Job!) {
    GetJobStatus(job: $job) {
      status
      message
    }
  }
`;

/**
 * __useGetJobStatusQuery__
 *
 * To run a query within a React component, call `useGetJobStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobStatusQuery({
 *   variables: {
 *      job: // value for 'job'
 *   },
 * });
 */
export function useGetJobStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetJobStatusQuery,
    GetJobStatusQueryVariables
  >
) {
  return Apollo.useQuery<GetJobStatusQuery, GetJobStatusQueryVariables>(
    GetJobStatusDocument,
    baseOptions
  );
}
export function useGetJobStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetJobStatusQuery,
    GetJobStatusQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetJobStatusQuery, GetJobStatusQueryVariables>(
    GetJobStatusDocument,
    baseOptions
  );
}
export type GetJobStatusQueryHookResult = ReturnType<
  typeof useGetJobStatusQuery
>;
export type GetJobStatusLazyQueryHookResult = ReturnType<
  typeof useGetJobStatusLazyQuery
>;
export type GetJobStatusQueryResult = Apollo.QueryResult<
  GetJobStatusQuery,
  GetJobStatusQueryVariables
>;
export const GetReportDocument = gql`
  query GetReport($rnid: String!) {
    GetReport(rID: $rnid) {
      data
    }
  }
`;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      rnid: // value for 'rnid'
 *   },
 * });
 */
export function useGetReportQuery(
  baseOptions: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>
) {
  return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(
    GetReportDocument,
    baseOptions
  );
}
export function useGetReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetReportQuery,
    GetReportQueryVariables
  >
) {
  return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(
    GetReportDocument,
    baseOptions
  );
}
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<
  typeof useGetReportLazyQuery
>;
export type GetReportQueryResult = Apollo.QueryResult<
  GetReportQuery,
  GetReportQueryVariables
>;
export const SetVisibilityFilterDocument = gql`
  mutation SetVisibilityFilter(
    $filterName: EntityFilterName!
    $filterInputs: [EntityFilterInput!]!
  ) {
    SetEntityFilter(filterName: $filterName, filterInputs: $filterInputs) {
      ...EntityType
    }
  }
  ${EntityTypeFragmentDoc}
`;
export type SetVisibilityFilterMutationFn = Apollo.MutationFunction<
  SetVisibilityFilterMutation,
  SetVisibilityFilterMutationVariables
>;

/**
 * __useSetVisibilityFilterMutation__
 *
 * To run a mutation, you first call `useSetVisibilityFilterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetVisibilityFilterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setVisibilityFilterMutation, { data, loading, error }] = useSetVisibilityFilterMutation({
 *   variables: {
 *      filterName: // value for 'filterName'
 *      filterInputs: // value for 'filterInputs'
 *   },
 * });
 */
export function useSetVisibilityFilterMutation(
  baseOptions?: Apollo.MutationHookOptions<
    SetVisibilityFilterMutation,
    SetVisibilityFilterMutationVariables
  >
) {
  return Apollo.useMutation<
    SetVisibilityFilterMutation,
    SetVisibilityFilterMutationVariables
  >(SetVisibilityFilterDocument, baseOptions);
}
export type SetVisibilityFilterMutationHookResult = ReturnType<
  typeof useSetVisibilityFilterMutation
>;
export type SetVisibilityFilterMutationResult =
  Apollo.MutationResult<SetVisibilityFilterMutation>;
export type SetVisibilityFilterMutationOptions = Apollo.BaseMutationOptions<
  SetVisibilityFilterMutation,
  SetVisibilityFilterMutationVariables
>;
export const GetEntityFilterDocument = gql`
  query GetEntityFilter(
    $filterName: EntityFilterName!
    $entityInputs: [EntityInput!]!
  ) {
    GetEntityFilter(filterName: $filterName, entityInputs: $entityInputs) {
      ...EntityType
    }
  }
  ${EntityTypeFragmentDoc}
`;

/**
 * __useGetEntityFilterQuery__
 *
 * To run a query within a React component, call `useGetEntityFilterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityFilterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityFilterQuery({
 *   variables: {
 *      filterName: // value for 'filterName'
 *      entityInputs: // value for 'entityInputs'
 *   },
 * });
 */
export function useGetEntityFilterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetEntityFilterQuery,
    GetEntityFilterQueryVariables
  >
) {
  return Apollo.useQuery<GetEntityFilterQuery, GetEntityFilterQueryVariables>(
    GetEntityFilterDocument,
    baseOptions
  );
}
export function useGetEntityFilterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEntityFilterQuery,
    GetEntityFilterQueryVariables
  >
) {
  return Apollo.useLazyQuery<
    GetEntityFilterQuery,
    GetEntityFilterQueryVariables
  >(GetEntityFilterDocument, baseOptions);
}
export type GetEntityFilterQueryHookResult = ReturnType<
  typeof useGetEntityFilterQuery
>;
export type GetEntityFilterLazyQueryHookResult = ReturnType<
  typeof useGetEntityFilterLazyQuery
>;
export type GetEntityFilterQueryResult = Apollo.QueryResult<
  GetEntityFilterQuery,
  GetEntityFilterQueryVariables
>;
