/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Rpt_NonIndividualBalanceSheetFragment } from "graphql/hasura/types-and-hooks";
import { StructureInterface } from "../balance-sheet-list/balance-sheet-list.component";

// eslint-disable-next-line import/prefer-default-export
export const getStructure = (
  data: Rpt_NonIndividualBalanceSheetFragment
): StructureInterface[] => {
  return [
    {
      label: "",
      isBold: false,
      level: 0,
      value: data.RtnPeriodYear?.replace("-", "/"),
      value_highlighted: false,
      format: "string",
    },
    {
      label: "Return ID",
      isBold: true,
      level: 0,
      value: data.RtnId,
      value_highlighted: false,
      format: "string",
    },
    {
      label: "Return number",
      isBold: true,
      level: 0,
      value: data.RtnNo,
      value_highlighted: false,
      format: "string",
    },
    {
      label: "APPLICATION OF FUNDS",
      isBold: true,
      level: 0,
      value_highlighted: true,
      format: "string",
      isMega: true,
      emptyRowAbove: true,
    },
    {
      label: "1. Fixed Assets - Non Current Assets",
      isBold: true,
      level: 0,
      value: "",
      value_highlighted: false,
      format: "string",
    },
    {
      label: "a. Land and Building",
      isBold: false,
      level: 1,
      value: data.LandBuild,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "b. Plant and Machinery",
      isBold: false,
      level: 1,
      value: data.PlantMachine,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "c. Motor Vehicles",
      isBold: false,
      level: 1,
      value: data.MotorVehicles,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "d. Furniture and Fixtures",
      isBold: false,
      level: 1,
      value: data.FurnitureFixture,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "e. Other Assets",
      isBold: false,
      level: 1,
      value: data.OthrDeprecAsset,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "f. Intangible Assets",
      isBold: false,
      level: 1,
      value: data.IntangibleAssets,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "g. Total Fixed Assets",
      isBold: false,
      level: 1,
      value: data.TotFixedAsset,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "h. Accumulated Depreciation / Amortization",
      isBold: false,
      level: 1,
      value: data.Depreciation,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "i. Net Book Value of Fixed Assets",
      isBold: false,
      level: 1,
      value: data.NetFixedAsset,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "2. Investments",
      isBold: true,
      level: 0,
      value_highlighted: false,
      format: "currency",
      emptyRowAbove: true,
    },
    {
      label: "a. Shares",
      isBold: false,
      level: 1,
      value: data.EquityShare,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "b. Debentures",
      isBold: false,
      level: 1,
      value: data.Debenture,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "c. Fixed Deposits",
      isBold: false,
      level: 1,
      value: data.FixedDeposit,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "d. Government Securities",
      isBold: false,
      level: 1,
      value: data.GovtSecurities,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "e. Other Investments",
      isBold: false,
      level: 1,
      value: data.OtherInvestments,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "f. Total Investments",
      isBold: false,
      level: 1,
      value: data.TotTradeInvestment,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "3. Current Assets, Loans and Advances",
      isBold: true,
      level: 0,
      value: "",
      value_highlighted: false,
      format: "string",
      emptyRowAbove: true,
    },
    {
      label: "a. Current Assets",
      isBold: false,
      level: 1,
      value: "",
      value_highlighted: false,
      format: "string",
    },
    {
      label: "i. Inventories",
      isBold: false,
      level: 2,
      value_highlighted: false,
      value: "",
      format: "string",
    },
    {
      label: "Stores / Consumable",
      isBold: false,
      level: 3,
      value_highlighted: false,
      value: data.CnsmPckInventory,
      format: "currency",
    },
    {
      label: "Raw Materials",
      isBold: false,
      level: 3,
      value: data.RawMtrlInventory,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Work-in-Progress",
      isBold: false,
      level: 3,
      value: data.StockInProcess,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Finished or Trade Goods",
      isBold: false,
      level: 3,
      value: data.FinishTradeGoods,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Total Inventory",
      isBold: false,
      level: 3,
      value: data.TotInventory,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "ii. Accounts Receivable/Debtors",
      isBold: false,
      level: 2,
      value_highlighted: false,
      format: "string",
    },
    {
      label: "Trade Recievables Debtors",
      isBold: false,
      level: 3,
      value: data.AccntRcvdOverSix,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Prepayments",
      isBold: false,
      level: 3,
      value: data.Prepayments,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Other Recievables",
      isBold: false,
      level: 3,
      value: data.AccntRcvdOthers,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Total Receivables / Debtors",
      isBold: false,
      level: 3,
      value: data.TotAccntRcvdDebtors,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "iii. Cash and Bank Balances",
      isBold: false,
      level: 2,
      value_highlighted: false,
      value: "",
      format: "string",
    },
    {
      label: "Bank Balance",
      isBold: false,
      level: 3,
      value: data.BankBalance,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Cash in Hand / Equivalents",
      isBold: false,
      level: 3,
      value: data.HandCash,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Total Balance Available",
      isBold: false,
      level: 3,
      value: data.TotBalanceAvail,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "iv. Other Current Assets",
      isBold: false,
      level: 2,
      value: data.OthrCurrentAsset,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "v. Total Current Assets",
      isBold: false,
      level: 2,
      value: data.TotCurrentAsset,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "b. Loans and Advances",
      isBold: false,
      level: 1,
      value_highlighted: false,
      value: "",
      format: "string",
    },
    {
      label: "i. Loans to related Parties",
      isBold: false,
      level: 2,
      value: data.LoanSubCompany,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "ii. Loans and Advances to staff",
      isBold: false,
      level: 2,
      value: data.AdvanceRecoverable,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "iii. Deposits (Other than Fixed Deposit)",
      isBold: false,
      level: 2,
      value: data.Deposits,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "iv. Loans and Advances to Others",
      isBold: false,
      level: 2,
      value: data.AdvanceCorporate,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "v. Balance with Uganda Revenue Authority",
      isBold: false,
      level: 2,
      value: data.BalRevenueAuth,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "vi. Total of Loans and Advance",
      isBold: false,
      level: 2,
      value: data.TotLoanAdvance,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "c. Total Current Assets, Loans and Advances",
      isBold: false,
      level: 1,
      value: data.TotAssetLoanAdvance,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "d. Current Liabilities and Provisions",
      isBold: false,
      level: 1,
      value: "",
      value_highlighted: false,
      format: "string",
    },
    {
      label: "i. Current Liabilities",
      isBold: false,
      level: 2,
      value: "",
      value_highlighted: false,
      format: "string",
    },
    {
      label: "Trade Payable / Creditors",
      isBold: false,
      level: 3,
      value: data.SundryCreditors,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Liability for Leased Assets",
      isBold: false,
      level: 3,
      value: data.LiableLeaseAsset,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Accrued Interest",
      isBold: false,
      level: 3,
      value: data.IntLeaseAsset,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Other Current Liabilities",
      isBold: false,
      level: 3,
      value: data.OthrLiability,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Unpaid Mature Debts",
      isBold: false,
      level: 3,
      value: data.UnpaidMatureDebts,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Bank Overdrafts",
      isBold: false,
      level: 3,
      value: data.BankOverDraft,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Total Current Liabilities",
      isBold: false,
      level: 3,
      value: data.TotCurrLiability,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "ii. Provisions",
      isBold: false,
      level: 2,
      value: "",
      value_highlighted: false,
      format: "string",
    },
    {
      label: "Provision for Income Tax",
      isBold: false,
      level: 3,
      value: data.IncomeTaxProv,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Provision for Bad Debts",
      isBold: false,
      level: 3,
      value: data.ProvForBadDebts,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Other Provisions",
      isBold: false,
      level: 3,
      value: data.OtherProvision,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "Total Provisions",
      isBold: false,
      level: 3,
      value: data.TotalProvision,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "iii. Total Current Liabilities and Provisions",
      isBold: false,
      level: 2,
      value: data.TotLiabilityProv,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "e. Net Current Assets",
      isBold: false,
      level: 1,
      value: data.NetCurrentAsset,
      value_highlighted: false,
      format: "currency",
    },
    {
      label: "4. Deferred Tax Assets",
      isBold: true,
      level: 0,
      value: data.DeferredAsset,
      value_highlighted: false,
      format: "currency",
      emptyRowAbove: true,
    },
    {
      label: "5. Total Assets",
      isBold: true,
      level: 0,
      value: data.ApplicationFund,
      value_highlighted: false,
      format: "currency",
      emptyRowAbove: true,
    },
    {
      label: "SOURCE OF FUNDS",
      isBold: true,
      level: 0,
      value: "",
      value_highlighted: true,
      format: "string",
      isMega: true,
      emptyRowAbove: true,
    },
    {
      label: "6. Proprietor's Capital and Reserves",
      isBold: true,
      level: 0,
      value: "",
      value_highlighted: true,
      format: "string",
    },
    {
      label: "a. Proprietor Capital (Opening)",
      isBold: false,
      level: 1,
      value: data.ProprietorCapital,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "b. Revaluation Reserve",
      isBold: false,
      level: 1,
      value: data.RevaluationReserve,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "c. Translation Reserve",
      isBold: false,
      level: 1,
      value: data.TranslationReserve,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "d. General Reserve",
      isBold: false,
      level: 1,
      value: data.GeneralReserve,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "e. Any other Reserve",
      isBold: false,
      level: 1,
      value: data.OtherReserve,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "f. Profit/(Loss) for the year",
      isBold: false,
      level: 1,
      value: data.ProfitLossAccount,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "g. Less Drawings",
      isBold: false,
      level: 1,
      value: data.LessDrawings,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "h. Total Proprietor's Capital and Reserves",
      isBold: false,
      level: 1,
      value: data.TotalPropFund,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "7. Long Term Liabilities",
      isBold: true,
      level: 0,
      value: "",
      value_highlighted: true,
      format: "string",
      emptyRowAbove: true,
    },
    {
      label: "a. Secured Liabilities",
      isBold: false,
      level: 1,
      value: "",
      value_highlighted: true,
      format: "string",
    },
    {
      label: "i. Loan from Finance Institutions",
      isBold: false,
      level: 2,
      value: data.SecureLoanBank,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "ii. Other Loans",
      isBold: false,
      level: 2,
      value: data.SecureLoanOther,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "iii. Debt securities issued",
      isBold: false,
      level: 2,
      value: data.SecureDebtIssued,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "iv. Due to related Parties",
      isBold: false,
      level: 2,
      value: data.SecureLoanParties,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "v. Total Secured Liabilities",
      isBold: false,
      level: 2,
      value: data.SecureTotLoan,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "b. Unsecured Liabilities",
      isBold: false,
      level: 1,
      value: "",
      value_highlighted: true,
      format: "string",
    },
    {
      label: "i. Loan from Financial Institute",
      isBold: false,
      level: 2,
      value: data.UnsecureLoanBank,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "ii. Other Loans",
      isBold: false,
      level: 2,
      value: data.UnsecureLoanOther,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "iii. Payables/Creditors for more than one year",
      isBold: false,
      level: 2,
      value: data.UnsecureCreditorLoan,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "iv. Due to related Parties",
      isBold: false,
      level: 2,
      value: data.UnsecureLoanParties,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "v. Total Unsecured Liabilities",
      isBold: false,
      level: 2,
      value: data.UnsecureTotLoan,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "c. Total Loan Funds",
      isBold: false,
      level: 1,
      value: data.TotalLoanFund,
      value_highlighted: true,
      format: "currency",
    },
    {
      label: "8. Deferred Tax Liability",
      isBold: true,
      level: 0,
      value: data.DeferTaxLiability,
      value_highlighted: true,
      format: "currency",
      emptyRowAbove: true,
    },
    {
      label: "9. Proprietors Fund and Lond Term Liabilities",
      isBold: true,
      level: 0,
      value: data.SourceFund,
      value_highlighted: true,
      format: "currency",
      emptyRowAbove: true,
    },
  ];
};
