/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { UraNonIndividualTaxReportFragment } from "graphql/hasura/types-and-hooks";

export type NonIndividualTaxTableSchema = Omit<
  UraNonIndividualTaxReportFragment,
  "__typename"
>;

export const NON_INDIVIDUAL_TAX_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof NonIndividualTaxTableSchema,
  "string" | "number" | "Date"
> = {
  TinNo: "string",
  RtnPeriodYear: "string",
  RtnFromDt: "string",
  RtnDt: "string",
  RtnNo: "string",
  TaxPayerName: "string",
  BsnsName: "string",
  OrgType: "string",
  IsAmendment: "string",
  BusinessIncome: "number",
  RentincIncome: "number",
  RentincTax: "number",
  RentincRate: "number",
  TotIncome: "number",
  TotTax: "number",
  GrossRentalIncome: "number",
  TotExpPurelyRental: "number",
  OperatingExp: "number",
  AdministratingExp: "number",
  FinanceExp: "number",
  ProfitLossRental: "number",
  NonAllowblExp: "number",
  AdjProfitLossRental: "number",
  AllowblApportionedCap: "number",
  OthrAllowblExp: "number",
  AdjRentlIncmLoss: "number",
  BrghtFwdAssessedLoss: "number",
  AssessedChrgblRentlIncm: "number",
  AssessedRentlLoss: "number",
  BadDebtWriteOff: "number",
  BalanceCarryForward: "string",
  BalancePrevPeriod: "string",
  BusinessRate: "number",
  CostOfSales: "number",
  BusinessTax: "number",
  Debenture: "number",
  DeferTaxLiability: "number",
  Depreciation: "number",
  EquityShare: "number",
  FactoryRentRates: "number",
  FixedDeposit: "number",
  GovtSecurities: "number",
  GrossProfit: "number",
  IsResident: "string",
  LandBuild: "string",
  OtherInvestments: "number",
  PayableAmtInc: "number",
  PayableAmtRent: "number",
  ProfitAfterDividend: "number",
  ProfitAfterTax: "number",
  ProfitBeforeTax: "number",
  ProposedDividend: "number",
  ProvisionIncomeTax: "number",
  RefundAmtInc: "number",
  RefundAmtRent: "number",
  RentRates: "number",
  Rnid: "string",
  SecureTotLoan: "string",
  SelfAssessTaxPaidInc: "number",
  SelfAssessTaxPaidRent: "number",
  SourceFund: "string",
  TotalPropFund: "string",
  TotalReserve: "string",
  TotalShareCapital: "number",
  TotAdminExpense: "number",
  TotBsnsProfSales: "number",
  TotFinancialExpense: "number",
  TotFixedAsset: "string",
  TotNonChargeableIncome: "number",
  TotOperationExpense: "number",
  TotOtherExpense: "number",
  TotRentAdminiExpns: "number",
  TotRentFinancExpns: "number",
  TotRentIncmExpns: "number",
  TotRentOprtgExpns: "number",
  TotTaxesPaidInc: "number",
  TotTaxLiability: "number",
  TotTaxesPaidRent: "number",
  TotTaxLiabilityInc: "number",
  TotTaxLiabilityRent: "number",
  TotTradeInvestment: "string",
  TotWthdTaxDeduct: "number",
  TotRate: "number",
  UnsecureTotLoan: "string",
  TradingName: "string",
  OrgSubType: "string",
  IsIncomeSrcRental: "string",
  IsIncomeSrcPrpty: "string",
  MainActivity: "string",
  ReturnType: "string",
  // RENTINC_P: "number",
  // RENTAL_TAX_P: "number",
  // INCOME_TAX_P: "number",
};

export const getAccessorType = (
  accessor:
    | keyof NonIndividualTaxTableSchema
    | Accessor<NonIndividualTaxTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NON_INDIVIDUAL_TAX_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const nonIndividualTaxTableColumns: TableColumn<NonIndividualTaxTableSchema>[] =
  [
    // TODO: uncomment this lines into task RI-1465
    // {
    //   Header: "RNID",
    //   accessor: "Rnid",
    //   align: "center",
    //   Cell: ({
    //     value,
    //     row: {
    //       values: { Rnid },
    //     },
    //   }) => {
    //     return (
    //       <Link to={`/non-individuals/${Rnid}/identification`} color="inherit">
    //         {value}
    //       </Link>
    //     );
    //   },
    //   sort: true,
    // },
    {
      Header: "TIN No.",
      accessor: "TinNo",
      align: "center",
      Cell: ({
        value,
        row: {
          values: { TinNo },
        },
      }) => {
        return (
          <Link to={`/non-individuals/${TinNo}/identification`} color="inherit">
            {value}
          </Link>
        );
      },
    },
    {
      Header: "Year of Income",
      accessor: "RtnPeriodYear",
      align: "center",
      sort: true,
    },
    {
      Header: "Return From Date",
      accessor: "RtnFromDt",
      align: "center",
      format: "date",
    },
    {
      Header: "Return Date",
      accessor: "RtnDt",
      align: "center",
      format: "date",
      sort: true,
    },
    {
      Header: "Return No.",
      accessor: "RtnNo",
      align: "center",
      Cell: ({
        value,
        row: {
          values: { TinNo, RtnNo },
        },
      }) => {
        return (
          <Link
            to={`/non-individuals/${TinNo}/tax-history/${RtnNo}`}
            color="inherit"
          >
            {value}
          </Link>
        );
      },
    },
    {
      Header: "Entity's Legal Name",
      accessor: "TaxPayerName",
      align: "left",
      format: "string",
    },
    {
      Header: "Business Name",
      accessor: "BsnsName",
      align: "left",
      format: "string",
    },
    {
      Header: "Entity Type",
      accessor: "OrgType",
      align: "center",
      format: "string",
    },
    {
      Header: "Amendment?",
      accessor: "IsAmendment",
      align: "center",
      format: "string",
    },
    {
      Header: "ReturnType",
      accessor: "ReturnType",
    },
    {
      Header: "Business and Profession Income",
      accessor: "BusinessIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Assessed Chargeable Rental Income",
      accessor: "RentincIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Assessed Chargeable Rental Income Tax",
      accessor: "RentincTax",
      align: "right",
      format: "currency",
    },
    {
      Header: "Assessed Chargeable Rental Income Rate",
      accessor: "RentincRate",
      align: "right",
      format: "currency",
    },
    {
      Header: "Total Income",
      accessor: "TotIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Total Tax",
      accessor: "TotTax",
      align: "right",
      format: "currency",
    },
    {
      Header: "Gross Rental Income (SecF Line 4(d))",
      accessor: "GrossRentalIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "2d. Total Expenses purely related to Rental Income",
      accessor: "TotExpPurelyRental",
      align: "right",
      format: "currency",
    },
    {
      Header: "2a. Operating Expenses",
      accessor: "OperatingExp",
      align: "right",
      format: "currency",
    },
    {
      Header: "2b. Administrative Expenses",
      accessor: "AdministratingExp",
      align: "right",
      format: "currency",
    },
    {
      Header: "2c. Financing Expenses",
      accessor: "FinanceExp",
      align: "right",
      format: "currency",
    },
    {
      Header: "3. Profit/Loss on Account of Rental Income for Year",
      accessor: "ProfitLossRental",
      align: "right",
      format: "currency",
    },
    {
      Header: "4. Add: Allowable Expenses/Deductions",
      accessor: "NonAllowblExp",
      align: "right",
      format: "currency",
    },
    {
      Header: "5. Adjusted Profit or Loss on Rents",
      accessor: "AdjProfitLossRental",
      align: "right",
      format: "currency",
    },
    {
      Header: "6. Allowable Apportioned Capital Allowance",
      accessor: "AllowblApportionedCap",
      align: "right",
      format: "currency",
    },
    {
      Header: "7. Less: Other Allowable Expenses/Deductions",
      accessor: "OthrAllowblExp",
      align: "right",
      format: "currency",
    },
    {
      Header: "8. Adjusted Rental Income or Loss for the Year",
      accessor: "AdjRentlIncmLoss",
      align: "right",
      format: "currency",
    },
    {
      Header: "9. Brought forward Assessed Loss from Previous Year",
      accessor: "BrghtFwdAssessedLoss",
      align: "right",
      format: "currency",
    },
    {
      Header: "10. Assessed Chargeable Income for Year",
      accessor: "AssessedChrgblRentlIncm",
      align: "right",
      format: "currency",
    },
    {
      Header: "11. Assessed Rental Loss for the Year",
      accessor: "AssessedRentlLoss",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Operating Expenses - Bad Debts Written Off",
      accessor: "BadDebtWriteOff",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Balance carried to balance sheet",
      accessor: "BalanceCarryForward",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Balance of Profit/Loss brought from previous period",
      accessor: "BalancePrevPeriod",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Business and Profession Tax Rate",
      accessor: "BusinessRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Cost of Sales",
      accessor: "CostOfSales",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Business and Profession Principal Tax",
      accessor: "BusinessTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Debentures",
      accessor: "Debenture",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Deferred Tax Liability",
      accessor: "DeferTaxLiability",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Accumulated Depreciation/Amortization",
      accessor: "Depreciation",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Shares",
      accessor: "EquityShare",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Factory Rent",
      accessor: "FactoryRentRates",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Fixed Deposits",
      accessor: "FixedDeposit",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Government Securities",
      accessor: "GovtSecurities",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Grosss Profit",
      accessor: "GrossProfit",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Resident Status",
      accessor: "IsResident",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Land and Building",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Other Investmenets",
      accessor: "OtherInvestments",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Amount of Tax Payable on Busines Income",
      accessor: "PayableAmtInc",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Amount of Tax Payable on Rental Income",
      accessor: "PayableAmtRent",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Profit after Tax and Dividend",
      accessor: "ProfitAfterDividend",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Profit after Tax",
      accessor: "ProfitAfterTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Profit Before Tax",
      accessor: "ProfitBeforeTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Dividend",
      accessor: "ProposedDividend",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Income Tax Expense",
      accessor: "ProvisionIncomeTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Amount of Tax Refund on Business Income",
      accessor: "RefundAmtInc",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Amount of Tax Refund on Rental Income",
      accessor: "RefundAmtRent",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Operating Expenses - Rent",
      accessor: "RentRates",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Balance Sheet - Total Secured Liabilities",
      accessor: "SecureTotLoan",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Self Assessment on Business Income Tax",
      accessor: "SelfAssessTaxPaidInc",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Self Assessment on Rental Income Tax",
      accessor: "SelfAssessTaxPaidRent",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Equity and Long Term Liabilities",
      accessor: "SourceFund",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Shareholder's Fund",
      accessor: "TotalPropFund",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Reserve and Surplus",
      accessor: "TotalReserve",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Share Capital",
      accessor: "TotalShareCapital",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Administration Expenses",
      accessor: "TotAdminExpense",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Gross Income",
      accessor: "TotBsnsProfSales",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Financial Expenses",
      accessor: "TotFinancialExpense",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Fixed Assets",
      accessor: "TotFixedAsset",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Other Income",
      accessor: "TotNonChargeableIncome",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Operating Expenses",
      accessor: "TotOperationExpense",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Other Expenditure Expenses",
      accessor: "TotOtherExpense",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Rental Income Administrative Expenses Total",
      accessor: "TotRentAdminiExpns",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Rental Income Finance Expenses Total",
      accessor: "TotRentFinancExpns",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Expenses Related to Rental Income",
      accessor: "TotRentIncmExpns",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Operating Expenses Total",
      accessor: "TotRentOprtgExpns",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Taxes Paid for Business Income",
      accessor: "TotTaxesPaidInc",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Tax Paid",
      accessor: "TotTaxLiability",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Taxes Paid for Rental Income",
      accessor: "TotTaxesPaidRent",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Tax Liability Self-Assessed Income Tax",
      accessor: "TotTaxLiabilityInc",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Tax Liability Self-Assessed Rental Tax",
      accessor: "TotTaxLiabilityRent",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Investments",
      accessor: "TotTradeInvestment",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Gross Witholding Tax at Source from Income",
      accessor: "TotWthdTaxDeduct",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Tax Rate",
      accessor: "TotRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Balance Sheet - Total Unsecured Liabilities",
      accessor: "UnsecureTotLoan",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Main Trading/Business Name (if different)",
      accessor: "TradingName",
      align: "left",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Organization Sub Type",
      accessor: "OrgSubType",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Select Income Source - Rental",
      accessor: "IsIncomeSrcRental",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Select Income Source - Property",
      accessor: "IsIncomeSrcPrpty",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Select Income Source - Major Business Activity",
      accessor: "MainActivity",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    // {
    //   Header: "Rental Income %",
    //   accessor: "RENTINC_P",
    //   align: "center",
    //   hiddenColumn: true,
    //   format: "percent",
    // },
    // {
    //   Header: "Rental Tax %",
    //   accessor: "RENTAL_TAX_P",
    //   align: "center",
    //   hiddenColumn: true,
    //   format: "percent",
    // },
    // {
    //   Header: "Total Tax %",
    //   accessor: "INCOME_TAX_P",
    //   align: "center",
    //   hiddenColumn: true,
    //   format: "percent",
    // },
  ];
