/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import _ from "lodash";
import {
  AutocompleteChangeReason,
  AutocompleteProps as AutocompletePropsBase,
} from "@material-ui/lab/Autocomplete";
import {
  AutocompleteChangeDetails,
  AutocompleteInputChangeReason,
  Value,
} from "@material-ui/lab/useAutocomplete";

// Material-UI Components
import { TextFieldProps } from "@material-ui/core";

// Assets
import SC from "./autofill-field.styles";

export interface ServerSideSearchProps {
  onTriggerSearch?: (value: string) => void;
  minLengthSearch?: number | undefined;
  msDelaySearch?: number | undefined;
}

export interface AutofillFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
      AutocompletePropsBase<T, Multiple, DisableClearable, FreeSolo>,
      "renderInput" | "popupIcon"
    >,
    ServerSideSearchProps {
  serverSideSearchProps?: ServerSideSearchProps;
  textFieldProps?: TextFieldProps;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function AutofillField<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(props: AutofillFieldProps<T, Multiple, DisableClearable, FreeSolo>) {
  const { textFieldProps, serverSideSearchProps, ...muiProps } = props;
  const inputValue = muiProps?.inputValue ?? "";

  const minLengthSearch = serverSideSearchProps?.minLengthSearch ?? 0;
  const msDelaySearch = serverSideSearchProps?.msDelaySearch ?? 0;
  const searchFunction = serverSideSearchProps?.onTriggerSearch;
  // we can perform the search when the following conditions are true:
  // minLengthSearch: actual input length >= minLengthSearch
  // msDelaySearch: time in ms has been ocurred

  const [shouldExecuteSearch, setShouldExecuteSearch] = React.useState(true);

  React.useEffect(() => {
    const performSearch = inputValue.length >= minLengthSearch;
    if (shouldExecuteSearch && performSearch) {
      const timeOutIdFunc = _.debounce(() => {
        searchFunction?.(inputValue);
        setShouldExecuteSearch(false);
      }, msDelaySearch);
      timeOutIdFunc();
    }
    return undefined;
  }, [
    searchFunction,
    inputValue,
    minLengthSearch,
    msDelaySearch,
    shouldExecuteSearch,
  ]);

  const { onChange, onInputChange, ...remainingProps } = muiProps;

  const handlerOnChange = React.useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      event: React.ChangeEvent<{}>,
      value: Value<T, Multiple, DisableClearable, FreeSolo>,
      reason: AutocompleteChangeReason,
      details: AutocompleteChangeDetails<T> | undefined
    ) => {
      switch (reason) {
        case "select-option":
        case "blur":
          setShouldExecuteSearch(false);
          break;

        default:
          setShouldExecuteSearch(true);
          break;
      }

      onChange?.(event, value, reason, details);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onChange]
  );

  const handlerOnInputChange = React.useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/ban-types
      event: React.ChangeEvent<{}>,
      value: string,
      reason: AutocompleteInputChangeReason
    ) => {
      setShouldExecuteSearch(true);

      if (onInputChange) onInputChange(event, value, reason);
    },
    [onInputChange]
  );

  return (
    <SC.AutofillField
      {...remainingProps}
      onChange={handlerOnChange}
      onInputChange={handlerOnInputChange}
      popupIcon={<SC.SearchIcon />}
      renderInput={(params) => <SC.TextField {...textFieldProps} {...params} />}
    />
  );
}

export default AutofillField;
