/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import { useNonIndividualComplianceQuery } from "graphql/hasura/types-and-hooks";
// GraphQL
import { CLIENT_NAME } from "graphql/client";
import { columns } from "./compliance-reports.schema";

import SC from "./compliance-reports.styles";

const NonIndividualComplianceReports: React.FC = () => {
  const { data } = useNonIndividualComplianceQuery({
    context: { clientName: CLIENT_NAME.HASURA },
  });

  const reports = [
    {
      category: "COMPLIANT",
      count: data?.compliant.aggregate?.count,
      totalRentedProperties:
        data?.compliant.aggregate?.sum?.Numrentedproperties,
      grossRentedIncome: data?.compliant.aggregate?.sum?.Grossrentalincome,
      declaredRentedIncome: data?.compliant.aggregate?.sum?.Sumofadjustedincome,
    },
    {
      category: "UNREGISTERED LANDLORDS",
      count: data?.no_match.aggregate?.count,
      totalRentedProperties: data?.no_match.aggregate?.sum?.Numrentedproperties,
      grossRentedIncome: data?.no_match.aggregate?.sum?.Grossrentalincome,
      declaredRentedIncome: data?.no_match.aggregate?.sum?.Sumofadjustedincome,
      route: "unregistered-landlords",
    },
    {
      category: "TAXPAYERS WITH NO RETURNS",
      count: data?.no_return.aggregate?.count,
      totalRentedProperties:
        data?.no_return.aggregate?.sum?.Numrentedproperties,
      grossRentedIncome: data?.no_return.aggregate?.sum?.Grossrentalincome,
      declaredRentedIncome: data?.no_return.aggregate?.sum?.Sumofadjustedincome,
      route: "taxpayers-with-no-returns",
    },
    {
      category: "TAXPAYERS WITH NO INCOME",
      count: data?.no_income.aggregate?.count,
      totalRentedProperties:
        data?.no_income.aggregate?.sum?.Numrentedproperties,
      grossRentedIncome: data?.no_income.aggregate?.sum?.Grossrentalincome,
      declaredRentedIncome: data?.no_income.aggregate?.sum?.Sumofadjustedincome,
      route: "taxpayers-with-no-income",
    },
    {
      category: "TAXPAYERS WITH UNDERREPORTED INCOME",
      count: data?.underreported.aggregate?.count,
      totalRentedProperties:
        data?.underreported.aggregate?.sum?.Numrentedproperties,
      grossRentedIncome: data?.underreported.aggregate?.sum?.Grossrentalincome,
      declaredRentedIncome:
        data?.underreported.aggregate?.sum?.Sumofadjustedincome,
      route: "taxpayers-with-underreported-income",
    },
  ];

  return (
    <SC.Card>
      <SC.Table
        isPaginationHidden
        title="Risk Summary - Non Individuals"
        actionsOnRight={["fullscreen-expand"]}
        onAction={() => {}}
        // @ts-ignore
        columns={columns}
        data={reports ?? []}
      />
    </SC.Card>
  );
};

export default NonIndividualComplianceReports;
