import { FORMAT_VALUE_OPTIONS } from "components/formatted-value/formatted-value";

export interface KccaPropertyDetails {
  index: number;
  label: string;
  accessor?: string;
  format?: keyof typeof FORMAT_VALUE_OPTIONS;
}

const details: KccaPropertyDetails[] = [
  {
    index: 2,
    accessor: "PropertyType",
    label: "Property Type",
  },
  {
    index: 3,
    accessor: "SubPropertyTypeId",
    label: "Source SubProperty Type",
  },
  {
    index: 4,
    accessor: "RnSubpropertyType",
    label: "RippleNami Property Subtype",
  },
  {
    index: 5,
    accessor: "PropertyRentedStatusId",
    label: "Rented Status",
  },
  {
    index: 6,
    accessor: "Grossvalue",
    label: "Gross Value",
    format: "currency",
  },
  {
    index: 7,
    accessor: "Rateablevalue",
    label: "Rateable Value (RV)",
    format: "currency",
  },
  {
    index: 8,
    accessor: "Grossrentvalueadjusted",
    label: "Gross Adjusted Rental Income Estimate",
    format: "currency",
  },
  {
    index: 9,
    accessor: "AccomodationOthers",
    label: "Other Data",
  },
  {
    index: 10,
    accessor: "AccomodationBuiltUpArea",
    label: "Building Square Meters",
  },
];

const particularsResidentialSFH: KccaPropertyDetails[] = [
  {
    index: 0,
    accessor: "AccomodationOthers",
    label: "Other Data ",
  },
  {
    index: 1,
    accessor: "AccomodationBuiltUpArea",
    label: "Building Square Meters",
  },
];

const accomodationsResidentialSFH: KccaPropertyDetails[] = [
  {
    index: 0,
    accessor: "AccomodationBedrooms",
    label: "No. of Bedrooms by Source",
  },
  {
    index: 1,
    accessor: "DerivedNoOfBedrooms",
    label: "No. of Bedrooms Derived",
  },
];

const particularCommercialRetailAndOffices: KccaPropertyDetails[] = [
  {
    index: 0,
    accessor: "SquareMeters",
    label: "Total building square area (m2)",
  },
  {
    index: 1,
    accessor: "RentPerSquareMeter",
    label: "Approximate Rent Per Square Meter",
  },
  {
    index: 2,
    accessor: "MonthlyGrossIncome",
    label: "Approximate Monthly Gross Income",
    format: "currency",
  },
  {
    index: 3,
    accessor: "AnnualGrossValue",
    label: "Approximate Annual Gross Income",
    format: "currency",
  },
  {
    index: 4,
    accessor: "CurrencyType",
    label: "Currency Type",
  },
];

const particularWarehouseAndBars: KccaPropertyDetails[] = [
  {
    index: 0,
    accessor: "SquareMeters",
    label: "Total building square area (m2)",
  },
  {
    index: 1,
    accessor: "RentPerSquareMeter",
    label: "Approximate Rent Per Square Meter",
  },
  {
    index: 2,
    accessor: "Grossvalue",
    label: "Approximate Monthly Gross Income",
    format: "currency",
  },
  {
    index: 3,
    accessor: "AnnualGrossValue",
    label: "Approximate Annual Gross Income",
    format: "currency",
  },
  {
    index: 4,
    accessor: "CurrencyType",
    label: "Currency Type",
  },
];

const particulars: KccaPropertyDetails[] = [
  {
    index: 0,
    accessor: "ParticularsBlockName",
    label: "Block Name",
  },
  {
    index: 1,
    accessor: "ParticularsBlockNumber",
    label: "Block No.",
  },
  {
    index: 2,
    accessor: "ParticularsFolioNumber",
    label: "Folio No.",
  },
  {
    index: 3,
    accessor: "ParticularsHouseNumber",
    label: "House No.",
  },
  {
    index: 4,
    accessor: "ParticularsIsTitled",
    label: "Titled?",
  },
  {
    index: 5,
    accessor: "ParticularsPlotNumber",
    label: "Plot No.",
  },
  {
    index: 6,
    accessor: "ParticularsPlotShape",
    label: "Plot Shape",
  },
  {
    index: 7,
    accessor: "ParticularsPropertyName",
    label: "Name",
  },
  {
    index: 8,
    accessor: "ParticularsRoadStreet",
    label: "Road",
  },
  {
    index: 9,
    accessor: "ParticularsTypeOfLandInterest",
    label: "Type of Land Interest",
  },
];

const accomodations: KccaPropertyDetails[] = [
  {
    index: 0,
    accessor: "AccomodationBedrooms",
    label: "Bed Rooms",
  },
  {
    index: 1,
    accessor: "AccomodationBuiltUpArea",
    label: "Build Up Area",
    format: "area",
  },
  {
    index: 2,
    accessor: "AccomodationWc",
    label: "WC",
  },
  {
    index: 3,
    accessor: "AccomodationWcBathroom",
    label: "Bathroom",
  },
  {
    index: 4,
    accessor: "AccomodationWcShower",
    label: "Shower",
  },
];

const otherData: KccaPropertyDetails[] = [
  {
    index: 0,
    accessor: "ParticularsRoadStreet",
    label: "Street",
  },
  {
    index: 1,
    accessor: "Pvillage",
    label: "Village",
  },
  {
    index: 2,
    accessor: "ParticularsVolume",
    label: "Volume",
  },
  {
    index: 3,
    accessor: "ParticularsFolioNumber",
    label: "Folio",
  },
];
export default {
  details,
  particulars,
  accomodations,
  otherData,
  particularsResidentialSFH,
  accomodationsResidentialSFH,
  particularCommercialRetailAndOffices,
  particularWarehouseAndBars,
};
