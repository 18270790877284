/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { UraIndividualTaxFragment } from "graphql/hasura/types-and-hooks";

export type IndividualTaxTableSchema = Omit<
  UraIndividualTaxFragment,
  "__typename"
>;

export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof IndividualTaxTableSchema,
  "string" | "number"
> = {
  TinNo: "string",
  FirstName: "string",
  SurName: "string",
  IsIncomeSrcRental: "string",
  RtnNo: "string",
  RtnFromDt: "string",
  RtnToDt: "string",
  RtnDt: "string",
  RtnPeriodYear: "string",
  IsAmendment: "string",
  RentincTax: "number",
  TotIncome: "number",
  TotTax: "number",
  TotRentalIncm: "number",
  PartnerRentIncome: "number",
  GrossRentalIncome: "number",
  DeductIncmTax: "number",
  MotgageIntDecd: "number",
  NetRentIncm: "number",
  ThresholdAmnt: "number",
  ChargeableIncome: "number",
  TaxAssessed: "number",
  IsResident: "string",
  EmplymntIncome: "number",
  EmplymntTax: "number",
  EmplymntRate: "number",
  RentincRate: "number",
  PropincIncome: "number",
  PropincTax: "number",
  PropincRate: "number",
  TotRate: "number",
  ReturnType: "string",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualTaxTableSchema
    | Accessor<IndividualTaxTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const individualTaxTableColumns: TableColumn<IndividualTaxTableSchema>[] =
  [
    {
      Header: "TIN No.",
      accessor: "TinNo",
      align: "center",
      Cell: ({
        value,
        row: {
          values: { TinNo },
        },
      }) => {
        return (
          <Link to={`/individuals/${TinNo}/identification`} color="inherit">
            {value}
          </Link>
        );
      },
    },
    {
      Header: "First Name",
      accessor: "FirstName",
      align: "left",
      format: "string",
    },
    {
      Header: "Surname",
      accessor: "SurName",
      align: "left",
      format: "string",
    },
    {
      Header: "Income Rental?",
      accessor: "IsIncomeSrcRental",
      align: "center",
      format: "string",
    },
    {
      Header: "Unique Return Identification Number",
      accessor: "RtnNo",
      align: "center",
      Cell: ({
        value,
        row: {
          values: { TinNo, RtnNo },
        },
      }) => {
        return (
          <Link
            to={`/individuals/${TinNo}/tax-history/${RtnNo}`}
            color="inherit"
          >
            {value}
          </Link>
        );
      },
    },
    {
      Header: "ReturnType",
      accessor: "ReturnType",
      format: "string",
    },
    {
      Header: "Return Period From Date",
      accessor: "RtnFromDt",
      align: "center",
      format: "date",
    },
    {
      Header: "Return Period To Date",
      accessor: "RtnToDt",
      align: "center",
      format: "date",
    },
    {
      Header: "Tax Year",
      accessor: "RtnPeriodYear",
      align: "center",
      sort: true,
    },
    {
      Header: "Date Return Submitted",
      accessor: "RtnDt",
      align: "center",
      format: "date",
      sort: true,
      sortType: () => -1,
    },
    {
      Header: "Type of Return - Original/Amended",
      accessor: "IsAmendment",
      align: "center",
      format: "string",
    },
    {
      Header: "Total Rental Income Tax",
      accessor: "RentincTax",
      align: "right",
      format: "currency",
    },
    {
      Header: "Total Income",
      accessor: "TotIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Total Income Tax",
      accessor: "TotTax",
      align: "right",
      format: "currency",
    },
    {
      Header: "Gross Rental Income Other than Partnership",
      accessor: "TotRentalIncm",
      align: "right",
      format: "currency",
    },
    {
      Header: "Share of Gross Rental Income from Partnership",
      accessor: "PartnerRentIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Total Gross Rental Income (1+2)",
      accessor: "GrossRentalIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Allowable Deduction (20% of Gross)",
      accessor: "DeductIncmTax",
      align: "right",
      format: "currency",
    },
    {
      Header: "Interest Expense",
      accessor: "MotgageIntDecd",
      align: "right",
      format: "currency",
    },
    {
      Header: "Rental Income before Adjustment",
      accessor: "NetRentIncm",
      align: "right",
      format: "currency",
    },
    {
      Header: "Threshold for Rental Income",
      accessor: "ThresholdAmnt",
      align: "right",
      format: "currency",
    },
    {
      Header: "Chargeable Income",
      accessor: "ChargeableIncome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Tax Payable",
      accessor: "TaxAssessed",
      align: "right",
      format: "currency",
    },
    {
      Header: "Resident Status - Resident/NonResident",
      accessor: "IsResident",
      align: "center",
      hiddenColumn: true,
      format: "string",
    },
    {
      Header: "Employment Chargeable Income",
      accessor: "EmplymntIncome",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Employment Chargeable Income Total Tax",
      accessor: "EmplymntTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Employment Chargeable Tax Rate",
      accessor: "EmplymntRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Rental Tax Rate",
      accessor: "RentincRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Property Income",
      accessor: "PropincIncome",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Property Income Total Tax",
      accessor: "PropincTax",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Property Tax Rate",
      accessor: "PropincRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
    {
      Header: "Total Tax Rate",
      accessor: "TotRate",
      align: "right",
      hiddenColumn: true,
      format: "currency",
    },
  ];
