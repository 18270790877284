import React from "react";
import { TableColumn } from "components/table/table.component";
import {
  OwnersAndOfficersFragment,
  IndividualsAssociatedBusinessFragment,
} from "graphql/hasura/types-and-hooks";
import { Link } from "react-router-dom";
import { isValidTin } from "graphql/hasura/rtcs.utils";

export const associatedBusinessescolumns: TableColumn<IndividualsAssociatedBusinessFragment>[] =
  [
    {
      Header: "TIN No.",
      accessor: "TinNo",
      align: "left",
      sort: false,
    },
    {
      Header: "Business TIN",
      accessor: "AssociateTin",
      align: "left",
      sort: false,
      Cell: ({
        value,
        row: {
          values: { AssociateTin },
        },
      }: {
        value: string;
        row: { values: { AssociateTin: string } };
      }): React.ReactNode => {
        return isValidTin(AssociateTin) ? (
          <Link to={`/non-individuals/${AssociateTin}/identification`}>
            {value}
          </Link>
        ) : (
          <>{AssociateTin}</>
        );
      },
    },
    {
      Header: "Legal Entity Name",
      accessor: "AssociateTaxPayerName",
      align: "left",
      sort: false,
    },
    {
      Header: "Entity Relationship",
      accessor: "EntityDesignation",
      align: "left",
      sort: false,
    },
    {
      Header: "Main Activity",
      accessor: "MainActivity",
      align: "left",
      sort: false,
    },
    {
      Header: "Activity Description",
      accessor: "ActvtyDesc",
      align: "left",
      sort: false,
    },
  ];

export const ownersAndOfficersColumns: TableColumn<OwnersAndOfficersFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "left",
      sort: false,
    },
    {
      Header: "Individual TIN No.",
      accessor: "Individualtin",
      align: "left",
      sort: false,
    },
    {
      Header: "Name",
      accessor: "Name",
      align: "left",
      sort: false,
    },
    {
      Header: "Business TIN No.",
      accessor: "Tinno",
      align: "left",
      sort: false,
      Cell: ({
        value,
        row: {
          values: { Tinno },
        },
      }: {
        value: string;
        row: { values: { Tinno: string } };
      }): React.ReactNode => {
        return isValidTin(Tinno) ? (
          <Link to={`/non-individuals/${Tinno}/identification`}>{value}</Link>
        ) : (
          <>{Tinno}</>
        );
      },
    },
    {
      Header: "Business Name",
      accessor: "Taxpayername",
      align: "left",
      sort: false,
    },
    {
      Header: "Designation",
      accessor: "Designation",
      align: "center",
      sort: false,
    },
    {
      Header: "Percent Owned",
      accessor: "Pershare",
      align: "right",
      sort: false,
    },
    {
      Header: "Address",
      accessor: "Address",
      align: "left",
      sort: false,
    },
    {
      Header: "Return No.",
      accessor: "Rtnno",
      align: "left",
      sort: false,
    },
    {
      Header: "Tax Year",
      accessor: "Rtnperiodyear",
      align: "left",
      sort: false,
    },
    {
      Header: "Officers Index No.",
      accessor: "Rtnholdingofficersdtlid",
      align: "left",
      sort: false,
      hiddenColumn: true,
    },
    {
      Header: "Owners Index No.",
      accessor: "Rtnholdingownersdtlid",
      align: "left",
      sort: false,
      hiddenColumn: true,
    },
  ];

export default {
  ownersAndOfficersColumns,
  associatedBusinessescolumns,
};
