/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/no-danger */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/require-default-props */
// Dependencies
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

// Shapes
import { featureShape, propertiesShape } from "../propTypesShapes";

// Utils
import HtmlText from "../../shared/components/HtmlText";

import ImagePlaceholder from "./imagePlaceholder";

import SC from "./styles";

// Assets
import "./PlaceDescription.css";

const Field = ({ name, value }) => {
  if (!(name && value)) {
    return null;
  }

  let valueContent;

  if (_.isString(value)) {
    valueContent = <HtmlText text={value} />;
  } else {
    valueContent = value;
  }

  return (
    <p>
      <strong>{name}</strong>
      <br />
      {valueContent}
    </p>
  );
};
Field.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export const DescriptionContent = ({ featureProperties }) => {
  const { Address, Website, Telephone, Email, Description, Postcode } =
    featureProperties;

  return (
    <>
      <Field
        name="ADDRESS"
        value={Postcode ? `${Address} ${Postcode}` : Address}
      />
      <Field
        name="CONTACT"
        value={
          <>
            {Telephone && (
              <span>
                {Telephone}
                <br />
              </span>
            )}
            {Email && (
              <span>
                <span dangerouslySetInnerHTML={{ __html: Email }} />
                <br />
              </span>
            )}
            {Website && (
              <span>
                <span dangerouslySetInnerHTML={{ __html: Website }} />
                <br />
              </span>
            )}
          </>
        }
      />
      <Field name="DESCRIPTION" value={Description} />
    </>
  );
};
DescriptionContent.propTypes = {
  featureProperties: propertiesShape,
};

class PlaceDescription extends PureComponent {
  static propTypes = {
    feature: featureShape,
    leftPane: PropTypes.bool,
  };

  render() {
    const { feature, leftPane, history } = this.props;
    const {
      name,
      website,
      housenumber,
      village,
      plotnumber,
      parish,
      division,
      Rnid,
      propertyId,
    } = feature.properties;

    const goToProperty = (id) => {
      history.push(`/properties/${id}/details`);
    };

    return (
      <SC.MapContentContainer>
        {!leftPane && <ImagePlaceholder />}
        {!leftPane && <SC.MapContentLabel>{name}</SC.MapContentLabel>}
        <SC.MapContentLabel>{housenumber}</SC.MapContentLabel>
        <SC.MapContentLabel>{plotnumber}</SC.MapContentLabel>
        <SC.MapContentLabel>{village}</SC.MapContentLabel>
        <SC.MapContentLabel>{parish}</SC.MapContentLabel>
        <SC.MapContentLabel>{division}</SC.MapContentLabel>
        <br />
        <SC.MapContentLink
          onClick={() => goToProperty(Rnid)}
          href={website}
          underline="none"
        >
          {" "}
          Go to property{` ${propertyId}`}
        </SC.MapContentLink>
      </SC.MapContentContainer>
    );
  }
}

export default PlaceDescription;
