/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Typography as TypographyBase,
  Container as ContainerBase,
  Paper as PaperBase,
  withStyles,
  Grid as GridBase,
} from "@material-ui/core";
import Table from "components/table/table.component";
import LabelKeyValue from "components/label-key-value/label-key-value.component";
import SelectField from "components/select-field/select-field.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

const Typography = withStyles((theme: any) => ({
  root: {
    fontSize: theme.UtilitiesTab.header.fontSize,
    fontWeight: "bold",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))(TypographyBase);
const Paper = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(PaperBase);
const Grid = withStyles(() => ({}))(GridBase);
const Container = withStyles(() => ({
  root: {
    textAlign: "left",
  },
}))(ContainerBase);

export default {
  Table,
  Typography,
  Container,
  Paper,
  Grid,
  LabelKeyValue,
  SelectField,
  ProgressIndicator,
};
