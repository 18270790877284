export const MONTHS = [
  {
    label: "January",
    index: 0,
    value: "Jan",
  },
  {
    label: "February",
    index: 1,
    value: "Feb",
  },
  {
    label: "March",
    index: 2,
    value: "Mar",
  },
  {
    label: "April",
    index: 3,
    value: "Apr",
  },
  {
    label: "May",
    index: 4,
    value: "May",
  },
  {
    label: "June",
    index: 5,
    value: "Jun",
  },
  {
    label: "July",
    index: 6,
    value: "Jul",
  },
  {
    label: "August",
    index: 7,
    value: "Aug",
  },
  {
    label: "September",
    index: 8,
    value: "Sep",
  },
  {
    label: "October",
    index: 9,
    value: "Oct",
  },
  {
    label: "November",
    index: 10,
    value: "Nov",
  },
  {
    label: "December",
    index: 11,
    value: "Dec",
  },
];

export default {
  MONTHS,
};
