/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Container as ContainerBase,
  Card as CardBase,
  withStyles,
  makeStyles,
  ContainerProps,
  Typography as TypographyBase,
} from "@material-ui/core";

import Table from "components/table/table.component";

interface CustomContainerProps extends ContainerProps {
  maxHeight?: number;
}

const Card = withStyles((theme: any) => ({
  root: {
    border: theme.tableCard.card.border,
    borderRadius: theme.tableCard.card.border,
    margin: theme.tableCard.card.margin,
  },
}))(CardBase);

const useContainerStyles = makeStyles({
  root: {
    overflowX: "auto",
    maxHeight: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
    height: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
  },
});

const Container = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => {
    const { maxHeight, ...muiProps } = props;
    const classes = useContainerStyles(props);
    return <ContainerBase className={classes.root} {...muiProps} ref={ref} />;
  }
);

const Typography = withStyles((theme: any) => ({
  root: {
    marginTop: theme.analysis.noAnalysis.marginTop,
    textAlign: "left",
  },
}))(TypographyBase);

export default {
  Container,
  Table,
  Card,
  Typography,
};
