// Dependencies
import React from "react";
import {
  Check as CheckIcon,
  ErrorOutline as ErrorIcon,
  HourglassEmpty as WaitIcon,
} from "@material-ui/icons";

// GraphQL
import { DagsterPipelineRunStatus, Job } from "graphql/types-and-hooks";

// Utils
import useJobStatus from "jobs/useJobStatus";
import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

// Styles
import SC from "./data-permissions-applicator.styles";

const DataPermissionsApplicator: React.FC = () => {
  const [
    runJob,
    {
      jobStatus,
      jobMessage,
      jobRunning,
      jobStatusLoading,
      jobStatusError,
      runJobLoading,
      runJobError,
    },
  ] = useJobStatus(Job.ApplyDataPermissions);

  const errorHandler = useAppErrorHandler(jobStatusError || runJobError);

  const handlerApplyPermissions = React.useCallback(() => {
    runJob().catch((error) => {
      if (isServerError(error as Error)) {
        errorHandler(error as Error);
      } else {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while applying data permissions"
          )
        );
      }
    });
  }, [runJob, errorHandler]);

  const Icon = React.useMemo(() => {
    switch (jobStatus) {
      case DagsterPipelineRunStatus.Canceled:
      case DagsterPipelineRunStatus.NotStarted:
      case DagsterPipelineRunStatus.Success:
        return CheckIcon;

      case DagsterPipelineRunStatus.Failure:
        return ErrorIcon;

      default:
        return WaitIcon;
    }
  }, [jobStatus]);

  return (
    <SC.Card>
      <SC.CardHeader
        avatar={
          <SC.ButtonWrapper>
            <SC.StatusIndicator color="primary">
              <Icon />
            </SC.StatusIndicator>
            {jobStatusLoading && <SC.IndicatorProgress size={68} />}
          </SC.ButtonWrapper>
        }
        action={
          <SC.ButtonWrapper>
            <SC.Button
              color="primary"
              variant="outlined"
              disabled={!jobStatus || jobRunning}
              onClick={handlerApplyPermissions}
            >
              Apply
            </SC.Button>
            {(runJobLoading || jobRunning) && <SC.ButtonProgress size={24} />}
          </SC.ButtonWrapper>
        }
        title="Data Permissions"
        subheader={`Status: ${jobMessage}`}
      />
    </SC.Card>
  );
};

export default DataPermissionsApplicator;
