/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Modal as ModalBase,
  Backdrop,
  Fade as Paper,
  withStyles,
} from "@material-ui/core";

const Modal = withStyles(() => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))(ModalBase);

const Fade = withStyles((theme: any) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    border: theme.lightbox.fade.border,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))(Paper);

export default {
  Modal,
  Backdrop,
  Fade,
};
