// Dependencies
import {
  withStyles,
  Grid as GridBase,
  Box as BoxBase,
} from "@material-ui/core";

const Container = withStyles({
  root: {
    flexGrow: 1,
    paddingLeft: "1.14rem",
  },
})(GridBase);

const Box = withStyles(() => ({
  root: {
    "::-webkit-scrollbar": {
      display: "none",
    },
    overflow: "auto",
  },
}))(BoxBase);

export default {
  Container,
  Box,
};
