/* eslint-disable no-alert */
// Dependencies
import React, { useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Ura_NonIndividualsAssociatedBusinessArgs,
  Order_By,
  Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column,
  useGetPaginatedNonIndividualsAssociatedBusinessQuery,
  useNonIndividualsAssociatedBusinessReportQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  TableFetchDataFunction,
  INITIAL_ROWS_PER_PAGE,
} from "components/table/table.component";
import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";

// Schema
import {
  individualTableColumns,
  getAccessorType,
} from "./associated-registered-nonindividuals-to-nonindividuals.schema";

// Assets
import SC from "./associated-registered-nonindividuals-to-nonindividuals.styles";

const TABLE_NAME = "rtcs_db_Fnura_NonIndividualsAssociatedBusiness";

const IndividualRegistration: React.FC = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Ura_NonIndividualsAssociatedBusinessArgs | undefined
  >(undefined);
  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } =
    useGetPaginatedNonIndividualsAssociatedBusinessQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !queryVariables,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
        aggregateParams: jsonToStringQueryVariables({
          where: queryVariables?.where,
        }),
      },
    });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const NonIndividualData = React.useMemo(() => {
    const NonIndividual =
      data?.rtcs_db_Fnura_NonIndividualsAssociatedBusiness ?? [];

    return NonIndividual;
  }, [data]);

  const columns = useMemo(() => individualTableColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column.TinNo,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_Fnura_NonIndividualsAssociatedBusiness_aggregatecm[0].value ??
      "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData]
  );

  // TODO: Replace for useLazyQuery when the function has been updated
  // to return a Promise instead of void.
  // https://github.com/apollographql/react-apollo/issues/3499#issuecomment-639954192
  const { refetch: IndividualFilterFn } =
    useNonIndividualsAssociatedBusinessReportQuery({
      skip: true,
      context: {
        clientName: CLIENT_NAME.HASURA,
      },
    });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Ura_NonIndividualsAssociatedBusinessArgs =
            {
              where: { [column]: { _ilike: `${value}%` } },
              distinct_on: [
                column as Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column,
              ],
              limit: 10,
            };
          const individualFilterData = await IndividualFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions =
            individualFilterData.data?.rtcs_db_Fnura_NonIndividualsAssociatedBusiness.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column]
            );
        }

        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [IndividualFilterFn]
  );

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "TIN No.",
        columnName:
          Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column.TinNo,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Taxpayer Name",
        columnName:
          Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column.TaxPayerName,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Associate TIN",
        columnName:
          Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column.AssociateTin,
        fetchSuggestions,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Associated Business",
        columnName:
          Rtcs_Db_Ph_Ura_NonIndividualsAssociatedBusiness_Select_Column.AssociatedNonIndividual,
        fetchSuggestions,
      },
    ];
  }, [fetchSuggestions]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb />
      <Table
        actionsOnRight={[
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        title="Associated Registered NonIndividuals To NonIndividuals"
        columns={columns}
        data={NonIndividualData}
        onAction={() => alert("under construction")}
        headerPanel={<SC.Title>MDA - Uganda Revenue Authority</SC.Title>}
        leftPanel={
          <TableFilter
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        persistenceId="40db3f68-d66d-4258-a689-e0-d2-f2e-ea71b"
        paginationControlled={paginationControlled}
        stickyHeader
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
        exportData={exportDataFn}
      />
      <ProgressIndicator open={loading || exportLoading} />
    </SC.Box>
  );
};

export default IndividualRegistration;
