import React from "react";
import _ from "lodash";

// Components
import UniversalFilter, {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";
import SearchBox from "components/search-box/search-box.component";
import AutofillField from "components/autofill-field/autofill-field.component";
import SelectField, {
  MenuProps,
} from "components/select-field/select-field.component";
import RangeSlider from "components/range-slider/range-slider.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Assets
import SC from "./table-filter.styles";

const SEARCH_MIN_LENGTH = 3;
const SEARCH_DELAY_MS = 3000;
const AUTOFILL_MIN_LENGTH = 3;
const AUTOFILL_DELAY_MS = 3000;

export enum TableFilterType {
  AUTOFILL = "Autofill",
  SELECT = "Select",
  RANGE_SLIDER = "Range Slider",
}

export interface TableFilterInputBase {
  type: TableFilterType;
  label: string;
  columnName: string;
}

export interface TableFilterInputAutofill extends TableFilterInputBase {
  type: TableFilterType.AUTOFILL;
  fetchSuggestions: (value: string, columnName: string) => Promise<string[]>;
}

export interface TableFilterInputSelect extends TableFilterInputBase {
  type: TableFilterType.SELECT;
  options: MenuProps[];
}

export interface TableFilterInputRangeSlider extends TableFilterInputBase {
  type: TableFilterType.RANGE_SLIDER;
  min: number;
  max: number;
}

export type TableFilterInput =
  | TableFilterInputAutofill
  | TableFilterInputSelect
  | TableFilterInputRangeSlider;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type FilterCondition = { [x: string]: any };

export interface TableFilterSearch {
  label: string;
  columnNames: string[];
  // onSearchChange: (conditions: FilterCondition[]) => void;
}

const searchesToConditions = (
  searches: TableFilterState,
  searchInputs?: TableFilterSearch[]
) => {
  const conditions: FilterCondition[] = [];

  Object.keys(searches).forEach((label) => {
    const searchText = searches[label];
    const input = searchInputs?.find((inp) => inp.label === label);

    if (searchText !== "" && input) {
      const inputConditions =
        input.columnNames.map((column) => ({
          [column]: { _ilike: `%${searchText}%` },
        })) ?? [];

      conditions.push({
        _or: inputConditions,
      });
    }
  });

  return conditions;
};

const rangeToString = (range: number[]) => range.join(",");

const stringToRange = (strValue: string, defaultRange: number[]) =>
  _.isEmpty(strValue) ? defaultRange : strValue.split(",").map(Number);

const filtersToConditions = (
  filters: TableFilterState,
  inputs?: TableFilterInput[]
) => {
  const conditions: FilterCondition[] = [];

  Object.keys(filters).forEach((columnName) => {
    const columnValue = filters[columnName];
    const input = inputs?.find((inp) => inp.columnName === columnName);

    if (columnValue !== "" && input) {
      switch (input.type) {
        case TableFilterType.RANGE_SLIDER: {
          const range = stringToRange(columnValue, [input.min, input.max]);

          conditions.push(
            { [columnName]: { _gte: `${range[0]}` } },
            { [columnName]: { _lte: `${range[1]}` } }
          );
          break;
        }

        default:
          conditions.push({
            [columnName]: { _ilike: `${columnValue}%` },
          });
          break;
      }
    }
  });

  return conditions;
};

const initializeSearchesState = (
  searchInputs: TableFilterSearch[] | undefined
): TableFilterState => {
  return (
    searchInputs?.reduce(
      (state, input) => ({
        ...state,
        [input.label]: "",
      }),
      {}
    ) ?? {}
  );
};

const initializeFiltersState = (
  filterInputs: TableFilterInput[] | undefined
): TableFilterState => {
  return (
    filterInputs?.reduce(
      (state, input) => ({
        ...state,
        [input.columnName]: "",
      }),
      {}
    ) ?? {}
  );
};

export interface TableFilterProps {
  searchInputs?: TableFilterSearch[];
  onSearchChange?: (conditions: FilterCondition[]) => void;
  filterInputs?: TableFilterInput[];
  onFilterApply?: (conditions: FilterCondition[]) => void;
  enableApplyFilterButton?: boolean;
  universalFilterColumns?: ColumnsProps[];
  onUniversalFilterSubmit?: (condition: UniversalFilterResponse) => void;
  defaultUniversalFilterConditions?: FilterCondition[];
}

type TableFilterState = Record<string, string>;
type TableFilterSuggestions = Record<string, string[]>;
type TableFilterSuggestionsLoading = Record<string, boolean>;

export const TableFilter: React.FC<TableFilterProps> = ({
  searchInputs,
  onSearchChange,
  filterInputs,
  onFilterApply,
  universalFilterColumns,
  onUniversalFilterSubmit,
  defaultUniversalFilterConditions,
}) => {
  const [universalFilterResetSignal, setUniversalFilterResetSignal] =
    React.useState(false);
  const [searches, setSearches] = React.useState<TableFilterState>(() =>
    initializeSearchesState(searchInputs)
  );
  const [filters, setFilters] = React.useState<TableFilterState>(() =>
    initializeFiltersState(filterInputs)
  );
  const [suggestions, setSuggestions] = React.useState<TableFilterSuggestions>(
    () =>
      filterInputs?.reduce(
        (state, input) => ({
          ...state,
          [input.columnName]: [],
        }),
        {}
      ) ?? {}
  );
  const [suggestionsLoading, setSuggestionsLoading] =
    React.useState<TableFilterSuggestionsLoading>(
      () =>
        filterInputs?.reduce(
          (state, input) => ({
            ...state,
            [input.columnName]: false,
          }),
          {}
        ) ?? {}
    );

  const appErrorHandler = useAppErrorHandler();

  const onTriggerAutofillHandler = React.useCallback(
    async (text: string, input: TableFilterInputAutofill) => {
      try {
        setSuggestionsLoading((oldSuggestionsLoading) => ({
          ...oldSuggestionsLoading,
          [input.columnName]: true,
        }));

        const newSuggestions = await input.fetchSuggestions(
          text,
          input.columnName
        );
        setSuggestions((oldSuggestions) => ({
          ...oldSuggestions,
          [input.columnName]: newSuggestions ?? [],
        }));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        appErrorHandler(e);
      } finally {
        setSuggestionsLoading((oldSuggestionsLoading) => ({
          ...oldSuggestionsLoading,
          [input.columnName]: false,
        }));
      }
    },
    [appErrorHandler]
  );

  const renderFilterInput = React.useCallback(
    (input: TableFilterInput) => {
      switch (input.type) {
        case TableFilterType.AUTOFILL:
          return (
            <AutofillField
              inputValue={filters[input.columnName]}
              onInputChange={(event, newInputValue) => {
                setFilters((oldFilters) => ({
                  ...oldFilters,
                  [input.columnName]: newInputValue,
                }));
              }}
              serverSideSearchProps={{
                onTriggerSearch: (inputValue) =>
                  onTriggerAutofillHandler(inputValue, input),
                minLengthSearch: AUTOFILL_MIN_LENGTH,
                msDelaySearch: AUTOFILL_DELAY_MS,
              }}
              textFieldProps={{
                label: "Search",
                helperText: `Enter ${input.label}`,
              }}
              loading={suggestionsLoading[input.columnName]}
              options={suggestions[input.columnName]}
            />
          );
        case TableFilterType.SELECT:
          return (
            <SelectField
              defaultValue={filters[input.columnName]}
              label={input.label}
              options={input.options}
              onSelect={(valueString: string) => {
                setFilters((oldFilters) => ({
                  ...oldFilters,
                  [input.columnName]: valueString,
                }));
              }}
            />
          );
        case TableFilterType.RANGE_SLIDER: {
          const strValue = filters[input.columnName];
          const value = stringToRange(strValue, [input.min, input.max]);

          return (
            <RangeSlider
              label={input.label}
              min={input.min}
              max={input.max}
              value={value}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              onChange={(event, range) => {
                setFilters((oldFilters) => ({
                  ...oldFilters,
                  [input.columnName]: rangeToString(range as number[]),
                }));
              }}
            />
          );
        }
        default:
          return null;
      }
    },
    [filters, onTriggerAutofillHandler, suggestions, suggestionsLoading]
  );

  const applyFilterOnClick = React.useCallback(() => {
    setUniversalFilterResetSignal((previousSignal) => !previousSignal);
    onFilterApply?.(filtersToConditions(filters, filterInputs));
  }, [filterInputs, filters, onFilterApply]);

  const onTriggerSearchHandler = React.useCallback(() => {
    onSearchChange?.(searchesToConditions(searches, searchInputs));
  }, [onSearchChange, searchInputs, searches]);

  const onSearchBoxChange = React.useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      const { id, value } = event.target;

      setSearches((oldSearches) => ({
        ...oldSearches,
        [id]: value,
      }));
    },
    []
  );

  const universalFilterSubmitHandler = React.useCallback(
    (response: UniversalFilterResponse) => {
      setFilters(initializeFiltersState(filterInputs));
      onUniversalFilterSubmit?.(response);
    },
    [filterInputs, onUniversalFilterSubmit]
  );

  const isShownSearchSection = searchInputs;
  const isShownFilterSection = universalFilterColumns || filterInputs;

  return (
    <SC.Container>
      {isShownSearchSection &&
        searchInputs?.map(({ label }, index) => (
          <SC.Box key={label}>
            {index === 0 && <SC.Label>Search</SC.Label>}

            <SearchBox
              id={label}
              value={searches[label]}
              onChange={onSearchBoxChange}
              serverSideSearchProps={{
                onTriggerSearch: onTriggerSearchHandler,
                minLengthSearch: SEARCH_MIN_LENGTH,
                msDelaySearch: SEARCH_DELAY_MS,
              }}
              label="Search"
              helperText={`Enter ${label}`}
              fullWidth
            />
          </SC.Box>
        ))}

      {isShownSearchSection && isShownFilterSection && (
        <SC.Box>
          <SC.Divider />
        </SC.Box>
      )}

      {isShownFilterSection && (
        <>
          <SC.Box>
            <SC.Label>Filter</SC.Label>
            {universalFilterColumns && (
              <UniversalFilter
                resetSignal={universalFilterResetSignal}
                label="Universal Filter"
                columns={universalFilterColumns || []}
                onFilterSubmit={universalFilterSubmitHandler}
                defaultConditions={defaultUniversalFilterConditions}
              />
            )}
          </SC.Box>

          {filterInputs?.map((input) => (
            <SC.Box key={input.columnName}>{renderFilterInput(input)}</SC.Box>
          ))}

          {filterInputs && (
            <SC.BoxButton>
              <SC.ButtonSearch
                variant="contained"
                color="primary"
                onClick={applyFilterOnClick}
              >
                Apply
              </SC.ButtonSearch>
            </SC.BoxButton>
          )}
        </>
      )}
    </SC.Container>
  );
};

export default TableFilter;
