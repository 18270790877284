import { TableColumn } from "components/table/table.component";

export type Schema = {
  question: string;
  answertext: string;
};

export const columns: TableColumn<Schema>[] = [
  {
    Header: "Question Text",
    accessor: "question",
  },
  {
    Header: "Answer Text",
    accessor: "answertext",
  },
];
