/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { UraIndividualsAssociatedBusinessFragment } from "graphql/hasura/types-and-hooks";

export type IndividualTableSchema = Omit<
  UraIndividualsAssociatedBusinessFragment,
  "__typename"
>;

// @ts-ignore
export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof IndividualTableSchema,
  "string" | "number" | "Date"
> = {
  Surname: "string",
  TinNo: "string",
  // RegAssociatedEntityDtlId: "string",
  FirstName: "string",
  OtherName: "string",
  FamilyName: "string",
  AssociateTin: "string",
  EntityDesignation: "string",
  MainActivity: "string",
  ActvtyDesc: "string",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualTableSchema
    | Accessor<IndividualTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const individualTableColumns: TableColumn<IndividualTableSchema>[] = [
  {
    Header: "TIN No.",
    accessor: "TinNo",
    align: "center",
    Cell: ({
      value,
      row: {
        values: { TinNo },
      },
    }) => {
      return (
        <Link to={`/individuals/${TinNo}/identification`} color="inherit">
          {value}
        </Link>
      );
    },
    sort: true,
  },
  {
    Header: "First Name",
    accessor: "FirstName",
    align: "left",
    format: "string",
  },
  {
    Header: "Surname",
    accessor: "Surname",
    align: "left",
    format: "string",
  },
  {
    Header: "Other Name",
    accessor: "OtherName",
    align: "left",
    format: "string",
  },
  {
    Header: "Family Name",
    accessor: "FamilyName",
    align: "left",
    format: "string",
  },
  {
    Header: "Associate TIN",
    accessor: "AssociateTin",
    align: "center",
    Cell: ({
      value,
      row: {
        values: { AssociateTin },
      },
    }) => {
      return (
        <Link
          to={`/non-individuals/${AssociateTin}/identification`}
          color="inherit"
        >
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Legal Entity Name",
    accessor: "AssociateTaxPayerName",
    align: "left",
    format: "string",
  },
  {
    Header: "Entity Designation",
    accessor: "EntityDesignation",
    align: "left",
    format: "string",
  },
  {
    Header: "Main Activity",
    accessor: "MainActivity",
    align: "left",
    format: "string",
  },
  {
    Header: "Activity Description",
    accessor: "ActvtyDesc",
    align: "left",
    format: "string",
  },
];
