/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import IndividualComplianceReports from "./compliance-reports/individuals/compliance-reports.component";
import NonIndividualComplianceReports from "./compliance-reports/non-individuals/compliance-reports.component";

// styles
import SC from "./home.styles";

export const Home = () => {
  return (
    <SC.Container>
      <SC.Box mt={2}>
        <IndividualComplianceReports />
      </SC.Box>

      <SC.Box mt={2}>
        <NonIndividualComplianceReports />
      </SC.Box>
    </SC.Container>
  );
};

export default Home;
