import React from "react";
import { useAppErrorHandler } from "errors/app.errors";
import { CLIENT_NAME } from "graphql/client";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import {
  Query_RootRtcs_Db_Ph_Ura_RegIndividualArgs,
  useRegIndividualAffiliatesQuery,
} from "graphql/hasura/types-and-hooks";
import { affiliatesTableColumnsURA } from "../affiliates-tab.schema";
import SC from "./ura-affiliates-table.styles";

export interface UraAffiliatesProps {
  rnid?: string;
}

const UraAffiliates: React.FC<UraAffiliatesProps> = ({ rnid }) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Ura_RegIndividualArgs = {
    where: { Rnid: { _eq: rnid } },
  };

  const { data, error } = useRegIndividualAffiliatesQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !rnid,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  useAppErrorHandler(error);

  return (
    <SC.Table
      title="Uganda Revenue Authority (URA)"
      persistenceId="f1d0a915-179c-4970-bb0d-3cf73b80d401"
      actionsOnRight={[]}
      onAction={() => {}}
      columns={affiliatesTableColumnsURA}
      isPaginationHidden
      data={data?.rtcs_db_FnRPT_IndividualComposite ?? []}
    />
  );
};

export default UraAffiliates;
