/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import React from "react";
import { TableColumn } from "components/table/table.component";
import { Link, useRouteMatch } from "react-router-dom";

import { NonIndividualIncomeTaxSummariesFragment } from "graphql/hasura/types-and-hooks";

export type ReportTableSchema = Omit<
  NonIndividualIncomeTaxSummariesFragment,
  "__typename"
>;

export const REPORT_TABLE_SCHEMA: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  RtnPeriodYear: "string",
  RtnNo: "string",
  IsAmendment: "string",
  TotIncome: "number",
  TotTax: "number",
  TotRate: "number",
  GrossRentalIncome: "number",
  RentincIncome: "number",
  RentincTax: "number",
  RentincRate: "number",
  ChargeableIncomeP: "number",
  RentincIncomeP: "number",
  ReturnType: "string",
  RtnDt: "string",
};

// TODO: Add column - Rental Income as a % of Total Income
export const columns: TableColumn<ReportTableSchema>[] = [
  {
    Header: "Tax Year",
    accessor: "RtnPeriodYear",
    align: "center",
    sort: true,
    Cell: ({ cell: { value } }) => {
      return <>{value?.replace("-", "/")}</>;
    },
  },
  {
    Header: "Return No.",
    accessor: "RtnNo",
    align: "center",
    Cell: ({ cell: { value } }) => {
      const { url } = useRouteMatch();
      return (
        <Link to={`${url}/${value}`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Amendment?",
    accessor: "IsAmendment",
    align: "center",
  },
  {
    Header: "ReturnType",
    accessor: "ReturnType",
  },
  {
    Header: "Return Date",
    accessor: "RtnDt",
    align: "center",
    format: "date",
    sort: true,
  },
  {
    Header: "Total income",
    accessor: "TotIncome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Total Income Tax Declared",
    accessor: "TotTax",
    align: "right",
    format: "currency",
  },
  {
    Header: "Income Tax %",
    accessor: "TotRate",
    format: "percent",
  },
  {
    Header: "Gross Rental Income",
    accessor: "GrossRentalIncome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Chargeable Rental Income",
    accessor: "RentincIncome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Chargeable Rental Income %",
    accessor: "ChargeableIncomeP",
    align: "right",
    format: "percent",
  },
  {
    Header: "Rental Tax",
    accessor: "RentincTax",
    align: "right",
    format: "currency",
  },
  {
    Header: "Rental Tax %",
    accessor: "RentincRate",
    align: "right",
    format: "percent",
  },
  {
    Header: "Rental Income %",
    accessor: "RentincIncomeP",
    align: "right",
    format: "percent",
  },
];
