/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { useTheme } from "@material-ui/core";
import React from "react";

import { TablePaginationActionsProps } from "@material-ui/core/TablePagination/TablePaginationActions";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import LastPageIcon from "@material-ui/icons/LastPage";

// Assets
import SC from "./table-pagination-actions.styles";

const TablePaginationActions: React.FC<TablePaginationActionsProps> = ({
  count,
  rowsPerPage,
  onChangePage,
}) => {
  const theme: any = useTheme();
  const [pageIndex, setPageIndex] = React.useState<number>(0);

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setPageIndex(0);
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const pageIndexx = pageIndex - 1;
    setPageIndex(pageIndexx);
    onChangePage(event, pageIndexx);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const pageIndexx = pageIndex + 1;
    setPageIndex(pageIndexx);
    onChangePage(event, pageIndexx);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    const lastPageIndex = Math.max(0, Math.ceil(count / rowsPerPage) - 1);
    setPageIndex(lastPageIndex);
    onChangePage(event, lastPageIndex);
  };

  return (
    <SC.TablePaginationAction>
      <SC.IconButton
        onClick={handleFirstPageButtonClick}
        disabled={pageIndex === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </SC.IconButton>
      <SC.IconButton
        onClick={handleBackButtonClick}
        disabled={pageIndex === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </SC.IconButton>
      <SC.IconButton
        onClick={handleNextButtonClick}
        disabled={pageIndex >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </SC.IconButton>
      <SC.IconButton
        onClick={handleLastPageButtonClick}
        disabled={pageIndex >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </SC.IconButton>
    </SC.TablePaginationAction>
  );
};

export default TablePaginationActions;
