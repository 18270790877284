/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  useNonIndividualPropertyDetailsQuery,
  Query_RootRtcs_Db_Rmatch_NonIndividualComplianceDetailReportArgs,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

import { ServerDataToLayer } from "rView/home/utils/layer";
import { ColumnInstance } from "react-table";
import SC from "./properties-tab.styles";
import { propertiesTableColumns } from "./properties-tab.schema";

const persistenceId = "3282c668-19e1-43a3-8763-56c-0ab4b-233a";

interface NonIndividualPropertiesProps {
  rnid: string;
}

const TABLE_NAME = "RPT_PropertiesComposite";

const NonIndividualProperties: React.FC<NonIndividualPropertiesProps> = ({
  rnid,
}) => {
  const { rentedStatus } = useParams<{ rentedStatus: string }>();
  const queryVariables: Query_RootRtcs_Db_Rmatch_NonIndividualComplianceDetailReportArgs =
    {
      where: {
        _and: [
          { Rnidcustomer: { _eq: rnid } },
          rentedStatus === "rented"
            ? { Propertyrentedstatus: { _eq: "RENTED" } }
            : {},
        ],
      },
    };

  const { data, error } = useNonIndividualPropertyDetailsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const properties = useMemo(() => {
    return data?.rtcs_db_Fnrmatch_NonIndividualComplianceDetailReport ?? [];
  }, [data]);

  return (
    <>
      <SC.SwitchViews
        LeftView={
          <SC.MapTableContainer
            columns={propertiesTableColumns}
            properties={properties}
            persistenceId={persistenceId}
            paginationControlled={undefined}
            initialRowsPerPage={undefined}
            pageIndexResetSignal={undefined}
            stickyHeader
            exportData={(columns: ColumnInstance[]) =>
              exportDataFn(columns, undefined, properties)
            }
          />
        }
        leftViewName="Table"
        RightView={
          <SC.MapContainer properties={ServerDataToLayer(properties)} />
        }
        rightViewName="Map"
      />
      <SC.ProgressIndicator open={exportLoading} />
    </>
  );
};

export default NonIndividualProperties;
