/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Avatar,
  Box,
  Fab as FabMui,
  Tooltip,
  InputBase,
  InputLabel,
  Button,
} from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";

const ProfileContainer = withStyles({
  root: {
    position: "relative",
    width: "fit-content",
    height: "fit-content",
    margin: "auto",
  },
})(Box);

const Fab = withStyles({
  root: {
    position: "absolute",
    zIndex: 1,
    bottom: "-20%",
    right: 0,
    transform: "translate(-50%, -50%)",
    msTransform: "translate(-50%, -50%)",
  },
})(FabMui) as typeof FabMui;

const InputBasePicture = withStyles({
  root: {
    display: "none",
  },
})(InputBase);

const LabelPicture = InputLabel;

const UserAvatar = withStyles((theme: any) => ({
  root: {
    width: theme.userProfilePicture.width,
    height: theme.userProfilePicture.height,
  },
}))(Avatar);

const DefaultAvatarIcon = withStyles((theme: any) => ({
  root: {
    width: theme.userProfilePicture.width,
    height: theme.userProfilePicture.height,
    color: theme.userProfilePicture.defaultColor,
  },
}))(AccountCircleIcon);

export default {
  ProfileContainer,
  Tooltip,
  Fab,
  AddPhotoAlternateIcon,
  InputBasePicture,
  LabelPicture,
  Button,
  UserAvatar,
  DefaultAvatarIcon,
};
