/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
// Dependencies
import React from "react";

// Types
import { GroupData, RoleData, UserData } from "graphql/types-and-hooks";

// Components
import Pane from "components/pane/pane.component";
import TextField from "components/text-field/text-field.component";
import DateTimeField from "components/datetime-field/datetime-field.component";
import Autocomplete from "components/autocomplete/autocomplete.component";

// Assets
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import SC from "./group-form-fields.styles";

interface Props {
  groupData?: GroupData;
  isEditable: boolean;
  usersList: UserData[];
  rolesList: RoleData[];
  usersBelongToGroup: UserData[];
  onChange: (groupData: GroupData) => void;
  onUsersChange: (usersData: UserData[]) => void;
}
const GroupFormFields: React.FC<Props> = ({
  groupData,
  isEditable,
  usersList,
  rolesList,
  usersBelongToGroup,
  onChange,
  onUsersChange,
}) => {
  const groupRoles: RoleData[] = [];

  const handleTextFieldChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, value } = event.target;
    const newGroupData = { ...groupData };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    newGroupData[name] = value;
    onChange(newGroupData);
  };

  const handleCreationDate = (date: MaterialUiPickersDate) => {
    const newGroupData = { ...groupData, creationDate: date?.toISOString() };
    onChange(newGroupData);
  };

  const handleAutocompleteUsersChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: UserData[]
  ) => {
    const newUsersBelongToGroup = value ?? [];
    onUsersChange(newUsersBelongToGroup);
  };

  const handleAutocompleteRolesChange = (
    event: React.ChangeEvent<Record<string, unknown>>,
    value: RoleData[]
  ) => {
    const newGroupData = { ...groupData, roles: value };
    onChange(newGroupData);
  };

  groupData?.roles?.forEach((role) => {
    if (role != null) groupRoles.push(role);
  });

  return (
    <Pane>
      <SC.Grid container spacing={2} MarginTop={0}>
        <SC.Grid item xs={6} MarginTop={0}>
          <SC.Box disabledMarginTop>
            <TextField
              name="name"
              label="Name"
              autoComplete="off"
              value={groupData?.name}
              fullWidth
              required
              disabled={!isEditable}
              onChange={handleTextFieldChange}
            />
          </SC.Box>
        </SC.Grid>
        <SC.Grid item xs={6} MarginTop={0}>
          <SC.Box disabledMarginTop>
            <DateTimeField
              value={groupData?.creationDate}
              label="Creation Date"
              disabled
              fullWidth
              required
              onChange={handleCreationDate}
            />
          </SC.Box>
        </SC.Grid>
      </SC.Grid>
      <SC.Grid container spacing={2}>
        <SC.Grid item xs={6} MarginTop={0}>
          <SC.Box disabledMarginTop style={{ height: "100%" }}>
            <TextField
              name="description"
              label="Description"
              value={groupData?.description}
              fullWidth
              multiline
              rows={3}
              rowsMax={3}
              disabled={!isEditable}
              onChange={handleTextFieldChange}
            />
          </SC.Box>
        </SC.Grid>
        <SC.Grid item xs={6} MarginTop={-28}>
          <Autocomplete<RoleData, true, false, false>
            multiple
            options={rolesList}
            value={groupData?.roles as RoleData[]}
            disabled={!isEditable}
            getOptionLabel={(userGroup) => userGroup.name ?? ""}
            getOptionSelected={(option, value) => option.id === value.id}
            textFieldProps={{
              label: "Roles",
            }}
            onChange={handleAutocompleteRolesChange}
          />
        </SC.Grid>
        <SC.Grid item xs={6} />
        <SC.Grid item xs={6} MarginTop={-32}>
          <Autocomplete<UserData, true, false, false>
            multiple
            options={usersList}
            value={usersBelongToGroup}
            disabled={!isEditable}
            getOptionLabel={(user) =>
              `${user.firstName} ${user.lastName}` ?? ""
            }
            getOptionSelected={(option, value) => option.id === value.id}
            textFieldProps={{
              label: "Users",
            }}
            onChange={handleAutocompleteUsersChange}
          />
        </SC.Grid>
      </SC.Grid>
    </Pane>
  );
};

export default GroupFormFields;
