import { TableColumn } from "components/table/table.component";
import {
  KccaCustomerPrimaryOwnerFragment,
  PropertiesValuationFragment,
} from "graphql/hasura/types-and-hooks";

export type KccaCustomerPrimaryOwnerTableSchema = Omit<
  KccaCustomerPrimaryOwnerFragment,
  "__typename"
>;

export const ResidentialSubSFHColumns: TableColumn<
  PropertiesValuationFragment | Record<string, unknown>
>[] = [
  {
    Header: "Village",
    accessor: "Village",
    align: "left",
    format: "string",
  },
  {
    Header: "No. of Bedrooms",
    accessor: "Derivednoofbedrooms",
    align: "center",
    format: "string",
  },
  {
    Header: "Monthly Gazette Value",
    accessor: "Monthlygazettevalue",
    align: "right",
    format: "currency",
  },
  {
    Header: "Annual Gazette Value",
    accessor: "Annualgazettevalue",
    align: "right",
    format: "currency",
  },
];

export const CommercialColumns: TableColumn<
  PropertiesValuationFragment | Record<string, unknown>
>[] = [
  {
    Header: "Village",
    accessor: "Village",
    align: "left",
    format: "string",
  },
  {
    Header: "Building Area (m2)",
    accessor: "Squaremeters",
    align: "center",
    format: "string",
  },
  {
    Header: "Monthly Charge per Square Meter (UGX)",
    accessor: "MonthlyChargeSquare",
    align: "right",
    format: "currency",
  },
  {
    Header: "Monthly Charge (UGX)",
    accessor: "MonthlyCharge",
    align: "right",
    format: "currency",
  },
  {
    Header: "Annual Charge (UGX)",
    accessor: "AnnualCharge",
    align: "right",
    format: "currency",
  },
];
