/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles, Box as BoxBase } from "@material-ui/core/";

const Box = withStyles((theme: any) => ({
  root: {
    marginTop: theme.spacing(4),
  },
}))(BoxBase);

const PasswordContainer = withStyles((theme: any) => ({
  root: {
    marginTop: theme.spacing(4),
    width: "14rem",
  },
}))(BoxBase);

export default {
  Box,
  PasswordContainer,
};
