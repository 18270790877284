/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Paper as PaperBase,
  Typography as TypographyBase,
  List as ListBase,
  ListItem as ListItemBase,
  ListItemText as ListItemTextBase,
  withStyles,
} from "@material-ui/core";

import PropertyDetailsItemBase from "components/property-details-item/property-details-item.component";

const Paper = withStyles((theme: any) => ({
  root: {
    textAlign: "left",
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),

    "& .MuiGrid-container:not(:last-child)": {
      borderBottom: theme.propertyDetails.item.marginBottom,
      paddingTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
}))(PaperBase);

const Typography = withStyles((theme: any) => ({
  root: {
    fontSize: theme.propertyDetails.title.fontSize,
    fontWeight: theme.propertyDetails.title.fontWeight,
    marginBottom: theme.spacing(1),
  },
}))(TypographyBase);

const List = ListBase;
const ListItem = ListItemBase;
const ListItemText = ListItemTextBase;

const PropertyDetailsItem = withStyles(() => ({
  root: {
    padding: "1rem",
  },
}))(PropertyDetailsItemBase);

export default {
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  PropertyDetailsItem,
};
