// Dependencies
import React, { useMemo } from "react";
import Table from "components/table/table.component";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rpt_KccaCustomerRegistrationDataArgs,
  useKccaCustomerSecondaryOwnerQuery,
} from "graphql/hasura/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Schema
import { kccaCustomerSecondaryOwnerTableColumns } from "./secondary-table.schema";

interface PropertyProps {
  propertyIdentifier: string;
}

const SecondaryTable: React.FC<PropertyProps> = ({ propertyIdentifier }) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Rpt_KccaCustomerRegistrationDataArgs =
    {
      where: { Id: { _eq: propertyIdentifier } },
    };

  const { data: dataSecondaryOwner, error: errorSecondaryOwner } =
    useKccaCustomerSecondaryOwnerQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !propertyIdentifier,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
      },
    });

  useAppErrorHandler(errorSecondaryOwner);

  const kccaSecondaryOwnerData = React.useMemo(() => {
    const kcca =
      dataSecondaryOwner?.rtcs_db_FnRPT_KccaCustomerRegistrationData ?? [];
    return kcca;
  }, [dataSecondaryOwner]);

  const columnsSecondary = useMemo(
    () => kccaCustomerSecondaryOwnerTableColumns,
    []
  );

  return (
    <Table
      // stickyHeader
      isPaginationHidden
      onAction={() => {}}
      title="Secondary"
      initialRowsPerPage={10000}
      columns={columnsSecondary}
      data={kccaSecondaryOwnerData}
    />
  );
};

export default SecondaryTable;
