import React, { ChangeEvent, useState } from "react";
import BalanceSheetList, {
  StructureInterface,
} from "../balance-sheet-list/balance-sheet-list.component";

// Assets
import SC from "./balance-sheet-accordion.styles";

export interface BalanceSheetProps {
  // eslint-disable-next-line react/require-default-props
  slug?: string;
  structure: StructureInterface[];
}

const BalanceSheetAccordion: React.FC<BalanceSheetProps> = ({
  slug = "balance-sheet",
  structure,
}) => {
  const [expanded, setExpanded] = useState<string | false>(slug);

  const handleChange =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (panel: string) => (event: ChangeEvent<any>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <SC.Container>
      <SC.Accordion
        expanded={expanded === slug}
        onChange={handleChange(slug)}
        TransitionProps={{ unmountOnExit: true }}
      >
        <SC.AccordionSummary
          expandIcon={<SC.ExpandMore />}
          aria-controls="balan-content"
          id={`${slug}-header`}
        >
          <SC.Grid container justify="space-between">
            {structure.slice(0, 3).map(({ label, value }) => (
              <>
                <SC.Grid item xs={6}>
                  <SC.AccordionTitle variant="h6">{label}</SC.AccordionTitle>
                </SC.Grid>
                <SC.Grid item xs={6} wrap="nowrap">
                  <SC.AccordionTitleDetails variant="h6">
                    {value}
                  </SC.AccordionTitleDetails>
                </SC.Grid>
              </>
            ))}
          </SC.Grid>
        </SC.AccordionSummary>
        <SC.AccordionDetails>
          <SC.Container>
            <BalanceSheetList
              structure={structure.slice(3, structure.length)}
            />
          </SC.Container>
        </SC.AccordionDetails>
      </SC.Accordion>
    </SC.Container>
  );
};

export default BalanceSheetAccordion;
