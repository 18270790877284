/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react-hooks/exhaustive-deps */
// Dependencies
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import InfiniteScroller from "react-infinite-scroller";
import { Snackbar } from "@material-ui/core";

// Components
import { Alert, AlertProps } from "components/alert/alert.component";
import {
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

// GraphQL
import {
  MutationAddNoteArgs as AddNoteData,
  useAddNoteMutation,
  useGetNoteLazyQuery,
  Maybe,
} from "graphql/types-and-hooks";
import { createEmptyNoteData, NEW_NOTE_FRAGMENT_GQL } from "graphql/rbac.utils";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Assets
import SC from "./notes-tab.styles";

type CreationDateType = Maybe<string> | undefined;

const NotesTab: React.FC = () => {
  const { repId: rID = "", noteID = "" } = useParams<{
    repId: string;
    noteID: string;
  }>();
  const [openAddNoteForm, setOpenAddNoteForm] = React.useState(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [hasMoreNote, setHasMoreNote] = useState(true);
  const [creationDate, setCreationDate] = useState<CreationDateType>("");

  const [
    GetNoteFn,
    {
      loading: notesDataLoading,
      data: notesData,
      error: errorNotesDataLoading,
    },
  ] = useGetNoteLazyQuery({
    variables: { rID },
  });

  React.useEffect(() => {
    const id = noteID ? `#${noteID}` : undefined;
    GetNoteFn({ variables: { rID, id } });
  }, []);

  React.useEffect(() => {
    if (!notesData) return;

    if (notesData.GetNote.length < 10) {
      if (notesData.GetNote.length > 0) {
        const { creationDate: offset } = notesData.GetNote?.slice(-1)[0];
        setCreationDate(offset);
      }
      setHasMoreNote(false);
    }
  }, [notesData?.GetNote]);

  const errorHandler = useAppErrorHandler(errorNotesDataLoading);

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const [createNote, { loading: createNoteLoading }] = useAddNoteMutation({
    update(cache, { data }) {
      const newNote = data?.AddNote[0];
      if (newNote) {
        cache.modify({
          fields: {
            GetNote(existingNotes = []) {
              const newNoteRef = cache.writeFragment({
                id: cache.identify(newNote),
                data: newNote,
                fragment: NEW_NOTE_FRAGMENT_GQL,
              });
              return [newNoteRef, ...existingNotes];
            },
          },
        });
      }
    },
  });

  const handleOpenAddNoteForm = () => setOpenAddNoteForm(true);

  const handleCancelAddNote = () => setOpenAddNoteForm(false);

  const handleAddNoteFormOnSubmit = React.useCallback(
    async (noteData: AddNoteData) => {
      try {
        await createNote({
          variables: {
            comment: noteData.comment ?? "",
            rID: noteData.rID ?? "",
            attachments: [...(noteData.attachments || [])],
          },
        });
        setOpenAddNoteForm(false);
        setSnackBarMessage({
          message: "The note has been created successfully",
          security: "success",
        });
      } catch (error) {
        errorHandler(error as Error);
      }
    },
    [createNote, errorHandler]
  );

  const notes = notesData?.GetNote;

  const handleNextPageLoad = () => {
    if (notesDataLoading) return;

    if (notes && notes.length > 0) {
      const { creationDate: offset } = notes?.slice(-1)[0];

      if (offset === creationDate) return;

      setCreationDate(offset);

      GetNoteFn({
        variables: {
          rID,
          offset,
        },
      });
    }
  };

  const loading = createNoteLoading;

  return (
    <SC.Container maxWidth="xl">
      <Snackbar
        open={!!snackBarMessage}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={snackBarMessage?.severity}
          message={snackBarMessage?.message}
        />
      </Snackbar>

      <SC.ProgressIndicator open={loading} />

      <SC.TitleActionsContainer>
        <SC.Title variant="subtitle1">Notes</SC.Title>
        <AppPermissionValidator appPermission={APP_PERMISSION.PROPERTIES_EDIT}>
          <SC.IconButton onClick={handleOpenAddNoteForm}>
            <SC.Add />
          </SC.IconButton>
        </AppPermissionValidator>
      </SC.TitleActionsContainer>

      {openAddNoteForm && (
        <SC.NoteCreate
          initialNoteData={createEmptyNoteData()}
          onSubmit={handleAddNoteFormOnSubmit}
          onCancel={handleCancelAddNote}
        />
      )}
      <InfiniteScroller
        loadMore={handleNextPageLoad}
        hasMore={hasMoreNote}
        initialLoad={false}
        loader={
          <div className="loader" key={0}>
            Loading...
          </div>
        }
      >
        {notes?.map((note) => (
          <SC.Note key={note.id ?? ""} note={note} />
        ))}
      </InfiniteScroller>
    </SC.Container>
  );
};

export default NotesTab;
