// dependencies
/* eslint-disable no-alert */
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rcapture_RpTlessorsArgs,
  useRCapturePropertyLessorsQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

// Utils
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import { columns } from "./lessors.schema";

// Assets
import SC from "./lessors.styles";

const TABLE_NAME = "rtcs_db_Fnrcapture_RPTlessors";

const RCaptureLessors: React.FC = () => {
  const { lessorId } = useParams<{ lessorId: string }>();

  const queryVariables: Query_RootRtcs_Db_Ph_Rcapture_RpTlessorsArgs = {
    where: { Id: { _eq: lessorId } },
  };

  // Tenants
  const { data, loading, error } = useRCapturePropertyLessorsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const lessors = useMemo(() => {
    if (data?.rtcs_db_Fnrcapture_RPTlessors.length === 0) return [];

    const lessorData = data?.rtcs_db_Fnrcapture_RPTlessors[0];

    if (!lessorData) return [];

    const newData = _.omit(lessorData, "__typename");

    const lessorsData = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(newData)) {
      lessorsData.push({
        question: key,
        answertext: value,
      });
    }

    return lessorsData;
  }, [data]);

  return (
    <>
      <SC.ProgressIndicator open={loading || exportLoading} />
      <SC.Breadcrumb label="MDA" />
      <SC.Table
        title="Lessor Info"
        actionsOnRight={[]}
        onAction={() => alert("under construction")}
        persistenceId="b02c46fb-9ef9-45ac-a423-5e6fb8dddd6b"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        isPaginationHidden
        initialRowsPerPage={59}
        data={lessors}
        exportData={exportDataFn}
      />
    </>
  );
};

export default RCaptureLessors;
