import React, { useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRiskArgs,
  Order_By,
  Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column,
  useGetPaginatedNonIndividualReportsQuery,
  useNonIndividualReportsFilterQuery,
  useGetMaxMinNonIndividualReportsQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

import {
  aggregateArrayToObject,
  jsonToStringQueryVariables,
} from "graphql/hasura/rtcs.utils";

// Components
import Table, {
  TableFetchDataFunction,
  INITIAL_ROWS_PER_PAGE,
} from "components/table/table.component";
import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import {
  FilterCondition,
  TableFilter,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import { taxpayersColumns, getAccessorType } from "./non-individuals.schema";

// Assets
import SC from "./non-individuals.styles";

const ReportType = { Compliancecategory: { _eq: "NO MATCH" } };

const TABLE_NAME = "rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk";

const UnregisteredLandlordNonIndividual: React.FC = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    | Query_RootRtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRiskArgs
    | undefined
  >({
    where: { ...ReportType },
    limit: 0,
  });

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useGetPaginatedNonIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables?.limit,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  const {
    data: maxMinData,
    loading: maxMinLoading,
    error: maxMinError,
  } = useGetMaxMinNonIndividualReportsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    variables: {
      params: jsonToStringQueryVariables({ where: { ...ReportType } }),
    },
  });

  useAppErrorHandler(error || maxMinError || exportError);

  const taxpayersData = React.useMemo(() => {
    const taxpayers =
      data?.rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk ?? [];

    return taxpayers;
  }, [data]);

  const columns = useMemo(() => taxpayersColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    ({ pageIndex, pageSize, sortBy }) => {
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column.RiskScore,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm[0]
      .value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData]
  );

  // TODO: Replace for useLazyQuery when the function has been updated
  // to return a Promise instead of void.
  // https://github.com/apollographql/react-apollo/issues/3499#issuecomment-639954192
  const { refetch: IndividualFilterFn } = useNonIndividualReportsFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRiskArgs =
            {
              where: { [column]: { _ilike: `${value}%` }, _and: [ReportType] },
              distinct_on: [
                column as Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column,
              ],
              limit: 10,
            };
          const individualFilterData = await IndividualFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions =
            individualFilterData.data?.rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column]
            );
        }

        // eslint-disable-next-line @typescript-eslint/return-await
        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [IndividualFilterFn]
  );

  const newObj = aggregateArrayToObject(
    maxMinData?.rtcs_db_FnRPT_NonIndividualComplianceSummaryPlusRisk_aggregatecm
  );

  const minTotalRentedProperties = newObj?.['min("Numrentedproperties")'];

  const maxTotalRentedProperties = newObj?.['max("Numrentedproperties")'];

  const minSumofadjustedincome = newObj?.['min("Sumofadjustedincome")'];

  const maxSumofadjustedincome = newObj?.['max("Sumofadjustedincome")'];

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.AUTOFILL,
        label: "Entity Legal Name",
        columnName:
          Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column.Kccaname,
        fetchSuggestions,
      },
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Total Number of Rented Properties",
        min: minTotalRentedProperties,
        max: maxTotalRentedProperties,
        columnName:
          Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column.Numrentedproperties,
      },
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Adjusted Gross Rental Income",
        min: minSumofadjustedincome,
        max: maxSumofadjustedincome,
        columnName:
          Rtcs_Db_Ph_Rpt_NonIndividualComplianceSummaryPlusRisk_Select_Column.Sumofadjustedincome,
      },
    ];
  }, [
    fetchSuggestions,
    maxSumofadjustedincome,
    maxTotalRentedProperties,
    minSumofadjustedincome,
    minTotalRentedProperties,
  ]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: [...conditions, ReportType],
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  return (
    <>
      <SC.ProgressIndicator open={loading || maxMinLoading || exportLoading} />
      <Breadcrumb label="Analytics" />
      <Table
        actionsOnRight={[
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        title="Unregistered Landlords"
        columns={columns}
        data={taxpayersData}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        leftPanel={
          <TableFilter
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            defaultUniversalFilterConditions={[ReportType]}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        persistenceId="c2a47101-1812-4747-9185-f4112944f406"
        paginationControlled={paginationControlled}
        stickyHeader
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
        exportData={exportDataFn}
      />
    </>
  );
};

export default UnregisteredLandlordNonIndividual;
