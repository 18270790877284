/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import SC from "./nested-list.styles";

export type ListObject = {
  label: string;
  value: string;
  level: number;
  format: string | "currency" | "date" | "string";
};

interface ListObjectInterface {
  data?: ListObject[];
}

const NestedList: React.FC<ListObjectInterface> = ({ data }) => {
  return (
    <SC.List aria-label="secondary">
      {data?.map((item) => (
        <SC.NestListItem {...item} key={item.label} level={item.level} />
      ))}
    </SC.List>
  );
};

export default NestedList;
