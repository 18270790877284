/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import UraAffiliates from "./ura-affiliates-table/ura-affiliates-table.component";
import KccaAffiliates from "./kcca-affiliates-table/kcca-affiliates-table.component";

export interface AffiliatesProps {
  rnid?: string;
  customerId?: string;
}

const Affiliates: React.FC<AffiliatesProps> = ({ rnid, customerId }) => {
  return (
    <>
      <UraAffiliates rnid={rnid} />
      <KccaAffiliates customerId={customerId} />
    </>
  );
};

export default Affiliates;
