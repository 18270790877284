/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import { LandlordIncomeSummaryFragment } from "graphql/hasura/types-and-hooks";
import _ from "lodash";
import FormattedValue from "components/formatted-value/formatted-value";

export type LandlordIncomeSummaryTableSchema = Omit<
  LandlordIncomeSummaryFragment,
  "__typename"
>;

export const LANDLORD_INCOME_SUMMARY_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof LandlordIncomeSummaryTableSchema,
  "string" | "number" | "Date"
> = {
  LandlordTin: "string",
  LandlordTaxpayerName: "string",
  TaxYear: "string",
  ReturnNo: "string",
  ReturnPeriodFrom: "Date",
  ReturnPeriodTo: "Date",
  RentReportedByTenants: "number",
  GrossRentalIncome: "number",
  RentDeclaredByLandlord: "number",
  RmatchScore: "number",
  Difference: "number",
  DifferencePercentage: "string",
  GrossDifference: "number",
  GrossDifferencePercentage: "string",
  LandlordTinSource: "string",
  ReturnNoSource: "string",
};

export const getAccessorType = (
  accessor:
    | keyof LandlordIncomeSummaryTableSchema
    | Accessor<LandlordIncomeSummaryTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return LANDLORD_INCOME_SUMMARY_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const LandlordIncomeSummaryColumns: TableColumn<LandlordIncomeSummaryTableSchema>[] =
  [
    {
      Header: "Landlord TIN",
      accessor: "LandlordTin",
      align: "center",
      Cell: ({
        value,
        row: {
          values: { LandlordTin, LandlordTinSource },
        },
      }) => {
        if (LandlordTinSource === "Individual") {
          return (
            <Link to={`/individuals/${LandlordTin}/analysis`} color="inherit">
              {value}
            </Link>
          );
        }
        return (
          <Link to={`/non-individuals/${LandlordTin}/analysis`} color="inherit">
            {value}
          </Link>
        );
      },
    },
    {
      Header: "Landlord Taxpayer Name",
      accessor: "LandlordTaxpayerName",
      align: "left",
      format: "string",
    },
    {
      Header: "Tax Year",
      accessor: "TaxYear",
      align: "center",
      format: "string",
    },
    {
      Header: "Return No.",
      accessor: "ReturnNo",
      align: "center",
      format: "string",
    },
    {
      Header: "Return Period From",
      accessor: "ReturnPeriodFrom",
      align: "center",
      format: "date",
    },
    {
      Header: "Return Period To",
      accessor: "ReturnPeriodTo",
      align: "center",
      format: "date",
    },
    {
      Header: "Rent Income Reported by Tenant",
      accessor: "RentReportedByTenants",
      align: "right",
      Cell: ({
        value,
        row: {
          values: { LandlordTin, TaxYear },
        },
        cell,
      }) => {
        return (
          <Link to={`tenant/${LandlordTin}/${TaxYear}/detail`} color="inherit">
            {cell.row.id === "0" ? (
              <FormattedValue format="currency" value={value} />
            ) : (
              <FormattedValue value={value} format="number" />
            )}
          </Link>
        );
      },
    },
    {
      Header: "Rent Income Declared by Landlord",
      accessor: "RentDeclaredByLandlord",
      align: "right",
      Cell: ({
        value,
        row: {
          values: { ReturnNo },
        },
        cell,
      }) => {
        return (
          <Link to={`landlord/${ReturnNo}/detail`} color="inherit">
            {cell.row.id === "0" ? (
              <FormattedValue format="currency" value={value} />
            ) : (
              <FormattedValue value={value} format="number" />
            )}
          </Link>
        );
      },
    },
    {
      Header: "rMATCH Score",
      accessor: "RmatchScore",
      align: "center",
      format: "string",
    },
    {
      Header: "Difference - Tenant Report vs Landlord Declared",
      accessor: "Difference",
      align: "right",
      format: "currency",
    },
    {
      Header: "Diff %",
      accessor: "DifferencePercentage",
      align: "center",
      format: "percent",
    },

    {
      Header: "Landlord TIN Source",
      accessor: "LandlordTinSource",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Return No. Source",
      accessor: "ReturnNoSource",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
  ];
