import {
  withStyles,
  List as ListBase,
  Box,
  Typography as TypographyBase,
} from "@material-ui/core";
import NestListItem from "../nested-list-item/nested-list-item.component";

const Typography = withStyles({
  root: {
    fontWeight: "bold",
  },
})(TypographyBase);

const List = withStyles({
  root: {
    margin: 0,
    paddingRight: 0,
    paddingBottom: 0,
    paddingTop: `2.28rem`,
    "& li ": {
      width: "78%",
    },
  },
})(ListBase);

export default {
  List,
  NestListItem,
  Typography,
  Box,
};
