import Table from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";
import ImagePlaceholder from "components/image-placeholder/image-placeholder.component";
import { Grid, Card, CardMedia, CardContent } from "@material-ui/core";

export default {
  Table,
  ProgressIndicator,
  Grid,
  Breadcrumb,
  Card,
  CardMedia,
  CardContent,
  ImagePlaceholder,
};
