/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  withStyles,
  makeStyles,
  List,
  ListItem as ListItemBase,
  ListItemProps,
  ListItemText as ListItemTextBase,
  ListItemTextProps,
  ListItemSecondaryAction as ListItemSecondaryActionBase,
} from "@material-ui/core";
import FormatedValue from "components/formatted-value/formatted-value";

interface CustomListItemProps extends ListItemProps {
  level?: number;
  has_items: boolean;
}

interface CustomListItemTextProps extends ListItemTextProps {
  isTitle?: boolean;
  isMega?: boolean;
}

const useListItemStyles = makeStyles((theme: any) => ({
  root: {
    borderBottom: theme.balanceSheet.listItem.borderBottom,
    paddingTop: theme.balanceSheet.listItem.paddingTop,
    paddingBottom: theme.balanceSheet.listItem.paddingBottom,
    paddingLeft: ({ level = 0, has_items }: CustomListItemProps) => {
      return has_items && level <= 1 ? 0 : `${level * 2.28}em`;
    },
  },
}));

const ListItem = (props: CustomListItemProps): JSX.Element => {
  const { ...muiProps } = props;
  const classes = useListItemStyles(props);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ListItemBase className={classes.root} {...muiProps} />;
};
ListItem.defaultProps = {
  level: 0,
};

const ListItemText = withStyles((theme: any) => ({
  root: {
    margin: 0,
    "& .MuiListItemText-primary": {
      fontWeight: ({ isTitle }: CustomListItemTextProps) => {
        if (isTitle) return "bold";
        return "normal";
      },
      fontSize: ({ isMega }: CustomListItemTextProps) => {
        if (isMega) return "1.4286rem";
        return theme.nestedListItem.fontSize;
      },
      lineHeight: theme.balanceSheet.listText.lineHeight,
    },
  },
}))(ListItemTextBase);

const ListItemSecondaryAction = withStyles((theme: any) => ({
  root: {
    right: theme.balanceSheet.ListItemSecondaryAction.right,
  },
}))(ListItemSecondaryActionBase);

export default {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  FormatedValue,
};
