/* eslint-disable @typescript-eslint/ban-ts-comment */

import React from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Kcca_CustomerArgs,
  Query_RootRtcs_Db_Ph_Nira_RawDataArgs,
  Query_RootRtcs_Db_Ph_Rpt_IndividualCompositeArgs,
  useKccaCustomerCountyQuery,
  KccaCustomerCountyQuery,
  useKccaCustomerDistrictQuery,
  KccaCustomerDistrictQuery,
  useKccaCustomerParishQuery,
  KccaCustomerParishQuery,
  useKccaCustomerQuery,
  useKccaCustomerSubcountyQuery,
  KccaCustomerSubcountyQuery,
  useKccaCustomerVillageQuery,
  KccaCustomerVillageQuery,
  useNiraDataQuery,
  useUraDataQuery,
  Maybe,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ListAccordion from "components/list-accordion/list-accordion.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

export interface CustomerIdentification {
  index: number;
  source: string;
  customerId?: string;
  fullnames: string;
  data: {
    index: number;
    label: string;
    value: Maybe<string> | undefined;
    format?: "date" | "currency";
  }[];
}

export interface IdentificationTabProps {
  customerIds: string;
  tin: string;
}

const IdentificationTab: React.FC<IdentificationTabProps> = ({
  customerIds,
  tin,
}: IdentificationTabProps) => {
  const queryVariablesKccaCustomerIndividual: Query_RootRtcs_Db_Ph_Kcca_CustomerArgs =
    {
      where: {
        // @ts-ignore
        Id: { _eq: customerIds.split(",") },
      },
    };

  const {
    data: kccaCustomerIndividualData,
    error: kccaCustomerIndividualError,
  } = useKccaCustomerQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customerIds.length,
    variables: {
      params: jsonToStringQueryVariables(queryVariablesKccaCustomerIndividual),
    },
  });

  const customers = kccaCustomerIndividualData?.rtcs_db_Fnkcca_Customer;

  const queryVariablesNira: Query_RootRtcs_Db_Ph_Nira_RawDataArgs = {
    where: { TinNumber: { _eq: tin } },
    limit: 1,
  };

  const queryVariablesUra: Query_RootRtcs_Db_Ph_Rpt_IndividualCompositeArgs = {
    where: { UraTinNo: { _eq: tin } },
    limit: 1,
  };

  const { data: kccaVillageData, error: kccaVillageDataError } =
    useKccaCustomerVillageQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !customers,
    });

  const { data: kccaParishData, error: kccaParishDataError } =
    useKccaCustomerParishQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !customers,
    });

  const { data: kccaSubcountyData, error: kccaSubcountyDataError } =
    useKccaCustomerSubcountyQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !customers,
    });

  const { data: kccaCountyData, error: kccaCountyDataError } =
    useKccaCustomerCountyQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !customers,
    });

  const { data: kccaDistrictData, error: kccaDistrictDataError } =
    useKccaCustomerDistrictQuery({
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !customers,
    });

  const { data: nira, error: niraError } = useNiraDataQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin,
    variables: {
      params: jsonToStringQueryVariables(queryVariablesNira),
    },
  });

  const { data: ura, error: uraError } = useUraDataQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin,
    variables: {
      params: jsonToStringQueryVariables(queryVariablesUra),
    },
  });

  useAppErrorHandler(
    kccaCustomerIndividualError ||
      kccaVillageDataError ||
      kccaParishDataError ||
      kccaSubcountyDataError ||
      kccaCountyDataError ||
      kccaDistrictDataError ||
      niraError ||
      uraError
  );

  const getkccaCountyName = (
    kccaCustomerTypesData: KccaCustomerCountyQuery | undefined,
    CountyId: string | undefined
  ) => {
    if (CountyId) {
      return kccaCustomerTypesData?.rtcs_db_Fnkcca_County.filter(
        (county) => county.Id === CountyId
      )[0]?.Name;
    }

    return "";
  };

  const getkccaVillageName = (
    kccaCustomerTypesData: KccaCustomerVillageQuery | undefined,
    VillageId: string | undefined
  ) => {
    if (VillageId) {
      return kccaCustomerTypesData?.rtcs_db_Fnkcca_Village.filter(
        (village) => village.Id === VillageId
      )[0]?.Name;
    }

    return "";
  };

  const getkccaParishName = (
    kccaCustomerTypesData: KccaCustomerParishQuery | undefined,
    ParishId: string | undefined
  ) => {
    if (ParishId) {
      return kccaCustomerTypesData?.rtcs_db_Fnkcca_Parish.filter(
        (parish) => parish.Id === ParishId
      )[0]?.Name;
    }

    return "";
  };

  const getkccaSubCountyName = (
    kccaCustomerTypesData: KccaCustomerSubcountyQuery | undefined,
    SubCountyId: string | undefined
  ) => {
    if (SubCountyId) {
      return kccaCustomerTypesData?.rtcs_db_Fnkcca_SubCounty.filter(
        (subCounty) => subCounty.Id === SubCountyId
      )[0]?.Name;
    }

    return "";
  };

  const getkccaDistrictName = (
    kccaCustomerTypesData: KccaCustomerDistrictQuery | undefined,
    DistrictId: string | undefined
  ) => {
    if (DistrictId) {
      return kccaCustomerTypesData?.rtcs_db_Fnkcca_District.filter(
        (district) => district.Id === DistrictId
      )[0]?.Name;
    }

    return "";
  };

  let customerData: CustomerIdentification[] = [];

  const niraIndividual = nira?.rtcs_db_Fnnira_RawData?.[0];

  const uraIndividual = ura?.rtcs_db_FnRPT_IndividualComposite?.[0];

  if (customers && customers.length > 0) {
    // @ts-ignore
    customerData = customers.map((customer) => {
      const customerVillage = getkccaVillageName(
        kccaVillageData,
        customer.ResidentialAddressVillageId
      );

      const customerParish = getkccaParishName(
        kccaParishData,
        customer.ResidentialAddressParishId
      );

      const customerSubCounty = getkccaSubCountyName(
        kccaSubcountyData,
        customer.ResidentialAddressSubCountyId
      );

      const customerCounty = getkccaCountyName(
        kccaCountyData,
        customer.ResidentialAddressCountyId
      );

      const customerDistrict = getkccaDistrictName(
        kccaDistrictData,
        customer.ResidentialAddressDistrictId
      );

      const data = {
        index: 0,
        source: "Kampala Capital City Authority (KCCA) ",
        customerId: customer?.Id ?? "",
        fullnames: `${customer.Firstname} ${customer.Surname ?? ""}`,
        data: [
          {
            index: 0,
            label: "First Name",
            value: customer.Firstname,
          },
          {
            index: 1,
            label: "Middle Name",
            value: customer.MiddleName,
          },
          {
            index: 2,
            label: "Surname",
            value: customer.Surname ?? "",
          },
          { index: 3, label: "Email", value: customer.Email },
          {
            index: 4,
            label: "Mothers Maiden Name",
            value: customer.MotherMaidenName,
          },
          { index: 5, label: "COIN No.", value: customer.Coin },
          { index: 6, label: "TIN No.", value: customer.Tin },
          {
            index: 7,
            label: "National ID No.",
            value: customer.IdentificationNationalId,
          },
          {
            index: 8,
            label: "NSSF ID No.",
            value: customer.IdentificationNssfNumber,
          },
          {
            index: 9,
            label: "Passport No.",
            value: customer.IdentificationPassportNo,
          },
          { index: 10, label: "Plot No.", value: customer.PlotNumber },
          {
            index: 11,
            label: "Street Name",
            value: customer.StreetName,
          },
          {
            index: 12,
            label: "Village",
            value: customerVillage,
          },
          { index: 13, label: "Parish", value: customerParish },
          {
            index: 14,
            label: "Subcounty",
            value: customerSubCounty,
          },
          { index: 15, label: "County", value: customerCounty },
          {
            index: 16,
            label: "District",
            value: customerDistrict,
          },
          {
            index: 17,
            label: "Birth Date",
            value: customer.Birthdate,
            format: "date",
          },
          { index: 17, label: "Mobile 1 No.", value: customer.Mobile },
          { index: 18, label: "Mobile 2 No.", value: customer.SecondMobile },
          { index: 19, label: "Mobile 3 No.", value: customer.Telephone },
        ],
      };

      return data;
    });
  }

  if (niraIndividual) {
    customerData.push({
      index: 1,
      source: "National Identification And Registration Authority (NIRA)",
      fullnames: `${niraIndividual.GivenName} ${niraIndividual.Surname}`,
      customerId: undefined,
      data: [
        {
          index: 0,
          label: "National ID",
          value: niraIndividual?.NationalID ?? "",
        },

        {
          index: 1,
          label: "First Name",
          value: niraIndividual?.GivenName ?? "",
        },

        {
          index: 2,
          label: "Surname",
          value: niraIndividual?.Surname ?? "",
        },

        {
          index: 3,
          label: "Maiden Name",
          value: "",
        },
        {
          index: 4,
          label: "Date of Birth",
          value: niraIndividual?.DateOfBirth ?? "",
          format: "date",
        },
        {
          index: 5,
          label: "Phone",
          value: niraIndividual?.MobileNumber ?? "",
        },
        {
          index: 6,
          label: "Email",
          value: niraIndividual?.Email ?? "",
        },
      ],
    });
  }

  if (uraIndividual)
    customerData.push({
      index: 2,
      source: "Uganda Revenue Authority (URA)",
      fullnames: `${uraIndividual.Firstname} ${uraIndividual.Surname}`,
      customerId: undefined,
      data: [
        {
          index: 0,
          label: "Birth State",
          value: uraIndividual?.BirthState ?? "",
        },

        {
          index: 1,
          label: "Birth Country",
          value: uraIndividual?.BirthCountry ?? "",
        },

        {
          index: 2,
          label: "Business Name",
          value: uraIndividual?.BsnsName ?? "",
        },

        {
          index: 3,
          label: "Citizen Country",
          value: uraIndividual?.CitizenCountry ?? "",
        },
        {
          index: 4,
          label: "Existing TIN No. ",
          value: uraIndividual?.ExistTinNo ?? "",
        },
        {
          index: 5,
          label: "Birth Date",
          value: uraIndividual?.BirthDt ?? "",
          format: "date",
        },
      ],
    });

  return <ListAccordion data={customerData} />;
};

export default IdentificationTab;
