/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
// import { withStyles } from "@material-ui/core";
import { Container, ContainerProps, makeStyles } from "@material-ui/core";
import React from "react";

interface CustomContainerProps extends ContainerProps {
  maxHeight?: number;
}

const useContainerStyles = makeStyles(() => ({
  root: {
    "::-webkit-scrollbar": {
      width: "0px",
      background: "transparent" /* make scrollbar transparent */,
    },
    padding: 0,
    display: "flex",
    flexDirection: "column",
    maxHeight: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
  },
}));

const PageContent = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => {
    const { maxHeight, ...muiProps } = props;
    const classes = useContainerStyles(props);
    return <Container className={classes.root} {...muiProps} ref={ref} />;
  }
);

const Page = PageContent;

export default {
  Page,
  PageContent,
};
