/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { UraIndividualFragment } from "graphql/hasura/types-and-hooks";

export type IndividualTableSchema = Omit<UraIndividualFragment, "__typename">;

export const INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof IndividualTableSchema,
  "string" | "number" | "Date"
> = {
  TinNo: "string",
  TaxPayerType: "string",
  TaxPayerName: "string",
  TaxPayerId: "string",
  SurName: "string",
  RefTin: "string",
  RefSurname: "string",
  RefPrsnDesgn: "string",
  RefOtherName: "string",
  RefMobileNumber: "string",
  RefLandlineNumber: "string",
  RefFirstName: "string",
  RefFamilyName: "string",
  RefEmailId: "string",
  PostaddrId: "string",
  PostaddrDistictName: "string",
  OtherName: "string",
  NssfId: "string",
  NationalId: "string",
  MobileNumber: "string",
  MiddleName: "string",
  LandlineNumber: "string",
  IsIncomeSrcRental: "string",
  IsIncomeSrcPrpty: "string",
  IsIncomeSrcEmp: "string",
  IsIncomeSrcBsns: "string",
  HomeAddrVillageName: "string",
  HomeAddrTradeCentre: "string",
  HomeAddrTownName: "string",
  HomeAddrSubCountyName: "string",
  HomeAddrStrretAddr: "string",
  HomeAddrPlotno: "string",
  HomeAddrParishName: "string",
  HomeAddrLocalCouncil: "string",
  HomeAddrDistrictName: "string",
  HomeAddrBuildingname: "string",
  Gender: "string",
  FirstName: "string",
  FamilyName: "string",
  ExistTinNo: "string",
  EmailId: "string",
  CitizenCountry: "string",
  BsnsName: "string",
  BirthState: "string",
  BirthDt: "Date",
  BirthCountry: "string",
  TownName: "string",
  AuthctcdTin: "string",
  AuthctcdSurname: "string",
  AuthctcdPrsnDesgn: "string",
  AuthctcdOtherName: "string",
  AuthctcdMobileNumber: "string",
  AuthctcdLandlineNumber: "string",
  AuthctcdFirstName: "string",
  AuthctcdFamilyName: "string",
  AuthctcdEmailId: "string",
  AppliedForTin: "string",
};

export const getAccessorType = (
  accessor:
    | keyof IndividualTableSchema
    | Accessor<IndividualTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const individualTableColumns: TableColumn<IndividualTableSchema>[] = [
  {
    Header: "TIN No.",
    accessor: "TinNo",
    align: "center",
    Cell: ({
      value,
      row: {
        values: { TinNo },
      },
    }) => {
      return (
        <Link to={`/individuals/${TinNo}/identification`} color="inherit">
          {value}
        </Link>
      );
    },
    sort: true,
  },
  {
    Header: "First Name",
    accessor: "FirstName",
    align: "left",
    format: "string",
  },
  {
    Header: "Middle Name",
    accessor: "MiddleName",
    align: "left",
    format: "string",
  },
  {
    Header: "Surname",
    accessor: "SurName",
    align: "left",
    format: "string",
  },
  {
    Header: "Family Name",
    accessor: "FamilyName",
    align: "left",
    format: "string",
  },
  {
    Header: "Mobile Phone No.",
    accessor: "MobileNumber",
    align: "center",
    format: "string",
  },
  {
    Header: "Birth Date",
    accessor: "BirthDt",
    align: "center",
    format: "date",
  },
  {
    Header: "Email Address",
    accessor: "EmailId",
    align: "center",
    format: "string",
  },
  {
    Header: "National ID",
    accessor: "NationalId",
    align: "center",
    format: "string",
  },
  {
    Header: "Home District",
    accessor: "HomeAddrDistrictName",
    align: "center",
    format: "string",
  },
  {
    Header: "Home Parish",
    accessor: "HomeAddrParishName",
    align: "center",
    format: "string",
  },
  {
    Header: "Home Plot Number",
    accessor: "HomeAddrPlotno",
    align: "center",
    format: "string",
  },
  {
    Header: "Home Street Name",
    accessor: "HomeAddrStrretAddr",
    align: "center",
    format: "string",
  },
  {
    Header: "Home Sub-County",
    accessor: "HomeAddrSubCountyName",
    align: "center",
    format: "string",
  },
  {
    Header: "Home Town",
    accessor: "HomeAddrTownName",
    align: "center",
    format: "string",
  },
  {
    Header: "Is Income Source Rental?",
    accessor: "IsIncomeSrcRental",
    align: "center",
    format: "string",
  },
  {
    Header: "Previously applied for TIN?",
    accessor: "AppliedForTin",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Authenticator Email",
    accessor: "AuthctcdEmailId",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Authenticator Family Name",
    accessor: "AuthctcdFamilyName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Authenticator First Name",
    accessor: "AuthctcdFirstName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Authenticator Landline Phone No.",
    accessor: "AuthctcdLandlineNumber",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Authenticator Mobile Phone No.",
    accessor: "AuthctcdMobileNumber",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Authenticator Other Name",
    accessor: "AuthctcdOtherName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Authenticator Designation",
    accessor: "AuthctcdPrsnDesgn",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Authenticator Surname",
    accessor: "AuthctcdSurname",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Authenticator TIN",
    accessor: "AuthctcdTin",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Birth City",
    accessor: "TownName",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Birth Country",
    accessor: "BirthCountry",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Birth State",
    accessor: "BirthState",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Business Name",
    accessor: "BsnsName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Citizen Country",
    accessor: "CitizenCountry",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Existing TIN",
    accessor: "ExistTinNo",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Gender",
    accessor: "Gender",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Home Building Name",
    accessor: "HomeAddrBuildingname",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Home Local Council",
    accessor: "HomeAddrLocalCouncil",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Home Trade Centre",
    accessor: "HomeAddrTradeCentre",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Home Village",
    accessor: "HomeAddrVillageName",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Is Income Source Business?",
    accessor: "IsIncomeSrcBsns",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Is Income Source Employment?",
    accessor: "IsIncomeSrcEmp",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Is Income Source Property?",
    accessor: "IsIncomeSrcPrpty",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Landline Phone No.",
    accessor: "LandlineNumber",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "NSSF ID",
    accessor: "NssfId",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Other Name",
    accessor: "OtherName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Post Address District",
    accessor: "PostaddrDistictName",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Post Office Box No.",
    accessor: "PostaddrId",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Referee Email",
    accessor: "RefEmailId",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Referee Family Name",
    accessor: "RefFamilyName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Referee First Name",
    accessor: "RefFirstName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Referee Landline Phone No.",
    accessor: "RefLandlineNumber",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Referee Mobile Phone No.",
    accessor: "RefMobileNumber",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Referee Other Name",
    accessor: "RefOtherName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Referee Designation",
    accessor: "RefPrsnDesgn",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Referee Surname",
    accessor: "RefSurname",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Referee TIN",
    accessor: "RefTin",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Tax Payer ID",
    accessor: "TaxPayerId",
    align: "right",
    hiddenColumn: true,
    format: "number",
  },
  {
    Header: "Tax Payer Name",
    accessor: "TaxPayerName",
    align: "left",
    hiddenColumn: true,
    format: "string",
  },
  {
    Header: "Entity Type",
    accessor: "TaxPayerType",
    align: "center",
    hiddenColumn: true,
    format: "string",
  },
];
