/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  makeStyles,
  withStyles,
  MenuItem,
  MenuItemProps,
  ButtonProps,
  Box,
  Popover,
} from "@material-ui/core";
import { ArrowRight as ArrowRightIcon } from "@material-ui/icons";
import { LinkProps } from "react-router-dom";

// Functions
import { adaptativeSmallScreen } from "components/theme-provider/theme.utils";

const MenuItemStyles = makeStyles((theme: any) => ({
  root: {
    padding: "0",
    backgroundColor: ({ isOpen }: { isOpen: boolean }) =>
      isOpen ? theme.palette.action.hover : "transparent",
    [theme.breakpoints.down("xl")]: {
      width: (props: LinkMenuItemProps) =>
        props.to === undefined || props.parent
          ? adaptativeSmallScreen(16.725)
          : adaptativeSmallScreen(10.725),
    },
    [theme.breakpoints.up("xl")]: {
      width: (props: LinkMenuItemProps) =>
        props.to === undefined || props.parent ? "16.725rem" : "10.725rem",
    },
  },
}));

const LinkMenuItem: React.ComponentType<
  MenuItemProps & ButtonProps & LinkProps
> = MenuItem as React.ComponentType<MenuItemProps & ButtonProps & LinkProps>;

const ButtonMenuItem: React.ComponentType<MenuItemProps & ButtonProps> =
  MenuItem as React.ComponentType<MenuItemProps & ButtonProps>;

interface LinkMenuCustomProps {
  parent?: boolean;
}

type LinkMenuItemProps = MenuItemProps &
  ButtonProps &
  Omit<LinkProps, "to"> &
  LinkMenuCustomProps & {
    isOpen: boolean;
    to?: string;
    parent?: boolean;
  };

const AdministrationMenuItem = React.forwardRef<any, LinkMenuItemProps>(
  (props, ref) => {
    const { to, isOpen, parent, ...muiProps } = props;
    const classes = MenuItemStyles(props);

    return to ? (
      <LinkMenuItem to={to} className={classes.root} {...muiProps} ref={ref} />
    ) : (
      <ButtonMenuItem className={classes.root} {...muiProps} ref={ref} />
    );
  }
);

const ItemsContainer = withStyles((theme: any) => ({
  root: {
    [theme.breakpoints.down("xl")]: {
      fontSize: adaptativeSmallScreen(1),
      paddingTop: adaptativeSmallScreen(0.57),
      paddingLeft: adaptativeSmallScreen(1.14),
      paddingBottom: adaptativeSmallScreen(0.57),
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1rem",
      paddingTop: "0.57rem",
      paddingLeft: "1.14rem",
      paddingBottom: "0.57rem",
    },
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}))(Box);

const ParentName = withStyles({
  root: {
    paddingLeft: "0",
    paddingRight: 6,
  },
})(Box);

const ArrowIcon = withStyles((theme: any) => ({
  root: {
    [theme.breakpoints.down("xl")]: {
      fontSize: adaptativeSmallScreen(1.8),
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "1.8rem",
    },
  },
}))(ArrowRightIcon);

const MenuContent = withStyles({
  // "pointerEvents: none" to prevent invisible Popover wrapper div to capture mouse events
  root: {
    pointerEvents: "none",
  },
})(Popover);

const Content = withStyles({
  root: {
    pointerEvents: "auto",
    paddingTop: "8px",
    paddingBottom: "8px",
  },
})(Box);

export default {
  AdministrationMenuItem,
  ItemsContainer,
  ParentName,
  ArrowIcon,
  MenuContent,
  Content,
};
