/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles, Link } from "@material-ui/core";

const NoteAttachment = withStyles((theme: any) => ({
  root: {
    cursor: "pointer",
    marginRight: theme.notes.attachments.attachment.marginRight,
    color: theme.palette.text.disabled,
  },
}))(Link);

export default {
  NoteAttachment,
};
