/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { withStyles, Box, Slider as SliderBase } from "@material-ui/core";

const SliderWrapper = withStyles((theme: any) => ({
  root: {
    paddingTop: theme.spacing(5),
  },
}))(Box);

const Slider = withStyles((theme: any) => ({
  root: {
    marginBottom: theme.spacing(2),
  },
}))(SliderBase);

export default {
  Slider,
  SliderWrapper,
};
