/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from "react";

// Components
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import {
  UniversalFilterResponse,
  ColumnsProps,
} from "components/universal-filter/universal-filter.component";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rmatch_IndividualRiskScoreArgs,
  Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column,
  Order_By,
  useGetPaginatedMasterIndividualsQuery,
  useIndividualRiskScoresFilterQuery,
  useGetMaxMinIndividualRiskScoreQuery,
} from "graphql/hasura/types-and-hooks";
import {
  aggregateArrayToObject,
  jsonToStringQueryVariables,
} from "graphql/hasura/rtcs.utils";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

import TableFilter, {
  FilterCondition,
  TableFilterInput,
  TableFilterType,
} from "components/table-filter/table-filter.component";
import useExportData from "exports/useExportData";

import SC from "./individual.styles";

// Schema
import {
  individualTableColumns,
  MasterIndividualSchema,
  getAccessorType,
} from "./individual.schema";

export const IndividualPage = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Rmatch_IndividualRiskScoreArgs | undefined
  >();

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useGetPaginatedMasterIndividualsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData("rtcs_db_Fnrmatch_IndividualRiskScore", queryVariables);

  const {
    data: maxMinRiskScore,
    loading: maxMinRiskScoreLoading,
    error: maxMinRiskScoreError,
  } = useGetMaxMinIndividualRiskScoreQuery({
    context: { clientName: CLIENT_NAME.HASURA },
  });

  useAppErrorHandler(error || maxMinRiskScoreError || exportError);

  const individualData: MasterIndividualSchema[] = React.useMemo(() => {
    return data?.rtcs_db_Fnrmatch_IndividualRiskScore ?? [];
  }, [data]);

  const columns = useMemo(() => individualTableColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    (params) => {
      const { pageIndex, pageSize, sortBy } = params;
      // This default column is also set in the table columns with the property
      // "sort: true"
      const defaultSortColumn = {
        id: "Rnid",
        desc: true,
      };
      // Support just for one sort column at once
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;

      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_Fnrmatch_IndividualRiskScore_aggregatecm[0].value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData]
  );

  const { refetch: ReportFilterFn } = useIndividualRiskScoresFilterQuery({
    skip: true,
    context: {
      clientName: CLIENT_NAME.HASURA,
    },
  });

  const fetchSuggestions = React.useCallback(
    async (value: string, column: string) => {
      try {
        let suggestions: string[] = [];

        if (value !== "") {
          const filterVariables: Query_RootRtcs_Db_Ph_Rmatch_IndividualRiskScoreArgs =
            {
              where: { [column]: { _ilike: `${value}%` } },
              distinct_on: [
                column as Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column,
              ],
              limit: 10,
            };

          const individualReportFilterData = await ReportFilterFn({
            params: jsonToStringQueryVariables(filterVariables),
          });
          suggestions =
            individualReportFilterData.data.rtcs_db_Fnrmatch_IndividualRiskScore.map(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (option) => option[column]
            );
        }

        return Promise.resolve((suggestions ?? []) as string[]);
      } catch (err) {
        return Promise.reject(err);
      }
    },
    [ReportFilterFn]
  );

  const newObj = aggregateArrayToObject(
    maxMinRiskScore?.rtcs_db_Fnrmatch_IndividualRiskScore_aggregatecm
  );

  const minRiskscore = newObj?.['min("Riskscore")'];

  const maxRiskscore = newObj?.['max("Riskscore")'];

  const filterInputs: TableFilterInput[] = React.useMemo(() => {
    return [
      {
        type: TableFilterType.RANGE_SLIDER,
        label: "Min-Max of Risk Score",
        min: minRiskscore,
        max: maxRiskscore,
        columnName:
          Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column.Riskscore,
      },
      {
        type: TableFilterType.AUTOFILL,
        label: "Risk Code",
        columnName:
          Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column.Riskcode,
        fetchSuggestions,
      },
    ];
  }, [fetchSuggestions, minRiskscore, maxRiskscore]);

  const onTableFilterApply = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  const onTableFilterSearch = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  return (
    <SC.Box>
      <ProgressIndicator
        open={loading || maxMinRiskScoreLoading || exportLoading}
      />
      <Table
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        title="Individuals"
        columns={columns}
        data={individualData}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        leftPanel={
          <TableFilter
            searchInputs={[
              {
                label: "First name, Surname or TIN number",
                columnNames: [
                  Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column.Firstname,
                  Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column.Surname,
                  Rtcs_Db_Ph_Rmatch_IndividualRiskScore_Select_Column.Uratinno,
                ],
              },
            ]}
            onSearchChange={onTableFilterSearch}
            filterInputs={filterInputs}
            onFilterApply={onTableFilterApply}
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        paginationControlled={paginationControlled}
        persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193"
        stickyHeader
        pageIndexResetSignal={pageIndexResetSignal}
        exportData={exportDataFn}
      />
    </SC.Box>
  );
};

export default IndividualPage;
