import React from "react";
import { SnackbarCloseReason } from "@material-ui/core";
import { ErrorBoundary as ErrorBoundaryBase } from "react-error-boundary";

// Errors
import {
  ServerErrorCodes,
  useAppError,
  useAppErrorHandler,
} from "errors/app.errors";

// Utils
import auth from "graphql/authentication";

// Assets
import SC from "./error-boundary.styles";

const ErrorBoundary: React.FC = ({ children }) => {
  const appErrorData = useAppError();
  const appErrorHandler = useAppErrorHandler();

  React.useEffect(() => {
    if (appErrorData?.code === ServerErrorCodes.INVALID_JWT) {
      appErrorHandler(undefined);
      auth.logOut();
    }
  }, [appErrorData, appErrorHandler]);

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: SnackbarCloseReason): void => {
      if (reason === "clickaway") return;
      appErrorHandler(undefined);
    },
    [appErrorHandler]
  );

  const fallbackRenderHandler = React.useCallback(
    () => (
      <SC.Card>
        <SC.CardContent>
          <SC.Message>
            Please contact support@ripplenami.com for assistance.
          </SC.Message>
        </SC.CardContent>
      </SC.Card>
    ),
    []
  );

  return (
    <ErrorBoundaryBase
      // onError={(error) => {
      //   console.log("****** Error: ", JSON.stringify(error));
      // }}
      fallbackRender={fallbackRenderHandler}
    >
      {children}
      <SC.Snackbar open={!!appErrorData} onClose={handleCloseSnack}>
        <SC.Alert
          severity="error"
          message={appErrorData?.message}
          onClose={handleCloseSnack}
        />
      </SC.Snackbar>
    </ErrorBoundaryBase>
  );
};

export default ErrorBoundary;
