/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import { TextFieldProps } from "@material-ui/core/TextField";
import usePrevious from "@react-hook/previous";

// Assets
import SC from "./search-box.styles";

export interface ServerSideSearchProps {
  onTriggerSearch?: (value: string) => void;
  minLengthSearch?: number | undefined;
  msDelaySearch?: number | undefined;
}

export type SearchBoxProps = TextFieldProps & {
  serverSideSearchProps?: ServerSideSearchProps;
};

export const SearchBox: React.FC<SearchBoxProps> = ({
  serverSideSearchProps,
  ...muiProps
}) => {
  const inputValue = (muiProps?.value ?? "") as string;

  const minLengthSearch = serverSideSearchProps?.minLengthSearch ?? 0;
  const msDelaySearch = serverSideSearchProps?.msDelaySearch ?? 0;
  const searchFunction = serverSideSearchProps?.onTriggerSearch;
  // we can perform the search when the following conditions are true:
  // minLengthSearch: actual input length >= minLengthSearch
  // msDelaySearch: time in ms has been ocurred

  const [shouldExecuteSearch, setShouldExecuteSearch] = React.useState(true);

  const prevCount = usePrevious(inputValue.length);

  React.useEffect(() => {
    const performSearch =
      inputValue.length === 0 || inputValue.length >= minLengthSearch;

    if (shouldExecuteSearch && performSearch && prevCount) {
      const timeOutId = setTimeout(() => {
        searchFunction?.(inputValue);
        setShouldExecuteSearch(false);
      }, msDelaySearch);
      return () => clearTimeout(timeOutId);
    }
    return undefined;
  }, [
    searchFunction,
    inputValue,
    minLengthSearch,
    msDelaySearch,
    shouldExecuteSearch,
    prevCount,
  ]);

  const { onChange, ...remainingProps } = muiProps;

  const handlerOnChange:
    | React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
    | undefined = React.useCallback(
    (event) => {
      setShouldExecuteSearch(true);

      onChange?.(event);
    },
    [onChange]
  );

  return (
    <SC.TextField
      onChange={handlerOnChange}
      InputProps={{
        endAdornment: <SC.SearchIcon />,
      }}
      {...remainingProps}
    />
  );
};

export default SearchBox;
