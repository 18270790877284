/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import { withStyles, Box } from "@material-ui/core";

// Assets
import bannerUgandaArms from "assets/images/Mask_Group_73.png";
import bannerBackground from "assets/images/Rectangle_Copy_12.png";

const BannerNavigation = withStyles((theme: any) => ({
  root: {
    // position: "fixed",
    // top: "0",
    // zIndex: 1,
    fontSize: "initial",
    backgroundColor: "white",
    backgroundImage: `url(${bannerUgandaArms}), url(${bannerBackground})`,
    [theme.breakpoints.down("xl")]: {
      height: theme.bannerSmall.height,
    },
    [theme.breakpoints.up("xl")]: {
      height: theme.banner.height,
    },
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    display: "flex",
  },
}))(Box);

export default {
  BannerNavigation,
};
