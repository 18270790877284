// Dependencies
import { withStyles, Box, Snackbar } from "@material-ui/core";

const MyProfilePage = withStyles({
  root: {
    minHeight: "100VH",
  },
})(Box);

export default {
  MyProfilePage,
  Snackbar,
};
