// Dependencies
import RoomIcon from "@material-ui/icons/Room";
import Table from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

export default {
  ProgressIndicator,
  Table,
  RoomIcon,
};
