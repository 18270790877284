import {
  withStyles,
  Card as CardBase,
  CardContent,
  Typography,
  Snackbar,
} from "@material-ui/core";

// Components
import Alert from "components/alert/alert.component";

const Card = withStyles(() => ({
  root: {
    width: "50%",
    margin: "auto",
  },
}))(CardBase);

const Message = withStyles(() => ({
  root: {},
}))(Typography);

export default {
  Card,
  CardContent,
  Message,
  Snackbar,
  Alert,
};
