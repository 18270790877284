/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from "react";
import PropTypes, { any } from "prop-types";
import SC from "./nested-list-item.styles";

export const NestedChildPropTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string,
  level: PropTypes.number.isRequired,
  floatingText: PropTypes.string,
  items: PropTypes.array,
  format: any,
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
type NestedChildPropTypes = PropTypes.InferProps<typeof NestedChildPropTypes>;
type NestedChildPropWithoutItems = Omit<NestedChildPropTypes, "items">;

export type NestedChildProps = NestedChildPropWithoutItems & {
  items?: NestedChildProps[];
};

const NestListItem: React.FC<NestedChildProps> = (props) => {
  const {
    label,
    value,
    format,
    level = 0,
    items = [],
    floatingText = "",
  } = props;
  const isExpandable = items && items.length > 0;
  const [open, setOpen] = useState(true);

  let isTitle = level === 0 || false;

  if (items.length) isTitle = true;

  const handleClick = () => setOpen(!open);

  const ListItemRoot = (
    <>
      <SC.ListItem onClick={handleClick} level={level} has_items={isExpandable}>
        <SC.ListItemIcon level={level} has_items={isExpandable}>
          {isExpandable && !open && <SC.ArrowRight />}
          {isExpandable && open && <SC.ArrowDropDown />}
        </SC.ListItemIcon>
        <SC.ListItemText isTitle={isTitle} primary={label} />
        <SC.ListItemSecondaryAction>
          <SC.ListItemText
            isTitle={isTitle}
            primary={<SC.FormatedValue value={value ?? 0} format={format} />}
          />
          {floatingText && <SC.ListItemFloatingText primary={floatingText} />}
        </SC.ListItemSecondaryAction>
      </SC.ListItem>
    </>
  );

  const ListItemChildren = isExpandable ? (
    <SC.Collapse in={open} timeout="auto" unmountOnExit>
      <SC.List component="div">
        {items.map((item) => (
          <NestListItem {...item} key={item.label} />
        ))}
      </SC.List>
    </SC.Collapse>
  ) : null;

  return (
    <>
      {ListItemRoot}
      {ListItemChildren}
    </>
  );
};

NestListItem.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  level: 0,
};

export default NestListItem;
