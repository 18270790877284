import React from "react";

// Components Props
import AccordionHeader, {
  AccordionHeaderProps,
} from "components/accordion-header/accordion-header.component";

import TabSubHeader, {
  TabProps,
} from "components/tab-sub-header/tab-sub-header.component";

export interface SubHeaderProps {
  headerData: AccordionHeaderProps;
  tabsData: TabProps[];
}

const SubHeader: React.FC<SubHeaderProps> = ({
  headerData: { header, content, source },
  tabsData,
}: SubHeaderProps) => {
  return (
    <>
      <AccordionHeader header={header} content={content} source={source} />
      <TabSubHeader tabs={tabsData} />
    </>
  );
};

export default SubHeader;
