/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Grid as GridBase,
  Box as BoxBase,
  Switch as SwitchBase,
  Container as ContainerBase,
  withStyles,
} from "@material-ui/core";

const Grid = GridBase;

const Box = withStyles((theme: any) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: theme.switch.fontSize,
  },
}))(BoxBase) as typeof BoxBase;

const Switch = withStyles({
  root: {
    "& .MuiSwitch-thumb": {
      color: "#2e3f50",
    },
  },
})(SwitchBase);

const Container = withStyles({
  root: {
    padding: 0,
  },
})(ContainerBase);

export default {
  Grid,
  Box,
  Switch,
  Container,
};
