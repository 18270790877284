/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import { AutocompleteProps as AutocompletePropsBase } from "@material-ui/lab/Autocomplete";

// Material-UI Components
import TextField, {
  TextFieldProps,
} from "components/text-field/text-field.component";

// Assets
import SC from "./autocomplete.styles";

export interface AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends Omit<
    AutocompletePropsBase<T, Multiple, DisableClearable, FreeSolo>,
    "renderInput"
  > {
  textFieldProps?: TextFieldProps;
}

export function Autocomplete<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>(
  props: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>
): JSX.Element {
  const { textFieldProps, ...muiProps } = props;

  return (
    <SC.Autocomplete
      {...muiProps}
      renderInput={(params) => <TextField {...textFieldProps} {...params} />}
    />
  );
}

export default Autocomplete;
