/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable import/no-extraneous-dependencies */
// Dependencies
import React from "react";
import _ from "lodash";

// Components
import FilterList, {
  FilterValue,
} from "components/filter-list/filter-list.component";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

// Assets
import { GroupData, UserData } from "graphql/types-and-hooks";
import SC from "./user-filtering-left-pane.styles";

const filterUsers = (
  users: UserData[],
  searchTerm: string,
  filteredGroupsIds: string[]
) => {
  return users?.filter(
    (user) =>
      `${user.firstName?.toLocaleLowerCase()} ${user.lastName?.toLocaleLowerCase()} ${user.email?.toLocaleLowerCase()}`.includes(
        searchTerm.toLocaleLowerCase()
      ) &&
      user.groups?.some(
        (group) => group?.id && filteredGroupsIds.includes(group.id)
      )
  );
};

export interface UserFilteringLeftPaneProps {
  users: UserData[];
  groups?: GroupData[];
  onFilter: (filteredUsers: UserData[]) => void;
}

export const UserFilteringLeftPane: React.FC<UserFilteringLeftPaneProps> = ({
  users,
  groups,
  onFilter,
}) => {
  const [searchTerm, setSearchTerm] = React.useState("");
  const [filteredGroups, setFilteredGroups] = React.useState<string[]>([]);
  const [filteredUsers, setFilteredUsers] = React.useState(users);

  React.useEffect(() => {
    if (users.length > 0) {
      const newFilteredUsers = filterUsers(users, searchTerm, filteredGroups);

      setFilteredUsers(newFilteredUsers);
      onFilter(newFilteredUsers);
    }
  }, [users, searchTerm, filteredGroups, onFilter]);

  const handlerOnInputSearchBoxChange = React.useCallback(
    (event: React.ChangeEvent<unknown>, value: string) => {
      setSearchTerm(value);
    },
    []
  );

  const handlerOnSearchBoxChange = React.useCallback(
    (
      event: React.ChangeEvent<unknown>,
      value: string | UserData | (string | UserData)[] | null
    ) => {
      if (_.isString(value)) {
        setSearchTerm(value);
      }
    },
    []
  );

  const handleGroupSelection = React.useCallback((value: FilterValue) => {
    const newFilteredGroups = Object.keys(value).filter((k) => value[k]);
    setFilteredGroups(newFilteredGroups);
  }, []);

  const handlerFilterOptions = (): UserData[] => filteredUsers;

  return (
    <Box>
      <SC.SearchBoxFixed
        options={users}
        filterOptions={handlerFilterOptions}
        getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
        getOptionSelected={(user, currentUser) => user.id === currentUser.id}
        inputValue={searchTerm}
        onInputChange={handlerOnInputSearchBoxChange}
        onChange={handlerOnSearchBoxChange}
        textFieldProps={{
          label: "search",
        }}
      />
      <Divider />
      <FilterList
        items={groups}
        label="Group"
        getItemKey={(item) => item.id!}
        getItemLabel={(item) => item.name!}
        onChange={handleGroupSelection}
      />
    </Box>
  );
};

export default UserFilteringLeftPane;
