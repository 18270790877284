/* eslint-disable @typescript-eslint/no-explicit-any */
// Material-UI
import {
  withStyles,
  IconButton as IconButtonMui,
  Popover,
  Button,
  Typography,
  Paper as PaperMui,
} from "@material-ui/core";

const Paper = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: theme.popOverButton.backgroundColor,
  },
}))(PaperMui);

const Message = withStyles((theme: any) => ({
  root: {
    paddingBottom: theme.spacing(1),
    whiteSpace: "pre-line",
  },
}))(Typography);

const IconButton = withStyles((theme: any) => ({
  root: {
    padding: theme.table.IconButton.padding,
  },
}))(IconButtonMui);

export default {
  Paper,
  IconButton,
  Popover,
  Button,
  Message,
};
