/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withStyles,
  Toolbar as ToolbarBase,
  IconButton as IconButtonBase,
  Box,
  Typography,
} from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";

const Toolbar = withStyles((theme: any) => ({
  root: {
    padding: theme.notes.toolbar.padding,
    justifyContent: theme.notes.toolbar.justifyContent,
    minHeight: theme.notes.toolbar.minHeight,
  },
}))(ToolbarBase);

const ToolbarActions = withStyles({})(Box);

const IconButton = withStyles((theme: any) => ({
  root: {
    padding: theme.notes.toolbar.padding,
    marginRight: theme.notes.toolbarAction.marginRight,
    "&:not(.Mui-disabled) .MuiSvgIcon-root": {
      color: theme.notes.toolbarAction.color,
      fontSize: theme.notes.toolbarAction.fontSize,
    },
  },
}))(IconButtonBase);

const Title = withStyles((theme: any) => ({
  root: {
    fontWeight: theme.notes.toolbar.fontWeight,
    fontSize: theme.notes.title.fontSize,
  },
}))(Typography);

const ToolBarAction = withStyles((theme: any) => ({
  root: {
    display: theme.notes.toolbarAction.display,
    alignItems: theme.notes.toolbarAction.alignItems,
  },
}))(Box);

const ActionText = withStyles((theme: any) => ({
  root: {
    display: theme.notes.toolbarActionText.display,
    fontSize: theme.notes.toolbarActionText.fontSize,
    color: theme.notes.toolbarActionText.color,
  },
}))(Typography);

export default {
  Title,
  Toolbar,
  ToolbarActions,
  IconButton,
  FileCopyOutlined,
  ToolBarAction,
  ActionText,
};
