/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

export const Parent: any = {
  equalto: "_eq",
  noequalto: "_neq",
  lessthan: "_lt",
  greaterthan: "_gt",
  lessequalto: "_lte",
  greaterequalto: "_gte",
  isnull: "_is_null",
  notnull: "_is_not_null",
  begins: "_ilike",
  ends: "ilike_",
  islike: "_ilike_",
  pattern: "pattern",
};

export const joinOperands = [
  {
    index: 1,
    label: "AND",
    value: "_and",
  },
  {
    index: 2,
    label: "OR",
    value: "_or",
  },
];

export const QueryOptions = [
  {
    index: 1,
    label: "Begins with",
    value: "begins",
    disabled: false,
    type: "string",
  },
  {
    index: 2,
    label: "Ends with",
    disabled: false,
    value: "ends",
    type: "string",
  },
  {
    index: 3,
    label: "Is equal to/ is in",
    value: "equalto",
    disabled: false,
    type: "all",
  },
  {
    index: 4,
    label: "Is not equal to/ is not in",
    value: "noequalto",
    disabled: false,
    type: "all",
  },
  {
    index: 5,
    label: "Is less than",
    value: "lessthan",
    disabled: false,
    type: "number|date",
  },
  {
    index: 6,
    label: "Is greater than",
    value: "greaterthan",
    disabled: false,
    type: "number|date",
  },
  {
    index: 7,
    label: "Is less or equal to",
    value: "lessequalto",
    disabled: false,
    type: "number|date",
  },
  {
    index: 8,
    label: "Is greater or equal to",
    value: "greaterequalto",
    disabled: false,
    type: "number|date",
  },
  {
    index: 10,
    label: "Is null",
    value: "isnull",
    disabled: false,
    type: "all",
  },
  {
    index: 11,
    label: "Is not null",
    value: "notnull",
    disabled: false,
    type: "all",
  },
  {
    index: 12,
    label: "Pattern Match (Wild Card)",
    disabled: false,
    value: "pattern",
    type: "string",
  },
];

export const PascalCaseFormat = (obj: any) => {
  const mainKey = Object.keys(obj)[0];
  const otherKey = Object.keys(obj[mainKey])[0];
  const value = obj[mainKey][otherKey].replace(
    /(\w)(\w*)/g,
    (_g0: any, g1: any, g2: any) => g1.toUpperCase() + g2.toLowerCase()
  );
  return { [mainKey]: { [otherKey]: value } };
};
