import { InMemoryCache } from "@apollo/client";

// Reactive Vars
import { appErrorDataVar } from "errors/app.errors";
import { authenticationDataVar } from "./authentication.utils";

const cache = new InMemoryCache({
  typePolicies: {
    // Required only if we need access to the app local states from GQL queries
    Query: {
      fields: {
        appErrorData: {
          read() {
            return appErrorDataVar();
          },
        },
        authenticationData: {
          read() {
            return authenticationDataVar();
          },
        },
      },
    },
    PermissionData: { keyFields: false },
  },
});

export default cache;
