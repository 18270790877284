/* eslint-disable @typescript-eslint/no-explicit-any */
import { withStyles, Box as BoxBase, Typography } from "@material-ui/core";
import {
  TreeView as TreeViewBase,
  TreeItem as TreeItemBase,
} from "@material-ui/lab";

const Box = withStyles((theme: any) => ({
  root: {
    margin: theme.spacing(3),
  },
}))(BoxBase);

const Title = withStyles((theme: any) => ({
  root: {
    marginBottom: theme.spacing(3),
    fontSize: theme.revisionPage.title.fontSize,
    fontWeight: theme.revisionPage.title.fontWeight,
    textAlign: theme.revisionPage.title.textAlign,
  },
}))(Typography);

const TreeView = withStyles(() => ({
  root: {
    width: "auto",
  },
}))(TreeViewBase);

const TreeItem = withStyles((theme: any) => ({
  iconContainer: {
    width: theme.revisionPage.treeItemIcon.width,
    justifyContent: theme.revisionPage.treeItemIcon.justifyContent,
    fontWeight: theme.revisionPage.treeItemIcon.fontWeight,
  },
  label: {
    textAlign: theme.revisionPage.treeItemLabel.textAlign,
  },
}))(TreeItemBase);

export default {
  Box,
  Title,
  TreeView,
  TreeItem,
};
