/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { PropertyCompositeFragment } from "graphql/hasura/types-and-hooks";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { isEmptyString } from "utils/string";
import SC from "./properties.styles";

export type PropertiesTableSchema = Omit<
  PropertyCompositeFragment,
  "__typename"
>;

// @ts-ignore
export const MasterIndividualSchemaMap: Record<
  keyof PropertiesTableSchema | string,
  "string" | "number"
> = {
  Rnid: "string",
  PropertyTypeId: "string",
  SubPropertyTypeId: "string",
  Source: "string",
  MapTitle: "string",
  PropertyNo: "string",
  NwscCustomerName: "string",
  NumRentedProperties: "number",
  PlotNo: "string",
  BlockNo: "string",
  RoadName: "string",
  DistrictId: "string",
  CountyId: "string",
  VillageId: "string",
  PropertyRentedStatusId: "string",
  GrossRentValueAdjusted: "number",
  NoOfBedroomsRecorded: "number",
  NoOfBedroomDerived: "number",
  NoOfUnits: "number",
  RnidCustomer: "string",
  Map: "string",
  GrossValue: "number",
  RateableValue: "number",
  CustomerId: "string",
  StreetId: "string",
  ParishId: "string",
};

export const getAccessorType = (
  accessor:
    | keyof PropertiesTableSchema
    | Accessor<PropertiesTableSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return MasterIndividualSchemaMap[accessor];
};

export const propertiesTableColumns: TableColumn<PropertiesTableSchema>[] = [
  {
    Header: "RNID",
    accessor: "Rnid",
    align: "center",
    sort: false,
    Cell: ({
      value,
      row: {
        values: { Rnid },
      },
    }) => {
      return (
        <Link to={`/properties/${Rnid}/details`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Owner RNID",
    accessor: "RnidCustomer",
    align: "center",
    sort: false,
    Cell: ({
      row: {
        // @ts-ignore
        values: { RnidCustomer },
      },
    }) => {
      if (isEmptyString(RnidCustomer)) {
        return RnidCustomer;
      }
      const dynamicLink = RnidCustomer.startsWith("IND")
        ? `/individuals/${RnidCustomer}`
        : `/non-individuals/${RnidCustomer}`;
      return (
        <Link to={`${dynamicLink}/identification`} color="inherit">
          {RnidCustomer}
        </Link>
      );
    },
  },
  { Header: "Source Property ID", accessor: "PropertyNo" },
  { Header: "Customer ID", accessor: "CustomerId" },
  { Header: "Property Type", accessor: "PropertyTypeId" },
  { Header: "Property Subtype", accessor: "SubPropertyTypeId" },
  { Header: "Source", accessor: "Source" },
  { Header: "Gross Value", accessor: "GrossValue", format: "currency" },
  { Header: "Rateable Value", accessor: "RateableValue", format: "currency" },
  { Header: "Building Name", accessor: "MapTitle" },
  { Header: "Street", accessor: "StreetId" },
  { Header: "Parish", accessor: "ParishId" },
  { Header: "First Name", accessor: "Firstname" },
  { Header: "Surname", accessor: "Surname" },
  { Header: "Entity's Legal Name", accessor: "EntityLegalName" },
  { Header: "Plot No", accessor: "PlotNo" },
  { Header: "Block No", accessor: "BlockNo" },
  {
    Header: "Road Name",
    // @ts-ignore
    accessor: "RoadName",
    hiddenColumn: true,
    disableSortBy: true,
  },
  { Header: "District", accessor: "DistrictId", hiddenColumn: true },
  { Header: "County", accessor: "CountyId", hiddenColumn: true },
  { Header: "Village", accessor: "VillageId", hiddenColumn: true },
  { Header: "Rented Status", accessor: "PropertyRentedStatusId" },
  {
    Header: "No. of Bedrooms Recorded",
    // @ts-ignore
    accessor: "NoOfBedroomsRecorded",
    hiddenColumn: true,
    disableSortBy: true,
  },
  {
    Header: "No of Bedroom Derived",
    // @ts-ignore
    accessor: "NoOfBedroomDerived",
    hiddenColumn: true,
    disableSortBy: true,
  },
  {
    Header: "No. of Units",
    // @ts-ignore
    accessor: "NoOfUnits",
    hiddenColumn: true,
    disableSortBy: true,
  },
  {
    Header: "Property Owner",
    // @ts-ignore
    accessor: "n/a",

    align: "center",
    Cell: ({
      row: {
        // @ts-ignore
        values: { RnidCustomer },
      },
    }) => {
      if (isEmptyString(RnidCustomer)) {
        return <SC.RoomIcon color="primary" />;
      }
      const dynamicLink = RnidCustomer.startsWith("IND")
        ? `/individuals/${RnidCustomer}`
        : `/non-individuals/${RnidCustomer}`;
      return (
        <Link to={`${dynamicLink}/properties?mapview=visible`} color="inherit">
          <SC.RoomIcon color="primary" />
        </Link>
      );
    },
    disableSortBy: true,
  },
];
