// dependencies
/* eslint-disable no-alert */
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rcapture_RptUnittypesArgs,
  useRCapturePropertyUnitTypesQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Schema
import { columns } from "./unittypes.schema";

// Assets
import SC from "./unittypes.styles";

const TABLE_NAME = "rtcs_db_Fnrcapture_RPTUnittypes";

const RCaptureUnitTypes: React.FC = () => {
  const { unittypeId } = useParams<{ unittypeId: string }>();

  const unittypesQueryVariables: Query_RootRtcs_Db_Ph_Rcapture_RptUnittypesArgs =
    {
      where: { Id: { _eq: unittypeId } },
    };

  // Property Unittypes
  const { data, loading, error } = useRCapturePropertyUnitTypesQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !unittypesQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(unittypesQueryVariables ?? {}),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, unittypesQueryVariables);

  useAppErrorHandler(error || exportError);

  const unitType = useMemo(() => {
    if (data?.rtcs_db_Fnrcapture_RPTUnittypes.length === 0) return [];

    const unitTypeData = data?.rtcs_db_Fnrcapture_RPTUnittypes[0];

    if (!unitTypeData) return [];

    const newData = _.omit(unitTypeData, "__typename");

    const unitTypes = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(newData)) {
      unitTypes.push({
        question: key,
        answertext: value,
      });
    }

    return unitTypes;
  }, [data]);

  return (
    <>
      <SC.ProgressIndicator open={loading || exportLoading} />
      <SC.Breadcrumb label="MDA" />
      <SC.Table
        title="Unit Type Info"
        actionsOnRight={[]}
        onAction={() => alert("under construction")}
        persistenceId="1ed5ae0f-1f3e-496a-9ad3-f3832f32169a"
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        isPaginationHidden
        initialRowsPerPage={9}
        data={unitType}
        exportData={exportDataFn}
      />
    </>
  );
};

export default RCaptureUnitTypes;
