import { UserData } from "graphql/types-and-hooks";
import React from "react";

import SC from "./note-author.styles";

export interface NoteAuthorProps {
  name: string;
  image_url: string;
}

interface AuthorProps {
  author: UserData;
}

const NoteAuthor: React.FC<AuthorProps> = ({
  author: { firstName, lastName, profilePicture },
}) => {
  return (
    <SC.AuthorBox alignItems="center" m={1}>
      {profilePicture ? (
        <SC.UserAvatar src={profilePicture} />
      ) : (
        <SC.DefaultAvatarIcon />
      )}
      <SC.AuthorName variant="body2" display="inline">
        {`${firstName} ${lastName}`}
      </SC.AuthorName>
    </SC.AuthorBox>
  );
};

export default NoteAuthor;
