/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

// Components
import RVIEWHOME from "rView/home";

interface MapContainerProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  properties?: any;
}

const MapContainer: React.FC<MapContainerProps> = ({ properties }) => {
  return <RVIEWHOME properties={properties} />;
};

export default MapContainer;
