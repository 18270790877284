/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Kcca_CustomerArgs,
  useKccaApplicantTypeQuery,
  useKccaBusinessTypeQuery,
  useKccaCustomerNonIndividualQuery,
  useKccaCustomerTypeQuery,
  useUraNonIndividualQuery,
  Query_RootRtcs_Db_Ph_Ura_RegNonIndividualArgs,
  KccaCustomerTypeQuery,
  KccaApplicantTypeQuery,
  KccaBusinessTypeQuery,
} from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Components
import ListAccordion from "components/list-accordion/list-accordion.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

export interface CustomerIdentification {
  index: number;
  source: string;
  customerId?: string;
  fullnames: string;
  data: {
    index: number;
    label: string;
    value: string;
    format?: "date" | "currency";
  }[];
}

export interface IdentificationTabProps {
  customerIds: string;
  tin?: string;
  rnid?: string;
}

const IdentificationTab: React.FC<IdentificationTabProps> = ({
  customerIds,
  tin,
}: IdentificationTabProps) => {
  const queryVariablesKccaCustomerNonIndividual: Query_RootRtcs_Db_Ph_Kcca_CustomerArgs =
    {
      // @ts-ignore
      where: { Id: { _eq: customerIds.split(",") } },
    };

  const queryVariablesUra: Query_RootRtcs_Db_Ph_Ura_RegNonIndividualArgs = {
    where: { TinNo: { _eq: tin } },
    limit: 1,
  };

  const {
    data: kccaCustomerNonIndividualData,
    error: kccaCustomerNonIndividualError,
  } = useKccaCustomerNonIndividualQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customerIds,
    variables: {
      params: jsonToStringQueryVariables(
        queryVariablesKccaCustomerNonIndividual
      ),
    },
  });

  const { data: ura } = useUraNonIndividualQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariablesUra,
    variables: {
      params: jsonToStringQueryVariables(queryVariablesUra),
    },
  });

  const customers = kccaCustomerNonIndividualData?.rtcs_db_Fnkcca_Customer;

  const uraData = ura?.rtcs_db_Fnura_RegNonIndividual[0];

  const { data: kccaCustomerTypesData } = useKccaCustomerTypeQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customers,
  });

  const { data: kccaApplicantTypesData } = useKccaApplicantTypeQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customers,
  });

  const { data: kccaBusinessTypesData } = useKccaBusinessTypeQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !customers,
  });

  useAppErrorHandler(kccaCustomerNonIndividualError);

  const getkccaCustomerTypeName = (
    kccaCustomerTypesData: KccaCustomerTypeQuery | undefined,
    CustomerTypeId: string | undefined
  ) => {
    if (CustomerTypeId) {
      return kccaCustomerTypesData?.rtcs_db_Fnkcca_CustomerType.filter(
        (cType) => cType.Id === CustomerTypeId
      )[0]?.Name;
    }

    return "";
  };

  const getkccaApplicantTypeName = (
    kccaApplicantTypesData: KccaApplicantTypeQuery | undefined,
    CustomerApplicantTypeId: string | undefined
  ) => {
    if (CustomerApplicantTypeId) {
      return kccaApplicantTypesData?.rtcs_db_Fnkcca_CustomerApplicantType.filter(
        (applicationType) => applicationType.Id === CustomerApplicantTypeId
      )[0]?.Name;
    }

    return "";
  };

  const getkccaBusinessTypeName = (
    kccaBusinessTypesData: KccaBusinessTypeQuery | undefined,
    CustomerBusinessTypeId: string | undefined
  ) => {
    if (CustomerBusinessTypeId) {
      return kccaBusinessTypesData?.rtcs_db_Fnkcca_CustomerBusinessType.filter(
        (BusinessType) => BusinessType.Id === CustomerBusinessTypeId
      )[0]?.Name;
    }

    return "";
  };

  let customerData: CustomerIdentification[] = [];

  if (customers && customers.length > 0) {
    // @ts-ignore
    customerData = customers.map((customer) => {
      const customerType = getkccaCustomerTypeName(
        kccaCustomerTypesData,
        customer.CustomerTypeId
      );

      const applicantType = getkccaApplicantTypeName(
        kccaApplicantTypesData,
        customer.CustomerApplicantTypeId
      );

      const businessType = getkccaBusinessTypeName(
        kccaBusinessTypesData,
        customer.CustomerBusinessTypeId
      );

      const data = {
        index: 0,
        source: "Kampala Capital City Authority (KCCA) ",
        customerId: customer.Id ?? "",
        fullnames: customer.EntityLegalName,
        data: [
          {
            index: 0,
            label: "COIN",
            value: customer.Coin,
          },
          {
            index: 1,
            label: "TIN",
            value: customer.Tin,
          },
          {
            index: 2,
            label: "Entity Legal Name",
            value: customer.EntityLegalName,
          },
          { index: 3, label: "Business Name", value: customer.BusinessName },
          {
            index: 4,
            label: "Building Name",
            value: customer.BuildingName,
          },
          {
            index: 5,
            label: "Date of Incorporation",
            value: customer.DateOfIncorporation,
            format: "date",
          },
          {
            index: 6,
            label: "Trading Center",
            value: customer.TradingCenter,
          },
          {
            index: 7,
            label: "Customer Type",
            value: customerType,
          },
          {
            index: 8,
            label: "Applicant Type",
            value: applicantType,
          },
          {
            index: 9,
            label: "Business Type",
            value: businessType,
          },
          { index: 10, label: "Telephone", value: customer.Telephone },
          {
            index: 11,
            label: "Mobile Phone Number 1",
            value: customer.Mobile,
          },
          {
            index: 12,
            label: "Mobile Phone Number 2",
            value: customer.SecondMobile,
          },
          { index: 13, label: "Email Address", value: customer.Email },
        ],
      };
      return data;
    });
  }

  if (uraData) {
    customerData.push({
      index: 0,
      source: "Uganda Revenue Authority (URA)",
      fullnames: uraData.TaxPayerName ?? uraData.BsnsName ?? "",
      data: [
        {
          index: 0,
          label: "Holding Company TIN",
          value: uraData.HoldingCompTin ?? "",
        },
        {
          index: 1,
          label: "Holding Company Name",
          value: uraData.HoldingCompName ?? "",
        },
        {
          index: 2,
          label: "Main Activity",
          value: uraData.MainActivity ?? "",
        },
        {
          index: 3,
          label: "Activity Description",
          value: uraData.ActvtyDesc ?? "",
        },
        {
          index: 4,
          label: "Organization Type",
          value: uraData.OrgType ?? "",
        },
        {
          index: 5,
          label: "Organization Sub type",
          value: uraData.OrgSubType ?? "",
        },
        {
          index: 6,
          label: "Organization Subtype Description",
          value: uraData.OrgSubTypeDesc ?? "",
        },
        {
          index: 7,
          label: "Is Company a subsidiary?",
          value: uraData.IsSubsidaryComp ?? "",
        },
      ],
    });
  }

  return <ListAccordion data={customerData} />;
};

export default IdentificationTab;
