/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Typography as TypographyBase,
  Container as ContainerBase,
  Paper as PaperBase,
  withStyles,
  Grid as GridBase,
  Select,
} from "@material-ui/core";

import CalendarSelect from "components/calendar-select/calendar-select.component";

const Typography = withStyles((theme: any) => ({
  h6: {
    fontWeight: theme.table.tableRangeActions.fontWeight,
    fontSize: theme.table.tableRangeActions.fontSize,
  },
  body1: {
    textAlign: "right",
  },
}))(TypographyBase);
const Paper = withStyles(() => ({}))(PaperBase);
const Grid = withStyles((theme: any) => ({
  root: {
    marginTop: theme.spacing(2),
    marginLeft: "15px",
  },
}))(GridBase);

const Container = withStyles(() => ({
  root: {
    textAlign: "left",
  },
}))(ContainerBase);

const SelectField = withStyles(() => ({
  root: {
    "& .MuiSelect-root em": {
      color: "#000",
    },
  },
}))(CalendarSelect);

export default {
  Container,
  Typography,
  Paper,
  Grid,
  SelectField,
  Select,
};
