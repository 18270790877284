/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withStyles,
  Grid,
  Paper as PaperBase,
  Typography,
  Box as BoxBase,
} from "@material-ui/core";

const Paper = withStyles(() => ({
  root: {
    border: "0.07rem solid rgba(0,0,0, 0.12)",
    padding: "1.1rem",
  },
}))(PaperBase);

const ScrollView = withStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
    height: "auto",
    // overflow: "hidden",
    // overflowY: "auto",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    textAlign: "left",
  },
}))(BoxBase);

const Label = withStyles((theme: any) => ({
  root: {
    fontWeight: theme.table.toolbar.fontWeight,
    fontSize: "1.42rem",
    textAlign: "left",
    marginRight: theme.spacing(1),
    opacity: 0.8,
  },
}))(Typography);

const Content = withStyles((theme: any) => ({
  root: {
    fontSize: theme.reportCard.padding,
    textAlign: "left",
    paddingTop: theme.reportCard.padding,
  },
}))(Typography);

export default {
  Grid,
  Paper,
  Label,
  ScrollView,
  Content,
};
