import React, { ReactNode } from "react";
import { TableColumn } from "components/table/table.component";
import {
  IndividualCompliancePlusRiskReportFragment,
  IndividualExcessiveOwnerOccupancyFragment,
} from "graphql/hasura/types-and-hooks";
import { Link } from "react-router-dom";

export const noReturnsColumns: TableColumn<IndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "left",
    },
    {
      Header: "URA First Name",
      accessor: "Urafirstname",
      align: "left",
    },
    {
      Header: "URA Middle Name",
      accessor: "Uramiddlename",
      align: "left",
    },
    {
      Header: "URA Surname",
      accessor: "Urasurname",
      align: "left",
    },
    {
      Header: "rMATCH Score",
      accessor: "Matchscore",
      align: "center",
    },
    {
      Header: "TIN No.",
      accessor: "Uratinno",
      align: "center",
      sort: true,
    },
    {
      Header: "Rental Income?",
      accessor: "Isincomesrcrental",
      align: "center",
    },
    {
      Header: "URA Mobile No.",
      accessor: "Mobilenumber",
      align: "center",
    },
    {
      Header: "URA Email",
      accessor: "Emailid",
      align: "center",
    },
    {
      Header: "URA NIN",
      accessor: "Nationalid",
      align: "center",
    },
    {
      Header: "Tax Year",
      accessor: "Rtnperiodyear",
      align: "center",
    },
    {
      Header: "Total Number of Properties",
      accessor: "Numproperties",
      align: "center",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Total Number of Rental Properties",
      accessor: "Numrentedproperties",
      align: "center",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Total Adjusted Gross Rental Income",
      accessor: "Sumadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Average Rate Per Property",
      accessor: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
  ];

export const underreportedColumns: TableColumn<IndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "left",
    },
    {
      Header: "URA First Name",
      accessor: "Urafirstname",
      align: "left",
    },
    {
      Header: "URA Middle Name",
      accessor: "Uramiddlename",
      align: "left",
    },
    {
      Header: "URA Surname",
      accessor: "Urasurname",
      align: "left",
    },
    {
      Header: "rMATCH Score",
      accessor: "Matchscore",
      align: "right",
    },
    {
      Header: "TIN No.",
      accessor: "Uratinno",
      align: "left",
      sort: true,
    },
    {
      Header: "Rental Income?",
      accessor: "Isincomesrcrental",
      align: "center",
    },
    {
      Header: "URA Mobile No.",
      accessor: "Mobilenumber",
    },
    {
      Header: "URA Email",
      accessor: "Emailid",
    },
    {
      Header: "URA NIN",
      accessor: "Nationalid",
    },
    {
      Header: "Total Number of Properties",
      accessor: "Numproperties",
      align: "right",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Total Number of Rental Properties",
      accessor: "Numrentedproperties",
      align: "right",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Total Adjusted Gross Rental Income",
      accessor: "Sumadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Average Rate Per Property",
      accessor: "Averagerateperrentedproperty", // tbc
      align: "right",
      format: "currency",
    },
    {
      Header: "Return No.",
      accessor: "Rtnno",
      align: "left",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/tax-history/${value}`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Amendment",
      accessor: "Isamendment",
      align: "center",
    },
    {
      Header: "Property Income",
      accessor: "Propincincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Return Period",
      accessor: "Rtnperiodyear",
      align: "right",
    },
    {
      Header: "URA Gross Rent Income",
      accessor: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Difference",
      accessor: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const compliantColumns: TableColumn<IndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "left",
    },
    {
      Header: "URA First Name",
      accessor: "Urafirstname",
      align: "left",
    },
    {
      Header: "URA Middle Name",
      accessor: "Uramiddlename",
      align: "left",
    },
    {
      Header: "URA Surname",
      accessor: "Urasurname",
      align: "left",
    },
    {
      Header: "rMATCH Score",
      accessor: "Matchscore",
      align: "right",
    },
    {
      Header: "TIN No.",
      accessor: "Uratinno",
      align: "left",
      sort: true,
    },
    {
      Header: "Rental Income?",
      accessor: "Isincomesrcrental",
      align: "center",
    },
    {
      Header: "URA Mobile No.",
      accessor: "Mobilenumber",
    },
    {
      Header: "URA Email",
      accessor: "Emailid",
    },
    {
      Header: "URA NIN",
      accessor: "Nationalid",
    },
    {
      Header: "Total Number of Properties",
      accessor: "Numproperties",
      align: "right",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Total Number of Rental Properties",
      accessor: "Numrentedproperties",
      align: "right",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Total Adjusted Gross Rental Income",
      accessor: "Sumadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Average Rate Per Property",
      accessor: "Averagerateperrentedproperty", // tbc
      align: "right",
      format: "currency",
    },
    {
      Header: "Return No.",
      accessor: "Rtnno",
      align: "left",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/tax-history/${value}`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Amendment",
      accessor: "Isamendment",
      align: "center",
    },
    {
      Header: "Property Income",
      accessor: "Propincincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Return Period",
      accessor: "Rtnperiodyear",
      align: "right",
    },
    {
      Header: "URA Gross Rent Income",
      accessor: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Difference",
      accessor: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const noIncomeColumns: TableColumn<IndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "left",
    },
    {
      Header: "URA First Name",
      accessor: "Urafirstname",
      align: "left",
    },
    {
      Header: "URA Middle Name",
      accessor: "Uramiddlename",
      align: "left",
    },
    {
      Header: "URA Surname",
      accessor: "Urasurname",
      align: "left",
    },
    {
      Header: "rMATCH Score",
      accessor: "Matchscore",
      align: "center",
    },
    {
      Header: "TIN No.",
      accessor: "Uratinno",
      align: "center",
      sort: true,
    },
    {
      Header: "Rental Income?",
      accessor: "Isincomesrcrental",
      align: "center",
    },
    {
      Header: "URA Mobile No.",
      accessor: "Mobilenumber",
      align: "center",
    },
    {
      Header: "URA Email",
      accessor: "Emailid",
      align: "center",
    },
    {
      Header: "URA NIN",
      accessor: "Nationalid",
      align: "center",
    },
    {
      Header: "Total Number of Properties",
      accessor: "Numproperties",
      align: "center",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Total Number of Rental Properties",
      accessor: "Numrentedproperties",
      align: "center",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/properties/rented`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Total Adjusted Gross Rental Income",
      accessor: "Sumadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Average Rate Per Property",
      accessor: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
    {
      Header: "Return No.",
      accessor: "Rtnno",
      align: "center",
      Cell: ({
        cell: { value },
        row: {
          values: { Rnid },
        },
      }) => (
        <Link to={`/individuals/${Rnid}/tax-history/${value}`} color="inherit">
          {value}
        </Link>
      ),
    },
    {
      Header: "Amendment",
      accessor: "Isamendment",
      align: "center",
    },
    {
      Header: "Property Income",
      accessor: "Propincincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Return Period",
      accessor: "Rtnperiodyear",
      align: "center",
    },
    {
      Header: "URA Gross Rent Income",
      accessor: "Grossrentalincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Difference",
      accessor: "Difference",
      align: "right",
      format: "currency",
    },
  ];

export const unregisteredColumns: TableColumn<IndividualCompliancePlusRiskReportFragment>[] =
  [
    {
      Header: "RNID",
      accessor: "Rnid",
      align: "left",
    },
    {
      Header: "Customer ID",
      accessor: "Customerid",
      align: "center",
    },
    {
      Header: "First Name",
      accessor: "Kccafirstname",
      align: "left",
    },
    {
      Header: "Surname",
      accessor: "Kccasurname",
      align: "left",
    },
    {
      Header: "Mobile",
      accessor: "Kccamobile1",
      align: "center",
    },
    {
      Header: "Mobile2",
      accessor: "Kccamobile2",
      align: "center",
    },
    {
      Header: "Telephone",
      accessor: "Kccatelephone",
      align: "center",
    },
    {
      Header: "Birth Date",
      accessor: "Kccabirthdate",
      align: "center",
      format: "date",
    },
    {
      Header: "KCCA NIN",
      accessor: "Nationalid",
      align: "left",
    },
    {
      Header: "Email",
      accessor: "Kccaemail",
      align: "center",
    },

    {
      Header: "Total No. Of Properties",
      accessor: "Numproperties",
      align: "center",
      Cell: ({
        value,
        row: {
          values: { Rnid },
        },
      }: {
        value: string;
        row: { values: { Rnid: string } };
      }): ReactNode => {
        return (
          <Link to={`/individuals/${Rnid}/properties`} color="inherit">
            {value}
          </Link>
        );
      },
    },
    {
      Header: "Total No. Of Rented Properties",
      accessor: "Numrentedproperties",
      align: "center",
      Cell: ({
        value,
        row: {
          values: { Rnid },
        },
      }: {
        value: string;
        row: { values: { Rnid: string } };
      }): ReactNode => {
        return (
          <Link to={`/individuals/${Rnid}/properties`} color="inherit">
            {value}
          </Link>
        );
      },
    },
    {
      Header: "Sum Rateable Value Of Rented Properties",
      accessor: "Sumrateablevalue",
      align: "right",
      format: "currency",
    },
    {
      Header: "Sum Adjusted Gross Rental Income",
      accessor: "Sumadjustedincome",
      align: "right",
      format: "currency",
    },
    {
      Header: "Average Rate Per Rented Property",
      accessor: "Averagerateperrentedproperty",
      align: "right",
      format: "currency",
    },
  ];

export const excessiveOccupancyColumns: TableColumn<IndividualExcessiveOwnerOccupancyFragment>[] =
  [
    {
      Header: "Customer No",
      accessor: "CustomerId",
      align: "center",
    },
    {
      Header: "KCCA First Name",
      accessor: "KccaFirstName",
      align: "left",
    },
    {
      Header: "KCCA Middle Name",
      accessor: "KccaMiddleName",
      align: "center",
    },
    {
      Header: "KCCA Surname",
      accessor: "KccaSurname",
      align: "left",
    },
    { Header: "rMATCH Score", accessor: "MatchScore", align: "center" },
    {
      Header: "Number of Owner Occupied Properties",
      accessor: "NumOwnedOccupied",
      align: "center",
    },
    {
      Header: "Total Adjusted Gross Rental Income",
      accessor: "SumAdjustedIncome",
      align: "center",
      format: "currency",
    },
    {
      Header: "Match KCCA Owner to URA Taxpayer",
      accessor: "MatchKccaUra",
      align: "left",
    },
    {
      Header: "URA First Name",
      accessor: "UraFirstname",
      align: "left",
    },
    {
      Header: "URA Surname",
      accessor: "UraSurname",
      align: "left",
    },
    {
      Header: "TIN No.",
      accessor: "UraTin",
      align: "left",
    },
    {
      Header: "Rental Income?",
      accessor: "IsIncomeSrcRental",
      align: "left",
    },
    {
      Header: "URA Mobile No.",
      accessor: "MobileNumber",
      align: "left",
    },
    {
      Header: "URA Email",
      accessor: "EmailId",
      align: "left",
    },
    {
      Header: "URA NIN",
      accessor: "NationalId",
      align: "left",
    },
  ];

export default {
  noReturnsColumns,
};
