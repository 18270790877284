/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import { Tooltip, IconProps, IconButtonProps } from "@material-ui/core/";
import { PopoverButton } from "components/popover-button/popover-button.component";

// Assets
import SC from "./action-button.styles";

export interface ActionButtonProps extends IconButtonProps {
  title: string;
  icon: IconProps;
  handler: () => void;
  displayPopover?: boolean;
  popoverMessage?: string | JSX.Element;
  popoverButtons?: boolean;
}

export const ActionButton: React.FC<ActionButtonProps> = ({
  title,
  icon,
  handler,
  displayPopover = false,
  popoverMessage,
  popoverButtons = false,
  ...iconButtonProps
}) => {
  let buttonToRender = null;

  if (displayPopover) {
    buttonToRender = (
      <PopoverButton
        {...iconButtonProps}
        btnIcon={icon}
        popoverMessage={popoverMessage}
        popoverHandler={handler}
        buttonsActive={popoverButtons}
      />
    );
  } else if (handler) {
    buttonToRender = (
      <SC.IconButton {...iconButtonProps} onClick={handler}>
        {icon}
      </SC.IconButton>
    );
  } else {
    buttonToRender = (
      <SC.IconButton {...iconButtonProps} disableFocusRipple disableRipple>
        {icon}
      </SC.IconButton>
    );
  }

  return (
    <Tooltip key={title} title={title} placement="top" arrow>
      <span>{buttonToRender}</span>
    </Tooltip>
  );
};

export default ActionButton;
