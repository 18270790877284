/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import _ from "lodash";
import { Accessor } from "react-table";
import { TableColumn } from "components/table/table.component";
import { Link, useRouteMatch } from "react-router-dom";

import { Rpt_NonIndividualBalanceSheetFragment } from "graphql/hasura/types-and-hooks";

export type ReportTableSchema = Omit<
  Rpt_NonIndividualBalanceSheetFragment,
  | "__typename"
  | "AccntRcvdOthers"
  | "AccntRcvdOverSix"
  | "AdvanceCorporate"
  | "AdvanceRecoverable"
  | "ApplicationFund"
  | "BalRevenueAuth"
  | "BankBalance"
  | "BankOverDraft"
  | "CnsmPckInventory"
  | "Debenture"
  | "DeferTaxLiability"
  | "DeferredAsset"
  | "Deposits"
  | "Depreciation"
  | "EquityShare"
  | "FinishTradeGoods"
  | "FixedDeposit"
  | "FurnitureFixture"
  | "GeneralReserve"
  | "GovtSecurities"
  | "HandCash"
  | "IncomeTaxProv"
  | "IntLeaseAsset"
  | "IntangibleAssets"
  | "LandBuild"
  | "LessDrawings"
  | "LiableLeaseAsset"
  | "LoanSubCompany"
  | "MotorVehicles"
  | "NetCurrentAsset"
  | "NetFixedAsset"
  | "OtherInvestments"
  | "OtherProvision"
  | "OtherReserve"
  | "OthrCurrentAsset"
  | "OthrDeprecAsset"
  | "OthrLiability"
  | "PlantMachine"
  | "Prepayments"
  | "ProfitLossAccount"
  | "ProprietorCapital"
  | "ProvForBadDebts"
  | "RawMtrlInventory"
  | "RevaluationReserve"
  | "SecureDebtIssued"
  | "SecureLoanBank"
  | "SecureLoanOther"
  | "SecureLoanParties"
  | "SecureTotLoan"
  | "SourceFund"
  | "StockInProcess"
  | "SundryCreditors"
  | "TotAccntRcvdDebtors"
  | "TotAssetLoanAdvance"
  | "TotBalanceAvail"
  | "TotCurrLiability"
  | "TotInventory"
  | "TotalLoanFund"
  | "TotalPropFund"
  | "TotalProvision"
  | "TranslationReserve"
  | "UnpaidMatureDebts"
  | "UnsecureCreditorLoan"
  | "UnsecureLoanBank"
  | "UnsecureLoanOther"
  | "UnsecureLoanParties"
  | "UnsecureTotLoan"
  | "TinNo"
>;

export const REPORT_TABLE_SCHEMA: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  RtnId: "string",
  RtnPeriodYear: "string",
  RtnNo: "string",
  IsAmendment: "string",
  TotFixedAsset: "number",
  TotCurrentAsset: "number",
  TotLiabilityProv: "number",
  TotLoanAdvance: "number",
  TotTradeInvestment: "number",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | Accessor<ReportTableSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return REPORT_TABLE_SCHEMA[accessor];
};

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    Header: "Tax Year",
    accessor: "RtnPeriodYear",
    align: "center",
    sort: true,
    Cell: ({ cell: { value } }) => {
      return <>{value?.replace("-", "/")}</>;
    },
  },
  {
    Header: "Return No.",
    accessor: "RtnNo",
    align: "center",
    Cell: ({ cell: { value } }) => {
      const { url } = useRouteMatch();
      return (
        <Link to={`${url}/${value}/balance-sheet`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Amendment?",
    accessor: "IsAmendment",
    align: "center",
  },
  {
    Header: "Total Fixed Assets (UGX)",
    accessor: "TotFixedAsset",
    align: "right",
    format: "currency",
  },
  {
    Header: "Total Investments (UGX)",
    accessor: "TotTradeInvestment",
    align: "right",
    format: "currency",
  },
  {
    Header: "Total Current Assets (UGX)",
    accessor: "TotCurrentAsset",
    align: "right",
    format: "currency",
  },
  {
    Header: "Total Loans and Advances (UGX)",
    accessor: "TotLoanAdvance",
    align: "right",
    format: "currency",
  },
  {
    Header: "Total Current Liabilities and Provisions (UGX)",
    accessor: "TotLiabilityProv",
    align: "right",
    format: "currency",
  },
];
