/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  withStyles,
  Button as ButtonBase,
  CircularProgress,
} from "@material-ui/core";

const ToolbarButton = withStyles((theme: any) => ({
  root: {
    textTransform: "capitalize",
    fontSize: theme.table.toolbar.fontSize,
    border: theme.table.tablePagination.inputBorder,
    marginLeft: theme.spacing(3),
    "&:hover": {
      border: theme.table.tablePagination.inputBorder,
    },
  },
}))(ButtonBase);

const IndicatorProgress = withStyles(() => ({
  root: {
    position: "absolute",
    top: "10%",
    left: "35%",
    zIndex: 1,
  },
}))(CircularProgress);

export default {
  ToolbarButton,
  IndicatorProgress,
};
