// Dependencies
import React from "react";

// Components
import PAndL from "./p-and-l/p-and-l.component";
import BalanceSheetMain from "./balance-sheet-main/balance-sheet-main.component";

// Assets
import SC from "./tax-history.styles";

export interface TaxHistoryProps {
  tin: string;
}

const TaxHistory: React.FC<TaxHistoryProps> = ({ tin }) => {
  return (
    <SC.SwitchViewsBase
      LeftView={<PAndL tin={tin} />}
      leftViewName="P & L"
      RightView={<BalanceSheetMain tin={tin} />}
      rightViewName="Balance Sheet"
    />
  );
};

export default TaxHistory;
