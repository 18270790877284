/* eslint-disable @typescript-eslint/ban-ts-comment */
// dependencies
/* eslint-disable no-alert */
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import _ from "lodash";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rcapture_RpTtenants_AggregateArgs,
  useRCapturePropertyTenantsQuery,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

// Utils
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import { columns } from "./tenants.schema";

// Assets
import SC from "./tenants.styles";

const TABLE_NAME = "rtcs_db_Fnrcapture_RPTtenants";

const RCaptureTenants: React.FC = () => {
  const { tenantId: Id } = useParams<{ tenantId: string }>();

  const queryVariables: Query_RootRtcs_Db_Ph_Rcapture_RpTtenants_AggregateArgs =
    {
      where: { Id: { _eq: Id } },
    };

  // Tenants
  const { data, loading, error } = useRCapturePropertyTenantsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const reportData = useMemo(() => {
    if (data?.rtcs_db_Fnrcapture_RPTtenants.length === 0) return [];

    const tenantData = data?.rtcs_db_Fnrcapture_RPTtenants[0];

    if (!tenantData) return [];

    const newData = _.omit(tenantData, "__typename");

    const tenants = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(newData)) {
      tenants.push({
        question: key,
        answertext: value,
      });
    }

    return tenants;
  }, [data]);

  return (
    <>
      <SC.ProgressIndicator open={loading || exportLoading} />
      <SC.Breadcrumb label="MDA" />
      <SC.Table
        title="Tenant Info"
        actionsOnRight={[]}
        onAction={() => alert("under construction")}
        persistenceId="d69bb519-7d8c-4497-b152-a7cf2394e00e"
        // @ts-ignore
        columns={columns}
        isPaginationHidden
        initialRowsPerPage={68}
        data={reportData}
        exportData={exportDataFn}
      />
    </>
  );
};

export default RCaptureTenants;
