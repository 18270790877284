import React from "react";

import SC from "./lightbox.styles";

const LightBox: React.FC = () => {
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      {/* <button type="button" onClick={handleOpen}>
        react-transition-group
      </button> */}
      <SC.Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={SC.Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <SC.Fade in={open}>
          <div>
            <h2 id="transition-modal-title">Transition modal</h2>
            <p id="transition-modal-description">
              react-transition-group animates me.
            </p>
          </div>
        </SC.Fade>
      </SC.Modal>
    </div>
  );
};

export default LightBox;
