/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Box,
  Grid,
  withStyles,
  Typography,
  List,
  Link,
  ListItem,
  ListItemText as ListItemTextBase,
  Input,
} from "@material-ui/core";

import Autocomplete from "components/search-box/search-box.component";

import {
  ExpandLess,
  ExpandMore,
  PhotoLibrary,
  ArrowBack,
  Home,
  Search,
  Menu,
  Clear,
} from "@material-ui/icons";

const MapContainer = withStyles(() => ({
  root: {
    height: "85vh",
    width: "100%",
  },
}))(Box);

const ArrowBackIcons = withStyles(() => ({
  root: {
    color: "#fff",
    cursor: "pointer",
    marginRight: "10px",
  },
}))(ArrowBack);

const ClearIcon = withStyles(() => ({
  root: {
    cursor: "pointer",
  },
}))(Clear);

const HomeIcons = withStyles(() => ({
  root: {
    color: "#fff",
    cursor: "pointer",
  },
}))(Home);

const MenuIcons = withStyles(() => ({
  root: {
    color: "#fff",
    cursor: "pointer",
  },
}))(Menu);

const SearchIcons = withStyles(() => ({
  root: {
    color: "#fff",
    cursor: "pointer",
    marginLeft: "15px",
  },
}))(Search);

const Container = withStyles(() => ({
  root: {
    width: "100%",
  },
}))(Grid);

const MapContentContainer = withStyles(() => ({
  root: {
    width: "15rem",
    textAlign: "left",
  },
}))(Box);

const MapContentLabel = withStyles(() => ({
  root: {
    fontSize: "1rem",
    opacity: "80%",
    fontWeight: "bold",
    textAlign: "left",
  },
}))(Typography);

const MapContentLink = withStyles(() => ({
  root: {
    fontSize: "1rem",
    cursor: "pointer",
    fontWeight: "bold",
    color: "#4681AF",
    textAlign: "left",
  },
}))(Link);

const ImageContainer = withStyles(() => ({
  root: {
    width: "100%",
    height: "7rem",
    marginBottom: "1rem",
    marginTop: "1.3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}))(Box);

const HeaderContainer = withStyles(() => ({
  root: {
    height: "5.7rem",
  },
}))(Grid);

const TitleBold = withStyles((theme: any) => ({
  root: {
    fontSize: theme.map.title.fontSize,
    textAlign: theme.map.title.textAlign,
    marginLeft: theme.map.title.marginLeft,
    fontWeight: theme.map.title.fontWeight,
    wordWrap: "break-word",
  },
}))(Typography);

const Title = withStyles((theme: any) => ({
  root: {
    fontSize: theme.map.title.fontSize,
    wordWrap: "break-word",
  },
}))(Typography);

const ListContainer = withStyles(() => ({
  root: {
    width: "100%",
    maxHeight: "65vh",
    overflow: "hidden",
    overflowY: "scroll",
  },
}))(List);

const GridIconContainer = withStyles(() => ({
  root: {},
}))(Grid);

const ControlledListItem = withStyles(() => ({
  root: {
    paddingLeft: "2rem",
    cursor: "pointer",
  },
  selected: {
    border: "0.5rem solid #4681AF",
    paddingLeft: "2rem",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#fff !important",
  },
}))(ListItem);

const ListItemTextSelected = withStyles(() => ({
  root: {
    fontSize: "1.14rem",
    fontWeight: "bolder",
    color: "#4681AF",
  },
}))(ListItemTextBase);

const ListItemText = withStyles(() => ({
  root: {
    fontSize: "1.14rem",
    width: "100%",
    display: "block",
  },
}))(ListItemTextBase);

const Icon = withStyles(() => ({
  root: {
    opacity: 0.7,
    fontSize: "4rem",
  },
}))(PhotoLibrary);

const SearchInput = withStyles(() => ({
  root: {
    width: "100%",
  },
}))(Input);

const SearchContainer = withStyles(() => ({
  root: {
    marginBottom: "0.7rem",
    paddingLeft: "3.21rem",
  },
}))(Grid);

export default {
  Box,
  Grid,
  MapContainer,
  HeaderContainer,
  TitleBold,
  Title,
  ListContainer,
  ListItem,
  ControlledListItem,
  ListItemText,
  ListItemTextSelected,
  GridIconContainer,
  ExpandLess,
  ExpandMore,
  Icon,
  ImageContainer,
  MapContentContainer,
  MapContentLabel,
  MapContentLink,
  Container,
  Autocomplete,
  ArrowBackIcons,
  HomeIcons,
  SearchIcons,
  MenuIcons,
  ClearIcon,
  SearchContainer,
  SearchInput,
};
