import { TableColumn } from "components/table/table.component";
import { KccaCustomerPrimaryOwnerFragment } from "graphql/hasura/types-and-hooks";

export type KccaCustomerPrimaryOwnerTableSchema = Omit<
  KccaCustomerPrimaryOwnerFragment,
  "__typename"
>;

export const kccaCustomerPrimaryOwnerTableColumns: TableColumn<KccaCustomerPrimaryOwnerTableSchema>[] =
  [
    {
      Header: "COIN",
      accessor: "Coin",
      align: "right",
      format: "string",
    },
    {
      Header: "First Name",
      accessor: "Firstname",
      align: "left",
      format: "string",
    },
    {
      Header: "Middle Name",
      accessor: "MiddleName",
      align: "left",
      format: "string",
    },
    {
      Header: "Surname",
      accessor: "Surname",
      align: "left",
      format: "string",
    },
    {
      Header: "Mother Maiden Name",
      accessor: "MotherMaidenName",
      align: "left",
      format: "string",
    },
    {
      Header: "Business Name",
      accessor: "BusinessName",
      align: "left",
      format: "string",
    },
    {
      Header: "Legal Name",
      accessor: "EntityLegalName",
      align: "left",
      format: "string",
    },
    {
      Header: "Customer TIN",
      accessor: "Tin",
      align: "center",
      format: "string",
    },
    {
      Header: "Customer ID",
      accessor: "Id",
      align: "center",
      format: "string",
    },
  ];

// query {
//   ExportData(
//      tableName: "rtcs_db_FnRPT_IndividualComposite",
//         tableColumns: [
//           {
//           fieldName: "Rnid",
//           heading: "Rnid"
//         },
//           {
//           fieldName: "Surname",
//           heading: "Surname"
//         }
//         ],
//         tableFilterJSONString: "{\"orderBy\":{\"Rnid\":\"asc\"},\"limit\":25,\"offset\":0,\"where\":{\"_and\":[{\"_or\":[{\"Surname\":{\"_eq\":\"MUKWAYA\"}},{\"Surname\":{\"_eq\":\"Mukwaya\"}}]}]}}"
//                                "{\"orderBy\": {\"Rnid\":\"desc\"}, \"limit\":10000, \"offset\":0, \"where\": {\"_and\": [ {\"_or\": [ {\"Rnid\":{\"_eq\":\"IND0000005TZZ\"}}, {\"Rnid\":{\"_eq\":\"Ind0000005tzz\"}} ] } ] } }"
//   )
// }
