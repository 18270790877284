/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  Grid as GridBase,
  Container as ContainerBase,
  ContainerProps,
  makeStyles,
} from "@material-ui/core";

import LabelKeyValueBase from "components/label-key-value/label-key-value.component";
import TableBase from "components/table/table.component";

const Grid = GridBase;

interface CustomContainerProps extends ContainerProps {
  maxHeight?: number;
}

const useContainerStyles = makeStyles({
  root: {
    overflowX: "auto",
    maxHeight: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
    height: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
  },
});

const Container = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => {
    const { maxHeight, ...muiProps } = props;
    const classes = useContainerStyles(props);
    return <ContainerBase className={classes.root} {...muiProps} ref={ref} />;
  }
);

const LabelKeyValue = LabelKeyValueBase;

const Table = TableBase;

export default {
  Table,
  Grid,
  Container,
  LabelKeyValue,
};
