/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Toolbar as ToolbarBase,
  Box,
  Typography,
  TablePagination as TablePaginationBase,
} from "@material-ui/core";

// Components
import TableToolbarActions from "components/table-toolbar-actions/table-toolbar-actions.component";
import TableToolbarButton from "components/table-toolbar-button/table-toolbar-button.component";

const Toolbar = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(0, 2),
    justifyContent: "space-between",
  },
}))(ToolbarBase) as typeof ToolbarBase;

const TitleActionsContainer = withStyles({
  root: {
    display: "inline-flex",
    alignItems: "center",
  },
})(Box);

const TitleWrapper = withStyles((theme: any) => ({
  root: {
    marginRight: theme.spacing(1),
  },
}))(Box);

const Title = withStyles((theme: any) => ({
  root: {
    fontWeight: theme.table.toolbar.fontWeight,
    fontSize: theme.table.toolbar.fontSize,
  },
}))(Typography);

const TablePagination = withStyles((theme: any) => ({
  caption: theme.typography.subtitle1,
  input: {
    border: theme.table.tablePagination.inputBorder,
    backgroundColor: theme.palette.background.default,
  },
  selectIcon: {
    color: theme.table.tablePagination.selectIconColor,
  },
  root: {
    "& .MuiTablePagination-caption": {
      fontSize: theme.table.toolbar.fontSize,
    },
  },
}))(TablePaginationBase) as typeof TablePaginationBase;

export default {
  Toolbar,
  TitleActionsContainer,
  TitleWrapper,
  Title,
  TableToolbarButton,
  TablePagination,
  TableToolbarActions,
};
