import React from "react";

// Components
import ExpandedMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandedLessIcon from "@material-ui/icons/ExpandLess";

// Styles
import SC from "./expand-all-button.styles";

export interface ExpandAllButtonProps {
  onExpandAll?: (toogle: boolean) => void;
}

export const ExpandAllButton: React.FC<ExpandAllButtonProps> = ({
  onExpandAll,
}: ExpandAllButtonProps) => {
  const [expand, setExpand] = React.useState(false);

  const onClick = () => {
    const toogle = !expand;
    setExpand(toogle);
    if (onExpandAll) {
      onExpandAll(toogle);
    }
  };

  return (
    <SC.Grid container justify="flex-end">
      <SC.IconButton
        onClick={onClick}
        color="inherit"
        disableRipple
        size="small"
      >
        {expand ? (
          <ExpandedLessIcon color="primary" />
        ) : (
          <ExpandedMoreIcon color="primary" />
        )}
        <SC.LabelButtonText color="primary" variant="subtitle2">
          {expand ? "Collapse All" : "Expand All"}
        </SC.LabelButtonText>
      </SC.IconButton>
    </SC.Grid>
  );
};

export default ExpandAllButton;
