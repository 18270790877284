// Dependencies
import {
  withStyles,
  List as ListBase,
  ListItem as ListItemBase,
  ListItemText as ListItemTextBase,
} from "@material-ui/core";

const List = withStyles({
  root: {
    color: "#747474",
  },
})(ListBase);

const ListItem = withStyles({})(ListItemBase) as typeof ListItemBase;

const ListItemText = withStyles({})(ListItemTextBase);

export default {
  List,
  ListItem,
  ListItemText,
};
