// Dependencies
import React from "react";
import { useParams } from "react-router-dom";

// GraphQL
import { useGetPaginatedTenantIncomeDetailQuery } from "graphql/hasura/types-and-hooks";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { CLIENT_NAME } from "graphql/client";

// Component
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import { tenantIncomeDetailColumns } from "./tenant-income-detail.schema";

// Assets
import SC from "./tenant-income-detail.styles";

const TenantIncomeDetail: React.FC = () => {
  const { landlordTin } = useParams<{ landlordTin: string }>();
  const { taxYear } = useParams<{ taxYear: string }>();

  const tenantIncomeDetailQueryVariables = {
    where: {
      _and: [
        { LandlordTin: { _eq: landlordTin } },
        { TaxYear: { _eq: taxYear } },
      ],
    },
  };

  const { data, loading, error } = useGetPaginatedTenantIncomeDetailQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tenantIncomeDetailQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(
        tenantIncomeDetailQueryVariables ?? {}
      ),
      aggregateParams: jsonToStringQueryVariables({
        where: tenantIncomeDetailQueryVariables?.where,
      }),
    },
  });

  useAppErrorHandler(error);

  return (
    <SC.Container maxWidth={false}>
      <ProgressIndicator open={loading} />
      {data && (
        <SC.Card>
          <SC.Table
            isPaginationHidden
            initialRowsPerPage={data?.rtcs_db_Fnura_TenantIncomeDetail.length}
            title="Tenant Income Detail"
            actionsOnRight={[
              "fullscreen-expand",
              "hide/show-columns",
              "export-to-excel-sheet/csv",
            ]}
            onAction={() => {}}
            columns={tenantIncomeDetailColumns}
            data={data?.rtcs_db_Fnura_TenantIncomeDetail ?? []}
            persistenceId="dca67d6b-a513-4c1b-aa22-5f7a261a7193-non-tenant-income-detail-1"
          />
        </SC.Card>
      )}
    </SC.Container>
  );
};

export default TenantIncomeDetail;
