/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import _ from "lodash";

import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import { TableColumn } from "components/table/table.component";
import { NonIndividualRiskScoresFragment } from "graphql/hasura/types-and-hooks";

import SC from "./non-individual.styles";

export type ReportTableSchema = Omit<
  NonIndividualRiskScoresFragment,
  "__typename"
>;

export const NONINDIVIDUAL_TABLE_SCHEMA: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  Rnid: "string",
  Taxpayerassumed: "string",
  Tradingname: "string",
  Uratinno: "string",
  Isincomesrcrental: "string",
  Riskscore: "number",
  Compliancecategory: "string",
  Avgadjustedgrossincomeperproperty: "number",
  Grossrentalincome: "number",
  Rtnperiodyear: "string",
  Numproperties: "number",
  Numrentedproperties: "number",
  Averagerateperrentedproperty: "number",
  Riskcode: "string",
  Sumofadjustedincome: "string",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | Accessor<ReportTableSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NONINDIVIDUAL_TABLE_SCHEMA[accessor];
};

export const columns: TableColumn<ReportTableSchema>[] = [
  {
    Header: "RNID",
    accessor: "Rnid",
    align: "left",
    sort: true,
    Cell: ({ cell: { value } }) => (
      <Link to={`/non-individuals/${value}/identification`} color="inherit">
        {value}
      </Link>
    ),
  },
  {
    Header: "Taxpayer name",
    accessor: "Taxpayerassumed",
  },
  {
    Header: "Trading Name",
    accessor: "Tradingname",
  },
  {
    Header: "TIN",
    accessor: "Uratinno",
  },
  {
    Header: "Is Registered for Rental Income?",
    accessor: "Isincomesrcrental",
  },
  {
    Header: "Risk Score",
    accessor: "Riskscore",
    format: "string",
    align: "right",
  },
  {
    Header: "Risk Codes",
    accessor: "Riskcode",
    align: "left",
    Cell: ({
      value,
      row: {
        values: { Rnid },
      },
    }) => {
      return (
        <Link to={`/non-individuals/${Rnid}/analysis`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Estimated Gross Rental Income Per Sources",
    accessor: "Sumofadjustedincome",
    format: "currency",
    align: "right",
  },
  {
    Header: "Declared Gross Rental Income Per Return",
    accessor: "Grossrentalincome",
    format: "currency",
    align: "right",
  },
  {
    Header: "Tax Year",
    accessor: "Rtnperiodyear",
  },
  {
    Header: "Total No. Rental Properties",
    accessor: "Numrentedproperties",
    align: "right",
    Cell: ({
      cell: { value },
      row: {
        values: { Rnid },
      },
    }) => (
      <Link to={`/non-individuals/${Rnid}/properties/rented`} color="inherit">
        {value}
      </Link>
    ),
  },
  {
    Header: "Total No. of Properties",
    accessor: "Numproperties",
    align: "right",
    Cell: ({
      cell: { value },
      row: {
        values: { Rnid },
      },
    }) => (
      <Link to={`/non-individuals/${Rnid}/properties`} color="inherit">
        {value}
      </Link>
    ),
  },
  {
    Header: "Map",
    accessor: "Averagerateperrentedproperty",
    align: "center",
    Cell: ({
      row: {
        values: { Rnid },
      },
    }) => (
      <Link
        to={`/non-individuals/${Rnid}/properties?mapview=visible`}
        color="inherit"
      >
        <SC.RoomIcon color="primary" />
      </Link>
    ),
  },
];
