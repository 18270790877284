/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";

// Assets
import "./LayerHeading.css";
import SC from "./styles";

class LayerHeading extends React.PureComponent {
  render() {
    const { resultsCount = 0 } = this.props;

    return (
      <SC.HeaderContainer container direction="row" alignItems="center">
        <SC.Grid item xs={6}>
          <SC.TitleBold> Properties </SC.TitleBold>
        </SC.Grid>
        <SC.Grid item xs={6}>
          <SC.Title> {resultsCount} RESULTS </SC.Title>
        </SC.Grid>
      </SC.HeaderContainer>
    );
  }
}

export default LayerHeading;
