/* eslint-disable @typescript-eslint/no-explicit-any */
// import { Link as LinkButton } from "react-router-dom";
import {
  withStyles,
  Grid,
  Paper as PaperBase,
  Typography,
  Link,
} from "@material-ui/core";

const Container = withStyles((theme: any) => ({
  root: {
    marginTop: theme.reportCard.padding,
    alignItems: "flex-start",
  },
}))(Grid);

const Paper = withStyles((theme: any) => ({
  root: {
    border: theme.reportCard.border,
    padding: theme.reportCard.padding,
  },
}))(PaperBase);

const Label = withStyles((theme: any) => ({
  root: {
    fontSize: theme.reportCard.fontSize,
    textAlign: "left",
    marginTop: theme.reportCard.padding,
  },
}))(Typography);

const Title = withStyles((theme: any) => ({
  root: {
    fontSize: theme.reportCard.padding,
    fontWeight: "bold",
    textAlign: "left",
    color: "black",
    // "& a": {
    //   textDecoration: "none",
    // color: theme.reportCard.color,
    // color: "black",
    // },
  },
}))(Typography);

const TitleLink = withStyles((theme: any) => ({
  root: {
    display: "block",
    paddingTop: theme.reportCard.padding,
    paddingLeft: theme.reportCard.padding,
    fontSize: theme.reportCard.padding,
    fontWeight: "bold",
    textAlign: "left",
    "& a": {
      color: theme.reportCard.color,
      textDecoration: "none",
    },
  },
}))(Link) as typeof Link;

const TitleWithoutLink = withStyles((theme: any) => ({
  root: {
    fontWeight: theme.table.toolbar.fontWeight,
    fontSize: "1.42rem",
    textAlign: "left",
    marginRight: theme.spacing(1),
    opacity: 0.8,
  },
}))(Typography);

const SubTitle = withStyles((theme: any) => ({
  root: {
    display: "block",
    fontSize: theme.reportCard.padding,
    fontWeight: "bold",
    textAlign: "left",
    marginTop: theme.reportCard.padding,
    marginLeft: theme.reportCard.padding,
    cursor: "pointer",
    color: theme.reportCard.color,
  },
}))(Link);

const Content = withStyles((theme: any) => ({
  root: {
    fontSize: theme.reportCard.padding,
    textAlign: "left",
    paddingTop: theme.reportCard.padding,
  },
}))(Typography);

export default {
  Container,
  Grid,
  Paper,
  Label,
  Title,
  TitleWithoutLink,
  TitleLink,
  SubTitle,
  Content,
};
