/* eslint-disable func-names */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
// Dependencies
import { render } from "react-dom";

// function createElementFromHTML(htmlString) {
//   var div = document.createElement('div');
//
//   if (htmlString) {
//     div.innerHTML = htmlString.trim();
//   }
//
//   // Change this to div.childNodes to support multiple top-level nodes
//   return (htmlString) ? div.firstChild : div;
// }

/**
 * Create an instance of the Popup class. Based on
 * https://developers.google.com/maps/documentation/javascript/examples/overlay-popup
 * @param {google.maps} googleMaps Maps object of google library.
 * @param {google.map} googleMap Map object of the map rendered on web page
 * @return {void}
 */
export function createPopupInstance(googleMaps, googleMap) {
  const defaultOptions = {
    edgeOffset: {
      top: 20,
      right: 20,
      bottom: 20,
      left: 20,
    },
  };

  // NOTE: google.maps.OverlayView is only defined once the Maps API has
  // loaded. That is why Popup is defined inside initMap().
  class Popup extends googleMaps.OverlayView {
    /**
     * A customized popup on the map.
     * @param {!google.map} map Map object.
     * @param {object} options Options object.
     * @constructor
     * @extends {google.maps.OverlayView}
     */
    constructor(map, options = defaultOptions) {
      super();
      this._map = map;
      this._opts = options;
    }

    /**
     * Called when the popup is added to the map.
     * @return {void}
     */
    onAdd() {
      const buttonCloseElement = document.createElement("button");
      buttonCloseElement.setAttribute("type", "button");
      buttonCloseElement.innerHTML = "&#215;";
      buttonCloseElement.classList.add("popup-bubble-close-button");
      buttonCloseElement.addEventListener("click", () => this.close(), false);

      const buttonCloseContainerElement = document.createElement("div");
      buttonCloseContainerElement.classList.add(
        "popup-bubble-close-button-container"
      );
      buttonCloseContainerElement.appendChild(buttonCloseElement);

      const contentContainerElement = document.createElement("div");
      render(this._content, contentContainerElement);

      const contentElement = document.createElement("div");
      contentElement.classList.add("popup-bubble-content");
      contentElement.appendChild(buttonCloseContainerElement);
      // contentElement.appendChild(createElementFromHTML(this._content));
      contentElement.appendChild(contentContainerElement);

      const pixelOffset = document.createElement("div");
      pixelOffset.classList.add("popup-bubble-anchor");
      pixelOffset.appendChild(contentElement);

      this._anchor = document.createElement("div");
      this._anchor.classList.add("popup-tip-anchor");
      this._anchor.appendChild(pixelOffset);

      // Optionally stop clicks, etc., from bubbling up to the map.
      this.stopEventPropagation();
      this.getPanes().floatPane.appendChild(this._anchor);

      this.reposition(contentElement);
    }

    /**
     * Called when the popup is removed from the map.
     * @return {void}
     */
    onRemove() {
      if (this._anchor.parentElement) {
        this._anchor.parentElement.removeChild(this._anchor);
      }
      this._anchor = null;
    }

    /**
     * Called when the popup needs to draw itself.
     * @return {void}
     */
    draw() {
      const divPosition = this.getProjection().fromLatLngToDivPixel(
        this._position
      );
      // Hide the popup when it is far out of view.
      // const display =
      //     Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000 ?
      //       'block' :
      //       'none';

      // if (display === 'block') {
      if (divPosition) {
        this._anchor.style.left = `${divPosition.x}px`;
        this._anchor.style.top = `${divPosition.y}px`;
      }

      // }
      // if (this._anchor.style.display !== display) {
      //   this._anchor.style.display = display;
      // }
    }

    /**
     * Stops clicks/drags from bubbling up to the map.
     * @return {void}
     */
    stopEventPropagation() {
      const anchor = this._anchor;
      anchor.style.cursor = "auto";

      [
        "dblclick",
        "contextmenu",
        "wheel",
        "mousedown",
        "touchstart",
        "pointerdown",
      ].forEach(function (event) {
        anchor.addEventListener(event, function (e) {
          e.stopPropagation();
        });
      });
    }

    // The map inner bounds used for panning and resizing
    getMapInnerBounds() {
      const mb = this.getMap().getDiv().getBoundingClientRect();
      const mib = {
        top: mb.top + this._opts.edgeOffset.top,
        right: mb.right - this._opts.edgeOffset.right,
        bottom: mb.bottom - this._opts.edgeOffset.bottom,
        left: mb.left + this._opts.edgeOffset.left,
      };
      mib.width = mib.right - mib.left;
      mib.height = mib.bottom - mib.top;
      return mib;
    }

    // Pan the Google Map such that the info window is visible
    reposition(contentElement) {
      if (!contentElement) {
        return;
      }

      const mib = this.getMapInnerBounds();
      const wb = contentElement.getBoundingClientRect();

      let dx = 0;
      let dy = 0;
      if (mib.left >= wb.left) {
        dx = wb.left - mib.left;
      } else if (mib.right <= wb.right) {
        dx = wb.left - (mib.right - wb.width);
      }
      if (mib.top >= wb.top) {
        dy = wb.top - mib.top;
      } else if (mib.bottom <= wb.bottom) {
        dy = wb.top - (mib.bottom - wb.height);
      }

      if (dx !== 0 || dy !== 0) {
        this.getMap().panBy(dx, dy);
      }
    }

    /**
     * Verify if the Popup is opened.
     * @return {boolean} True if the Popup is opened. False in other case.
     */
    isOpen() {
      const map = this.getMap();
      return map !== null && typeof map !== "undefined";
    }

    /**
     * Open the Popup.
     * @return {void}
     */
    open() {
      // Note that if we later reattach the map, it will be visible again,
      // because the containing <div> is recreated in the overlay's onAdd() method.
      // if (!this.isOpen()) {
      this.close();
      this.setMap(this._map);
      // }
    }

    /**
     * Close the Popup.
     * @return {void}
     */
    close() {
      if (this.getMap()) {
        // Note: setMap(null) calls OverlayView.onRemove()
        this.setMap(null);
      }
    }

    /**
     * Returns the position where the Popup is displayed.
     * @return {!google.maps.LatLng} Coordinates where the Popup is displayed.
     */
    getPosition() {
      return this._position;
    }

    /**
     * Set the position where the Popup will be displayed.
     * @param {!google.maps.LatLng|google.maps.LatLngLiteral} position Coordinates where the Popup is displayed.
     * @return {void}
     */
    setPosition(position) {
      this._position = new googleMaps.LatLng(position);
    }

    /**
     * Set the content of the Popup.
     * @param {!string} content HTML string that contains the Popup's content.
     * @return {void}
     */
    setContent(content) {
      this._content = content;
    }
  }

  return new Popup(googleMap);
}
