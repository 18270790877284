import React from "react";

// Components
import AccordionBody from "components/accordion-body/accordion-body.component";
import ExpandAllButton from "components/expand-all-button/expnad-all-button.component";

// styles
import { Maybe } from "graphql/types-and-hooks";
import SC from "./list-accordion.styles";

export interface IdentificationTabProps {
  data: {
    index: number;
    source: string;
    customerId?: string;
    fullnames: string;
    data: {
      index: number;
      label: string;
      value: Maybe<string> | undefined;
      format?: "date" | "currency";
    }[];
  }[];
}

const IdentificationTab: React.FC<IdentificationTabProps> = ({
  data,
}: IdentificationTabProps) => {
  const [expand, setExpand] = React.useState(false);

  return (
    <SC.Box>
      <ExpandAllButton onExpandAll={(toogle) => setExpand(toogle)} />
      <SC.BoxInner>
        {data.map((item) => {
          const half = Math.ceil(item.data?.length / 2);
          const leftHalf = item.data?.slice(0, half);
          const rightHalf = item.data?.slice(half);

          return (
            <AccordionBody
              key={item.index}
              title={item.source}
              subtitle={item.fullnames}
              itemsLeft={leftHalf}
              itemsRight={rightHalf}
              expanded={expand}
              customerId={item.customerId ?? ""}
            />
          );
        })}
      </SC.BoxInner>
    </SC.Box>
  );
};

export default IdentificationTab;
