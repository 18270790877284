/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";

import SC from "./styles";

// Assets
import "./SearchControl.css";

class SearchControl extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      value: "",
    };
  }

  handleOnSearchInputChange = (event) => {
    const { onSearchProperty } = this.props;
    this.setState({ value: event.target.value });
    onSearchProperty(event.target.value);
  };

  handleOnSearchInputKeyPress = (event) => {
    if (event.key === "Enter") {
      const { onSearchProperty } = this.props;
      const { value } = this.state;
      if (value !== "") {
        onSearchProperty(value);
      }
    }
  };

  handleOnValueClearRequested = () => {
    const { onSearchProperty } = this.props;
    this.setState({ value: "" });
    onSearchProperty("");
  };

  render() {
    const { value } = this.state;
    const inputProps = {
      placeholder: "Search for a Property",
      value,
      onChange: this.handleOnSearchInputChange,
      onKeyPress: this.handleOnSearchInputKeyPress,
    };

    return (
      <SC.SearchContainer className="InputContainer" container spacing={1}>
        <SC.Grid item xs={10}>
          <SC.SearchInput
            {...inputProps}
            inputProps={{ "aria-label": "description" }}
          />
        </SC.Grid>
        <SC.Grid item xs={2}>
          <SC.ClearIcon onClick={this.handleOnValueClearRequested} />
        </SC.Grid>
      </SC.SearchContainer>
    );
  }
}

export default SearchControl;
