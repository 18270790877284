/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import {
  withStyles,
  makeStyles,
  List,
  ListItem as ListItemBase,
  ListItemProps,
  ListItemIcon as ListItemIconBase,
  ListItemText as ListItemTextBase,
  ListItemTextProps,
  ListItemSecondaryAction as ListItemSecondaryActionBase,
  Divider,
  Collapse as CollapseBase,
  Container,
} from "@material-ui/core";
import {
  ArrowRight as ArrowRightBase,
  ArrowDropDown as ArrowDropDownBase,
} from "@material-ui/icons";
import FormatedValue from "components/formatted-value/formatted-value";

interface CustomListItemIconProps extends ListItemProps {
  level?: number;
  has_items: boolean;
}

interface CustomListItemProps extends ListItemProps {
  level?: number;
  has_items: boolean;
}

interface CustomListItemTextProps extends ListItemTextProps {
  isTitle?: boolean;
}

const useListItemStyles = makeStyles({
  root: {
    borderBottom: "0.03rem solid rgba(0, 0, 0, 0.12)",
    paddingTop: `0.28rem`,
    paddingBottom: "0.28rem",
    paddingLeft: ({ level = 0, has_items }: CustomListItemProps) => {
      return has_items && level <= 1 ? 0 : `${level * 2.28}em`;
    },
  },
});

const ListItem = (props: CustomListItemProps): JSX.Element => {
  const { ...muiProps } = props;
  const classes = useListItemStyles(props);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <ListItemBase className={classes.root} {...muiProps} />;
};
ListItem.defaultProps = {
  level: 0,
};
const ListItemIcon = withStyles({
  root: {
    cursor: "pointer",
    minWidth: "2.2rem",
    visibility: ({ level = 0, has_items }: CustomListItemIconProps) => {
      if (!has_items && level === 0) return "collapse";
      if (level === 0 || (level > 0 && !has_items)) return "collapse";
      if (level > 0 && has_items) return "visible";
      return "hidden";
    },
    display: ({ level = 0, has_items }: CustomListItemIconProps) => {
      if (!has_items && level === 0) return "none";
      if (level === 0 || (level > 0 && !has_items)) return "none";
      return "block";
    },
  },
})(ListItemIconBase);

const ListItemText = withStyles((theme: any) => ({
  root: {
    margin: 0,
    "& .MuiListItemText-primary": {
      fontWeight: ({ isTitle }: CustomListItemTextProps) => {
        if (isTitle) return "bold";
        return "normal";
      },
      fontSize: theme.nestedListItem.fontSize,
      lineHeight: "1.71rem",
    },
  },
}))(ListItemTextBase);

const ListItemFloatingText = withStyles({
  root: {
    flex: "none",
    position: "absolute",
    right: "-19.68rem",
    top: 1,
    marginTop: 0,
    "& .MuiListItemText-primary": {
      fontWeight: "bold",
    },
  },
})(ListItemTextBase);

const ListItemSecondaryAction = withStyles({
  root: {
    right: 0,
  },
})(ListItemSecondaryActionBase);

const Collapse = withStyles(() => ({
  wrapperInner: {
    "& .MuiList-root": {
      paddingBottom: 0,
    },
  },
}))(CollapseBase);

const ArrowRight = withStyles((theme: any) => ({
  root: {
    color: theme.palette.primary.main,
  },
}))(ArrowRightBase);

const ArrowDropDown = withStyles((theme: any) => ({
  root: {
    color: theme.palette.primary.main,
  },
}))(ArrowDropDownBase);

export default {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemFloatingText,
  ListItemSecondaryAction,
  Divider,
  Collapse,
  ArrowRight,
  ArrowDropDown,
  Container,
  FormatedValue,
};
