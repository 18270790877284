/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react-dom";
import { TableColumn } from "components/table/table.component";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { KccaPropertyRegistrationFragment } from "graphql/hasura/types-and-hooks";

export type KccaPropertyRegistrationSchema = Omit<
  KccaPropertyRegistrationFragment,
  "__typename"
>;

export const KccaPropertyRegistrationSchemaMap: Record<
  keyof KccaPropertyRegistrationSchema,
  "string" | "number"
> = {
  Rnid: "string",
  RnidOwner: "string",

  CustomerId: "string",
  LocationAddress: "string",
  Address: "string",
  PlotNo: "string",
  FlatNo: "string",
  HouseNo: "string",
  BlockNo: "string",
  StreetId: "string",
  RoadOthers: "string",
  ParticularsFrontages: "string",
  Village: "string",
  Parish: "string",
  Division: "string",
  PropertyNo: "string",
  SerialNo: "string",
  Latitude: "number",
  Longitude: "number",
  PropertyType: "string",
  PropertySubType: "string",
  PropertyRentedStatus: "string",
  GrossValue: "number",
  RateableValue: "number",
  CurrentRateableValue: "number",
  StatusId: "number",
  CamvId: "string",
  CreatedBy: "string",
  CreatedDate: "string",

  Firstname: "string",
  Surname: "string",
  MotherMaidenName: "string",
  EntityLegalName: "string",
  Birthdate: "string",
  Mobile: "string",
  SecondMobile: "string",
  Email: "string",
  BusinessName: "string",
  Coin: "string",
  MiddleName: "string",
  Tin: "string",

  OwnerTin: "string",
  OwnerFirstname: "string",
  OwnerLegalSurnameMaiden: "string",
  OwnerTitle: "string",
  ContactMobile1: "string",
  ContactLandline: "string",
  ContactEmail: "string",
  AccomodationBedrooms: "string",
};

export const getAccessorType = (
  accessor:
    | keyof KccaPropertyRegistrationSchema
    | Accessor<KccaPropertyRegistrationSchema>
    | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return KccaPropertyRegistrationSchemaMap[accessor];
};

export const propertyTableColumns: TableColumn<KccaPropertyRegistrationSchema>[] =
  [
    {
      Header: "Property RNID",
      accessor: "Rnid",
      align: "center",
      Cell: ({
        value,
        row: {
          values: { Rnid },
        },
      }) => {
        return (
          <Link to={`/properties/${Rnid}/details`} color="inherit">
            {value}
          </Link>
        );
      },
    },
    {
      Header: "Owner RNID",
      accessor: "RnidOwner",
      align: "center",
      Cell: ({
        value,
        row: {
          values: { RnidOwner },
        },
      }) => {
        if (RnidOwner !== null) {
          return RnidOwner.startsWith("IND") ? (
            <Link
              to={`/individuals/${RnidOwner}/identification`}
              color="inherit"
            >
              {value}
            </Link>
          ) : (
            <Link
              to={`/non-individuals/${RnidOwner}/identification`}
              color="inherit"
            >
              {value}
            </Link>
          );
        }
        return null;
      },
    },
    {
      Header: "Property No.",
      accessor: "PropertyNo",
      align: "center",
    },
    {
      Header: "Business Name",
      accessor: "BusinessName",
      align: "left",
      format: "string",
    },
    {
      Header: "COIN",
      accessor: "Coin",
      align: "right",
      format: "string",
    },
    {
      Header: "Legal Name",
      accessor: "EntityLegalName",
      align: "left",
      format: "string",
    },
    {
      Header: "First Name",
      accessor: "Firstname",
      align: "left",
      format: "string",
    },
    {
      Header: "Middle Name",
      accessor: "MiddleName",
      align: "left",
      format: "string",
    },
    {
      Header: "Surname",
      accessor: "Surname",
      align: "left",
      format: "string",
    },
    {
      Header: "Mother Maiden Name",
      accessor: "MotherMaidenName",
      align: "left",
      format: "string",
    },
    {
      Header: "KCCA TIN No.",
      accessor: "Tin",
      align: "center",
      format: "string",
    },
    {
      Header: "Customer ID",
      accessor: "CustomerId",
      align: "center",
      format: "string",
    },
    {
      Header: "Address",
      accessor: "Address",
      align: "center",
      format: "string",
    },
    {
      Header: "Plot No.",
      accessor: "PlotNo",
      align: "center",
      format: "string",
    },
    {
      Header: "Flat No.",
      accessor: "FlatNo",
      align: "center",
      format: "string",
    },
    {
      Header: "House No.",
      accessor: "HouseNo",
      align: "center",
      format: "string",
    },
    {
      Header: "Block No.",
      accessor: "BlockNo",
      align: "center",
      format: "string",
    },
    {
      Header: "Street",
      accessor: "StreetId",
      align: "center",
      format: "string",
    },
    {
      Header: "Village",
      accessor: "Village",
      align: "center",
      format: "string",
    },
    {
      Header: "Parish",
      accessor: "Parish",
      align: "center",
      format: "string",
    },
    {
      Header: "Division",
      accessor: "Division",
      align: "center",
      format: "string",
    },
    {
      Header: "Property Rental Status",
      accessor: "PropertyRentedStatus",
      align: "center",
      format: "string",
    },
    {
      Header: "Gross Value",
      accessor: "GrossValue",
      align: "right",
      format: "currency",
    },
    {
      Header: "Rateable Value",
      accessor: "RateableValue",
      align: "right",
      format: "currency",
    },
    {
      Header: "Current Rateable Value",
      accessor: "CurrentRateableValue",
      align: "right",
      format: "currency",
    },
    {
      Header: "Location Address",
      accessor: "LocationAddress",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Other Road",
      accessor: "RoadOthers",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Frontages",
      accessor: "ParticularsFrontages",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Serial No.",
      accessor: "SerialNo",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Lat.",
      accessor: "Latitude",
      align: "center",
      hiddenColumn: true,
    },
    {
      Header: "Long.",
      accessor: "Longitude",
      align: "center",
      hiddenColumn: true,
    },
    {
      Header: "Property Type",
      accessor: "PropertyType",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Property SubType",
      accessor: "PropertySubType",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Balance",
      accessor: "StatusId",
      align: "right",
      format: "currency",
      hiddenColumn: true,
    },
    {
      Header: "Created By",
      accessor: "CreatedBy",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Created Date",
      accessor: "CreatedDate",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Date of Birth",
      accessor: "Birthdate",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Mobile",
      accessor: "Mobile",
      align: "right",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Mobile 2",
      accessor: "SecondMobile",
      align: "right",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "Email",
      accessor: "Email",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "P1Owner TIN",
      accessor: "OwnerTin",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "P1Owner First Name",
      accessor: "OwnerFirstname",
      align: "left",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "P1Owner Surname/Legal Name",
      accessor: "OwnerLegalSurnameMaiden",
      align: "left",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "P1Title",
      accessor: "OwnerTitle",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "P1Mobile No",
      accessor: "ContactMobile1",
      align: "right",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "P1Landline No",
      accessor: "ContactLandline",
      align: "right",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "P1Email",
      accessor: "ContactEmail",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
    {
      Header: "P1Bedrooms",
      accessor: "AccomodationBedrooms",
      align: "center",
      format: "string",
      hiddenColumn: true,
    },
  ];
