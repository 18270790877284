// Dependencies
import React, { useMemo } from "react";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import { ColumnInstance } from "react-table";
import useExportData from "exports/useExportData";
import {
  Query_RootRtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividualArgs,
  useNonIndividualIncomeTaxSummariesQuery,
  Order_By,
} from "graphql/hasura/types-and-hooks";

// Utils
import { useAppErrorHandler } from "errors/app.errors";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { columns as reportColumns } from "./p-and-l.schema";

// Assets
import SC from "./p-and-l.styles";

export interface PAndLProps {
  tin: string;
}

const persistenceId = "dab529ec-1354-46df-8e7a-e1ec8ad1ad8a";

const TABLE_NAME = "rtcs_db_Fnura_IncomeTaxSummaryNonIndividual";

const PAndL: React.FC<PAndLProps> = ({ tin }) => {
  const queryVariables: Query_RootRtcs_Db_Ph_Ura_IncomeTaxSummaryNonIndividualArgs =
    {
      where: { TinNo: { _eq: tin } },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      orderBy: { RtnPeriodYear: Order_By.Desc, RtnDt: Order_By.Desc },
    };

  const { data, loading, error } = useNonIndividualIncomeTaxSummariesQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tin,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const taxHistoryColumns = useMemo(() => reportColumns, []);

  const taxHistoryData = useMemo(() => {
    return data?.rtcs_db_FnRPT_NonIndividualTaxSummaryData || [];
  }, [data]);

  return (
    <>
      {loading && <SC.ProgressIndicator open />}
      <SC.Table
        stickyHeader
        title="Tax History"
        actionsOnRight={["hide/show-columns", "export-to-excel-sheet/csv"]}
        // eslint-disable-next-line no-alert
        onAction={() => alert("under construction")}
        persistenceId={persistenceId}
        enableMultiSort
        columns={taxHistoryColumns}
        data={taxHistoryData}
        paginationControlled={undefined}
        initialRowsPerPage={undefined}
        pageIndexResetSignal={undefined}
        exportData={(columns: ColumnInstance[]) =>
          exportDataFn(columns, undefined, taxHistoryData)
        }
      />
      <SC.ProgressIndicator open={exportLoading} />
    </>
  );
};

export default PAndL;
