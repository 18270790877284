import {
  Query_RootRtcs_Db_Ph_Rpt_NonIndividualBalanceSheetArgs,
  useRpt_NonIndividualBalanceSheetDetailsQuery,
} from "graphql/hasura/types-and-hooks";
import React from "react";
import { useParams } from "react-router-dom";

// GraphQL
import { CLIENT_NAME } from "graphql/client";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Schema
import { useAppErrorHandler } from "errors/app.errors";
import { getStructure } from "./balance-sheet-details.schema";

// Assets
import SC from "./balance-sheet-details.styles";

const BalanceSheetDetails: React.FC = () => {
  const { rtnNO } = useParams<{ rtnNO: string }>();

  const queryVariables: Query_RootRtcs_Db_Ph_Rpt_NonIndividualBalanceSheetArgs =
    {
      where: { RtnNo: { _eq: rtnNO } },
    };

  const { data, loading, error } = useRpt_NonIndividualBalanceSheetDetailsQuery(
    {
      context: { clientName: CLIENT_NAME.HASURA },
      skip: !rtnNO,
      variables: {
        params: jsonToStringQueryVariables(queryVariables ?? {}),
      },
    }
  );

  useAppErrorHandler(error);

  if (!data) return null;

  const structure = getStructure(
    data?.rtcs_db_FnRPT_NonIndividualBalanceSheet[0]
  );

  return (
    <>
      {loading && <SC.ProgressIndicator open />}
      <SC.BalanceSheetAccordion slug="balance-sheet" structure={structure} />
    </>
  );
};

export default BalanceSheetDetails;
