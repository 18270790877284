/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React from "react";
import { Link } from "react-router-dom";
import { Accessor } from "react-table";
import _ from "lodash";
import { TableColumn } from "components/table/table.component";
import { NonIndividualCompliancePlusRiskReportFragment } from "graphql/hasura/types-and-hooks";
import FormattedValue from "components/formatted-value/formatted-value";

export type ReportTableSchema = Omit<
  NonIndividualCompliancePlusRiskReportFragment,
  | "__typename"
  | "Rentincincome"
  | "Rtnno"
  | "Isamendment"
  | "Difference"
  | "Kccacustomerid"
  | "Tinno"
  | "Mobilenumber"
  | "Sumgrossvalue"
  | "Emailid"
  | "Grossrentalincome"
  | "Compliancecategory"
  | "Formerbusinessname"
  | "LastUpdated"
  | "Percentagedifference"
  | "Tradingcenter"
  | "Uraname"
  | "Sumrateablevalue"
  | "Sumrateablevalue"
  | "RiskCode"
>;

export const NON_INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP: Record<
  keyof ReportTableSchema,
  "string" | "number"
> = {
  Rnid: "string",
  Kccaname: "string",
  Matchscore: "number",
  Uratinno: "string",
  Isincomesrcrental: "number",
  Numproperties: "number",
  Numrentedproperties: "number",
  Rtnperiodyear: "string",
  Sumofadjustedincome: "number",
  Averagerateperrentedproperty: "number",
  Source: "string",
  RiskScore: "number",
};

export const getAccessorType = (
  accessor: keyof ReportTableSchema | Accessor<ReportTableSchema> | undefined
) => {
  if (!accessor || _.isFunction(accessor)) return undefined;

  return NON_INDIVIDUAL_TABLE_SCHEMA_DATA_TYPE_MAP[accessor];
};

export const taxpayersColumns: TableColumn<ReportTableSchema>[] = [
  {
    Header: "RNID",
    accessor: "Rnid",
    align: "center",
    Cell: ({ cell: { value } }) => (
      <Link to={`/non-individuals/${value}/identification`} color="inherit">
        {value}
      </Link>
    ),
  },
  {
    Header: "Risk Score",
    accessor: "RiskScore",
    align: "center",
    sort: true,
  },
  {
    Header: "Source",
    accessor: "Source",
    align: "center",
    format: "string",
  },
  {
    Header: "Entity Legal Name",
    accessor: "Kccaname",
    align: "left",
    format: "string",
  },
  {
    Header: "rMATCH Score",
    accessor: "Matchscore",
    align: "center",
    Cell: ({
      value,
      row: {
        values: { Rnid },
      },
      cell,
    }) => {
      let cellValue = Number(cell.value);
      if (Number(cell.value) === 0) return 0;

      if (cellValue > 0) {
        cellValue /= 100;
      }

      return (
        <Link to={`/non-individuals/${Rnid}/analysis`} color="inherit">
          {cell.row.id === "0" ? (
            <FormattedValue format="percent" value={cellValue} />
          ) : (
            <FormattedValue format="number" value={Number(value)} />
          )}
        </Link>
      );
    },
  },
  {
    Header: "TIN No.",
    accessor: "Uratinno",
    align: "center",
    format: "string",
  },
  {
    Header: "Rental Income?",
    accessor: "Isincomesrcrental",
    align: "center",
    format: "string",
  },
  {
    Header: "Tax Year",
    accessor: "Rtnperiodyear",
    align: "center",
  },
  {
    Header: "Total Number of Properties",
    accessor: "Numproperties",
    align: "center",
    Cell: ({
      cell: { value },
      row: {
        values: { Rnid },
      },
    }) => (
      <Link to={`/non-individuals/${Rnid}/properties`} color="inherit">
        {value}
      </Link>
    ),
  },
  {
    Header: "Total Number of Rental Properties",
    accessor: "Numrentedproperties",
    align: "center",
    Cell: ({
      cell: { value },
      row: {
        values: { Rnid },
      },
    }) => (
      <Link to={`/non-individuals/${Rnid}/properties/rented`} color="inherit">
        {value}
      </Link>
    ),
  },
  {
    Header: "Total Adjusted Gross Rental Income",
    accessor: "Sumofadjustedincome",
    align: "right",
    format: "currency",
  },
  {
    Header: "Average Rate Per Rented Property",
    accessor: "Averagerateperrentedproperty",
    align: "right",
    format: "currency",
  },
];
