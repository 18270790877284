/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/sort-comp */
/* eslint-disable react/require-default-props */
/* eslint-disable react/static-property-placement */
// Dependencies
import React, { Component } from "react";
// import PropTypes from 'prop-types';

import {
  List,
  ListItem as ListItemBase,
  ListItemText,
  withStyles,
} from "@material-ui/core";

// Shapes & Types
import { gMapTypeIdType } from "./propTypesShapes";

// Assets
import "./index.css";

// Constants
import { SETTINGS, MAP } from "./constants";

// Utils
import { isFirstRender } from "../shared/utils/data";

const ListItem = withStyles(() => ({
  root: {
    cursor: "pointer",
  },
}))(ListItemBase);

const settingsOptions = Object.freeze({
  [`${SETTINGS.VIEW}`]: {
    "Street Map": { action: MAP.TYPE_ID.ROADMAP },
    Terrain: { action: MAP.TYPE_ID.TERRAIN },
    Satellite: { action: MAP.TYPE_ID.SATELLITE },
    Hybrid: { action: MAP.TYPE_ID.HYBRID },
  },
});

class Settings extends Component {
  static propTypes = {
    mapTypeId: gMapTypeIdType,
  };

  constructor(props) {
    super(props);
    const { mapTypeId } = props;
    let firstTierStatus = null;

    if (!isFirstRender(settingsOptions)) {
      firstTierStatus = {};

      Object.keys(settingsOptions).forEach((optionName) => {
        firstTierStatus[optionName] = {
          hasChildren: !settingsOptions[optionName].action,
          expanded: false,
          selectedOption: null,
        };

        switch (optionName) {
          case SETTINGS.VIEW: {
            const suboptionName = Object.keys(settingsOptions[optionName]).find(
              (sName) => {
                const suboptionObj = settingsOptions[optionName][sName];
                return suboptionObj.action && suboptionObj.action === mapTypeId;
              }
            );
            firstTierStatus[optionName].selectedOption = suboptionName;
            break;
          }

          default:
            break;
        }
      });
    }

    this.state = { firstTierStatus };

    this.handleFirstTierOnClick = this.handleFirstTierOnClick.bind(this);
    this.handleSecondTierOnClick = this.handleSecondTierOnClick.bind(this);
  }

  handleFirstTierOnClick(optionName) {
    const { firstTierStatus } = this.state;
    const newFirstTierStatus = { ...firstTierStatus };

    Object.keys(newFirstTierStatus).forEach((oName) => {
      newFirstTierStatus[oName] = {
        ...firstTierStatus[oName],
        expanded:
          oName === optionName ? !firstTierStatus[optionName].expanded : false,
      };
    });

    this.setState({ firstTierStatus: newFirstTierStatus });
  }

  selectSuboption(optionName, suboptionName) {
    const { firstTierStatus } = this.state;
    const newFirstTierStatus = { ...firstTierStatus };

    // Object.keys(newFirstTierStatus).forEach(oName => {
    //   newFirstTierStatus[oName] = {
    //     ...firstTierStatus[oName],
    //     selectedOption: oName === optionName
    //       ? suboptionName
    //       : false
    //   };
    // });

    newFirstTierStatus[optionName].selectedOption = suboptionName;

    this.setState({ firstTierStatus: newFirstTierStatus });
  }

  handleSecondTierOnClick(optionName, suboptionName) {
    const { action } = settingsOptions[optionName][suboptionName];

    if (action) {
      this.selectSuboption(optionName, suboptionName);

      switch (optionName) {
        case SETTINGS.VIEW: {
          const { onUpdateMapType } = this.props;
          onUpdateMapType(action);
          break;
        }

        default:
          break;
      }
    }
  }

  render() {
    const { firstTierStatus } = this.state;

    if (isFirstRender(settingsOptions) || isFirstRender(firstTierStatus)) {
      return null;
    }

    return (
      <List>
        {Object.keys(settingsOptions).map((optionName, index) => (
          <ListItem className="MenuNavOption" key={index}>
            <ListItemText
              className={`MenuOptionLink ${
                firstTierStatus[optionName].hasChildren ? "icon-arrow" : ""
              }`}
              data-toggle="collapse"
              aria-expanded={firstTierStatus[optionName].expanded}
              onClick={() => this.handleFirstTierOnClick(optionName)}
            >
              {optionName}
            </ListItemText>

            {firstTierStatus[optionName].hasChildren && (
              <List
                className={`MenuTierContainer collapse list-unstyled ${
                  firstTierStatus[optionName].expanded ? "show" : ""
                }`}
              >
                {Object.keys(settingsOptions[optionName]).map(
                  (suboptionName, index2) => (
                    <ListItem
                      className={`MenuNavOption ${
                        suboptionName ===
                        firstTierStatus[optionName].selectedOption
                          ? "active"
                          : ""
                      }`}
                      key={index2}
                    >
                      {optionName === suboptionName ? (
                        <ListItemText className="MenuOptionContent">
                          {settingsOptions[optionName][suboptionName]} lal
                        </ListItemText>
                      ) : (
                        <ListItemText
                          className="MenuOptionLink"
                          onClick={() =>
                            this.handleSecondTierOnClick(
                              optionName,
                              suboptionName
                            )
                          }
                        >
                          {suboptionName}
                        </ListItemText>
                      )}
                    </ListItem>
                  )
                )}
              </List>
            )}
          </ListItem>
        ))}
      </List>
    );
  }
}

export default Settings;
