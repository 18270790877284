/* eslint-disable react/destructuring-assignment */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/prefer-default-export */
import { TableColumn } from "components/table/table.component";
import React from "react";
import { Link } from "react-router-dom";
import { IndividualPropertyDetailsFragment } from "graphql/hasura/types-and-hooks";

import FormattedValue from "components/formatted-value/formatted-value";
import { formatNumber } from "../../../utils/math";

export const propertiesTableColumns: TableColumn<
  IndividualPropertyDetailsFragment | Record<string, unknown>
>[] = [
  {
    Header: "RNID",
    accessor: "Propertyrnid",
    align: "center",
    Cell: ({
      value,
      row: {
        values: { Propertyrnid },
      },
    }: {
      value: string;
      row: { values: { Propertyrnid: string } };
    }) => {
      return (
        <Link to={`/properties/${Propertyrnid}/details`} color="inherit">
          {value}
        </Link>
      );
    },
  },
  {
    Header: "Source Property ID",
    accessor: "Propertyno",
    // @ts-ignore
    Footer: "Totals",
    align: "center",
    sort: true,
  },
  {
    Header: "Customer ID",
    accessor: "Customerid",
    align: "center",
  },
  {
    Header: "Source",
    accessor: "Source",
    align: "center",
    // @ts-ignore
    Footer: false,
  },
  {
    Header: "Mobile Number",
    accessor: "Mobilenumber",
    format: "string",
  },
  {
    Header: "Owner First Name",
    accessor: "Ownerfirstname",
    format: "string",
  },
  {
    Header: "Owner Legal Surname Maiden",
    accessor: "Ownerlegalsurnamemaiden",
    format: "string",
  },
  {
    Header: "KCCA Firstname",
    accessor: "Kccafirstname",
    format: "string",
  },
  {
    Header: "KCCA Surname",
    accessor: "Kccasurname",
    format: "string",
  },
  {
    Header: "KCCA Middlename",
    accessor: "Kccamiddlename",
    format: "string",
  },
  {
    Header: "KCCA Mobile 1",
    accessor: "Kccamobile1",
    format: "string",
  },
  {
    Header: "KCCA Telephone",
    accessor: "Kccatelephone",
    format: "string",
  },
  {
    Header: "KCCA Mobile 2",
    accessor: "Kccamobile2",
    format: "string",
  },
  {
    Header: "KCCA Birthdate",
    accessor: "Kccabirthdate",
    format: "date",
  },
  {
    Header: "KCCA Email",
    accessor: "Kccaemail",
  },
  {
    Header: "Last Update",
    accessor: "LastUpdated",
    align: "center",
    format: "date",
  },
  {
    Header: "Property Type",
    accessor: "Propertytype",
    align: "center",
  },
  {
    Header: "Sub Property Type",
    accessor: "Subpropertytype",
    align: "center",
  },
  {
    Header: "Plot No.",
    accessor: "Plotno",
    align: "center",
  },
  {
    Header: "Block No.",
    accessor: "Blockno",
    align: "center",
  },
  {
    Header: "House No.",
    accessor: "Houseno",
    align: "center",
  },
  {
    Header: "Flat No.",
    accessor: "Flatno",
    align: "center",
  },
  {
    Header: "Street",
    accessor: "Streetid",
    align: "left",
  },
  {
    Header: "Parish",
    accessor: "Parish",
    align: "left",
  },
  {
    Header: "Address",
    accessor: "Address",
    align: "left",
  },
  {
    Header: "Village",
    accessor: "Village",
    align: "center",
  },
  {
    Header: "Division",
    accessor: "Division",
    align: "center",
  },
  {
    Header: "District",
    accessor: "District",
    align: "center",
  },
  {
    Header: "Rented Status",
    accessor: "Propertyrentedstatus",
    align: "center",
  },
  {
    Header: "Rnid Owner",
    accessor: "Rnidowner",
    align: "center",
  },
  {
    Header: "Gross Value",
    accessor: "Grossvalue",
    align: "right",
    format: "currency",
    // @ts-ignore
    Footer: (info: { rows: unknown[] }) => {
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            // @ts-ignore
            (sum: never, row: { values: { Grossvalue: string } }) =>
              // @ts-ignore
              formatNumber(row.values.Grossvalue) + sum,
            0
          ),
        [info.rows]
      );
      return <FormattedValue format="currency" value={total as number} />;
    },
  },
  {
    Header: "Rateable Value",
    accessor: "Rateablevalue",
    align: "right",
    format: "currency",
    // @ts-ignore
    Footer: (info: { rows: unknown[] }) => {
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            // @ts-ignore
            (sum: never, row: { values: { Rateablevalue: string } }) =>
              formatNumber(row.values.Rateablevalue) + sum,
            0
          ),
        [info.rows]
      );
      return <FormattedValue format="currency" value={total as number} />;
    },
  },
  {
    Header: "Current Rateable Value",
    accessor: "Currentrateablevalue",
    align: "right",
    format: "currency",
    // @ts-ignore
    Footer: (info: { rows: unknown[] }) => {
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            // @ts-ignore
            (sum: never, row: { values: { Currentrateablevalue: string } }) =>
              formatNumber(row.values.Currentrateablevalue) + sum,
            0
          ),
        [info.rows]
      );
      return <FormattedValue format="currency" value={total as number} />;
    },
  },
  {
    Header: "Adjusted Gross Income",
    accessor: "Grossrentvalueadjusted",
    align: "right",
    format: "currency",
    // @ts-ignore
    Footer: (info: { rows: unknown[] }) => {
      const total = React.useMemo(
        () =>
          info.rows.reduce(
            // @ts-ignore
            (sum: never, row: { values: { Grossrentvalueadjusted: string } }) =>
              formatNumber(row.values.Grossrentvalueadjusted) + sum,
            0
          ),
        [info.rows]
      );
      return <FormattedValue format="currency" value={total as number} />;
    },
  },
  {
    Header: "Field Assess Date",
    accessor: "Createddate",
    align: "center",
    format: "date",
  },
  {
    Header: "Latitude",
    accessor: "Latitude",
    align: "center",
  },
  {
    Header: "Longitude",
    accessor: "Longitude",
    align: "center",
  },
  {
    Header: "Customer Matchscore",
    accessor: "Customermatchscore",
    align: "center",
  },
];
