/* eslint-disable no-alert */
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";

// Components
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
} from "components/table/table.component";
import {
  ColumnsProps,
  UniversalFilterResponse,
} from "components/universal-filter/universal-filter.component";
import { TableFilter } from "components/table-filter/table-filter.component";
import Breadcrumb from "components/breadcrumb/breadcrumb.component";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Ura_TenantIncomeDetailArgs,
  useGetPaginatedTenantIncomeDetailQuery,
  Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column,
  Order_By,
} from "graphql/hasura/types-and-hooks";

import useExportData from "exports/useExportData";

import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";

// Utils
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import {
  getAccessorType,
  TenantIncomeDetailColumns,
} from "./tenant-income-detail.schema";

// Assets
import SC from "./tenant-income-detail.styles";

const TABLE_NAME = "rtcs_db_Fnura_TenantIncomeDetail";

const TenantIncomeDetail: React.FC = () => {
  const { landlordTin } = useParams<{ landlordTin: string }>();
  const { taxYear } = useParams<{ taxYear: string }>();

  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Ura_TenantIncomeDetailArgs | undefined
  >({
    where: {
      _and: [
        { LandlordTin: { _eq: landlordTin } },
        { TaxYear: { _eq: taxYear } },
      ],
    },
  });

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);

  const { data, loading, error } = useGetPaginatedTenantIncomeDetailQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  useAppErrorHandler(error || exportError);

  const TenantIncomeDetailData = React.useMemo(() => {
    const DetailData = data?.rtcs_db_Fnura_TenantIncomeDetail ?? [];
    return DetailData;
  }, [data]);

  const columns = React.useMemo(() => TenantIncomeDetailColumns, []);

  const universalColumns: ColumnsProps[] = useMemo(() => {
    return columns.map((column, i) => {
      return {
        index: i,
        label: `${column.Header}`,
        value: `${column.accessor}`,
        type: `${getAccessorType(column.accessor)}`,
      };
    });
  }, [columns]);

  const onUniversalFilterer = ({ condition }: UniversalFilterResponse) => {
    const columnObject = condition[Object.keys(condition)[0]];
    if (columnObject[Object.keys(columnObject)[0]] === "") {
      // eslint-disable-next-line no-param-reassign
      condition = {};
    }
    setQueryVariables((oldQueryVariables) => ({
      ...oldQueryVariables,
      where: condition,
      offset: 0,
    }));
    setPageIndexResetSignal((previousSignal) => !previousSignal);
  };

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    (params) => {
      const { pageIndex, pageSize, sortBy } = params;
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Ura_TenantIncomeDetail_Select_Column.RtnNo,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    data?.rtcs_db_Fnura_TenantIncomeDetail_aggregatecm[0].value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading,
      totalRowsCount,
    }),
    [totalRowsCount, loading, handlePaginatedFetchData]
  );

  return (
    <SC.Box pl={2} pr={2} pt={2}>
      <Breadcrumb
        staticRoutes={[
          { index: 0, path: "/reports", text: "reports" },
          { index: 1, path: "/reports", text: "analytics" },
          { index: 2, path: "/reports", text: "declared vs reported income" },
          { index: 3, path: "/reports", text: "tenant" },
          { index: 4, path: "/reports", text: "detail" },
        ]}
      />
      <Table
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        title="Tenant Income Detail"
        columns={columns}
        data={TenantIncomeDetailData}
        onAction={() => alert("under construction")}
        headerPanel={<SC.Title>Analytics - Tenant Income Detail</SC.Title>}
        leftPanel={
          <TableFilter
            universalFilterColumns={universalColumns}
            onUniversalFilterSubmit={onUniversalFilterer}
          />
        }
        persistenceId="e6ccf8c3-025c-4e9a-aee8-42a2b0919c0d"
        paginationControlled={paginationControlled}
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        pageIndexResetSignal={pageIndexResetSignal}
        stickyHeader
        exportData={exportDataFn}
      />
      <ProgressIndicator open={loading || exportLoading} />
    </SC.Box>
  );
};

export default TenantIncomeDetail;
