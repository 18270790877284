/* eslint-disable @typescript-eslint/no-explicit-any */
// Dependencies
import {
  withStyles,
  Dialog as MuiDialog,
  CircularProgress,
} from "@material-ui/core";

const Dialog = withStyles((theme: any) => ({
  root: {
    "& .MuiBackdrop-root": {
      backgroundColor: theme.progressIndicator.backgroundColor,
    },
  },
  paper: {
    backgroundColor: "transparent",
    boxShadow: "none",
  },
}))(MuiDialog);

const ProgressIndicator = withStyles((theme: any) => ({
  root: {
    margin: theme.spacing(2),
  },
}))(CircularProgress);

export default {
  Dialog,
  ProgressIndicator,
};
