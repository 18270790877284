/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from "react";

// Components
import Table, {
  INITIAL_ROWS_PER_PAGE,
  TableFetchDataFunction,
  VISIBILITY_CELL_ID,
} from "components/table/table.component";
import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import { TableToolbarButtonProps } from "components/table-toolbar-button/table-toolbar-button.component";

// Utils
import {
  isServerError,
  UIError,
  UIErrorCodes,
  useAppErrorHandler,
} from "errors/app.errors";

import {
  FilterCondition,
  TableFilter,
} from "components/table-filter/table-filter.component";
import useExportData from "exports/useExportData";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  EntityFilterName,
  EntityIdType,
  EntityInput,
  Job,
  useGetEntityFilterQuery,
  useSetVisibilityFilterMutation,
} from "graphql/types-and-hooks";

import {
  Order_By,
  Query_RootRtcs_Db_Ph_Ura_RegNonIndividualArgs,
  Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column,
  usePaginatedRegNonIndividualQuery,
} from "graphql/hasura/types-and-hooks";
import useJobStatus from "jobs/useJobStatus";
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { checkVisibleIndividual } from "utils/visibility";

// Styles
import { ColumnInstance } from "react-table";
import SC from "./tax-exempt-organisation.styles";

// Schema
import {
  NonIndividualTableColumns,
  NonIndividualTableSchema,
} from "./tax-exempt-organisation.schema";

const TABLE_NAME = "rtcs_db_Fnura_RegNonIndividual";

export const TaxExemptOrganisation = () => {
  const [queryVariables, setQueryVariables] = React.useState<
    Query_RootRtcs_Db_Ph_Ura_RegNonIndividualArgs | undefined
  >(undefined);

  const [pageIndexResetSignal, setPageIndexResetSignal] = React.useState(false);
  const [filterInputs, setFilterInputs] = useState([]);
  const [entityVariables, setEntityVariables] = useState<
    EntityInput[] | undefined
  >(undefined);

  const {
    data: RegNonIndData,
    loading: RegNonIndLoading,
    error: errorRegNonInd,
  } = usePaginatedRegNonIndividualQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !queryVariables,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
      aggregateParams: jsonToStringQueryVariables({
        where: queryVariables?.where,
      }),
    },
  });

  const [updateVisibilityFn, { data: updateData, error: errorUpdate }] =
    useSetVisibilityFilterMutation();

  const [exportDataFn, { loading: exportLoading, error: exportError }] =
    useExportData(TABLE_NAME, queryVariables);

  const {
    loading: entityLoading,
    data: entityData,
    error: entityError,
  } = useGetEntityFilterQuery({
    skip: !entityVariables,
    variables: {
      filterName: EntityFilterName.TaxExempt,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      entityInputs: entityVariables,
    },
  });

  const [runJob, { jobStatus, jobStatusError, runJobError, jobRunning }] =
    useJobStatus(Job.UpdateEntityFilters);

  const errorHandler = useAppErrorHandler(
    jobStatusError || runJobError || errorUpdate || exportError
  );

  const handlerApplyJobs = React.useCallback(() => {
    runJob().catch((_error) => {
      if (isServerError(_error as Error)) {
        errorHandler(_error as Error);
      } else {
        errorHandler(
          new UIError(
            UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
            "An error has ocurred while applying Tax Exempt permissions"
          )
        );
      }
    });
  }, [errorHandler, runJob]);

  useAppErrorHandler(errorRegNonInd || errorUpdate || entityError);

  useEffect(() => {
    if (RegNonIndData) {
      const nonIndividuals = RegNonIndData.rtcs_db_Fnura_RegNonIndividual || [];
      // @ts-ignore
      const entityInputs: EntityInput[] = nonIndividuals.map((user) => ({
        entityIdType: EntityIdType.Rnid,
        entityId: user.Rnid,
      }));
      setEntityVariables(entityInputs);
    }
  }, [RegNonIndData]);

  const nonIndividuals = React.useMemo(() => {
    if (!RegNonIndData && !entityData) {
      return [];
    }
    const NonIndividuals = RegNonIndData?.rtcs_db_Fnura_RegNonIndividual || [];
    if (entityData) {
      return NonIndividuals.map((user) => ({
        ...user,
        Visible: checkVisibleIndividual(entityData.GetEntityFilter, user.Rnid),
      }));
    }
    return [];
  }, [RegNonIndData, entityData]);

  useEffect(() => {
    if (updateData && updateData?.SetEntityFilter.length > 0) {
      if (filterInputs.length > 0) {
        setFilterInputs([]);
        handlerApplyJobs();
      }
    }
  }, [handlerApplyJobs, updateData, filterInputs]);

  const columns = useMemo(() => NonIndividualTableColumns, []);

  const handlePaginatedFetchData: TableFetchDataFunction = React.useCallback(
    (params) => {
      const { pageIndex, pageSize, sortBy } = params;
      const defaultSortColumn = {
        id: Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column.Rnid,
        desc: true,
      };
      const sortByColumn = sortBy.length > 0 ? sortBy[0] : defaultSortColumn;
      setQueryVariables((oldVariables) => ({
        ...oldVariables,
        orderBy: {
          [sortByColumn.id]: sortByColumn.desc ? Order_By.Desc : Order_By.Asc,
        },
        limit: pageSize,
        offset: pageIndex * pageSize,
      }));
    },
    []
  );

  const totalRowsCount = parseInt(
    RegNonIndData?.rtcs_db_Fnura_RegNonIndividual_aggregatecm[0].value ?? "-1",
    10
  );

  const paginationControlled = React.useMemo(
    () => ({
      fetchData: handlePaginatedFetchData,
      loading: RegNonIndLoading,
      totalRowsCount,
    }),
    [totalRowsCount, RegNonIndLoading, handlePaginatedFetchData]
  );

  const onTableFilterSearch = React.useCallback(
    (conditions: FilterCondition[]) => {
      setQueryVariables((oldQueryVariables) => ({
        ...oldQueryVariables,
        where: {
          _and: conditions.length > 0 ? conditions : undefined,
        },
        offset: 0,
      }));
      setPageIndexResetSignal((previousSignal) => !previousSignal);
    },
    []
  );

  const setUpdatedTableData = (values: { Rnid: string; Visible: boolean }) => {
    const hideNonIndividuals = Object.assign(filterInputs);
    hideNonIndividuals.push({
      entityIdType: EntityIdType.Rnid,
      entityId: values.Rnid,
      filterValue: values.Visible ? 1 : 0,
    });
    setFilterInputs(hideNonIndividuals);
  };

  const saveButtonProps = React.useMemo(
    () =>
      ({
        children: "Save Changes",
        isLoading: !jobStatus || jobRunning,
        disabled: !jobStatus || jobRunning,
        onClick: () => {
          if (filterInputs.length > 0) {
            updateVisibilityFn({
              variables: {
                filterName: EntityFilterName.TaxExempt,
                filterInputs,
              },
            });
          }
        },
      } as TableToolbarButtonProps),
    [filterInputs, jobRunning, jobStatus, updateVisibilityFn]
  );

  const dataExport = useCallback(
    (cols: ColumnInstance<NonIndividualTableSchema>[]) =>
      exportDataFn(cols.filter((col) => col.id !== VISIBILITY_CELL_ID)),
    [exportDataFn]
  );

  return (
    <SC.Box mt={3}>
      <Table
        actionsOnRight={[
          "fullscreen-expand",
          "hide/show-columns",
          "filter-results",
          "export-to-excel-sheet/csv",
        ]}
        title="Tax Exempt Organisations"
        paginationControlled={paginationControlled}
        toolbarButtonProps={saveButtonProps}
        renderVisibilityRowComponent
        selectorColumnHeader="Exempt"
        // todo when working on save functionality
        onRowSelect={(values) => setUpdatedTableData(values)}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        columns={columns}
        // todo, to remove any
        data={nonIndividuals as NonIndividualTableSchema[]}
        initialRowsPerPage={INITIAL_ROWS_PER_PAGE.REPORTS}
        // eslint-disable-next-line no-alert
        onAction={() => alert("feature under construction")}
        leftPanel={
          <TableFilter
            searchInputs={[
              {
                label: "TaxPayers Name or TIN number",
                columnNames: [
                  Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column.TinNo,
                  Rtcs_Db_Ph_Ura_RegNonIndividual_Select_Column.TaxPayerName,
                ],
              },
            ]}
            onSearchChange={onTableFilterSearch}
          />
        }
        persistenceId="e0880d91-1215-4ee3-8afe-380881d543c6"
        stickyHeader
        pageIndexResetSignal={pageIndexResetSignal}
        exportData={dataExport}
      />
      <ProgressIndicator
        open={RegNonIndLoading || entityLoading || exportLoading}
      />
    </SC.Box>
  );
};

export default TaxExemptOrganisation;
