// Dependencies
/* eslint-disable no-alert */
import React from "react";
import { useParams } from "react-router-dom";

// GraphQL
import { CLIENT_NAME } from "graphql/client";
import {
  Query_RootRtcs_Db_Ph_Rcapture_RpTpropertiesArgs,
  useRCapturePropertyDetailsQuery,
  Query_RootRtcs_Db_Ph_Rcapture_RpTlessorsArgs,
  useRCapturePropertyLessorsQuery,
  Query_RootRtcs_Db_Ph_Rcapture_RpTtenants_AggregateArgs,
  useRCapturePropertyTenantsQuery,
  Query_RootRtcs_Db_Ph_Rcapture_RptUnittypesArgs,
  useRCapturePropertyUnitTypesQuery,
} from "graphql/hasura/types-and-hooks";

// Utils
import { jsonToStringQueryVariables } from "graphql/hasura/rtcs.utils";
import { useAppErrorHandler } from "errors/app.errors";

// Schema
import {
  lessorColumns,
  tenantColumns,
  unitTypeColumns,
} from "./property-dashboard.schema";

// Assets
import SC from "./property-dashboard.styles";

const RCapturePropertyDashboard: React.FC = () => {
  const { propertyId: id } = useParams<{ propertyId: string }>();

  // Fetch Selected Property
  const queryVariables: Query_RootRtcs_Db_Ph_Rcapture_RpTpropertiesArgs = {
    where: { Id: { _eq: id } },
    limit: 1,
  };

  const lessorQueryVariables: Query_RootRtcs_Db_Ph_Rcapture_RpTlessorsArgs = {
    where: { Propertiesuuids: { _ilike: `%${id}%` } },
  };

  const tenantQueryVariables: Query_RootRtcs_Db_Ph_Rcapture_RpTtenants_AggregateArgs =
    {
      where: { Uuidproperty: { _eq: id } },
    };

  const unittypesQueryVariables: Query_RootRtcs_Db_Ph_Rcapture_RptUnittypesArgs =
    {
      where: { Uuidproperty: { _eq: id } },
    };

  // Property Details
  const {
    data: propertyData,
    error: propertyError,
    loading: propertyLoading,
  } = useRCapturePropertyDetailsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !id,
    variables: {
      params: jsonToStringQueryVariables(queryVariables ?? {}),
    },
  });

  // Property Lessors
  const {
    data: lessorData,
    loading: lessorLoading,
    error: lessorError,
  } = useRCapturePropertyLessorsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !lessorQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(lessorQueryVariables ?? {}),
    },
  });

  // Property Lessors
  const {
    data: tenantData,
    loading: tenantLoading,
    error: tenantError,
  } = useRCapturePropertyTenantsQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !tenantQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(tenantQueryVariables ?? {}),
    },
  });

  // Property Unittypes
  const {
    data: unitTypeData,
    loading: unitTypeLoading,
    error: unitTypeError,
  } = useRCapturePropertyUnitTypesQuery({
    context: { clientName: CLIENT_NAME.HASURA },
    skip: !unittypesQueryVariables,
    variables: {
      params: jsonToStringQueryVariables(unittypesQueryVariables ?? {}),
    },
  });

  useAppErrorHandler(
    propertyError || lessorError || tenantError || unitTypeError
  );

  const reportLessorData = React.useMemo(() => {
    const lessors = lessorData?.rtcs_db_Fnrcapture_RPTlessors ?? [];
    return lessors;
  }, [lessorData]);

  const reportTenantData = React.useMemo(() => {
    const tenants = tenantData?.rtcs_db_Fnrcapture_RPTtenants ?? [];
    return tenants;
  }, [tenantData]);

  const reportUnitTypeData = React.useMemo(() => {
    const unittypes = unitTypeData?.rtcs_db_Fnrcapture_RPTUnittypes ?? [];
    return unittypes;
  }, [unitTypeData]);

  if (
    propertyData &&
    propertyData.rtcs_db_Fnrcapture_RPTproperties.length > 0
  ) {
    const propertyDetails = propertyData.rtcs_db_Fnrcapture_RPTproperties[0];

    return (
      <>
        <SC.ProgressIndicator
          open={
            propertyLoading || lessorLoading || tenantLoading || unitTypeLoading
          }
        />
        <SC.Breadcrumb label="MDA" />
        <SC.Grid
          container
          direction="row"
          spacing={2}
          style={{ width: "100vw", marginTop: "1.14rem" }}
        >
          <SC.Grid item xs={4} style={{ paddingLeft: "1.4rem" }}>
            {propertyDetails.Photo && (
              <SC.Card>
                <SC.CardMedia
                  component="img"
                  alt={`${propertyDetails.Buildingname}`}
                  image={`${propertyDetails.Photo}`}
                />
              </SC.Card>
            )}
            {!propertyDetails.Photo && <SC.ImagePlaceholder />}
          </SC.Grid>
          <SC.Grid item xs={8}>
            <SC.Table
              title="Unit Types"
              actionsOnRight={[]}
              onAction={() => alert("under construction")}
              persistenceId="7ab532b9-487a-466b-8e05-432abc605fc8"
              columns={unitTypeColumns}
              data={reportUnitTypeData}
            />
          </SC.Grid>
          <SC.Grid item xs>
            <SC.Table
              title="Lessors"
              actionsOnRight={[]}
              onAction={() => alert("under construction")}
              persistenceId="0a9ea9cd-62ad-417e-b35f-57f450f944eb"
              columns={lessorColumns}
              data={reportLessorData}
            />
          </SC.Grid>
          <SC.Grid item xs>
            <SC.Table
              title="Tenants"
              actionsOnRight={[]}
              onAction={() => alert("under construction")}
              persistenceId="ae7b794e-1073-400f-92e8-dae5ab6849bd"
              columns={tenantColumns}
              data={reportTenantData}
            />
          </SC.Grid>
        </SC.Grid>
      </>
    );
  }

  return <>No Data Available</>;
};

export default RCapturePropertyDashboard;
