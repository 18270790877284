/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import { makeStyles, Button, ButtonProps } from "@material-ui/core";

const ButtonAdminStyles = makeStyles((theme: any) => ({
  root: {
    textTransform: "none",
    padding: "0",
    borderRadius: "0",
    width: "auto",
    height: "auto",
    alignSelf: "flex-end",
    fontWeight: theme.administrationMenu.fontWeight,
    color: theme.administrationMenu.color,
    [theme.breakpoints.down("xl")]: {
      paddingTop: theme.administrationMenuSmall.paddingTop,
      paddingRight: theme.administrationMenuSmall.paddingRight,
      paddingBottom: theme.administrationMenuSmall.paddingBottom,
      paddingLeft: theme.administrationMenuSmall.paddingLeft,
      borderTopRightRadius: theme.administrationMenuSmall.borderTopRightRadius,
      borderTopLeftRadius: theme.administrationMenuSmall.borderTopLeftRadius,
    },
    [theme.breakpoints.up("xl")]: {
      paddingTop: theme.administrationMenu.paddingTop,
      paddingRight: theme.administrationMenu.paddingRight,
      paddingBottom: theme.administrationMenu.paddingBottom,
      paddingLeft: theme.administrationMenu.paddingLeft,
      borderTopRightRadius: theme.administrationMenu.borderTopRightRadius,
      borderTopLeftRadius: theme.administrationMenu.borderTopLeftRadius,
    },

    "& .MuiButton-label": {
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("xl")]: {
          fontSize: theme.administrationMenuIconSmall.fontSize,
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: theme.administrationMenuIcon.fontSize,
        },
      },
      "& span": {
        [theme.breakpoints.down("xl")]: {
          fontSize: theme.administrationMenuSmall.fontSize,
          lineHeight: theme.administrationMenuSmall.lineHeight,
        },
        [theme.breakpoints.up("xl")]: {
          fontSize: theme.administrationMenu.fontSize,
          lineHeight: theme.administrationMenu.lineHeight,
        },
      },
    },
    "&:hover": {
      background: theme.administrationMenu.background,
    },
  },
  active: {
    background: theme.navigationTabActive.background,
    color: theme.navigationTabActive.color,
    opacity: "1",
    "&:hover": {
      background: theme.navigationTabActive.background,
    },
  },
}));

const ButtonAdmin: React.FC<ButtonProps> = (props) => {
  const { disabled, ...muiProps } = props;
  const classes = ButtonAdminStyles();
  return (
    <Button
      className={disabled ? classes.root : `${classes.root} ${classes.active}`}
      {...muiProps}
    />
  );
};

export default {
  ButtonAdmin,
};
